.news-card {
    // Vars
    $author-image-size: 32px;

    // Support

    // Module
    & {
        height: 100%;
        padding: $sz--card-padding;

        @include mq('<=sm') {
            padding: $sz--card-padding-xs;
        }

        .news-card--figure {
            display: block;
            width: 100%;

            @media print {
                display: none;
            }
        }

        .news-card--content {
            z-index: 1;
            position: relative;
            display: flex;
            flex-wrap: wrap;
            background: $c--monochrome-white;
            border-radius: $sz--border-radius-base;
            min-width: 276px;
            min-height: 268px;

            @include mq('<=sm') {
                min-width: 224px;
                min-height: 236px;
            }

            @media print {
                min-height: 0;
            }
        }


        .news-card--date {
            width: 100%;
            color: $c--monochrome-dark-gray;
            font-size: $fz--x-small;
        }

        .news-card--title {
            width: 100%;
            margin: 0 0 24px;
            color: $c--monochrome-black-gray;

            @include type--h3;
            @include ff--base-medium;
        }

        .news-card--author {
            display: flex;
            align-items: center;
            flex: 0 0 100%;
            margin: auto 0 0;
        }

        .news-card--author-image {
            width: 100%;
        }

        .news-card--author-figure {
            flex: 0 0 $author-image-size;
            width: $author-image-size;
            height: $author-image-size;
            border-radius: 100%;
            overflow: hidden;
        }

        .news-card--author-description {
            font-size: $fz--small;
            color: $c--monochrome-dark-gray;
            margin-left: 12px;
        }
    }

    // Facets
    & {
        &.news-card__next {
            display: flex;
            height: 100%;
            width: 100%;
            color: $c--monochrome-white;
            padding: 0;


            @media print {
                display: none;
            }

            @include ff--base-medium;

            .news-card--content {
                display: flex;
                flex: 1 1 100%;
                align-items: center;
                justify-content: center;
                min-height: 100%;
            }

            /* stylelint-disable max-nesting-depth */
            @each $name, $theme in $themes {
                $theme-color: get-theme-color($name);

                &.news-card__#{$name} {
                    .news-card--content {
                        border: 2px solid $theme-color;
                        color: $theme-color;

                        @include has-focus(true) {
                            color: $c--monochrome-white;
                            background: $theme-color;
                            box-shadow: $shadow-level-4;
                        }
                    }
                }
            }
            /* stylelint-enable max-nesting-depth */
        }

        &.news-card__slider {
            padding: 0;

            @media print {
                width: 100%;
            }

            @include has-focus(true) {
                .news-card--content {
                    box-shadow: $shadow-level-4;
                }
            }

            .news-card--content {
                box-shadow: $shadow-level-3;
                transition: box-shadow $trs--base;
                padding: $sz--card-padding;

                @include mq('<=sm') {
                    padding: $sz--card-padding-xs;
                }
            }

            .news-card--figure {
                @each $value in $grid--custom-1-spacing {
                    @include mq(map-get($value, 'mq')) {
                        $half-gutter: (map-get($value, 'gutter')) / 2;
                        margin-top: -$half-gutter;
                        transform: translate($half-gutter, 0);
                    }
                }
            }
        }

        &.news-card__focus {
            display: flex;
            flex-direction: column;

            .news-card--content {
                flex: 1 1 auto;
                min-height: inherit;
                padding-bottom: $sz--card-padding;
                min-width: 224px;

                @include mq('<=sm') {
                    padding-bottom: $sz--card-padding-xs;
                    width: 224px;
                }
            }

            .news-card--figure {
                align-self: flex-end;
            }
        }

        &.news-card__horizontal,
        &.news-card__news-big,
        &.news-card__news {
            display: flex;
            flex-direction: column;

            .news-card--content {
                flex: 1 1 100%;
                min-height: inherit;
                min-width: inherit;
                padding-top: $sz--card-padding;

                @include mq('<=sm') {
                    padding-top: $sz--card-padding-xs;
                }
            }

            .news-card--figure {
                order: -1;
            }
        }

        &.news-card__news-big {
            @include mq('>lg') {
                .news-card--title {
                    @include type--h2;
                }
            }
        }

        &.news-card__horizontal {
            display: flex;
            flex: 1 1 auto;

            @include mq('>sm') {
                flex-direction: row;

                .news-card--title {
                    @include type--h1;
                }

                .news-card--content {
                    padding-top: 0;
                    padding-right: $sz--card-padding;
                }

                .news-card--figure {
                    order: 1;
                }

                .news-card--content,
                .news-card--figure {
                    flex: 0 0 50%;
                }
            }
        }

        &.news-card__list {
            display: flex;
            padding: 8px 48px 8px 8px;

            @include mq('<=sm') {
                padding-right: 8px;
            }

            .news-card--figure {
                order: -1;
                flex: 0 0 108px;
                width: 108px;
                height: 72px;

                @include mq('<=md') {
                    display: none;
                }
            }

            .news-card--title {
                order: -1;
                margin-bottom: 0;
            }

            .news-card--content {
                display: flex;
                margin-left: 16px;
                flex: 1 1 auto;
                align-items: center;
                min-height: inherit;
                min-width: inherit;

                @include mq('<=md') {
                    margin-left: 8px;
                }
            }

            .news-card--intro {
                display: flex;
                flex-direction: column;
                flex: 1 1 75%;

                @include mq('<=md') {
                    flex: 1 1 100%;
                }
            }

            .news-card--author {
                flex: 1 1 25%;
                padding-left: 24px;
                align-items: center;
                justify-content: flex-end;
                margin: auto;

                @include mq('<=md') {
                    display: none;
                }
            }
        }
    }

    // States
}
