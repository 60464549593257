/// Medium
///
@mixin btn--size-large {
    font-size: 21px;
    padding: 24px 32px 23px;

    @include mq('xl') {
        @include btn--size-medium;
    }

    @include mq('<=lg') {
        @include btn--size-small;
    }
}

@mixin btn--size-medium {
    font-size: 19px;
    padding: 21px 24px 20px;

    @include mq('<=sm') {
        @include btn--size-small;
    }
}

/// Small
///
@mixin btn--size-small {
    padding: 19px 24px 18px;
    font-size: 17px;
}

/// Large bubble
///
@mixin btn--size-bubble-large {
    padding: 12px;
}

/// Medium bubble
///
@mixin btn--size-bubble-medium {
    padding: 8px;
}

/// Small bubble
///
@mixin btn--size-bubble-small {
    padding: 4px;
}
