////
/// List styles (ul, ol)
////

@mixin type--list {
    padding-left: $sz--list-padding;
    list-style: none;

    > li {
        position: relative;
        display: inline-block;
        width: 100%;

        > * { // stylelint-disable selector-max-universal, plugin/selector-bem-pattern
            margin-bottom: 0;
        }

        ~ li {
            margin-top: $sz--list-vertical-spacing;
        }

        &::before {
            position: absolute;
            top: 0;
            right: 100%;
            display: inline-block;
            width: $sz--list-padding;
            height: auto;
            font-size: inherit;

            color: currentColor;
        }

        > ul {
            margin-top: 8px;

            > li::before {
                content: '\25CB';
                transform: none;
            }
        }

        > ol {
            margin-top: 8px;
            padding-left: $sz--list-padding + $sz--list-padding * 0.25;

            > li {
                padding-left: $sz--list-padding * 0.5;

                &::before {
                    width: $sz--list-padding * 1.2;

                    @include ff--base-regular;
                }
            }
        }
    }
}

@mixin type--ul {
    @include type--list;

    > li {
        &::before {
            line-height: 1.31;
            content: '\2022';
            text-align: left;
            transform-origin: left center;
            transform: scale(2);

            @include all-themes-color;
        }

        > ul > li {
            &::before {
                line-height: 1.5;
            }
        }
    }
}

@mixin type--ol {
    @include type--list;
    counter-reset: li;

    > li {
        &::before {
            content: counters(li, '.') '.';
            counter-increment: li;
            text-align: left;

            @include ff--base-medium;
            @include all-themes-color;
        }
    }
}
