.opening-hours-table {
    // Vars

    // Support

    // Module
    & {
        @include type--copy-small;

        thead {
            @include hide-visually;
        }

        td {
            padding-top: 3px;
            padding-bottom: 1px;

            > span {
                display: block;
            }

            &:first-child {
                padding-right: 16px;
            }
        }

        tr:first-child td {
            padding-top: 0;
        }

        tr:last-child td {
            padding-bottom: 0;
        }
    }

    // Facets
    & {
        & {
            .opening-hours-table--tr.opening-hours-table--tr__is-current-day {
                @include ff--base-medium;
                color: $c--monochrome-black-gray;
            }
        }
    }

    // States
}
