.teaser-area {
    // Vars

    // Support

    // Module
    & {
        @include grid--root((row: 'collection', cols: 'item'));
        @include grid--space((row: 'collection', cols: 'item', gutter: 0));

        .teaser-area--main-title {
            @include type--h1;
            @include centered-section-title;
        }

        .teaser-area--collection {
            overflow: hidden;
            border-radius: $sz--border-radius-base;
            box-shadow: $shadow-level-3;
            transition: box-shadow $trs--base;

            @include has-focus(true) {
                box-shadow: $shadow-level-4;
            }
        }

        .teaser-area--item {
            position: relative;
            display: flex;
            // overflow: hidden;
            background: $c--monochrome-white;

            @include grid--span((ratio: 3 / 12, mq: '>md'));
            @include grid--span((ratio: 6 / 12, mq: 'md'));
            @include grid--span((ratio: 12 / 12, mq: '<=sm'));

            @include grid-tiles-borders((
                (mq: '>md', columns: 4),
                (mq: ('md'), columns: 2),
                (mq: '<=sm')
            ), 1px solid rgba($c--monochrome-black, 0.05));

            &::before {
                content: '';
                z-index: 1;
                position: absolute;
                display: block;
            }

            // fancy border-positions
            @include mq('>sm') {
                &::before {
                    top: 0;
                    left: 0;
                    height: 4px;
                    width: calc(100% + 1px);
                }
            }

            @include mq('md') {
                &:nth-last-child(-n+2) {
                    &::before {
                        top: inherit;
                        bottom: 0;
                        left: 0;
                        width: calc(100% + 1px);
                    }
                }
            }

            @include mq('<=sm') {
                &::before {
                    top: 0;
                    left: 0;
                    width: 4px;
                    height: calc(100% + 1px);
                }
            }
        }

        .teaser-area--description {
            color: $c--monochrome-dark-gray;
            margin-bottom: 48px;

            @include type--copy-small;

            @include mq('<=sm') {
                display: none;
            }

            @media print {
                display: block;
                margin-bottom: 0;
            }
        }

        .teaser-area--title {
            @include mq('>sm') {
                margin-bottom: 24px;
            }
        }

        .teaser-area--icon {
            @include mq('>sm') {
                margin-top: auto;
            }

            @include mq('<=sm') {
                padding-left: 8px;
                margin-left: auto;
                margin-right: 0;
            }

            @media print {
                display: none;
            }
        }

        .teaser-area--link {
            display: flex;
            flex: 1 1 100%;
            min-width: 100%;

            @media print {
                display: block;
            }

            @include mq('>sm') {
                padding: 40px 24px 24px;
                flex-direction: column;
            }

            @include mq('<=sm') {
                align-items: center;
                justify-content: space-between;
                padding: 16px 24px;
            }

            @include has-focus(true) {
                color: $c--monochrome-white;
            }
        }
    }

    // Facets
    & {
        /* stylelint-disable max-nesting-depth */
        .teaser-area--item {
            @each $name, $theme in $themes {
                $current-theme-color: get-theme-color($name);

                &.teaser-area--item__#{$name} {
                    &::before {
                        background: $current-theme-color;
                    }

                    .teaser-area--icon {
                        color: $current-theme-color;
                    }

                    .teaser-area--link {
                        @include mq('<=sm') {
                            color: $current-theme-color;
                        }

                        @include has-focus(true) {
                            background: $current-theme-color;

                            .teaser-area--description,
                            .teaser-area--title,
                            .teaser-area--icon {
                                color: $c--monochrome-white;
                            }
                        }
                    }
                }
            }
        }

        /* stylelint-enable max-nesting-depth */
    }

    // States
}
