.protected {
    // Vars
    @mixin lateral-line {
        position: relative;

        &::before {
            content: '';
            position: absolute;
            left: -18px;
            top: 0;
            display: block;
            width: 4px;
            height: 100%;
            border-radius: $sz--border-radius-base;
            background: transparent;

            @include all-themes-background-color('light');

            @include mq('<=xs') {
                left: -11px;
                width: 2px;
            }
        }
    }

    // Support

    // Module
    & {
        margin: 64px 0;

        @media print {
            display: none;
        }

        @include mq('<=md') {
            margin: 48px 0;
        }

        @include lateral-line;

        .protected--title {
            display: flex;
            align-items: center;
            padding-bottom: 16px;
        }

        .protected--icon {
            padding-right: 8px;

            @include all-themes-color('light');

            @include mq('<=xs') {
                transform: scale(0.8);
            }
        }

        .protected--cta {
            border-radius: $sz--border-radius-base;
            box-shadow: $shadow-level-1;
        }


        .protected--actions {
            display: flex;
            flex-wrap: wrap;
        }

        .protected--action {
            display: flex;
            align-items: center;
            flex-direction: column;
            padding: 32px 24px;
            text-align: center;
            color: $c--monochrome-dark-gray;

            @include mq('>sm') {
                flex: 1 1 50%;

                ~ .protected--action {
                    border-left: 1px solid $c--monochrome-paper-white;
                }
            }

            @include mq('<=sm') {
                flex: 1 1 100%;

                ~ .protected--action {
                    border-top: 1px solid $c--monochrome-paper-white;
                }
            }
        }

        .protected--button {
            min-width: 224px;
            margin-top: auto;
        }

        .protected--action-description {
            margin-bottom: 24px;
        }

        .protected--cta-title {
            text-align: center;
            padding: 24px;

            border-bottom: 1px solid $c--monochrome-paper-white;
        }
    }

    // Facets

    // States
}
