////
/// Font inclusion and support library
////

/// Dependencies and components
///
@import 'font__vars';
@import 'font__helpers';
@import 'font__definitions';



// @import must be at top of file, otherwise CSS will not work
@import url('//hello.myfonts.net/count/3894d0');
@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Serif:400i');

/*
@font-face {font-family: 'TTNorms-BlackItalic';src: url('webfonts/3894D0_0_0.eot');src: url('webfonts/3894D0_0_0.eot?#iefix') format('embedded-opentype'),url('webfonts/3894D0_0_0.woff2') format('woff2'),url('webfonts/3894D0_0_0.woff') format('woff'),url('webfonts/3894D0_0_0.ttf') format('truetype');}


@font-face {font-family: 'TTNorms-ExtraBoldItalic';src: url('webfonts/3894D0_1_0.eot');src: url('webfonts/3894D0_1_0.eot?#iefix') format('embedded-opentype'),url('webfonts/3894D0_1_0.woff2') format('woff2'),url('webfonts/3894D0_1_0.woff') format('woff'),url('webfonts/3894D0_1_0.ttf') format('truetype');}


@font-face {font-family: 'TTNorms-Black';src: url('webfonts/3894D0_2_0.eot');src: url('webfonts/3894D0_2_0.eot?#iefix') format('embedded-opentype'),url('webfonts/3894D0_2_0.woff2') format('woff2'),url('webfonts/3894D0_2_0.woff') format('woff'),url('webfonts/3894D0_2_0.ttf') format('truetype');}


@font-face {font-family: 'TTNorms-BoldItalic';src: url('webfonts/3894D0_3_0.eot');src: url('webfonts/3894D0_3_0.eot?#iefix') format('embedded-opentype'),url('webfonts/3894D0_3_0.woff2') format('woff2'),url('webfonts/3894D0_3_0.woff') format('woff'),url('webfonts/3894D0_3_0.ttf') format('truetype');}


@font-face {font-family: 'TTNorms-Bold';src: url('webfonts/3894D0_4_0.eot');src: url('webfonts/3894D0_4_0.eot?#iefix') format('embedded-opentype'),url('webfonts/3894D0_4_0.woff2') format('woff2'),url('webfonts/3894D0_4_0.woff') format('woff'),url('webfonts/3894D0_4_0.ttf') format('truetype');}


@font-face {font-family: 'TTNorms-ExtraBold';src: url('webfonts/3894D0_5_0.eot');src: url('webfonts/3894D0_5_0.eot?#iefix') format('embedded-opentype'),url('webfonts/3894D0_5_0.woff2') format('woff2'),url('webfonts/3894D0_5_0.woff') format('woff'),url('webfonts/3894D0_5_0.ttf') format('truetype');}


@font-face {font-family: 'TTNorms-ExtraLightItalic';src: url('webfonts/3894D0_6_0.eot');src: url('webfonts/3894D0_6_0.eot?#iefix') format('embedded-opentype'),url('webfonts/3894D0_6_0.woff2') format('woff2'),url('webfonts/3894D0_6_0.woff') format('woff'),url('webfonts/3894D0_6_0.ttf') format('truetype');}


@font-face {font-family: 'TTNorms-ExtraLight';src: url('webfonts/3894D0_7_0.eot');src: url('webfonts/3894D0_7_0.eot?#iefix') format('embedded-opentype'),url('webfonts/3894D0_7_0.woff2') format('woff2'),url('webfonts/3894D0_7_0.woff') format('woff'),url('webfonts/3894D0_7_0.ttf') format('truetype');}


@font-face {font-family: 'TTNorms-HeavyItalic';src: url('webfonts/3894D0_8_0.eot');src: url('webfonts/3894D0_8_0.eot?#iefix') format('embedded-opentype'),url('webfonts/3894D0_8_0.woff2') format('woff2'),url('webfonts/3894D0_8_0.woff') format('woff'),url('webfonts/3894D0_8_0.ttf') format('truetype');}


@font-face {font-family: 'TTNorms-Heavy';src: url('webfonts/3894D0_9_0.eot');src: url('webfonts/3894D0_9_0.eot?#iefix') format('embedded-opentype'),url('webfonts/3894D0_9_0.woff2') format('woff2'),url('webfonts/3894D0_9_0.woff') format('woff'),url('webfonts/3894D0_9_0.ttf') format('truetype');}


@font-face {font-family: 'TTNorms-Italic';src: url('webfonts/3894D0_A_0.eot');src: url('webfonts/3894D0_A_0.eot?#iefix') format('embedded-opentype'),url('webfonts/3894D0_A_0.woff2') format('woff2'),url('webfonts/3894D0_A_0.woff') format('woff'),url('webfonts/3894D0_A_0.ttf') format('truetype');}


@font-face {font-family: 'TTNorms-LightItalic';src: url('webfonts/3894D0_B_0.eot');src: url('webfonts/3894D0_B_0.eot?#iefix') format('embedded-opentype'),url('webfonts/3894D0_B_0.woff2') format('woff2'),url('webfonts/3894D0_B_0.woff') format('woff'),url('webfonts/3894D0_B_0.ttf') format('truetype');}


@font-face {font-family: 'TTNorms-Light';src: url('webfonts/3894D0_C_0.eot');src: url('webfonts/3894D0_C_0.eot?#iefix') format('embedded-opentype'),url('webfonts/3894D0_C_0.woff2') format('woff2'),url('webfonts/3894D0_C_0.woff') format('woff'),url('webfonts/3894D0_C_0.ttf') format('truetype');}


@font-face {font-family: 'TTNorms-MediumItalic';src: url('webfonts/3894D0_D_0.eot');src: url('webfonts/3894D0_D_0.eot?#iefix') format('embedded-opentype'),url('webfonts/3894D0_D_0.woff2') format('woff2'),url('webfonts/3894D0_D_0.woff') format('woff'),url('webfonts/3894D0_D_0.ttf') format('truetype');}


@font-face {font-family: 'TTNorms-Medium';src: url('webfonts/3894D0_E_0.eot');src: url('webfonts/3894D0_E_0.eot?#iefix') format('embedded-opentype'),url('webfonts/3894D0_E_0.woff2') format('woff2'),url('webfonts/3894D0_E_0.woff') format('woff'),url('webfonts/3894D0_E_0.ttf') format('truetype');}


@font-face {font-family: 'TTNorms-Regular';src: url('webfonts/3894D0_F_0.eot');src: url('webfonts/3894D0_F_0.eot?#iefix') format('embedded-opentype'),url('webfonts/3894D0_F_0.woff2') format('woff2'),url('webfonts/3894D0_F_0.woff') format('woff'),url('webfonts/3894D0_F_0.ttf') format('truetype');}


@font-face {font-family: 'TTNorms-ThinItalic';src: url('webfonts/3894D0_10_0.eot');src: url('webfonts/3894D0_10_0.eot?#iefix') format('embedded-opentype'),url('webfonts/3894D0_10_0.woff2') format('woff2'),url('webfonts/3894D0_10_0.woff') format('woff'),url('webfonts/3894D0_10_0.ttf') format('truetype');}


@font-face {font-family: 'TTNorms-Thin';src: url('webfonts/3894D0_11_0.eot');src: url('webfonts/3894D0_11_0.eot?#iefix') format('embedded-opentype'),url('webfonts/3894D0_11_0.woff2') format('woff2'),url('webfonts/3894D0_11_0.woff') format('woff'),url('webfonts/3894D0_11_0.ttf') format('truetype');}
*/

/// Font-face definitions
@include font--create('#{$web-static}/fonts/#{$ff--base}/3894D0_F_0', ('woff2', 'woff', 'ttf')) {
    @include ff--base-regular($ff--base);
}

@include font--create('#{$web-static}/fonts/#{$ff--base}/3894D0_A_0', ('woff2', 'woff', 'ttf')) {
    @include ff--base-regular-italic($ff--base);
}

@include font--create('#{$web-static}/fonts/#{$ff--base}/3894D0_E_0', ('woff2', 'woff', 'ttf')) {
    @include ff--base-medium($ff--base);
}

@include font--create('#{$web-static}/fonts/#{$ff--base}/3894D0_3_0', ('woff2', 'woff', 'ttf')) {
    @include ff--base-bold-italic($ff--base);
}
