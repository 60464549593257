&.grid__custom-1 {
    // Vars
    $scrollbar-offset: 16px;
    $grid--custom-1-container-sizes: (
        (mq: 'xxs', container-size: none),
        (mq: 'xs', container-size: none),
        (mq: 'sm', container-size: 544px),
        (mq: 'md', container-size: 744px),
        (mq: 'lg', container-size: 968px),
        (mq: 'xl', container-size: 1244px),
        (mq: 'xxl', container-size: 1576px),
    ) !global;
    $grid--custom-1-spacing: (
        (mq: 'xxs', gutter: 16px, edge: 16px),
        (mq: 'xs', gutter: 16px, edge: 24px),
        (mq: 'sm', gutter: 24px, edge: 28px),
        (mq: 'md', gutter: 24px, edge: 32px),
        (mq: 'lg', gutter: 32px, edge: 32px),
        (mq: 'xl', gutter: 32px, edge: 32px),
        (mq: 'xxl', gutter: 40px, edge: 40px),
    ) !global;

    // Support

    // Module
    & {
        @include grid--root;

        @each $value in $grid--custom-1-container-sizes {
            @include grid--constrain($value);
        }

        @each $value in $grid--custom-1-spacing {
            @include grid--space($value);
        }

        .grid--container.grid--container__fluid {
            max-width: none;
        }
    }

    // Facets

    // States
}
