.main-footer {
    // Vars

    // Support

    // Module
    & {
        .main-footer--divider-col {
            @include grid--span((ratio: 12 / 12));
            margin: 48px 0;
        }

        .main-footer--divider {
            border-top: solid 4px $c--monochrome-paper-white;
        }

        .main-footer--col {
            margin-bottom: 64px;

            @include grid--span((ratio: 3 / 12, mq: '>lg'));
            @include grid--span((ratio: 4 / 12, mq: ('lg')));
            @include grid--span((ratio: 6 / 12, mq: ('md', 'sm')));
            @include grid--span((ratio: 12 / 12, mq: '<=xs'));
        }

        .main-footer--social {
            display: flex;

            @include grid--span((ratio: 6 / 12, mq: '>lg'));
            @include grid--span((ratio: 4 / 12, mq: ('lg', 'md')));
            @include grid--span((ratio: 12 / 12, mq: '<=xs'));

            @include mq(('md', 'sm')) {
                margin-bottom: 0;
            }

            @media print {
                display: none;
            }
        }

        .main-footer--social-list {
            @include grid--span((ratio: 6 / 12, mq: '>lg'));
            @include grid--span((ratio: 12 / 12, mq: '<=lg'));
        }

        .main-footer--social-row {
            flex: 1 1 100%;
            justify-content: space-between;
        }

        .main-footer--links {
            @include mq(('md', 'sm')) {
                order: 1;
            }

            @media print {
                display: none;
            }
        }

        .main-footer--company-name {
            @include ff--base-medium;
        }

        .main-footer--company-list {
            margin-top: 8px;
        }

        .main-footer--company-link {
            display: inline-block;
            padding: 8px 0;
            color: get-theme-color('association');

            @include ff--base-medium;

            @include has-focus(true) {
                color: get-theme-color('association', 'dark');
            }
        }

        .main-footer--address-text {
            font-size: $fz--medium;
            color: $c--monochrome-dark-gray;

            @include ff--base-regular;

            > p {
                @include has-focus(true) {
                    color: $c--monochrome-quasi-black;
                }
            }

            strong {
                color: $c--monochrome-dark-gray;
                line-height: $lh--xx-small;
            }

            a {
                @include ff--base-medium;
            }

            @media print {
                margin-bottom: 24px;
            }
        }

        .main-footer--link-list-item {
            margin-top: -16px;

            @include mq('<=md') {
                margin-top: -24px;
            }

            .main-footer--link {
                display: inline-block;
                padding: 16px 0;

                @include mq('<=md') {
                    padding: 24px 0;
                }
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        .main-footer--link {
            @include ff--base-medium;
            color: $c--monochrome-dark-gray;
            font-size: $fz--medium;
            line-height: $lh--xx-small;

            @include has-focus(true) {
                color: get-theme-color('association', 'dark');
            }
        }
    }

    // Facets

    // States
}
