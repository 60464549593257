.tag-list {
    // Vars

    // Support

    // Module
    & {
        @include grid--root((row: 'collection', cols: 'item'));
        @include grid--space((row: 'collection', cols: 'item', gutter: (16px, 16px)));

        .tag-list--nav {
            display: flex;
            flex-wrap: wrap;

            @include mq('<=md') {
                display: none;
            }
        }

        .tag-list--collection {
            flex: 1 1 100%;
        }

        .tag-list--dropdown {
            position: relative;
            display: none;

            @include mq('<=md') {
                display: flex;
            }

            @include mq('<=xs') {
                justify-content: center;
            }
        }

        .tag-list--link {
            color: $c--monochrome-dark-gray;
            padding: 8px 16px;
            border-radius: 16px;

            @include ff--base-medium;

            @include has-focus(true) {
                color: $c--monochrome-quasi-black;
            }
        }
    }

    // Facets
    & {
        .tag-list--link {
            &.tag-list--link__active {
                background: $c--monochrome-paper-white;
            }
        }
    }

    // States
}
