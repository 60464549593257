.inline-image {
    // Vars

    // Support

    // Module
    & {
        padding: 64px 0;

        @include mq('<=md') {
            padding: 48px 0;
        }

        .inline-image--caption {
            text-align: center;
            margin-top: 16px;
            color: $c--monochrome-dark-gray;

            @include type--copy-small;
        }
    }

    // Facets

    // States
}
