.freeform-image {
    // Vars

    // Support

    // Module
    & {
        & {
            padding: 32px 0;

            @include mq('<=md') {
                padding: 24px 0;
            }
        }

        .freeform-image--img {
            width: auto;
            max-width: 100%;
        }

        .freeform-image--caption {
            margin-top: 8px;
            color: $c--monochrome-dark-gray;

            @include type--copy-small;
        }
    }

    // Facets

    // States
}
