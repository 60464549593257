.canvas-animation {
    // Vars

    // Support

    // Module
    & {
        z-index: -1;
        position: absolute;
        left: 0;
        top: 0;

        @include mq('>md') {
            position: fixed;
        }

        @media print {
            display: none;
        }

        @each $name, $theme in $themes {
            @include at-root($themes-root-el, theme-name-to-css-selector($name)) {
                color: get-theme-color($name);
            }
        }

        @include at-root($themes-root-el, theme-name-to-css-selector('default')) {
            color: get-theme-color('association');
        }
    }

    // Facets

    // States
}
