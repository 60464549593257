.video {
    // Vars

    // Support

    // Module
    & {
        @media print {
            display: none;
        }

        .video--title {
            @include centered-section-title;
        }

        .video--row {
            justify-content: center;
        }

        .video--col {
            @include grid--span((ratio: 10 / 12, mq: '>sm'));
            @include grid--span((ratio: 12 / 12, mq: '<=sm'));
        }

        .video--wrapper {
            position: relative;
            margin: 0 auto;
            height: 0;
            padding-top: 9 / 16 * 100%;
        }

        .video--frame {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            box-shadow: $shadow-level-1;
        }
    }

    // Facets

    // States
}
