$responsive-typography-properties: (
    // headings
    'h1-big': (
        'xxl': (fz: 72px, lh: 1.2,),
        'xl': (fz: 64px, lh: 1.2,),
        'lg': (fz: 48px, lh: 1.2,),
        'md': (fz: 44px, lh: 1.2,),
        'sm': (fz: 40px, lh: 1.2,),
        'xs': (fz: 30px, lh: 1.2,),
        'xxs': (fz: 28px, lh: 1.2,),
    ),
    'h1': (
        'xxl': (fz: 36px, lh: 1.25,),
        'xl': (fz: 35px, lh: 1.25,),
        'lg': (fz: 30px, lh: 1.25,),
        'md': (fz: 28px, lh: 1.25,),
        'sm': (fz: 27px, lh: 1.25,),
        'xs': (fz: 26px, lh: 1.25,),
        'xxs': (fz: 25px, lh: 1.25,),
    ),
    'h2': (
        'xxl': (fz: 29px, lh: 1.25,),
        'xl': (fz: 25px, lh: 1.25,),
        'lg': (fz: 25px, lh: 1.25,),
        'md': (fz: 24px, lh: 1.25,),
        'sm': (fz: 24px, lh: 1.25,),
        'xs': (fz: 22px, lh: 1.3,),
        'xxs': (fz: 21px, lh: 1.4,),
    ),
    'h3': (
        'xxl': (fz: 21px, lh: 1.25,),
        'xl': (fz: 19px, lh: 1.3,),
        'lg': (fz: 17px, lh: 1.3,),
        'md': (fz: 17px, lh: 1.3,),
        'sm': (fz: 17px, lh: 1.3,),
        'xs': (fz: 17px, lh: 1.3,),
        'xxs': (fz: 17px, lh: 1.4,),
    ),
    'h4': (
        'xxl': (fz: 19px, lh: 1.4,),
        'xl': (fz: 17px, lh: 1.4,),
        'lg': (fz: 15px, lh: 1.4,),
        'md': (fz: 15px, lh: 1.4,),
        'sm': (fz: 15px, lh: 1.4,),
        'xs': (fz: 15px, lh: 1.4,),
        'xxs': (fz: 15px, lh: 1.4,),
    ),
    'h5': (
        'xxl': (fz: 17px, lh: 1.4,),
        'xl': (fz: 15px, lh: 1.4,),
        'lg': (fz: 13px, lh: 1.4,),
        'md': (fz: 13px, lh: 1.4,),
        'sm': (fz: 13px, lh: 1.4,),
        'xs': (fz: 13px, lh: 1.4,),
        'xxs': (fz: 13px, lh: 1.4,),
    ),
    'h6': (
        'xxl': (fz: 15px, lh: 1.4,),
        'xl': (fz: 13px, lh: 1.4,),
        'lg': (fz: 11px, lh: 1.4,),
        'md': (fz: 11px, lh: 1.4,),
        'sm': (fz: 11px, lh: 1.4,),
        'xs': (fz: 11px, lh: 1.4,),
        'xxs': (fz: 11px, lh: 1.4,),
    ),
    // content
    'lead': (
        'xxl': (fz: 31px, lh: 1.35,),
        'xl': (fz: 29px, lh: 1.35,),
        'lg': (fz: 25px, lh: 1.35,),
        'md': (fz: 24px, lh: 1.35,),
        'sm': (fz: 24px, lh: 1.35,),
        'xs': (fz: 22px, lh: 1.35,),
        'xxs': (fz: 21px, lh: 1.35,),
    ),
    'quote': (
        'xxl': (fz: 31px, lh: 1.4,),
        'xl': (fz: 29px, lh: 1.4,),
        'lg': (fz: 25px, lh: 1.4,),
        'md': (fz: 24px, lh: 1.4,),
        'sm': (fz: 24px, lh: 1.4,),
        'xs': (fz: 22px, lh: 1.4,),
        'xxs': (fz: 21px, lh: 1.4,),
    ),
    'copy': (
        'xxl': (fz: 21px, lh: 1.44,),
        'xl': (fz: 19px, lh: 1.57,),
        'lg': (fz: 17px, lh: 1.52,),
        'md': (fz: 17px, lh: 1.52,),
        'sm': (fz: 17px, lh: 1.52,),
        'xs': (fz: 17px, lh: 1.52,),
        'xxs': (fz: 17px, lh: 1.52,),
    ),
    'copy-small': (
        'xxl': (fz: 19px, lh: 1.4,),
        'xl': (fz: 17px, lh: 1.4,),
        'lg': (fz: 15px, lh: 1.4,),
        'md': (fz: 15px, lh: 1.4,),
        'sm': (fz: 15px, lh: 1.4,),
        'xs': (fz: 15px, lh: 1.4,),
        'xxs': (fz: 15px, lh: 1.4,),
    ),
    'copy-tiny': (
        'xxl': (fz: 17px, lh: 1.4,),
        'xl': (fz: 15px, lh: 1.4,),
        'lg': (fz: 13px, lh: 1.4,),
        'md': (fz: 13px, lh: 1.4,),
        'sm': (fz: 13px, lh: 1.4,),
        'xs': (fz: 13px, lh: 1.4,),
        'xxs': (fz: 13px, lh: 1.4,),
    ),
    'menu-meta': (
        'xxl': (fz: 15px, lh: 1.4,),
        'xl': (fz: 15px, lh: 1.4,),
        'lg': (fz: 14px, lh: 1.4,),
        'md': (fz: 14px, lh: 1.4,),
        'sm': (fz: 14px, lh: 1.4,),
        'xs': (fz: 14px, lh: 1.4,),
        'xxs': (fz: 14px, lh: 1.4,),
    ),
);

@mixin responsive-typography($type) {
    $font-type: map-get($responsive-typography-properties, $type);

    @each $query, $font-style in $font-type {
        @include mq($query) {
            font-size: map-get($font-style, 'fz');
            line-height: map-get($font-style, 'lh');
        }
    }
}

