.header-landing {
    // Vars

    // Support

    // Module
    & {
        position: relative;
        padding: 64px 0 0;

        @include mq('<=sm') {
            padding: 48px 0;
        }

        .header-landing--title {
            @include type--h1;
        }

        .header-landing--hook {
            position: absolute;
            top: 50%;
        }

        .header-landing--description {
            color: $c--monochrome-dark-gray;
            margin-top: 24px;
        }

        .header-landing--text-col {
            text-align: center;
            margin: 0 auto;

            @include grid--span((ratio: 8 / 12, mq: '>md'));
            @include grid--span((ratio: 10 / 12, mq: 'md'));
            @include grid--span((ratio: 12 / 12, mq: '<=sm'));
        }

        .header-landing--buttons-group {
            margin-top: 8px;
            justify-content: center;
        }

        .header-landing--button {
            margin-top: 32px;

            @include grid--span((ratio: 12 / 12, mq: '<=sm'));

            @include mq('<=sm') {
                display: flex;
                text-align: center;
                flex-direction: column;
            }
        }
    }

    // Facets

    // States
}
