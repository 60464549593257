.event-card {
    // Vars

    // Support

    // Module
    & {
        padding: $sz--card-padding;
        height: 100%;

        @include mq('<=xs') {
            padding: $sz--card-padding-xs;
        }

        .event-card--content {
            display: flex;
            flex-direction: column;
            height: 100%;
        }

        .event-card--intro {
            display: flex;
            margin-bottom: 24px;
            color: $c--monochrome-quasi-black;
        }

        .event-card--title {
            margin-bottom: 16px;

            @include type--h3;
        }

        .event-card--paragraph {
            margin-bottom: 16px;
            color: $c--monochrome-dark-gray;

            @include type--copy-small;
        }

        .event-card--icon {
            margin-top: auto;
        }
    }

    // Facets
    & {
        @each $name, $theme in $themes {
            &.event-card__#{$name} {
                @include has-focus(true) {
                    .event-card--icon {
                        color: get-theme-color($name, 'dark');
                    }
                }

                .event-card--icon {
                    color: get-theme-color($name);
                }
            }
        }
    }

    // States
}
