.news-meta {
    // Vars

    // Support

    // Module
    & {
        @include grid--root((row: 'collection', cols: 'item'));
        @include grid--space((row: 'collection', cols: 'item', gutter: (8px 8px)));

        .news-meta--author {
            display: flex;
            margin-bottom: 24px;
        }

        .news-meta--figure {
            border-radius: 100%;
            overflow: hidden;
            margin-right: 12px;

            @include mq('>sm') {
                @include square(48px);
            }

            @include mq('<=sm') {
                @include square(24px);
            }
        }

        .news-meta--figcaption {
            @include hide-visually;
        }

        .news-meta--text {
            display: flex;

            @include mq('>sm') {
                flex-direction: column;
                justify-content: center;
            }

            @include mq('<=sm') {
                align-items: center;
            }

            @include type--copy-small;
        }

        .news-meta--text-divider {
            display: none;

            @include mq('<=sm') {
                margin-right: 4px;
                display: inline-block;
            }
        }

        .news-meta--image {
            height: 100%;
        }

        .news-meta--title {
            @include hide-visually;
        }

        .news-meta--tag {
            padding: 9px 16px 7px;
            color: $c--monochrome-white;
            border-radius: 16px;

            @include type--copy-tiny;
            @include ff--base-medium;
        }
    }

    // Facets

    & {
        .news-meta--tag {
            @each $name, $theme in $themes {
                &.news-meta--tag__#{$name} {
                    background: get-theme-color($name);

                    @include has-focus(true) {
                        background: get-theme-color($name, 'dark');
                    }
                }
            }
        }
    }
    // States
}
