.collection-points-app {
    // Vars

    // Support

    // Module
    & {
        margin-top: 24px;
    }

    // Facets

    // States
}
