.focus-themes {
    // Vars

    // Support

    // Module
    & {
        @include grid--root((row: 'row', cols: 'col'));
        @include grid--space((row: 'row', cols: 'col', gutter: 0));

        .focus-themes--title {
            @include centered-section-title;
        }

        .focus-themes--subtitle {
            margin-bottom: 24px;
            padding: 0 24px;

            @include type--h2;

            @include mq('<=lg') {
                text-align: center;
            }
        }

        .focus-themes--wrap {
            box-shadow: $shadow-level-3;
            border-radius: $sz--border-radius-base;
            transition: box-shadow $trs--base;
            background: $c--monochrome-white;

            @include has-focus(true) {
                box-shadow: $shadow-level-4;
            }
        }

        .focus-themes--item {
            width: 100%;

            ~ .focus-themes--item {
                margin-top: 64px;

                @include mq('<=md') {
                    margin-top: 48px;
                }
            }
        }

        .focus-themes--row.focus-themes--row {
            @media print {
                display: block;
            }
        }

        .focus-themes--col {
            display: flex;
            overflow: auto;

            @include grid--col((ratio: 6 / 12, mq: '>lg'));
            @include grid--col((ratio: 12 / 12, mq: '<=lg'));

            @media print {
                @include grid--col((ratio: 12 / 12));
                display: block;
            }
        }

        .focus-themes--collection {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            flex: 1 1 100%;

            @include mq('<=md') {
                flex-direction: column;
            }
        }

        .focus-themes--card {
            display: flex;
            border-right: 1px solid $c--monochrome-paper-white;
            flex: 1 1 50%;

            @include mq('<=lg') {
                min-width: 256px;
                border-bottom: 1px solid $c--monochrome-paper-white;
            }

            @media print {
                flex: 1 1 100%;
            }
        }

        .focus-themes--link {
            height: 100%;

            @media print {
                width: 100%;
            }
        }
    }

    // Facets
    & {
        .focus-themes--wrap {
            @each $name, $theme in $themes {
                &.focus-themes--wrap__#{$name} {
                    @include mq('>lg') {
                        border-top: 4px solid get-theme-color($name);
                    }

                    @include mq('<=lg') {
                        border-left: 4px solid get-theme-color($name);
                    }

                    .focus-themes--link {
                        $theme-color: get-theme-color($name, 'dark');

                        @include has-focus(true) {
                            color: $theme-color;

                            * { // stylelint-disable-line
                                color: $theme-color;
                            }

                            figure { // stylelint-disable-line
                                filter: saturate(80%);
                            }
                        }
                    }
                }
            }
        }
    }

    // States
}
