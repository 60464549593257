.animation-hook {
    // Vars

    // Support

    // Module
    & {
        position: absolute;
        width: 4px;
        height: 4px;
        //background: $c--signal-success;
    }

    // Facets

    // States
}
