@mixin centered-section-title() {
    display: flex;
    justify-content: center;
    text-align: center;
    margin: 0 auto 24px;

    @include type--h1;
    @include grid--span((ratio: 8 / 12, mq: '>sm'));
    @include grid--span((ratio: 12 / 12, mq: '<=sm'));
}
