/// Primary
///
@mixin btn--primary {
    // Vars

    // Support

    // Module

    // Facets

    // States
    & {
        // Base
        @include btn--apply-states(('disabled': false)) {
            @include btn--primary-base;
        }

        // Focus
        @include btn--apply-states(('disabled': false, 'focus': true)) {
            @include btn--primary-focus;
        }

        // Active
        @include btn--apply-states(('disabled': false, 'focus': default, 'active': true)) {
            @include btn--primary-active;
        }

        // Disabled
        @include btn--apply-states(('disabled': true, 'focus': default)) {
            @include btn--primary-disabled;
        }
    }
}

/// Base
///
@mixin btn--primary-base {
    color: $c--monochrome-white;
    border-color: transparent;
    box-shadow: $shadow-level-3;

    @each $name, $theme in $themes {
        @include at-root($themes-root-el, theme-name-to-css-selector($name)) {
            background: get-theme-color($name);
        }
    }
}

/// Focus
///
@mixin btn--primary-focus {
    box-shadow: $shadow-level-4;

    @each $name, $theme in $themes {
        @include at-root($themes-root-el, theme-name-to-css-selector($name)) {
            background: darken(get-theme-color($name), 5%);
        }
    }
}

/// Active
///
@mixin btn--primary-active {
    box-shadow: none;
    color: $c--monochrome-white;

    @each $name, $theme in $themes {
        @include at-root($themes-root-el, theme-name-to-css-selector($name)) {
            background: get-theme-color($name, 'dark');
        }
    }
}

/// Disabled
///
@mixin btn--primary-disabled {
    color: $c--monochrome-silver;
    background-color: $c--monochrome-light-gray;
}
