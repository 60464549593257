.header-search {
    // Vars

    // Support
    @keyframes input-slide-from-right {
        from {
            opacity: 0;
            transform: translate(16px, 0);
        }

        to {
            opacity: 1;
            transform: translate(0, 0);
        }
    }

    @keyframes items-slide-from-right {
        from {
            opacity: 0;
            transform: translate(64px, 0);
        }

        to {
            opacity: 1;
            transform: translate(0, 0);
        }
    }

    @keyframes submit-button-slide-from-left {
        from {
            opacity: 0;
            transform: translate(-25%, 0);
        }

        to {
            opacity: 1;
            transform: translate(0, 0);
        }
    }

    @keyframes close-button-scale {
        from {
            opacity: 0;
            transform: scale(0.5);
        }

        to {
            opacity: 1;
            transform: scale(1);
        }
    }

    // Module
    & {
        z-index: z-index(search);
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        background: $c--monochrome-white;
        box-shadow: $shadow-level-3;
        border-radius: $sz--border-radius-base;
        overflow: hidden;

        @include grid--root((row: 'row', cols: 'col'));
        @include grid--space((row: 'row', cols: 'col', gutter: 0));

        .header-search--col {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
        }

        .header-search--top-wrap {
            display: flex;
            flex: 1 1 auto;
            box-shadow: inset 0 -1px 0 0 $c--monochrome-paper-white;
        }

        .header-search--submit {
            margin-left: auto;
            cursor: pointer;
            opacity: 0;
            animation: submit-button-slide-from-left $trs--base-speed ($trs--header-search-speed * 1.6) $trs--header-search-fn forwards;
            padding: 0 8px;

            @include mq('<=sm') {
                padding: 0 16px;
            }

            @include has-focus(true) {
                color: $c--monochrome-quasi-black;
            }
        }

        .header-search--input {
            flex: 1 1 100%;
            padding: 18px 0 16px;
            border-bottom: 1px solid $c--monochrome-quasi-black;
            box-shadow: none;
            animation: input-slide-from-right $trs--base-speed ($trs--header-search-speed * 2) $trs--header-search-fn forwards;
            transition: border $trs--base;
            opacity: 0;

            &:invalid {
                border-bottom-color: transparent;
            }

            @include has-focus(true) {
                border-bottom-color: $c--monochrome-quasi-black;
            }
        }

        .header-search--close {
            display: flex;
            justify-content: flex-end;
            padding: 0 16px;
            cursor: pointer;
            opacity: 0;
            animation: close-button-scale $trs--header-search forwards;

            @include has-focus(true) {
                color: $c--monochrome-quasi-black;
            }
        }

        .header-search--quick-links {
            flex: 1 1 100%;
            align-items: flex-start;
            margin: 16px 0 0;
            flex-direction: column;
            padding: 0 8px 24px;

            @include mq('>sm') {
                box-shadow: inset 1px 0 0 0 $c--monochrome-paper-white;
            }
        }

        .header-search--title {
            margin-bottom: 8px;
            padding: 8px 0;
            font-size: 1.2rem;
            color: $c--monochrome-black-gray;
            opacity: 0;
            animation: items-slide-from-right $trs--header-search-speed $trs--header-search forwards;

            @include ff--base-bold-italic;

            @include mq('<=sm') {
                padding: 8px;
            }
        }


        .header-search--link {
            color: $c--monochrome-dark-gray;
            display: inline-block;
            font-size: $fz--small;
            padding: 8px 0;
            opacity: 0;
            width: 100%;
            animation: items-slide-from-right $trs--header-search-speed $trs--header-search forwards;

            @include ff--base-medium;

            @include mq('>xl') {
                font-size: $fz--medium;
            }

            @include mq('<=sm') {
                padding: 8px;
            }

            @include has-focus(true) {
                color: get-theme-color('association');

                @include all-themes-color;
            }
        }

        .header-search--collection {
            @include mq('<=sm') {
                display: flex;
                flex-wrap: wrap;
            }
        }

        .header-search--item {
            @include mq('<=sm') {
                flex: 0 0 50%;
            }
        }
    }

    // Facets
    .header-search--col {
        &.header-search--col__side {
            position: relative;

            @include grid--span((ratio: 3 / 12, mq: '>sm'));

            @include mq('<=sm') {
                flex: 1 1 auto;
            }
        }

        &.header-search--col__main {
            @include grid--span((ratio: 6 / 12, mq: '>sm'));

            @include mq('<=sm') {
                flex: 1 1 100%;
            }
        }

        &.header-search--col__tail {
            justify-content: flex-end;

            @include grid--span((ratio: 3 / 12, mq: '>sm'));

            @include mq('<=sm') {
                flex: 1 1 auto;
            }
        }
    }


    // States
}
