////
/// Button facets and state generation library
////
/// Dependencies and components
///
@import 'btn__helpers';
@import 'btn__states';
@import 'btn__facets';


/// All applicable btn facets, sizes and special types
///
.btn {
    // Vars

    // Support

    // Module
    & {
        @include btn--all;
    }

    // Facets
    & {
        // Sizes
        &.btn__small {
            @include btn--size-small;
        }

        &.btn__medium {
            @include btn--size-medium;
        }

        &.btn__large {
            @include btn--size-large;
        }

        // Facets
        &.btn__primary {
            @include btn--primary;
        }

        &.btn__secondary {
            @include btn--secondary;
        }

        &.btn__transparent {
            @include btn--transparent;
        }

        // Special
        &.btn__has-grid {
            @include btn--has-grid;
        }

        // Bubble
        &.btn__bubble {
            @include btn--bubble;
        }
    }

    // States
}
