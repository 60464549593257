.link-item {
    // Vars
    $figure-size: 66px;
    $figure-size-xs: 54px;

    // Support

    // Module
    & {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        padding: 16px;
        background: $c--monochrome-white;
        transition: $trs--common-props;

        @include mq('<=md') {
            padding: 8px 16px 8px 8px;
        }

        @include has-focus(true) {
            .link-item--figure {
                filter: saturate(80%);
            }
        }

        .link-item--title {
            @include type--h3;

            color: $c--monochrome-dark-gray;
        }

        .link-item--figure {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 0 0 $figure-size;
            width: $figure-size;
            height: $figure-size;
            margin-right: 16px;
            border-radius: 100%;
            overflow: hidden;

            @include mq('<=sm') {
                flex: 0 0 $figure-size-xs;
                width: $figure-size-xs;
                height: $figure-size-xs;
            }
        }

        .link-item--text {
            flex: 1 1 auto;
            padding-right: 16px;

            transform: translate(0, 1px);
        }

        .link-item--description {
            color: $c--monochrome-dark-gray;

            @include type--copy-small;

            @include mq('>sm') {
                margin-top: 4px;
            }

            @include mq('<=sm') {
                display: none;
            }
        }
    }

    // Facets

    & {
        &.link-item__base {
            @include has-focus(true) {
                .link-item--arrow {
                    color: currentColor;
                }
            }
        }

        @each $name, $theme in $themes {
            &.link-item__#{$name} {
                color: get-theme-color($name);

                .link-item--arrow,
                .link-item--title {
                    color: currentColor;
                }

                @include has-focus(true) {
                    color: $c--monochrome-white;
                    background: get-theme-color($name);

                    .link-item--arrow,
                    .link-item--description,
                    .link-item--title {
                        color: currentColor;
                    }
                }
            }
        }

        &.link-item__focus-themes {
            min-height: 64px;

            @include mq('<=md') {
                padding: 8px 16px;
            }

            .link-item--figure {
                @include mq('<=sm') {
                    display: none;
                }
            }
        }

        &.link-item__deeplink {
            @include has-focus(true) {
                color: $c--monochrome-white;

                @include all-themes-background-color;

                .link-item--arrow,
                .link-item--description,
                .link-item--title {
                    color: currentColor;
                }

                .link-item--figure {
                    @include all-themes-background-color('dark');
                }
            }
        }
    }

    // States
}
