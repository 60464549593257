.xml-feed {
    // Vars

    // Support

    // Module
    & {
        @include grid--root((row: 'row', cols: 'col'));
        @include grid--space((row: 'row', cols: 'col', gutter: 32px));


        .xml-feed--title {
            margin-bottom: 16px;

            @include unwrapped-title;
        }

        .xml-feed--description {
            margin-bottom: 24px;
            color: $c--monochrome-dark-gray;
        }

        .xml-feed--row {
            justify-content: space-between;

            @include mq('>sm') {
                align-items: center;
                flex-wrap: nowrap;
            }
        }

        .xml-feed--col {
            @include grid--span((ratio: 12 / 12, mq: '<=sm'));

            &:first-child {
                flex: 1 1 auto;
            }

            &:not(:first-child) {
                @include grid--span((ratio: 3 / 12, mq: '>lg'));
                @include grid--span((ratio: 4 / 12, mq: ('md', 'lg')));
            }
        }

        .xml-feed--tbody {
            display: flex;
            flex-direction: column;
        }

        .xml-feed--toggle {
            cursor: pointer;
            padding: 16px 0 32px;

            @include all-themes-color;

            @include has-focus(true) {
                @include all-themes-color('dark');
            }
        }

        .xml-feed--toggle-text {
            padding: 0 8px;
            transform: translate(0, 1px);

            @include ff--base-medium;
        }

        .xml-feed--more,
        .xml-feed--less {
            display: flex;
            align-items: center;

            @media print {
                display: none;
            }
        }

        .xml-feed--less {
            display: none;
        }

        .xml-feed--extra-items {
            order: 1;
            display: none;

            @media print {
                display: block;
            }
        }

        .xml-feed--meta {
            @include type--copy-small;
        }

        .xml-feed--link {
            @include ff--base-medium;

            @include has-focus(true) {
                color: $c--monochrome-black-gray;
            }
        }
    }

    // Facets
    & {
        .xml-feed--row {
            position: relative;

            &::after {
                content: '';
                position: absolute;
                left: 16px;
                bottom: 0;
                display: block;
                width: calc(100% - 32px);
            }

            &.xml-feed--row__head {
                padding-bottom: 6px;

                &::after {
                    border-bottom: 2px solid $c--monochrome-paper-white;
                }
            }

            &.xml-feed--row__item {
                padding-top: 16px;
                padding-bottom: 17px;

                &::after {
                    border-bottom: 1px solid $c--monochrome-paper-white;
                }
            }
        }

        .xml-feed--col {
            &.xml-feed--col__head {
                @include type--copy-small;
                @include ff--base-medium;

                &:not(:first-child) {
                    @include mq('<=sm') {
                        display: none;
                    }
                }
            }

            &.xml-feed--col__item {
                color: $c--monochrome-dark-gray;
            }
        }
    }

    // States
    & {
        .xml-feed--checkbox {
            &:checked ~ {
                .xml-feed--extra-items {
                    order: 0;
                    display: block;
                }

                .xml-feed--toggle {
                    .xml-feed--less {
                        display: flex;
                    }

                    .xml-feed--more {
                        display: none;
                    }
                }
            }
        }
    }
}
