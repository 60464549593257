.cookie-banner {
    // Vars

    // Support

    // Module
    & {
        & {
            z-index: z-index(cookie-banner);
            position: fixed;
            bottom: 0;
            width: 100%;
            padding: 16px 0;
            background-color: $c--monochrome-quasi-black;
            color: $c--monochrome-white;

            @include mq('<=md') {
                padding: 8px 0;
            }

            @media print {
                display: none;
            }
        }

        .cookie-banner--message-col {
            @include grid--span((ratio: 9 / 12));
            @include grid--span((ratio: 12 / 12, mq: '<=md'));

            p {
                @include type--copy-small;
            }

            a {
                color: $c--monochrome-white !important; // stylelint-disable-line
                text-decoration: underline !important; // stylelint-disable-line

                @include type--copy-small;
            }
        }

        .cookie-banner--button {
            @include has-focus(true) {
                color: $c--monochrome-paper-white;
            }
        }

        .cookie-banner--actions-col {
            @include grid--span((ratio: 3 / 12));
            @include grid--span((ratio: 12 / 12, mq: '<=md'));

            display: flex;
            justify-content: flex-end;
        }

        .cookie-banner--row {
            justify-content: space-between;
            align-items: center;
        }
    }

    // Facets

    // States
}
