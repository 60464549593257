.trs-drill-down {
    // Vars

    // Support

    // Module

    // Facets

    // States
    &-enter-active, &-leave-active {
        transition: transition-props($trs--base-speed $trs--base-fn, transform, opacity);
    }

    &-enter, &-leave-to {
        opacity: 0;
    }

    &-enter {
        transform: translate(100%, 0);
    }

    &-leave-to {
        transform: translate(-100%, 0);
    }

    &-leave-active {
        position: absolute;
        width: 100%;
    }
}
