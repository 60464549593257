@mixin ui-form-field--size-large {
    font-size: 2.1rem;
    padding: 24px 24px 23px;

    @include mq('xl') {
        @include ui-form-field--size-medium;
    }

    @include mq('<=lg') {
        @include ui-form-field--size-small;
    }
}

@mixin ui-form-field--size-medium {
    padding: 22px 12px 19px;
    font-size: 1.9rem;
}

/// Small
///
@mixin ui-form-field--size-small {
    padding: 20px 12px 17px;
    font-size: 1.7rem;
}
