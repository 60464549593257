// stylelint-disable plugin/selector-bem-pattern, selector-no-qualifying-type
/* Friendly reminder to myself: remember this file anytime you think you will be faster using 3rd party scripts */

.VueTables {
    @include type--copy-tiny;

    .VueTables__table {
        width: 100%;
        table-layout: fixed;
        margin-bottom: 16px;
        overflow: hidden;
        border-radius: $sz--border-radius-base;

        @include mq('<=xs') {
            display: block;
            overflow: auto;
            max-width: calc(100vw - 48px);
        }

        @include mq('xxs') {
            max-width: calc(100vw - 32px);
        }
    }

    select.form-control {
        appearance: menulist;
        cursor: pointer;
        background: $c--monochrome-light-gray;
        margin-left: 8px;
    }

    .table-responsive {
        border-bottom: 1px solid $c--monochrome-light-gray;
    }

    .VueTables__sortable {
        cursor: pointer;
        text-align: left;
    }

    .VueTables__limit,
    .VueTables__filters-row {
        display: none;
    }

    .VuePagination__count.VuePagination__count {
        text-align: center;

        @include type--copy-tiny;
    }

    .VueTables__limit-field {
        padding: 8px;
    }

    .VueTables__search-field {
        display: flex;
        align-items: center;
        padding: 8px 0;

        > input {
            display: inline-block;
            padding: 7px 16px 6px;
            border-width: 1px;
            border-style: solid;
            border-radius: $sz--border-radius-base;
            line-height: 1.3;
            cursor: text;
            transition: $trs--common-props;
            margin-left: 16px;
            font-size: 1.6rem;

            @include placeholder {
                transition: $trs--common-props;
            }
        }

        > label {
            @include ff--base-medium;
        }
    }

    .glyphicon-chevron-down {
        position: absolute;

        &::before {
            content: '\2191';
        }
    }

    .glyphicon-chevron-up {
        position: absolute;

        &::before {
            content: '\2193';
        }
    }

    thead {
        tr:nth-child(1) {
            th {
                padding-bottom: 0;
            }
        }

        tr:nth-child(2) {
            border-bottom: 1px solid $c--monochrome-light-gray;

            th {
                padding-top: 0;
            }
        }

        //background: $c--monochrome-paper-white;

        th {
            padding: 8px;
            text-align: left;
            color: $c--monochrome-quasi-black;

            @include ff--base-medium;
        }
    }

    tbody {
        tr {
            &:nth-child(even) {
                background: lighten($c--monochrome-light-gray, 3%);
            }

            &:nth-child(odd) {
                background: $c--monochrome-white;
            }
        }

        td {
            padding: 8px;
        }
    }

    .VuePagination__pagination.VuePagination__pagination {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        margin: 32px auto 24px;

        .VuePagination__pagination-item {
            margin: 0;
            padding: 0 8px;
            width: auto;

            &::before {
                display: none;
                padding: 0;
            }

            &.disabled {
                a {
                    color: $c--monochrome-dark-gray;
                }
            }

            &.active {
                a {
                    @include ff--base-medium;
                    @include all-themes-color('dark');
                }
            }
        }
    }
}
