.teaser-person {
    // Vars

    // Support

    // Module
    & {
        @media print {
            page-break-before: always;
        }

        .teaser-person--col {
            margin: 0 auto;

            @include grid--span((ratio: 8 / 12, mq: '>md'));
            @include grid--span((ratio: 10 / 12, mq: 'md'));
            @include grid--span((ratio: 12 / 12, mq: '<=sm'));
        }

        .teaser-person--wrap {
            padding: 48px;
            box-shadow: $shadow-level-3;
            border-radius: $sz--border-radius-base;

            @media print {
                padding: 24px;
            }
        }

        .teaser-person--title {
            text-align: center;

            @include type--h2;

            &::after {
                @include line-divider;
            }
        }

        .teaser-person--card {
            display: flex;
            justify-content: center;
            margin: 32px auto 0;

            @include mq('<=sm') {
                flex-direction: column;
                align-items: center;
                text-align: center;
            }
        }

        .teaser-person--name {
            @include type--h4;
        }

        .teaser-person--meta {
            @include mq('>sm') {
                margin-left: 24px;
            }
        }

        .teaser-person--description {
            color: $c--monochrome-dark-gray;

            @include type--copy-small;
        }

        .teaser-person--figure {
            width: 115px;
            height: 115px;
            border-radius: 100%;
            overflow: hidden;

            @include mq('<=sm') {
                margin-bottom: 24px;
            }
        }

        .teaser-person--image {
            display: inline-block;
            width: 100%;

            @supports (object-fit: cover) {
                height: 100%;
                object-fit: cover;
            }
        }

        .teaser-person--role {
            display: inline-block;
            margin-bottom: 8px;

            @include mq('<=sm') {
                margin-bottom: 16px;
            }
        }

        .teaser-person--link {
            display: inline-block;
            margin-top: 8px;

            @include mq('<=sm') {
                margin-top: 16px;
            }
        }

        .teaser-person--link,
        .teaser-person--tel {
            @include ff--base-medium;
            @include all-themes-color;

            @include has-focus(true) {
                @include all-themes-color('dark');
            }
        }
    }

    // Facets

    // States
}
