.news-filters {
    // Vars
    $buttons-padding: 8px;
    $buttons-padding-xs: 16px;

    // Support

    // Module
    & {
        margin-bottom: 68px;

        @include mq('<=md') {
            margin-bottom: 8px;
        }

        .news-filters--row {
            @include mq(('md', 'sm')) {
                align-items: center;
                justify-content: space-between;
            }
        }

        .news-filters--tags {
            @include grid--span((ratio: 10 / 12, mq: '>md'));
            @include grid--span((ratio: 8 / 12, mq: '<=xs'));

            @include mq('<=xs') {
                display: flex;
                justify-content: flex-start;
            }
        }

        .news-filters--buttons {
            $negative-margin: $buttons-padding * -1;

            display: flex;
            justify-content: flex-end;
            margin: $negative-margin $negative-margin 0 0;

            @include grid--span((ratio: 2 / 12, mq: '>md'));
            @include grid--span((ratio: 4 / 12, mq: '<=xs'));

            @include mq(('md', 'sm')) {
                margin-top: 0;
                margin-right: $buttons-padding-xs * -1;
            }
        }

        .news-filters--button {
            padding: $buttons-padding;
            color: $c--monochrome-dark-gray;

            @include mq('<=md') {
                margin-top: 0;
                padding: $buttons-padding-xs;
            }
        }
    }

    // Facets
    & {
        .news-filters--button {
            &.news-filters--button__active {
                color: $c--monochrome-black-gray;
            }
        }
    }

    // States
}
