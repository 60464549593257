/// Monochrome special decoration colors
///
$c--monochrome-white: #ffffff;
$c--monochrome-black: #000000;
$c--monochrome-quasi-black: #111111;
$c--monochrome-black-gray: #2c2c2c;
$c--monochrome-dark-gray: #555555;
$c--monochrome-silver: #a5a3a3;
$c--monochrome-light-gray: #eeeeee;
$c--monochrome-paper-white: #f1f1f2;

$monochromes: (
    'white': $c--monochrome-white,
    'black': $c--monochrome-black,
    'quasi-black': $c--monochrome-quasi-black,
    'black-gray': $c--monochrome-black-gray,
    'dark-gray': $c--monochrome-dark-gray,
    'silver': $c--monochrome-silver,
    'light-gray': $c--monochrome-light-gray,
    'paper-white': $c--monochrome-paper-white,
);
/// Brand specific colors
///
$c--brand-primary: #d9213d;
$c--brand-facebook: #3c5a99;
$c--brand-whatstapp: #25d366;
$c--brand-twitter: #1da1f2;
$c--brand-linkedin: #0077b5;

$brands: (
    'primary': $c--brand-primary,
    'facebook': $c--brand-facebook,
    'whatstapp': $c--brand-whatstapp,
    'twitter': $c--brand-twitter,
    'linkedin': $c--brand-linkedin,
);

/// Single purpose colors
///
$c--label-text: $c--monochrome-dark-gray;
$c--label-link: #d9213d;
$c--label-selection: lighten(#d9213d, 25%);

$labels: (
    'text': $c--label-text,
    'link': $c--label-link,
    'selection': $c--label-selection
);

// Theme colors


// Main theme
$c--themes-association-light: #ff5e67;
$c--themes-association-dark: #a00017;
$c--themes-association: #d9213d;

$c--themes-safety: #e66e00;
$c--themes-safety-light: #ff9e3e;
$c--themes-safety-dark: #ad4000;
$c--themes-recycling: #2c834c;
$c--themes-recycling-light: #dbebdb;
$c--themes-recycling-dark: #005523;
$c--themes-knowledge: #1469c9;
$c--themes-knowledge-light: #6096fd;
$c--themes-knowledge-dark: #003f97;
$c--themes-education: #631e65;
$c--themes-education-light: #934b93;
$c--themes-education-dark: #36003a;
$c--themes-sustainability: #1da321;
$c--themes-sustainability-light: #c3eac4;
$c--themes-sustainability-dark: #116214;

$themes: (
    'association': ('default': $c--themes-association, 'light': $c--themes-association-light, 'dark': $c--themes-association-dark),
    'safety': ('default': $c--themes-safety, 'light': $c--themes-safety-light, 'dark': $c--themes-safety-dark),
    'recycling': ('default': $c--themes-recycling, 'light': $c--themes-recycling-light, 'dark': $c--themes-recycling-dark),
    'knowledge': ('default': $c--themes-knowledge, 'light': $c--themes-knowledge-light, 'dark': $c--themes-knowledge-dark),
    'education': ('default': $c--themes-education, 'light': $c--themes-education-light, 'dark': $c--themes-education-dark),
    'sustainability': ('default': $c--themes-sustainability, 'light': $c--themes-sustainability-light, 'dark': $c--themes-sustainability-dark),
    'default': ('default': $c--themes-association, 'light': $c--themes-association-light, 'dark': $c--themes-association-dark),
);

/// Signal colors
///
$c--signal-info: #0000ff;
$c--signal-error: #ff0033;
$c--signal-warning: #ff8800;
$c--signal-success: #00c851;

$signals: (
    'info': $c--signal-info,
    'error': $c--signal-error,
    'warning': $c--signal-warning,
    'success': $c--signal-success,
);

/// Main color map
///
$color-maps: (
    monochrome: $monochromes,
    brand: $brands,
    label: $labels,
    signal: $signals,
    themes: (
        'association': $c--themes-association,
        'safety': $c--themes-safety,
        'recycling': $c--themes-recycling,
        'knowledge': $c--themes-knowledge,
        'education': $c--themes-education,
        'sustainability': $c--themes-sustainability,
    ),
);
