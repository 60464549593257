.team-card {
    // Vars

    // Support
    @mixin card-focus-state() {
        box-shadow: $shadow-level-2;

        .team-card--figure-image-overlay {
            visibility: hidden;
            opacity: 0;
            transition-duration: $trs--base-speed;
        }

        .team-card--figure {
            transform: scale(1);
        }

        .team-card--phone,
        .team-card--link,
        .team-card--small-text,
        .team-card--tail,
        .team-card--name {
            @include final-text-state;
        }
    }

    @mixin final-text-state() {
        opacity: 1;
        transform: translate(0, 0);
    }

    @mixin initial-text-state() {
        opacity: 0;
        transform: translate(0, 16px);
    }

    @mixin text-animation-props($stagger) {
        transition: transition-props(ease 250ms $stagger, opacity, transform);
    }

    // Module
    & {
        & {
            position: relative;
            display: flex;
            justify-content: center;
            flex-direction: column;
            height: 100%;
            padding: 32px 16px;
            transition: box-shadow $trs--base;
            box-shadow: $shadow-level-1;
            text-align: center;
            border-radius: $sz--border-radius-base;
            overflow: hidden;

            &:focus, &:hover {
                @include card-focus-state;
            }

            // this state can't be grouped with the previous ones because otherwise IE11 does break on the hover state
            &:focus-within {
                @include card-focus-state;
            }

            @include mq('lg') {
                min-height: 391px;
            }

            @include mq('<=xs') {
                min-height: 100vw;
            }
        }


        .team-card--figure-image-overlay {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            overflow: hidden;
            background: $c--monochrome-white;
            transition: transition-props($trs--base-fn 0, visibility, opacity);
        }

        .team-card--image-overlay {
            pointer-events: none;
            border-radius: $sz--border-radius-base;
            position: absolute;
            left: 50%;
            top: 50%;
            height: 100%;
            width: auto;
            transform: translate(-50%, -50%);
            object-position: 50% 0;

            @supports (object-fit: cover) {
                width: 101%;
                object-fit: cover;
            }
        }

        .team-card--image {
            display: inline-block;
            width: 100%;
        }

        .team-card--info {
            display: flex;
            flex-direction: column;
        }

        .team-card--figure {
            border-radius: 100%;
            overflow: hidden;
            margin: 0 auto;
            margin-bottom: 16px;

            transform: scale(0.9);
            transition: transition-props($trs--base, transform);

            @include mq('>xs') {
                @include square(112px);
            }

            @include mq('<=xs') {
                @include square(144px);
            }
        }

        .team-card--name {
            transition: transition-props($trs--base, opacity, transform);

            @include initial-text-state;
        }

        .team-card--description {
            @include type--copy-small;
        }

        .team-card--small-text {
            margin-bottom: 8px;
            display: inline-block;

            @include text-animation-props(50ms);
            @include initial-text-state;
            @include type--copy-tiny;
        }

        .team-card--phone {
            display: inline-block;

            @include text-animation-props(100ms);
            @include initial-text-state;
        }

        .team-card--link {
            display: inline-block;

            @include text-animation-props(125ms);
            @include ff--base-medium;
            @include initial-text-state;
        }

        .team-card--tail {
            margin-top: auto;
            color: $c--monochrome-quasi-black;

            @include text-animation-props(50ms);
            @include initial-text-state;
            @include type--copy-small;
            @include ff--base-medium;
        }
    }

    // Facets
    & {
        &.team-card__focused {
            @include card-focus-state;
        }
    }

    // States
}
