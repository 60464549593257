.mobile-menu {
    // Vars

    // Support

    // Module
    & {
        margin-top: 8px;

        .mobile-menu--main-col {
            @include grid--span((ratio: 12 / 12));
        }

        .mobile-menu--bar {
            position: relative;
            display: flex;
            box-shadow: $shadow-level-3;
            border-radius: $sz--border-radius-base;
            justify-content: space-between;
            align-content: center;
            padding-top: 16px;
            padding-bottom: 16px;

            transition: box-shadow $trs--base;
        }

        .mobile-menu--logo {
            display: flex;
            align-items: center;
            margin: auto;
        }

        .mobile-menu--search {
            padding-right: 16px;
        }

        .mobile-menu--img {
            height: 19px;
            margin-left: 50px;
        }
    }

    // Facets
    & {
        &.mobile-menu__no-shadow {
            .mobile-menu--bar {
                box-shadow: none;
            }
        }
    }

    // States
}
