////
/// Form field facets and state generation library
////
/// Dependencies and components
///
@import 'ui-form-field__helpers';
@import 'ui-form-field__states';
@import 'ui-form-field__facets';
@import 'ui-form-field__sizes';

/// Form field facets
///
.ui-form-field {
    // Vars

    // Support

    // Module

    // Facets
    &.ui-form-field__base {
        @include ui-form-field--primary;
    }

    &.ui-form-field__search {
        @include ui-form-field--primary-search;
    }

    &.ui-form-field__large {
        .ui-form-field--input {
            @include ui-form-field--size-large;
        }
    }

    &.ui-form-field__medium {
        .ui-form-field--input {
            @include ui-form-field--size-medium;
        }
    }

    &.ui-form-field__small {
        .ui-form-field--input {
            @include ui-form-field--size-small;
        }
    }

    // States
}
