.event-meta {
    // Vars

    // Support

    // Module
    & {
        @include grid--root((row: 'collection', cols: 'item'));
        @include grid--space((row: 'collection', cols: 'item', gutter: (24px 0)));

        .event-meta--title {
            @include hide-visually;
        }

        .event-meta--address {
            margin-bottom: 64px;

            @include mq('<=sm') {
                margin-bottom: 48px;
            }
        }

        .event-meta--date,
        .event-meta--address {
            @include type--copy-small;
        }

        .event-meta--date {
            display: inline-block;
            width: 100%;
            margin-bottom: 24px;
        }

        .event-meta--item {
            display: flex;
            flex-direction: column;
            width: 100%;
            text-align: center;
        }

        .event-meta--event-link {
            @include ff--base-medium;
        }
    }

    // Facets

    // States
}
