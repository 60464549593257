.main-header {
    // Vars

    // Support
    @mixin link-active() {
        color: get-theme-color();
    }

    // Module
    & {
        display: none;

        @media print {
            display: block;
        }

        @include grid--root((row: 'collection', cols: 'item'));
        @include grid--space((row: 'collection', cols: 'item', gutter: 32px));

        @include mq('>md') {
            display: block;
        }

        .main-header--row {
            align-items: center;
            height: 72px;
        }

        .main-header--logo {
            display: flex;
            align-items: center;

            @include grid--span((ratio: 4 / 12, mq: '>md'));
            @include grid--span((ratio: 12 / 12, mq: '<=md'));

            @media print {
                display: block;
            }
        }

        .main-header--nav {
            display: flex;
            justify-content: flex-end;

            @include grid--span((ratio: 8 / 12, mq: '>md'));
            @include grid--span((ratio: 12 / 12, mq: '<=md'));

            @media print {
                display: none;
            }
        }

        .main-header--collection {
            display: flex;
            align-items: center;
        }

        .main-header--link {
            display: flex;
            align-items: center;
            color: $c--monochrome-dark-gray;

            @include type--menu-meta;

            @include has-focus(true) {
                @include link-active;
            }
        }
    }

    // Facets

    & {
        .main-header--link {
            &.main-header--link__highlight {
                @include ff--base-medium;

                color: get-theme-color();
            }
        }
    }

    // States
}
