.detail-intro {
    // Vars

    // Support

    // Module
    & {
        width: 100%;
        padding: 64px 0 0;

        @include mq('<=sm') {
            padding: 48px 0 0;
        }

        .detail-intro--img {
            width: 100%;
        }
    }

    // Facets
    & {
        .detail-intro--col {
            &.detail-intro--col__main {
                @media screen {
                    margin-left: auto;

                    @include grid--span((ratio: 8 / 12, mq: '>sm'));
                    @include grid--span((ratio: 12 / 12, mq: '<=sm'));
                }

                @media print {
                    @include grid--span((ratio: 12 / 12));
                }
            }

            &.detail-intro--col__pull {
                @media screen {
                    @include grid--span((ratio: 1 / 12, mq: '>md'));
                }
            }
        }
    }

    // States
}
