.infobox {
    // Vars

    // Support

    // Module
    & {
        margin: 64px 0;
        padding: 24px;
        background: $c--monochrome-paper-white;

        @media print {
            padding: 0;
        }

        @include mq('<=md') {
            margin: 48px 0;
        }

        .infobox--header {
            display: flex;
            align-items: center;
            margin-bottom: 24px;
        }

        .infobox--title {
            padding-left: 8px;
            transform: translate(0, 1px);

            @include type--h3;
        }

        .infobox--content {
            color: $c--monochrome-dark-gray;
            margin-bottom: 16px;
        }
    }

    // Facets

    // States
}
