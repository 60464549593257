.team-list {
    // Vars

    // Support

    // Module
    & {
        @include grid--root((row: 'collection', cols: 'item'));
        @include grid--space((row: 'collection', cols: 'item', gutter: (32px, 0), mq: '>md'));
        @include grid--space((row: 'collection', cols: 'item', gutter: (16px, 0), mq: '<=md'));

        .team-list--title {
            @include unwrapped-title;
        }

        .team-list--title,
        .team-list--description {
            margin-bottom: 24px;
        }

        .team-list--item {
            display: flex;
            flex-direction: column;
            overflow: hidden;

            @include grid--span((ratio: 4 / 12, mq: '>lg'));
            @include grid--span((ratio: 6 / 12, mq: ('md', 'sm', 'lg')));
            @include grid--span((ratio: 12 / 12, mq: ('<=xs')));
        }
    }

    // Facets

    // States
}
