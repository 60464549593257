.picture-element {
    // Vars

    // Support

    // Module
    & {
        display: block;
        width: 100%;
        height: auto;
        transition: filter $trs--base;
    }

    // Facets
    & {
        &.picture-element__is-blurry {
            filter: blur(12px);
        }
    }

    // States
}
