.collection-points-info-panel {
    // Vars

    // Support

    // Module
    & {
        position: relative;
        width: 100%;
        height: 100%;

        @include mq(('md')) {
            width: 304px;
        }

        @include mq('>md') {
            width: 372px;
        }

        .collection-points-info-panel--loader,
        .collection-points-info-panel--details,
        .collection-points-info-panel--search {
            width: 100%;
        }

        .collection-points-info-panel--loader {
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;
            padding: 24px 12px;
        }
    }

    // Facets

    // States
}
