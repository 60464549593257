.image {
    // Vars

    // Support

    // Module
    & {
        .image--col {
            margin: 0 auto;

            @include grid--span((ratio: 8 / 12, mq: '>md'));
            @include grid--span((ratio: 10 / 12, mq: 'md'));
            @include grid--span((ratio: 12 / 12, mq: '<=sm'));
        }

        .image--img {
            width: 100%;
            margin-bottom: 16px;
        }

        .image--caption {
            text-align: center;
            color: $c--monochrome-dark-gray;

            @include type--copy-small;
        }
    }

    // Facets

    // States
}
