.collection-points-panel {
    // Vars

    // Support

    // Module
    & {
        pointer-events: auto;
        display: flex;
        flex-flow: column nowrap;

        max-height: 100%;
        background-color: $c--monochrome-white;
        border-radius: $sz--border-radius-base;
        box-shadow: 0 20px 32px 0 rgba($c--monochrome-black, 0.2), 0 12px 16px 0 rgba($c--monochrome-black, 0.12);
        overflow: hidden;

        .collection-points-panel--header {
            border-bottom: 1px solid $c--monochrome-paper-white;
        }

        .collection-points-panel--footer {
            border-top: 1px solid $c--monochrome-paper-white;
        }

        .collection-points-panel--map {
            border-bottom: 1px solid $c--monochrome-paper-white;
        }

        .collection-points-panel--content {
            @include ios-native-scrolling;
            flex: 0 1 auto;
            width: 100%;
            overflow-y: auto;
            max-height: 100%;
        }
    }

    // Facets
    & {
        // Search
        &.collection-points-panel__search {
            $sz--header-height: 64px + 1px;

            .collection-points-panel--header {
                flex: 0 0 $sz--header-height;
                height: $sz--header-height;
            }
        }

        // Details
        &.collection-points-panel__details {
            // We need to disable a stylelint rule for the next line as it doesn't respect scopes
            // @link https://github.com/kristerkari/stylelint-scss/issues/277
            // stylelint-disable-next-line scss/no-duplicate-dollar-variables
            $sz--header-height: 64px + 1px;

            .collection-points-panel--header {
                flex: 0 0 $sz--header-height;
                height: $sz--header-height;
            }
        }
    }

    // States
}
