/* mixin for multiline */
@mixin multi-line-ellipsis($line-height: 1.4em, $line-count: 2, $bg-color: white) {
    overflow: hidden;
    position: relative;
    line-height: $line-height;
    max-height: $line-height * $line-count;

    /* stylelint-disable */
    @supports (-webkit-line-clamp: $line-count) {
        display: -webkit-box;
        -webkit-line-clamp: $line-count;
        -webkit-box-orient: vertical;
    }
    /* stylelint-enable */

    @supports not (-webkit-line-clamp: $line-count) {
        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            right: 0;
            display: block;
            background: linear-gradient(to right, rgba($bg-color, 0), rgba($bg-color, 1) 50%);
            width: $line-height * 4;
            height: $line-height;
        }
    }
}
