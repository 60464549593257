.collection-points-search {
    // Vars

    // Support

    // Module
    & {
        .collection-points-search--input {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            height: 64px;
        }

        .collection-points-search--input-element {
            flex: 1 0 auto;
        }

        .collection-points-search--input-actions {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: stretch;
            flex: 1 0 auto;
        }

        .collection-points-search--input-search-btn,
        .collection-points-search--input-clear-btn {
            pointer-events: auto;
            height: 100%;
            border: none;

            @include has-focus(true) {
                color: $c--themes-recycling;
            }

            @include btn--state-active(true) {
                color: $c--themes-recycling-dark;
            }
        }

        .collection-points-search--input-search-btn {
            @include btn--state-disabled(true) {
                color: $c--monochrome-silver;
            }
        }

        .collection-points-search--input-clear-btn {
            @include btn--state-disabled(true) {
                opacity: 0;
                color: $c--monochrome-silver;
            }
        }

        .collection-points-search--input-search-btn {
            padding: 0 12px 0 16px;
        }

        .collection-points-search--input-clear-btn {
            padding: 0 16px 0 12px;
        }

        .collection-points-search--locator {
            //
        }

        .collection-points-search--locator-btn {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            width: 100%;
            height: 56px;
            border: none;
            padding: 0 16px;
            text-align: left;

            @include has-focus(true) {
                color: $c--themes-recycling;
            }

            @include btn--state-active(true) {
                color: $c--themes-recycling-dark;
            }
        }

        .collection-points-search--locator-btn-icon {
            margin-right: 12px;
        }

        .collection-points-search--locator-btn-text {
            @include type--copy-small;
            @include ff--base-medium;
        }

        .collection-points-search--footer {
            padding: 10px 16px;
        }

        .collection-points-search--footer-text {
            @include ff--base-medium;
            font-size: $fz--x-small;
            line-height: $fz--x-small;
            text-align: center;

            &.collection-points-search--footer-text__base {
                color: $c--monochrome-black-gray;
            }

            &.collection-points-search--footer-text__error {
                color: $c--signal-error;
            }
        }

        .collection-points-search--loader {
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;
            padding: 24px 12px;
        }
    }

    // Facets
    & {
        & {
            .collection-points-search--footer {
                border-top: none;

                &.collection-points-search--footer__has-results {
                    border-top: 1px solid $c--monochrome-paper-white;
                }
            }
        }
    }

    // States
}
