.teaser-events {
    // Vars


    // Support

    // Module
    & {
        position: relative;

        .teaser-events--col {
            margin: auto;
            background: $c--monochrome-white;

            @include grid--span((ratio: 8 / 12, mq: ('>lg')));
            @include grid--span((ratio: 10 / 12, mq: 'lg'));
            @include grid--span((ratio: 12 / 12, mq: '<=md'));
        }

        .teaser-events--tags {
            margin: 0 auto 64px;

            @include mq('<=sm') {
                margin-bottom: 48px;
            }
        }

        .teaser-events--title {
            @include centered-section-title;
        }


        .teaser-events--collection {
            margin-bottom: 32px;
        }

        .teaser-events--item {
            & ~ .teaser-events--item {
                margin-top: 32px;
            }
        }

        .teaser-events--link {
            display: flex;
            border-bottom: 1px solid $c--monochrome-paper-white;
            transition: transition-props($trs--base, color, border-bottom-color);
            color: $c--monochrome-quasi-black;

            @include has-focus(true) {
                border-bottom-color: currentColor;

                .teaser-events--event-title,
                .teaser-events--event-description,
                .teaser-events--event-icon {
                    color: currentColor;
                }

                @include all-themes-color('dark');
            }
        }

        .teaser-events--event-info {
            display: flex;
            flex: 1 1 100%;
            flex-direction: column;
            padding: 0 32px;

            @include mq('>xs') {
                justify-content: center;
            }

            @include mq('<=sm') {
                padding: 0 24px;
            }

            @include mq('<=xs') {
                padding: 8px 16px 0;
            }
        }

        .teaser-events--event-title {
            @include type--h3;

            @include mq('>sm') {
                padding-bottom: 4px;
            }
        }

        .teaser-events--event-icon {
            display: flex;
            align-items: center;

            @include all-themes-color;
        }

        .teaser-events--event-description {
            color: $c--monochrome-dark-gray;

            @include multi-line-ellipsis;
            @include type--copy-small;

            @include mq('<=md') {
                padding-bottom: 8px;
            }

            @include mq('<=xxs') {
                display: none;
            }
        }

        .teaser-events--more-link {
            display: flex;
            justify-content: center;

            @include ff--base-medium;
        }

        .teaser-events--no-events-col {
            display: flex;
            justify-content: center;
            text-align: center;
            margin: 0 auto 32px;
            padding-top: 40px;
            padding-bottom: 40px;
            border-top: 1px solid $c--monochrome-paper-white;
            border-bottom: 1px solid $c--monochrome-paper-white;

            @include grid--span((ratio: 8 / 12, mq: ('>lg')));
            @include grid--span((ratio: 10 / 12, mq: 'lg'));
            @include grid--span((ratio: 12 / 12, mq: '<=md'));
        }

        .teaser-events--hook {
            position: absolute;
            top: 50%;
        }
    }

    // Facets


    // States
}
