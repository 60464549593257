/* stylelint-disable plugin/selector-bem-pattern */
$hamburger-layer-spacing: 1px;
$hamburger-layer-width: 18px;
$hamburger-layer-height: 2px;
$hamburger-hover-transition-duration: 200ms;
$hamburger-layer-color: $c--label-text;
$hamburger-types: (elastic);

@import 'node_modules/hamburgers/_sass/hamburgers/hamburgers';

.hamburger {
    z-index: z-index(hamburger);
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    padding: 16px;

    .hamburger-inner {
        &::before {
            display: none;
        }
    }
}
