.pagination {
    // Vars

    // Support

    // Module
    & {
        margin-top: 64px;

        @include mq('<=sm') {
            margin-top: 48px;
        }

        @include grid--root((row: 'collection', cols: 'item'));
        @include grid--space((row: 'collection', cols: 'item', gutter: 16px));


        .pagination--collection {
            display: flex;
            justify-content: center;
            align-items: center;

            @include ff--base-medium;
        }

        .pagination--link {
            color: $c--monochrome-dark-gray;

            @include has-focus(true) {
                color: $c--monochrome-quasi-black;
            }
        }
    }

    // Facets

    & {
        .pagination--item {
            &.pagination--item__disabled {
                color: $c--monochrome-silver;
            }
        }
    }

    // States
}
