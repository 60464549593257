.event-date {
    // Vars

    // Support

    // Module
    & {

        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        text-align: right;
        padding-left: 8px;
        border-bottom: 4px solid currentColor;

        // this transform allows the borders overlapping
        transform: translate(0, 1px);

        @include mq('xxl') {
            min-width: 110px;
        }

        @include mq('xl') {
            min-width: 96px;
        }

        @include mq(('lg', 'md')) {
            min-width: 76px;
        }

        @include mq('<=sm') {
            padding-left: 0;
            min-width: 68px;
            border-bottom-width: 3px;
        }

        .event-date--day {
            @include type--h1-big;
            transform: translate(0.1em, 0.1em);
            color: currentColor;

            // trick to avoid the !important declaration
            &.event-date--day {
                line-height: 1.2;
            }
        }

        .event-date--month,
        .event-date--year {
            white-space: nowrap;

            @include type--h5;
            @include ff--base-medium;
            color: currentColor;
        }
    }

    // Facets

    // States
}
