/// Primary
///
@mixin btn--transparent {
    // Vars

    // Support

    // Module

    // Facets

    // States
    & {
        // Base
        @include btn--apply-states(('disabled': false)) {
            @include btn--transparent-base;
        }

        // Focus
        @include btn--apply-states(('disabled': false, 'focus': true)) {
            @include btn--transparent-focus;
        }

        // Active
        @include btn--apply-states(('disabled': false, 'focus': default, 'active': true)) {
            @include btn--transparent-active;
        }

        // Disabled
        @include btn--apply-states(('disabled': true, 'focus': default)) {
            @include btn--transparent-disabled;
        }
    }
}

/// Base
///
@mixin btn--transparent-base {
    border-color: transparent;
    background: transparent;

    @include all-themes-color;
}

/// Focus
///
@mixin btn--transparent-focus {
    @include all-themes-color('dark');
}

/// Active
///
@mixin btn--transparent-active {
    @include all-themes-color('dark');
}

/// Disabled
///
@mixin btn--transparent-disabled {
    // the at root is needed here to override the theming colors
    @include at-root('html') {
        color: $c--monochrome-paper-white;
    }
}
