.richtext {
    // Vars
    $external-link-icon-path: 'M19,19 L5,19 L5,5 L12,5 L12,3 L5,3 C3.89,3 3,3.9 3,5 L3,19 C3,20.1 3.89,21 5,21 L19,21 C20.1,21 21,20.1 21,19 L21,12 L19,12 L19,19 Z M14,3 L14,5 L17.59,5 L7.76,14.83 L9.17,16.24 L19,6.41 L19,10 L21,10 L21,3 L14,3 Z';
    $arrow-icon-path: 'M15.814 7l-1.12 1.172 2.938 2.988H5v1.68h12.632l-2.938 2.988L15.814 17l4.866-5z';

    // Support

    // Module
    & {
        h1, h2, h3, h4 {
            display: inline-block;
            padding-bottom: 24px;
        }

        h1, h2 {
            @include unwrapped-title;
        }

        h3 {
            padding-top: 40px;
            padding-bottom: 24px;
        }

        h4 {
            padding-top: 16px;
            padding-bottom: 16px;
        }

        h1, h2, h3, h4, p, ul, ol {
            width: 100%;
        }

        p {
            display: inline-block;
            margin-bottom: 16px;
        }

        ul, ol {
            margin-bottom: 16px;
        }

        table {
            @include type--copy-tiny;
            border-collapse: collapse;
            margin: 0;
            padding: 0;
            width: 100%;
            table-layout: auto;
            margin-bottom: 16px;
            margin-left: -8px;
            margin-right: -8px;
        }

        table p {
            margin-bottom: 0;
        }

        table thead th {
            @include type--copy-tiny;
            @include ff--base-medium;
        }

        table tr {
            border-bottom: 1px solid $c--monochrome-light-gray;
            padding: 4px;
        }

        table th,
        table td {
            padding: 8px;
        }

        table th {
            text-align: left;
        }

        // mobile styles
        @include mq('<=md') {
            table {
                border: 0;
                margin-left: -12px;
                margin-right: -12px;
            }

            table thead {
                position: absolute;
                border: none;
                clip: rect(0 0 0 0);
                height: 1px;
                margin: -1px;
                overflow: hidden;
                padding: 0;
                width: 1px;
            }

            table tr {
                display: block;
                margin-bottom: 32px;
                border-bottom: 0;
            }

            table td {
                border-bottom: 1px solid $c--monochrome-light-gray;
                display: block;
                text-align: right;
            }

            table td::before {
                content: attr(data-label);
                float: left;
            }

            table td:last-child {
                border-bottom: 0;
            }
        }

        .richtext--arrow-link,
        .richtext--external-link {
            display: inline-flex;
            align-items: center;
            margin-bottom: 24px;

            @include ff--base-medium;

            &::before,
            &::after {
                content: '';
                display: block;
                flex: 0 0 24px;
                width: 24px;
                height: 24px;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
            }
        }

        /* stylelint-disable string-quotes */
        .richtext--external-link {
            &::after {
                display: none;
            }

            &::before {
                margin-right: 8px;

                @each $name, $theme in $themes {
                    @include at-root($themes-root-el, theme-name-to-css-selector($name)) {
                        background-image: url(svg--create(24, 24, '<path fill="#{get-theme-color($name)}" d="#{$external-link-icon-path}"/>'));
                    }
                }
            }

            @include has-focus(true) {
                &::before {
                    @each $name, $theme in $themes {
                        @include at-root($themes-root-el, theme-name-to-css-selector($name)) {
                            background-image: url(svg--create(24, 24, '<path fill="#{get-theme-color($name, 'dark')}" d="#{$external-link-icon-path}"/>'));
                        }
                    }
                }
            }
        }

        .richtext--arrow-link {
            &::before {
                display: none;
            }

            &::after {
                margin-left: 4px;

                @each $name, $theme in $themes {
                    @include at-root($themes-root-el, theme-name-to-css-selector($name)) {
                        background-image: url(svg--create(24, 24, '<path fill="#{get-theme-color($name)}" d="#{$arrow-icon-path}"/>'));
                    }
                }
            }

            @include has-focus(true) {
                &::after {
                    @each $name, $theme in $themes {
                        @include at-root($themes-root-el, theme-name-to-css-selector($name)) {
                            background-image: url(svg--create(24, 24, '<path fill="#{get-theme-color($name, 'dark')}" d="#{$arrow-icon-path}"/>'));
                        }
                    }
                }
            }
        }

        /* stylelint-enable string-quotes */
    }

    // Facets

    // States
}
