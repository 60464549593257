.skip-to-content {
    // Vars

    // Support

    // Module
    & {
        @include hide-visually;

        @include has-focus(true) {
            top: 0;
            left: 0;
            max-width: initial;
            max-height: initial;
            padding: 16px;
            border: 0;
            margin: auto;
            clip: initial;
            background: $c--themes-association;
            color: $c--monochrome-white;

            @include ff--base-medium;
        }
    }

    // Facets

    // States
}
