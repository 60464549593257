.accordion-wrapper {
    .accordion-wrapper--head {
        display: flex;
        flex-wrap: nowrap;
        width: 100%;
    }
}

@keyframes flash {
    0%, 100% {
        background-color: initial;
    }

    50% {
        background-color: transparentize($c--brand-primary, 0.8);
    }
}

.accordion-wrapper--highlight {
    animation: flash 2500ms ease-in-out;
}
