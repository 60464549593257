.inline-video {
    // Vars

    // Support

    // Module
    & {
        margin-bottom: 48px;

        @media print {
            display: none;
        }

        .inline-video--title {
            margin-bottom: 24px;

            @include type--h2;
            @include unwrapped-title;
        }

        .inline-video--wrapper {
            position: relative;
            margin: 0 auto;
            height: 0;
            padding-top: 9 / 16 * 100%;
        }

        .inline-video--frame {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            box-shadow: $shadow-level-1;
        }
    }

    // Facets

    // States
}
