.collection-points-opening-hours-notice {
    // Vars

    // Support

    // Module
    & {
        @include type--copy;
        display: block;
    }

    // Facets
    & {
        &.collection-points-opening-hours-notice__base {
            @include type--copy-tiny;
            font-weight: $fw--medium;

            &.collection-points-opening-hours-notice__opening-state-open {
                color: $c--themes-recycling;
            }

            &.collection-points-opening-hours-notice__opening-state-closing-soon {
                color: $c--themes-safety;
            }

            &.collection-points-opening-hours-notice__opening-state-closed {
                color: $c--monochrome-dark-gray;
            }

            &.collection-points-opening-hours-notice__opening-state-no-info {
                color: $c--monochrome-dark-gray;
            }
        }

        &.collection-points-opening-hours-notice__in-detail {
            color: $c--monochrome-dark-gray;
            font-weight: $fw--medium;
        }
    }

    // States
}
