.document-link {
    // Vars

    // Support

    // Module
    & {
        box-shadow: $shadow-level-1;
        transition: $trs--common-props;
        border-radius: $sz--border-radius-base;
        overflow: hidden;
        margin-bottom: 16px;

        @include has-focus(true) {
            box-shadow: $shadow-level-2;
        }
    }

    // Facets

    // States
}
