.arrow-link {
    // Vars

    // Support

    // Module
    & {
        display: flex;
        align-items: center;

        @include ff--base-medium;

        .arrow-link--text {
            padding-right: 8px;
        }
    }

    // Facets

    // States
}
