.header-detail {
    // Vars

    // Support

    // Module
    & {
        padding: 24px 0 0;

        @include mq('<=sm') {
            padding: 12px 0 0;
        }

        .header-detail--breadcrumbs {
            display: flex;
            margin-bottom: 16px;
        }

        .header-detail--breadcrumbs-item {
            display: flex;
            align-items: center;
        }

        .header-detail--title {
            @include type--h1-big;
            @include type--h1-big-indentation;
        }

        .header-detail--title-col {
            @include grid--span((ratio: 11 / 12, mq: '>sm'));
        }

        .header-detail--breadcrumbs-link {
            color: $c--monochrome-dark-gray;

            @include type--copy-tiny;
            @include ff--base-medium;

            &[href] {
                @include has-focus(true) {
                    color: $c--monochrome-black-gray;
                }
            }
        }
    }

    // Facets

    // States
}
