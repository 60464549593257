.desktop-menu-dropdown {
    // Vars

    // Support
    @mixin link-active($theme-name) {
        color: get-theme-color($theme-name, 'dark');
    }

    // Module
    & {
        border-top: 1px solid $c--monochrome-light-gray;
        background: $c--monochrome-white;
        padding: 24px 16px 0;
        box-shadow: $shadow-level-1;
        border-radius: 0 0 $sz--border-radius-base $sz--border-radius-base;
        transition: box-shadow $trs--base;

        .desktop-menu-dropdown--menu-list-col {
            @include grid--span((ratio: 9 / 12));
        }

        .desktop-menu-dropdown--title-col {
            @include grid--span((ratio: 3 / 12));
        }

        .desktop-menu-dropdown--person-col {
            display: flex;
            margin-bottom: 16px;

            @include grid--span((ratio: 9 / 12));
        }

        .desktop-menu-dropdown--menu-col {
            padding-bottom: 24px;
            box-shadow: -1px 0 0 0 $c--monochrome-light-gray;

            @include grid--span((ratio: 4 / 12));

            @include last-row(3) {
                margin-top: 16px;
            }
        }

        .desktop-menu-dropdown--title {
            font-size: 2.4rem;
            color: $c--monochrome-black-gray;

            @include type--h2;
        }

        .desktop-menu-dropdown--subtitle {
            margin-bottom: 8px;
            padding: 14px 0 8px;
            color: $c--monochrome-black-gray;

            @include type--h6;
            @include ff--base-bold-italic;
        }

        .desktop-menu-dropdown--link {
            color: $c--monochrome-dark-gray;
            display: inline-block;
            padding: 8px 0;

            @include type--copy-small;
            @include ff--base-medium;
        }
    }

    // Facets
    & {
        @include at-root('.desktop-menu', '.desktop-menu__big-shadow') {
            box-shadow: $shadow-level-3;
        }

        .desktop-menu-dropdown--link {
            &.desktop-menu-dropdown--link__team {
                margin-top: 16px;
            }
        }

        @each $name, $theme in $themes {
            &.desktop-menu-dropdown__#{$name} {
                .desktop-menu-dropdown--link {
                    &.desktop-menu-dropdown--link__active {
                        @include link-active($name);
                    }

                    @include has-focus(true) {
                        @include link-active($name);
                    }
                }
            }
        }
    }

    // States
}
