/* stylelint-disable max-nesting-depth,  plugin/selector-bem-pattern, selector-no-qualifying-type */
.rate-table {
    // Vars

    // Support

    // Module
    & {
        width: 100%;

        @include grid--root((row: 'filters', cols: 'filter'));
        @include grid--space((row: 'filters', cols: 'filter', gutter: (16px, 16px)));

        .rate-table--loader {
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 1 1 auto;
            min-height: 200px;
        }

        .rate-table--content {
            display: flex;
            flex: 1 1 auto;
            flex-direction: column;
        }

        .rate-table--wrapper {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
        }

        .rate-table--link {
            display: flex;
            align-items: center;
        }

        .rate-table--link-label {
            margin-left: 8px;

            transform: translate(0, 1px);

            @include type--copy-small;
            @include ff--base-medium;
        }

        .rate-table--filters {
            margin-bottom: 16px;

            @include mq('>lg') {
                justify-content: space-between;
            }
        }

        .rate-table--filter {
            align-items: center;

            @include grid--col((ratio: 6 / 12, mq: '>lg'));
            @include grid--col((ratio: 12 / 12, mq: '<=lg'));
        }

        table {
            tbody,
            thead {
                tr.VueTables__no-results {
                    display: block;
                    width: 100%;
                    padding-top: 16px;
                }
            }

            tr {
                th:nth-child(1) {
                    white-space: nowrap;
                    width: 22%;
                }

                th:nth-last-child(5) {
                    white-space: nowrap;
                    width: 22%;
                }

                th:nth-last-child(5),
                th:nth-last-child(4),
                th:nth-last-child(3),
                th:nth-last-child(2),
                th:nth-last-child(1) {
                    width: 14%;
                }
            }

            tbody {
                tr {
                    td:nth-last-child(2),
                    td:last-of-type {
                        @include ff--base-medium;
                    }
                }
            }
        }
    }

    // Facets
    & {
        .rate-table--filter {
            &.rate-table--filter__checkbox {
                display: flex;
                padding-top: 16px;
                padding-bottom: 16px;
            }

            &.rate-table--filter__export {
                @include grid--col((ratio: 12 / 12, mq: '>lg'));
            }
        }
    }

    // States
}
