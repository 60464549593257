.social-list {
    // Vars

    // Support

    // Module
    & {
        width: 100%;

        .social-list--title {
            @include ff--base-bold-italic;
            color: $c--monochrome-quasi-black;

            font-size: $fz--xx-small;
            line-height: $lh--xxxx-small;
            margin-bottom: 16px;
        }

        .social-list--list-item {
            display: inline-block;
            color: $c--monochrome-dark-gray;

            @include mq('>sm') {
                padding-right: 24px;
            }

            @include mq(('sm')) {
                padding-right: 18px;
            }

            @include mq('<=xs') {
                padding-right: 10%;
            }

            &:last-child {
                padding-right: 0;
            }
        }
    }

    // Facets
    & {
        &.social-list__association {
            margin-bottom: 48px;
        }

        @each $name, $theme in $themes {
            &.social-list__#{$name} {
                .social-list--list-item {
                    @include has-focus(true) {
                        color: get-theme-color($name, 'dark');
                    }
                }
            }
        }
    }

    // States
}
