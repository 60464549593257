.side-panel {
    // Vars

    // Support

    // Module
    & {
        z-index: z-index(side-panel);
        position: fixed;
        top: 0;
        left: 0;
        flex-direction: column;

        display: none;
        width: 100%;
        height: 100%;

        background: $c--monochrome-white;

        @include mq('<=md') {
            display: flex;
        }

        .side-panel--menu-wrap {
            overflow: auto;
            flex: 1 1 auto;

            @include ios-native-scrolling;
        }

        .side-panel--header {
            margin-top: 8px;
            padding-top: 20px;
            padding-bottom: 20px;
        }

        .side-panel--header-row {
            justify-content: flex-end;
        }

        .side-panel--breadcrumbs {
            display: flex;
            flex: 0 0 auto;
            align-items: center;
            padding: 12px 0;
            border-top: 1px solid $c--monochrome-paper-white;
            border-bottom: 1px solid $c--monochrome-paper-white;
        }

        .side-panel--back {
            display: flex;
            align-items: center;
            color: $c--monochrome-dark-gray;
        }

        .side-panel--link {
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            padding: 16px 0;
            color: $c--monochrome-dark-gray;

            @include ff--base-medium;

            @include has-focus(true) {
                color: get-theme-color();
            }

            @each $name, $theme in $themes {
                &.side-panel--link__#{$name} {
                    @include has-focus(true) {
                        color: get-theme-color($name, 'dark');
                    }
                }
            }
        }

        .side-panel--category-title {
            margin: 32px 0 0;

            @include type--h2;
        }

        .side-panel--group-title {
            font-size: 1.2rem;
            padding: 0 0 8px;
            margin-top: -16px;

            @include ff--base-bold-italic;
        }

        .side-panel--col {
            display: flex;
            flex-direction: column;
            margin: auto;

            @include grid--col((ratio: 8 / 12, mq: 'md'));
            @include grid--col((ratio: 12 / 12, mq: '<=sm'));
        }

        .side-panel--group {
            display: flex;
            flex: 1 1 100%;
            min-width: 100%;
            font-size: 1.7rem;
            padding: 16px 0;

            &:first-of-type {
                .side-panel--group-title {
                    margin-top: 0;
                    padding-top: 16px;
                }
            }
        }

        .side-panel--collection {
            width: 100%;
        }
    }

    // Facets
    & {
        border-top: 4px solid transparent;

        @each $name, $theme in $themes {
            &.side-panel__#{$name} {
                border-top: 4px solid get-theme-color($name);
            }
        }

        &.side-panel__root {
            .side-panel--group:last-of-type {
                padding-top: 20px;
                font-size: 1.5rem;
                border-top: 1px solid $c--monochrome-paper-white;

                .side-panel--link {
                    padding: 12px 0;
                }
            }
        }

        .side-panel--link {
            &.side-panel--link__active {
                color: get-theme-color();
            }

            &.side-panel--link__registration {
                color: get-theme-color();

                @include ff--base-medium;
            }

            @each $name, $theme in $themes {
                &.side-panel--link__#{$name} {
                    &.side-panel--link__active {
                        color: get-theme-color($name, 'dark');
                    }
                }
            }
        }
    }

    // States
}
