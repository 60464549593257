////
/// Header styles (hX)
////

@mixin type--h1-big {
    color: $c--monochrome-quasi-black;

    @include ff--base-medium;
    @include responsive-typography('h1-big');
}

@mixin type--h1-big-indentation {
    transform: translate(0, -0.06em);
}

@mixin type--h1 {
    color: $c--monochrome-quasi-black;

    @include ff--base-medium;
    @include responsive-typography('h1');
}

@mixin type--h1-indentation {
    transform: translate(0, -0.05em);
}


@mixin type--h2 {
    color: $c--monochrome-quasi-black;

    @include ff--base-medium;
    @include responsive-typography('h2');
}

@mixin type--h2-indentation {
    transform: translate(0, -0.03em);
}

@mixin type--h3 {
    color: $c--monochrome-quasi-black;

    @include ff--base-medium;
    @include responsive-typography('h3');
}

@mixin type--h3-indentation {
    transform: translate(0, -0.03em);
}

@mixin type--h4 {
    color: $c--monochrome-quasi-black;

    @include ff--base-medium;
    @include responsive-typography('h4');
}

@mixin type--h4-indentation {
    transform: translate(0, -0.02em);
}

@mixin type--h5 {
    color: $c--monochrome-quasi-black;

    @include ff--base-medium;
    @include responsive-typography('h5');
}

@mixin type--h5-indentation {
    transform: translate(0, -0.01em);
}

@mixin type--h6 {
    color: $c--monochrome-quasi-black;


    @include ff--base-medium;
    @include responsive-typography('h6');
}

@mixin type--h6-indentation {
    transform: translate(0, -0.01em);
}
