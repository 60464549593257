.link-collection {
    // Vars

    // Support

    // Module
    & {
        .link-collection--item {
            ~ .link-collection--item {
                border-top: 1px solid $c--monochrome-paper-white;
            }
        }
    }

    // Facets

    & {
        &.link-collection__focus-themes {
            .link-collection--item:last-of-type {
                @include mq('>lg') {
                    border-bottom: 1px solid $c--monochrome-paper-white;
                }
            }
        }
    }

    & {
        &.link-collection__single {
            margin-bottom: 16px;
        }
    }

    // States
}
