// stylelint-disable unit-blacklist

////
/// Support styles, to make a print version more useful
////


@page {
    size: A4; // stylelint-disable-line value-keyword-case
    margin: 24mm 0;
}

@media print {
    html, body {
        width: 210mm;
        height: 297mm;
    }
}
