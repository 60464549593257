.sticky-panel {
    // Vars

    // Support

    // Module
    & {
        z-index: z-index(sticky-panel);
        position: relative;

        @media print {
            display: none;
        }

        .sticky-panel--wrap {
            transform: translate3d(0, 0, 0);
            pointer-events: none;

            @include mq('>sm') {
                position: absolute;
                top: 0;
                width: 100%;
            }
        }

        .sticky-panel--col {
            pointer-events: initial;
            display: flex;
            flex-wrap: wrap;

            @include grid--span((ratio: 3 / 12, mq: '>sm'));
            @include grid--span((ratio: 4 / 12, mq: 'md'));
            @include grid--span((ratio: 12 / 12, mq: '<=sm'));

            @include mq('<=sm') {
                align-items: center;
                justify-content: space-between;
            }
        }

        .sticky-panel--main {
            @include mq('>sm') {
                margin-top: 0.5em;
            }

            @include mq('>md') {
                padding-top: 64px;
            }

            @include mq('md') {
                padding-top: 48px;
            }
        }

        .sticky-panel--main {
            flex: 1 1 100%;

            &:empty {
                display: none;
            }
        }

        .sticky-panel--footer {
            flex: 0 1 auto;

            @include mq('<=sm') {
                flex: 1 1 100%;
            }
        }

        .sticky-panel--footer {
            @include mq('>sm') {
                margin-top: 64px;
                margin-bottom: 64px;
            }

            @include mq('<=sm') {
                display: flex;
                justify-content: center;
                margin-bottom: 24px;
                margin-top: 24px;
                padding: 16px;

                // add a border without impacting on the bounding box
                box-shadow: 0 -1px 0 0 $c--monochrome-light-gray;
            }
        }
    }

    // Facets

    & {
        &.sticky-panel__is-sticky {
            .sticky-panel--wrap {
                position: fixed;
            }
        }

        &.sticky-panel__is-hidden {
            .sticky-panel--wrap {
                visibility: hidden;
            }
        }

        &.sticky-panel__detail-page {
            .sticky-panel--footer {
                @include mq('<=sm') {
                    // add a border without impacting on the bounding box
                    box-shadow: -1px 0 0 0 $c--monochrome-light-gray;
                    margin: 0;
                    padding: 0;
                }
            }

            .sticky-panel--main,
            .sticky-panel--footer {
                @include mq('<=sm') {
                    display: flex;
                    justify-content: center;
                    flex: 1 1 50%;
                }
            }
        }
    }

    // States
}
