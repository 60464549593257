.collection-points-search-results {
    // Vars

    // Support

    // Module
    & {
        .collection-points-search-results--item {
            ~ .collection-points-search-results--item {
                border-top: 1px solid $c--monochrome-paper-white;
            }
        }
    }

    // Facets

    // States
}
