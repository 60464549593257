// stylelint-disable
// # Here we can do a bit of CSS wrestling
// Our client has 3rd party javascript that injects form fields in certain pages and we need to style them with the site look and feel
// At moment we have 3 different types of form fields on this site (default, iframe and these ones)


._form-content {
    margin-bottom: 24px;
    
    ._form-title {
        font-weight: bold;
    }
    
    ._form-label {
        font-size: $fz--medium;
        margin-bottom: 4px;
    }
    
    ._error-inner {
        margin-top: 8px;
        color: $c--signal-error;
        
        @include type--copy-tiny;
    }
    
    ._field-wrapper {
        margin-bottom: 16px;
    }
    
    input:not([type="checkbox"]):not([type="radio"]), textarea {
        color: $c--label-text;
        background-color: $c--monochrome-white;
        border-color: $c--monochrome-dark-gray;
        border-style: solid;
        border-radius: $sz--border-radius-base;
        border-width: 1px;
        line-height: 1;
        transition: $trs--common-props;
        
        @include ui-form-field--size-large;
        
        @include mq('>sm') {
            min-width: 480px;
        }
        
        @include mq('<=sm') {
            min-width: 100%;
        }
        
        &:focus {
            box-shadow: $shadow-level-2;
            border-color: transparent;
        }
    }
    
    button {
        @extend .btn;
        @extend .btn.btn__primary;
        @extend .btn.btn__large;
    }
}
