// The code above is needed to create the grid borders properly
// see also https://stackoverflow.com/questions/47882924/preventing-double-borders-in-css-grid
//
// @example
// @include grid-tiles-borders((
//    (mq: '>lg', columns: 4),
//    (mq: ('lg', 'md', 'sm'), columns: 2),
//    (mq: '<=xs')
// ));
@mixin grid-tiles-borders($queries, $border: 1px solid $c--monochrome-paper-white) {
    @each $query in $queries {
        $mq: map-get($query, 'mq');
        $columns: map-get($query, 'columns');
        $items-offset: map-get($query, 'offset');

        $offset: if($items-offset, $items-offset, 0);

        @include mq($mq) {
            border-bottom: $border;

            @if $columns and $columns > 0 {
                $num-cols: $columns + (if($offset > 0, $offset, 0));
                $selector: nth(nth(&, 1), length(nth(&, 1)));

                border-right: $border;

                // fork of the last-row mixin
                &:nth-child(#{$columns}n + #{1 + $offset}):nth-last-child(-n + #{$columns}),
                &:nth-child(#{$columns}n + #{1 + $offset}):nth-last-child(-n + #{$columns}) ~ #{$selector} {
                    border-bottom: none;
                }

                &:nth-child(#{$columns}n+#{$offset}) {
                    border-right: none;
                }
            } @else {
                border-right: none;

                &:last-child {
                    border-bottom: none;
                }
            }
        }
    }
}
