.opacc-iframe {
    // Vars

    // Support

    // Module
    & {
        padding: 64px 0;

        @include mq('<=sm') {
            padding: 48px 0;
        }

        .opacc-iframe--col {
            margin: 0 auto;

            @include grid--col((ratio: 6 / 12, mq: '>lg'));
            @include grid--col((ratio: 8 / 12, mq: 'lg'));
            @include grid--col((ratio: 8 / 12, mq: 'md'));
            @include grid--col((ratio: 12 / 12, mq: '<=sm'));

            @include mq('<=xs') {
                overflow: auto;
                width: 100%;
            }
        }
    }

    // Facets

    // States
}
