/// Web-static folder
///
$web-static: '../..';

/// BEM options
///
$bem-element-separator: '--';
$bem-modifier-separator: '__';

/// Media queries and breakpoints
/// See: `util/mq.scss`

/// Colors
/// See: `util/colors.scss`

/// Layers (z-index)
///
$layers: (
    content: 0,
    sticky-panel: 8,
    side-panel: 9,
    desktop-menu-dropdown: 11,
    desktop-menu-second-level: 12,
    hamburger: 15,
    search: 16,
    dropdown: 17,
    cookie-banner: 99,
    alert-banner: 99,
);

/// Fonts
///
$fz--medium: 1.5rem;
$fz--small: 1.4rem;
$fz--x-small: 1.3rem;
$fz--xx-small: 1.2rem;

$lh--medium: 1.2;
$lh--small: 1.25;
$lh--x-small: 1.3;
$lh--xx-small: 1.47;
$lh--xxx-small: 1.85;
$lh--xxxx-small: 2.17;

/// Sizes & spacing
///
$sz--list-icon-width: 0.6em;
$sz--list-icon-gutter: 0.8em;
$sz--list-padding: ($sz--list-icon-width + $sz--list-icon-gutter);
$sz--list-vertical-spacing: 8px;

/// Transitions & animations
///
$trs--base-speed: 200ms;
$trs--base-fn: ease;
$trs--base: $trs--base-speed $trs--base-fn;

$trs--header-search-speed: 300ms;
$trs--header-search-fn: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$trs--header-search: $trs--header-search-speed $trs--header-search-fn;

$trs--news-slider-speed: 600ms;
$trs--news-slider-fn: cubic-bezier(0.19, 1, 0.22, 1);
$trs--news-slider: $trs--news-slider-speed $trs--news-slider-fn;

$trs--accordion-speed: 300ms;
$trs--accordion-fn: ease;
$trs--accordion: $trs--accordion-speed $trs--accordion-fn;

$trs--common-property-list: color, border-color, background-color, opacity, transform, box-shadow;
$trs--common-props: transition-props($trs--base, $trs--common-property-list...);

/// Border radius
///
$sz--border-radius-base: 4px;
$sz--border-width-base: 2px;

// Cards
$sz--card-padding: 24px;
$sz--card-padding-xs: 16px;
