.deeplink-panel {
    // Vars

    // Support

    // Module
    & {
        @include grid--root((row: 'collection', cols: 'item'));
        @include grid--space((row: 'collection', cols: 'item', gutter: 0, mq: '>md'));

        .deeplink-panel--collection {
            box-shadow: $shadow-level-3;
            transition: box-shadow $trs--base;
            border-radius: $sz--border-radius-base;
            overflow: hidden;
            background: $c--monochrome-white;

            @include has-focus(true) {
                box-shadow: $shadow-level-4;
            }
        }

        .deeplink-panel--title-col {
            text-align: center;
            margin: 0 auto 24px;

            @include grid--span((ratio: 8 / 12, mq: '>sm'));
            @include grid--span((ratio: 12 / 12, mq: '<=sm'));
        }

        .deeplink-panel--title {
            @include type--h2;
        }

        .deeplink-panel--item {
            overflow: hidden;

            @include grid--span((ratio: 3 / 12, mq: '>lg'));
            @include grid--span((ratio: 6 / 12, mq: ('lg', 'md')));
            @include grid--span((ratio: 12 / 12, mq: '<=sm'));

            /*
            @include has-focus(true) {
                @each $name, $theme in $themes {
                    @include at-root($themes-root-el, theme-name-to-css-selector($name)) {
                        border-color: get-theme-color($name);
                    }
                }
            }
            */

            @include grid-tiles-borders((
                (mq: '>lg', columns: 4),
                (mq: ('lg', 'md', 'sm'), columns: 2),
                (mq: '<=xs', columns: 0)
            ));
        }
    }

    // Facets

    // States
}
