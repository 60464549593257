.news-list {
    // Vars

    // Support

    // Module
    & {
        .news-list--collection {
            box-shadow: $shadow-level-1;
            transition: box-shadow $trs--base;
            border-radius: $sz--border-radius-base;

            @include has-focus(true) {
                box-shadow: $shadow-level-2;
            }
        }

        .news-list--item {
            ~ .news-list--item {
                border-top: 1px solid $c--monochrome-paper-white;
            }
        }

        .news-list--link {
            display: flex;
            align-items: center;
        }

        .news-list--card {
            flex: 1 1 auto;
        }

        .news-list--icon {
            padding-right: 24px;
        }
    }

    // Facets
    & {
        .news-list--item {
            @each $name, $theme in $themes {
                &.news-list--item__#{$name} {
                    .news-list--link {
                        color: get-theme-color($name);

                        @include has-focus(true) {
                            $theme-color: get-theme-color($name, 'dark');
                            color: $theme-color;

                            * { // stylelint-disable-line
                                color: $theme-color;
                            }
                        }
                    }
                }
            }
        }
    }

    // States
}
