.collection-points-search-result {
    // Vars

    // Support

    // Module
    & {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        padding: 8px 16px;
        transition: background-color $trs--base;

        @include has-focus(true) {
            background-color: $c--monochrome-paper-white;
        }

        .collection-points-search-result--icon {
            flex: 0 0 24px;
        }

        .collection-points-search-result--text {
            position: relative;
            transform: translateY(3px);
            flex: 0 1 100%;
            padding-left: 12px;
        }

        .collection-points-search-result--title {
            color: $c--monochrome-dark-gray;
        }

        .collection-points-search-result--description {
            @include type--copy-tiny;
        }
    }

    // Facets

    & {
        .collection-points-search-result--icon {
            &.collection-points-search-result--icon__is-active {
                color: get-theme-color('recycling');
            }
        }
    }

    // States
}
