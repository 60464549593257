.teaser-text-images {
    // Vars

    // Support

    // Module
    & {
        .teaser-text-images--text-col {
            margin: 0 auto;

            @include grid--span((ratio: 8 / 12, mq: '>md'));
            @include grid--span((ratio: 10 / 12, mq: 'md'));
            @include grid--span((ratio: 12 / 12, mq: '<=sm'));
        }

        .teaser-text-images--title {
            @include centered-section-title;
        }

        .teaser-text-images--text {
            text-align: center;
            margin-bottom: 32px;
        }

        .teaser-text-images--cta {
            display: flex;
            justify-content: center;
            margin-bottom: 48px;
        }

        .teaser-text-images--collection.teaser-text-images--collection {
            justify-content: center;
        }

        .teaser-text-images--item {
            text-align: center;

            @include grid--span((ratio: 2 / 12, mq: '>md'));
            @include grid--span((ratio: 12 / 12, mq: '<=sm'));

            @include mq('<=sm') {
                & ~ .teaser-text-images--item {
                    margin-top: 24px;
                }
            }
        }
    }

    // Facets

    // States
}
