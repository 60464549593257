.color-filter {
    // Vars

    // Support

    // Module
    & {
        pointer-events: none;
        z-index: 10000;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }


    @keyframes animate-arcobaleno {
        0% {
            background-position: 0% 99%;
        }

        50% {
            background-position: 100% 2%;
        }

        100% {
            background-position: 0% 99%;
        }
    }
    // Facets

    & {
        &.color-filter__arcobaleno {
            background-image: linear-gradient(45deg, orange, yellow, green, cyan, blue, violet); // stylelint-disable-line
            background-size: 100% 1000%;
            mix-blend-mode: overlay;
            animation: animate-arcobaleno 10000ms ease infinite;
        }

        &.color-filter__what-is-love {
            background-image: linear-gradient(45deg, #eb80ff, #ff0065); // stylelint-disable-line
            background-size: 100% 1000%;
            mix-blend-mode: overlay;
        }
    }

    // States
}
