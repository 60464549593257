.testimonial {
    // Vars

    // Support

    // Module
    & {
        .testimonial--container {
            position: relative;
        }

        .testimonial--image-col {
            @include grid--span((ratio: 6 / 12, mq: '>sm'));
            @include grid--span((ratio: 12 / 12, mq: '<=sm'));
        }

        .testimonial--quote-col {
            display: flex;
            flex-wrap: nowrap;
            align-items: flex-start;
            justify-content: flex-end;

            @media print {
                justify-content: unset;
            }

            @include grid--span((ratio: 6 / 12, mq: '>sm'));
            @include grid--span((ratio: 12 / 12, mq: '<=sm'));

            @include mq('>sm') {
                padding-top: 16%;
            }
        }

        .testimonial--quote-wrap {
            padding: 40px;
            background: $c--monochrome-white;
            border: 4px solid $c--monochrome-paper-white;

            @include grid--span((ratio: 7 / 6, mq: '>sm'));


            @include mq('<=sm') {
                padding: 16px;
                margin-top: -16px;
                width: calc(100% - 16px);
            }

            @media print {
                width: 100%;
                margin-top: 0;
            }
        }

        .testimonial--quote-text {
            margin-bottom: 24px;

            &::before {
                content: '\00ab';
            }

            &::after {
                content: '\00bb';
            }

            @include type--quote;
        }

        .testimonial--image {
            width: 100%;

            @include mq('<=sm') {
                padding-right: 16px;
            }
        }

        .testimonial--author {
            display: inline-block;
            width: 100%;

            @include type--copy;
            @include ff--base-medium;
        }

        .testimonial--role {
            color: $c--monochrome-silver;

            @include type--copy-small;
        }
    }

    // Facets
    & {
        &.testimonial__without-image {
            .testimonial--quote-col {
                display: block;
                padding-top: 0;

                @include mq('>md') {
                    margin: auto 40px;
                }
            }

            .testimonial--quote-wrap {
                width: 100%;
            }
        }
    }

    // States
}
