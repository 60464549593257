/* stylelint-disable max-nesting-depth */
.search-results {
    // Vars

    // Support

    // Module
    & {
        padding: 40px 0 0;

        @include mq('<=sm') {
            padding: 24px 0 0;
        }


        .search-results--col {
            margin: 0 auto;

            @include grid--span((ratio: 8 / 12, mq: '>md'));
            @include grid--span((ratio: 10 / 12, mq: 'md'));
            @include grid--span((ratio: 12 / 12, mq: '<=sm'));
        }

        .search-results--collection {
            padding: 64px 0 0;

            @include mq('<=sm') {
                padding: 48px 0 0;
            }
        }

        .search-results--item {
            ~ .search-results--item {
                padding: 64px 0 0;

                @include mq('<=sm') {
                    padding: 32px 0 0;
                }
            }
        }

        .search-results--cite {
            margin-bottom: 8px;
            display: inline-block;

            @include type--copy-small;
            @include ff--base-medium;
        }
    }

    // Facets

    & {
        @each $name, $theme in $themes {
            $theme-color: get-theme-color($name);

            .search-results--item {
                &.search-results--item__#{$name} {
                    .search-results--description {
                        > span {
                            background: rgba($theme-color, 0.3);
                        }
                    }

                    .search-results--cite {
                        color: $theme-color;
                    }

                    .search-results--link {
                        @include has-focus(true) {
                            .search-results--cite {
                                color: get-theme-color($name, 'dark');
                            }

                            .search-results--description {
                                > span {
                                    background: rgba($theme-color, 0.4);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    // States
}
