$shadow-level-1: 0 1px 3px rgba(0, 0, 0, 0.09), 0 1px 2px rgba(0, 0, 0, 0.20);
$shadow-level-2: 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 3px 6px 0 rgba(0, 0, 0, 0.12);
$shadow-level-3: 0 16px 32px 0 rgba(0, 0, 0, 0.08), 0 8px 16px 0 rgba(0, 0, 0, 0.12);
$shadow-level-4: 0 20px 32px 0 rgba(0, 0, 0, 0.2), 0 12px 16px 0 rgba(0, 0, 0, 0.12);

$shadows: (
    'shadow-level-1': $shadow-level-1,
    'shadow-level-2': $shadow-level-2,
    'shadow-level-3': $shadow-level-3,
    'shadow-level-4': $shadow-level-4,
);
