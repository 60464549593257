/* stylelint-disable max-nesting-depth */

.desktop-menu {
    // Vars

    @mixin first-level-link-active-style($theme-name) {
        color: get-theme-color($theme-name, 'dark');
    }

    @mixin second-level-active-style($color-name) {
        color: get-theme-color($color-name, 'dark');
        border-bottom: 1px solid get-theme-color($color-name, 'dark');
    }

    // Module
    & {
        position: relative;

        .desktop-menu--collection {
            display: flex;
            align-items: center;
            border-radius: $sz--border-radius-base;
        }

        .desktop-menu--shadow {
            position: relative;
            box-shadow: $shadow-level-1;
            transition: box-shadow $trs--base;
            border-radius: $sz--border-radius-base;
            background-color: $c--monochrome-white;
        }

        .desktop-menu--wrap {
            @include grid--col((ratio: 12 / 12));
        }

        .desktop-menu--link {
            display: flex;
            padding-left: 16px;
            padding-right: 16px;
            color: $c--monochrome-dark-gray;
            transform: translate(0, 1px);
            cursor: pointer;

            @include mq('>xl') {
                padding-left: 24px;
                padding-right: 24px;
            }

            @include ff--base-medium;

            @include has-focus(true) {
                color: $c--monochrome-quasi-black;
            }
        }

        .desktop-menu--dropdown {
            z-index: z-index('desktop-menu-dropdown');
            position: absolute;
            left: 0;
            top: 100%;
            width: 100%;

            &::before {
                content: '';
                position: absolute;
                top: -$sz--border-radius-base;
                left: 0;
                height: $sz--border-radius-base;
                width: 100%;
                background: $c--monochrome-white;
            }
        }
    }

    // Facets
    & {
        &.desktop-menu__big-shadow {
            .desktop-menu--shadow {
                box-shadow: $shadow-level-3;
            }
        }

        &.desktop-menu__no-shadow {
            .desktop-menu--shadow {
                box-shadow: none;
            }
        }

        .desktop-menu--collection {
            /**
            * START first level navigation styling
            */

            &.desktop-menu--collection__first {
                > .desktop-menu--item {
                    > .desktop-menu--link {
                        padding-top: 20px;
                        padding-bottom: 18px;

                        @include type--copy-small;
                        @include ff--base-medium;

                        @include mq('<=xl') {
                            padding-top: 17px;
                            padding-bottom: 16px;
                        }
                    }

                    &.desktop-menu--item__search {
                        margin-left: auto;

                        .desktop-menu--link {
                            padding-top: 16px;
                            padding-bottom: 16px;
                        }
                    }

                    @each $name, $theme in $themes {
                        &.desktop-menu--item__#{$name} {
                            > .desktop-menu--link {
                                &.desktop-menu--link__active {
                                    @include first-level-link-active-style($name);
                                }

                                @include has-focus(true) {
                                    @include first-level-link-active-style($name);
                                }
                            }
                        }
                    }


                    &.desktop-menu--item__active {
                        border-radius: $sz--border-radius-base $sz--border-radius-base 0 0;
                        background: $c--monochrome-white;
                    }
                }
            }

            /**
            * END first level navigation styling
            */

            /**
            * START second level navigation styling
            */

            &.desktop-menu--collection__second {
                position: absolute;
                left: 0;
                width: 100%;

                &::before {
                    content: '';
                    z-index: z-index('desktop-menu-second-level');
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 100%;
                    width: 100%;
                    background: $c--monochrome-white;
                    border-radius: 0 0 $sz--border-radius-base $sz--border-radius-base;
                }

                > .desktop-menu--item {
                    > .desktop-menu--details > .desktop-menu--link,
                    > .desktop-menu--link {
                        z-index: z-index('desktop-menu-second-level') + 1;
                        position: relative;

                        @include type--copy-small;
                        @include ff--base-medium;
                    }

                    .desktop-menu--link-label {
                        display: inline-block;
                        padding-top: 19px;
                        padding-bottom: 18px;
                        border-bottom: 1px solid transparent;

                        @include type--copy-small;
                        @include ff--base-medium;

                        @include mq('xl') {
                            padding-top: 17px;
                            padding-bottom: 15px;
                        }

                        @include mq('<=lg') {
                            padding-top: 18px;
                            padding-bottom: 16px;
                        }
                    }
                }
            }

            .desktop-menu--item {
                @each $name, $theme in $themes {
                    &.desktop-menu--item__#{$name} {
                        .desktop-menu--collection__second {
                            .desktop-menu--item {
                                .desktop-menu--details[open] {
                                    .desktop-menu--link-label {
                                        @include second-level-active-style($name);
                                    }
                                }

                                .desktop-menu--details > .desktop-menu--link,
                                > .desktop-menu--link {
                                    &.desktop-menu--link__active {
                                        color: get-theme-color($name, 'dark');
                                    }

                                    @include has-focus(true) {
                                        color: get-theme-color($name, 'dark');
                                    }
                                }
                            }
                        }
                    }
                }
            }

            /**
            * END second level navigation styling
            */
        }
    }

    // States
    & {
        &.desktop-menu__open {
            .desktop-menu--collection__first {
                background: $c--monochrome-light-gray;
                padding-bottom: 64px;

                @include mq('<=xl') {
                    padding-bottom: 56px;
                }

                > .desktop-menu--item {
                    > .desktop-menu--link {
                        padding-top: 12px;
                        padding-bottom: 11px;

                        @include mq('<=lg') {
                            padding-top: 13px;
                            padding-bottom: 12px;
                        }

                        @include type--menu-meta;
                    }

                    &.desktop-menu--item__search {
                        .desktop-menu--link {
                            padding-top: 10px;
                            padding-bottom: 10px;
                        }
                    }
                }
            }
        }
    }
}
