.old-browser {
    // Vars

    // Support

    // Module
    & {
        background: $c--monochrome-light-gray;

        @include grid--root((row: 'btn-group', cols: 'btn-col'));
        @include grid--space((row: 'btn-group', cols: 'btn-col', gutter: (24px, 24px)));

        @media print {
            display: none;
        }

        .old-browser--wrapper {
            width: 100%;
            text-align: center;
        }

        .old-browser--container {
            padding: 48px 0;
        }

        .old-browser--paragraph {
            margin-bottom: 32px;
        }

        .old-browser--title {
            margin-bottom: 16px;
        }

        .old-browser--btn {
            box-shadow: $shadow-level-3;
            color: $c--monochrome-white;
            background: get-theme-color('recycling');

            @include has-focus(true) {
                box-shadow: $shadow-level-4;
            }
        }

        .old-browser--btn-group {
            justify-content: center;
        }
    }

    // Facets

    // States
}
