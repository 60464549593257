.form-field-messages {
    // Vars

    // Support

    // Module
    & {
        position: relative;
        margin-top: 8px;
        text-align: left;

        .form-field-messages--message {
            display: block;

            @include type--copy-tiny;

            ~ .form-field-messages--message {
                margin-top: 3px;
            }

            &.form-field-messages--message__info {
                color: $c--signal-info;
            }

            &.form-field-messages--message__error {
                color: $c--signal-error;
            }
        }
    }

    // Facets

    // States
}
