.inline-links-menu {
    // Vars

    // Support

    // Module
    & {
        .inline-links-menu--content {
            padding-left: 8px;
            border-left: 2px solid $c--monochrome-paper-white;

            @include type--copy-small;
        }

        .inline-links-menu--link {
            display: inline-flex;
            align-items: center;
            padding: 8px 0;

            @include all-themes-color;
            @include ff--base-medium;

            @include has-focus(true) {
                @include all-themes-color('dark');
            }
        }

        .inline-links-menu--text {
            margin-right: 8px;
            hyphens: auto;
        }

        .inline-links-menu--dropdown {
            position: relative;
        }

        .inline-links-menu--title {
            font-size: 1.2rem;
            color: $c--monochrome-black-gray;
            margin-bottom: 8px;

            @include ff--base-bold-italic;
        }
    }

    // Facets

    // States
    & {
        .inline-links-menu--link {
            &.inline-links-menu--link__is-active {
                @include all-themes-color('dark');
            }
        }
    }
}
