.collection-points-map {
    // Vars

    // Support

    // Module
    & {
        .collection-points-map--outer-wrapper {
            position: relative;
            width: 100%;
            padding-top: 100% / 3 * 2;

            @include mq('md') {
                padding-top: 100% / 1 * 1;
            }
        }

        .collection-points-map--inner-wrapper {
            @include overlay;
        }

        .collection-points-map--element {
            width: 100%;
            height: 100%;
        }

        .collection-points-map--info-panel {
            pointer-events: none;
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            flex-flow: row nowrap;
            align-items: flex-start;
            height: 100%;
            padding: 16px;
        }

        // These styles are necessary to override inline styles by the `@google/markercluster` module
        .collection-points-map--marker-cluster {
            /* stylelint-disable declaration-no-important */
            > img {
                pointer-events: none;
                width: 100%;
                height: 100%;
                opacity: 0;
            }

            > div {
                color: $c--themes-recycling !important;
                font-size: 1.7rem !important;
                line-height: 1 !important;

                display: flex;
                flex-flow: row nowrap;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                background-color: $c--monochrome-white;
                border-radius: 50%;
                box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.09), 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 0 0 4px rgba(#5fb378, 0.3);
                border: solid 2px $c--themes-recycling;
                box-sizing: border-box;
            }
            /* stylelint-enable declaration-no-important */
        }
    }

    // Facets

    // States
}
