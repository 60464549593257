.auth {
    // Vars

    // Support

    // Module
    & {
        margin: 120px 0 72px;
        text-align: center;

        @include mq('md') {
            margin: 64px 0 16px;
        }

        @include mq('<=md') {
            margin: 84px 0 36px;
        }

        .auth--form-wrap {
            padding: 32px 24px 24px;
            background-color: $c--monochrome-white;
            box-shadow: $shadow-level-1;
            transition: box-shadow $trs--base;
            border-radius: $sz--border-radius-base;

            @include has-focus(true) {
                box-shadow: $shadow-level-2;
            }
        }

        .auth--col {
            margin: 0 auto;

            @include grid--span((ratio: 4 / 12, mq: '>md'));
            @include grid--span((ratio: 6 / 12, mq: 'md'));
            @include grid--span((ratio: 12 / 12, mq: '<=sm'));
        }

        .auth--title {
            margin-bottom: 16px;
        }

        .auth--description {
            margin-bottom: 8px;
        }

        .auth--list {
            margin-bottom: 32px;
            text-align: left;
        }

        .auth--form-row {
            position: relative;
            display: flex;
            flex-direction: column;
            margin-bottom: 16px;
        }
    }

    // Facets
    & {
        .auth--form-row {
            &.auth--form-row__button {
                margin-bottom: 16px;
            }
        }
    }

    // States
}
