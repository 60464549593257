.news-wall {
    // Vars

    // Support

    // Module
    & {
        @include grid--root((row: 'collection', cols: 'item'));
        @include grid--space((row: 'collection', cols: 'item', gutter: 0));

        .news-wall--collection {
            border-radius: $sz--border-radius-base;
            transition: box-shadow $trs--base;
            box-shadow: $shadow-level-1;

            @include has-focus(true) {
                box-shadow: $shadow-level-2;
            }
        }

        .news-wall--item {
            overflow: hidden;

            @include grid--col((ratio: 3 / 12, mq: '>md'));
            @include grid--col((ratio: 4 / 12, mq: 'md'));
            @include grid--col((ratio: 12 / 12, mq: '<=sm'));

            @include grid-tiles-borders((
                (mq: '>md', columns: 4, offset: 2),
                (mq: 'md', columns: 3, offset: 1),
                (mq: '<=sm', columns: 0)
            ));

            &:first-child {
                @include grid--col((ratio: 8 / 12, mq: '>md'));
                @include grid--col((ratio: 12 / 12, mq: 'md'));
            }

            &:nth-child(2) {
                @include grid--col((ratio: 4 / 12, mq: '>md'));
            }
        }
    }

    // Facets
    & {
        .news-wall--link {
            @each $name, $theme in $themes {
                $theme-color: get-theme-color($name, 'dark');

                &.news-wall--link__#{$name} {
                    @include has-focus(true) {
                        color: $theme-color;

                        * { // stylelint-disable-line
                            color: $theme-color;
                        }

                        figure { // stylelint-disable-line
                            filter: saturate(80%);
                        }
                    }
                }
            }
        }
    }

    // States
}
