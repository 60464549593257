.collection-points-details {
    // Vars

    // Support

    // Module
    & {
        @include grid--root((row: 'content-items', cols: 'content-item'));
        @include grid--space((row: 'content-items', cols: 'content-item', gutter: (24px 0)));

        .collection-points-details--content {
            width: 100%;
            padding: 24px 20px 32px 16px;
        }

        .collection-points-details--content-item {
            @include grid--span((ratio: 1 / 1));
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
        }

        .collection-points-details--content-icon {
            flex: 0 0 24px;
        }

        .collection-points-details--content-icon-el {
            position: relative;

            @include mq('<=lg') {
                top: -1px;
            }
        }

        .collection-points-details--content-text {
            flex: 0 1 100%;
            padding-left: 12px;
        }

        .collection-points-details--back {
            //
        }

        .collection-points-details--back-btn {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            width: 100%;
            height: 100%;
            border: none;
            padding: 0 16px;
            text-align: left;

            @include has-focus(true) {
                color: $c--themes-recycling;
            }

            @include btn--state-active(true) {
                color: $c--themes-recycling-dark;
            }
        }

        .collection-points-details--back-btn-icon {
            margin-right: 12px;
        }

        .collection-points-details--back-btn-text {
            position: relative;
            transform: translateY(2px);
        }

        .collection-points-details--map {
            position: relative;
        }

        .collection-points-details--map-link {
            display: block;
            width: 100%;
            padding-top: 100% / 16 * 9;
        }

        .collection-points-details--map-image {
            @include overlay;
            display: block;
        }

        .collection-points-details--map-marker {
            z-index: 1;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -100%);
        }

        .collection-points-details--title {
            color: $c--monochrome-black-gray;
        }

        .collection-points-details--title-link {
            color: $c--themes-recycling;

            @include has-focus(true) {
                color: $c--themes-recycling-dark;
            }
        }

        .collection-points-details--phone {
            @include ff--base-medium;
            color: $c--themes-recycling;

            @include has-focus(true) {
                color: $c--themes-recycling-dark;
            }
        }

        .collection-points-details--opening-hours-table {
            width: 100%;
            color: $c--monochrome-dark-gray;
        }

        .collection-points-details--opening-hours-disclaimer {
            @include type--copy-tiny;
            display: block;
            margin-top: 8px;
            color: $c--monochrome-dark-gray;
        }

        .collection-points-details--opening-hours-notice {
            margin-bottom: 8px;
        }

        .collection-points-details--location {
            @include has-focus(true) {
                color: $c--monochrome-quasi-black;
            }
        }

        .collection-points-details--directions {
            @include ff--base-medium;
        }
    }

    // Facets

    // States
}
