.legal {
    // Vars

    // Support

    // Module
    & {
        border-top: solid 1px $c--monochrome-paper-white;
        padding: 24px 0;
        margin: 0;

        @include mq('<=xs') {
            margin-top: 0;
        }

        @media print {
            padding: 12px;
        }

        .legal--content {
            display: flex;
            flex-wrap: wrap;
            align-items: baseline;
        }

        .legal--copyright {
            font-size: $fz--x-small;
            line-height: $lh--xxx-small;
            display: inline-flex;
            margin-right: 20px;

            @include mq('<=xs') {
                margin-bottom: 12px;
            }
        }

        .legal--collection {
            display: flex;
            align-items: baseline;

            @include mq('<=xs') {
                width: 100%;
                flex-direction: column;
            }
        }

        .legal--link {
            font-size: $fz--x-small;
            line-height: $lh--xxx-small;
            display: inline-block;

            color: $c--monochrome-dark-gray;

            @include ff--base-medium;

            @include has-focus(true) {
                color: $c--monochrome-quasi-black;
            }

            @include mq('>xs') {
                padding: 0 20px;
            }

            @include mq('<=xs') {
                padding: 12px 0;
            }
        }
    }

    // Facets

    // States
}
