/* stylelint-disable scss/declaration-nested-properties */
/* mixin for multiline */
/* stylelint-disable scss/media-feature-value-dollar-variable */
/**
 * Render the background primary color based on the theme received.
 */
/**
 * Use the theme primary color to style the color of a specific DOM element
 */
/**
 * Loop all the themes using the $themes variable,
 * if the body has that class to set the component will inherit the color property from the selected theme
 */
/**
 * Loop all the themes using the $themes variable
 * if the body has that class to set the component inherit the background primary color from the selected theme
 */
/**
 * Helper function if we want to override the default themes in specific components
 */
/* stylelint-disable */
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   dreipol fork
   License: none (public domain)
*/
@import url("//hello.myfonts.net/count/3894d0");
@import url("https://fonts.googleapis.com/css?family=IBM+Plex+Serif:400i");
html, body, div, span, iframe, object,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details,
figure, figcaption, footer, header,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote::before, blockquote::after,
q::before, q::after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

summary {
  cursor: pointer;
  display: inline-block;
  list-style: none; }
  summary::-webkit-details-marker, summary::before {
    display: none; }

.u-mq-spy-type::before,
.u-mq-spy-conditions::before,
.u-mq-spy-expressions::before {
  position: absolute;
  overflow: hidden;
  max-width: 1px;
  max-height: 1px;
  padding: 0;
  border: 0;
  margin: -1px;
  clip: rect(0 0 0 0); }

html.u-mq-info .u-mq-spy-conditions::before {
  pointer-events: none;
  z-index: 9999;
  position: fixed;
  bottom: 0;
  left: 0;
  display: block;
  width: auto;
  height: auto;
  max-width: 50%;
  max-height: none;
  padding: 15px;
  margin: 0;
  clip: auto;
  font-family: monospace;
  font-size: 12px;
  line-height: 1.1;
  word-wrap: break-word;
  color: #ffffff;
  background-color: rgba(22, 25, 40, 0.9);
  border-top-right-radius: 5px;
  text-align: left; }

html.u-mq-info .u-mq-spy-conditions::before {
  transition: visibility 0ms ease 2000ms;
  visibility: hidden; }

@media screen {
  .u-mq-spy-type::before {
    content: '"screen"'; } }

@media print {
  .u-mq-spy-type::before {
    content: '"print"'; } }

.u-mq-spy-conditions::before {
  content: '{"width":null,"orientation":null,"resolution":null}'; }

@media (max-width: 374px) {
  .u-mq-spy-conditions::before {
    content: '{"width":"xxs","orientation":null,"resolution":null}'; } }

@media (min-width: 375px) and (max-width: 543px) {
  .u-mq-spy-conditions::before {
    content: '{"width":"xs","orientation":null,"resolution":null}'; } }

@media (min-width: 544px) and (max-width: 767px) {
  .u-mq-spy-conditions::before {
    content: '{"width":"sm","orientation":null,"resolution":null}'; } }

@media (min-width: 768px) and (max-width: 991px) {
  .u-mq-spy-conditions::before {
    content: '{"width":"md","orientation":null,"resolution":null}'; } }

@media (min-width: 992px) and (max-width: 1279px) {
  .u-mq-spy-conditions::before {
    content: '{"width":"lg","orientation":null,"resolution":null}'; } }

@media (min-width: 1280px) and (max-width: 1919px) {
  .u-mq-spy-conditions::before {
    content: '{"width":"xl","orientation":null,"resolution":null}'; } }

@media (min-width: 1920px) {
  .u-mq-spy-conditions::before {
    content: '{"width":"xxl","orientation":null,"resolution":null}'; } }

@media (orientation: portrait) {
  .u-mq-spy-conditions::before {
    content: '{"width":null,"orientation":"portrait","resolution":null}'; } }

@media (max-width: 374px) and (orientation: portrait) {
  .u-mq-spy-conditions::before {
    content: '{"width":"xxs","orientation":"portrait","resolution":null}'; } }

@media (min-width: 375px) and (max-width: 543px) and (orientation: portrait) {
  .u-mq-spy-conditions::before {
    content: '{"width":"xs","orientation":"portrait","resolution":null}'; } }

@media (min-width: 544px) and (max-width: 767px) and (orientation: portrait) {
  .u-mq-spy-conditions::before {
    content: '{"width":"sm","orientation":"portrait","resolution":null}'; } }

@media (min-width: 768px) and (max-width: 991px) and (orientation: portrait) {
  .u-mq-spy-conditions::before {
    content: '{"width":"md","orientation":"portrait","resolution":null}'; } }

@media (min-width: 992px) and (max-width: 1279px) and (orientation: portrait) {
  .u-mq-spy-conditions::before {
    content: '{"width":"lg","orientation":"portrait","resolution":null}'; } }

@media (min-width: 1280px) and (max-width: 1919px) and (orientation: portrait) {
  .u-mq-spy-conditions::before {
    content: '{"width":"xl","orientation":"portrait","resolution":null}'; } }

@media (min-width: 1920px) and (orientation: portrait) {
  .u-mq-spy-conditions::before {
    content: '{"width":"xxl","orientation":"portrait","resolution":null}'; } }

@media (orientation: landscape) {
  .u-mq-spy-conditions::before {
    content: '{"width":null,"orientation":"landscape","resolution":null}'; } }

@media (max-width: 374px) and (orientation: landscape) {
  .u-mq-spy-conditions::before {
    content: '{"width":"xxs","orientation":"landscape","resolution":null}'; } }

@media (min-width: 375px) and (max-width: 543px) and (orientation: landscape) {
  .u-mq-spy-conditions::before {
    content: '{"width":"xs","orientation":"landscape","resolution":null}'; } }

@media (min-width: 544px) and (max-width: 767px) and (orientation: landscape) {
  .u-mq-spy-conditions::before {
    content: '{"width":"sm","orientation":"landscape","resolution":null}'; } }

@media (min-width: 768px) and (max-width: 991px) and (orientation: landscape) {
  .u-mq-spy-conditions::before {
    content: '{"width":"md","orientation":"landscape","resolution":null}'; } }

@media (min-width: 992px) and (max-width: 1279px) and (orientation: landscape) {
  .u-mq-spy-conditions::before {
    content: '{"width":"lg","orientation":"landscape","resolution":null}'; } }

@media (min-width: 1280px) and (max-width: 1919px) and (orientation: landscape) {
  .u-mq-spy-conditions::before {
    content: '{"width":"xl","orientation":"landscape","resolution":null}'; } }

@media (min-width: 1920px) and (orientation: landscape) {
  .u-mq-spy-conditions::before {
    content: '{"width":"xxl","orientation":"landscape","resolution":null}'; } }

@media (min-resolution: 2dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":null,"orientation":null,"resolution":"res2x"}'; } }

@media (max-width: 374px) and (min-resolution: 2dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":"xxs","orientation":null,"resolution":"res2x"}'; } }

@media (min-width: 375px) and (max-width: 543px) and (min-resolution: 2dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":"xs","orientation":null,"resolution":"res2x"}'; } }

@media (min-width: 544px) and (max-width: 767px) and (min-resolution: 2dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":"sm","orientation":null,"resolution":"res2x"}'; } }

@media (min-width: 768px) and (max-width: 991px) and (min-resolution: 2dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":"md","orientation":null,"resolution":"res2x"}'; } }

@media (min-width: 992px) and (max-width: 1279px) and (min-resolution: 2dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":"lg","orientation":null,"resolution":"res2x"}'; } }

@media (min-width: 1280px) and (max-width: 1919px) and (min-resolution: 2dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":"xl","orientation":null,"resolution":"res2x"}'; } }

@media (min-width: 1920px) and (min-resolution: 2dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":"xxl","orientation":null,"resolution":"res2x"}'; } }

@media (orientation: portrait) and (min-resolution: 2dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":null,"orientation":"portrait","resolution":"res2x"}'; } }

@media (max-width: 374px) and (orientation: portrait) and (min-resolution: 2dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":"xxs","orientation":"portrait","resolution":"res2x"}'; } }

@media (min-width: 375px) and (max-width: 543px) and (orientation: portrait) and (min-resolution: 2dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":"xs","orientation":"portrait","resolution":"res2x"}'; } }

@media (min-width: 544px) and (max-width: 767px) and (orientation: portrait) and (min-resolution: 2dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":"sm","orientation":"portrait","resolution":"res2x"}'; } }

@media (min-width: 768px) and (max-width: 991px) and (orientation: portrait) and (min-resolution: 2dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":"md","orientation":"portrait","resolution":"res2x"}'; } }

@media (min-width: 992px) and (max-width: 1279px) and (orientation: portrait) and (min-resolution: 2dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":"lg","orientation":"portrait","resolution":"res2x"}'; } }

@media (min-width: 1280px) and (max-width: 1919px) and (orientation: portrait) and (min-resolution: 2dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":"xl","orientation":"portrait","resolution":"res2x"}'; } }

@media (min-width: 1920px) and (orientation: portrait) and (min-resolution: 2dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":"xxl","orientation":"portrait","resolution":"res2x"}'; } }

@media (orientation: landscape) and (min-resolution: 2dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":null,"orientation":"landscape","resolution":"res2x"}'; } }

@media (max-width: 374px) and (orientation: landscape) and (min-resolution: 2dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":"xxs","orientation":"landscape","resolution":"res2x"}'; } }

@media (min-width: 375px) and (max-width: 543px) and (orientation: landscape) and (min-resolution: 2dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":"xs","orientation":"landscape","resolution":"res2x"}'; } }

@media (min-width: 544px) and (max-width: 767px) and (orientation: landscape) and (min-resolution: 2dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":"sm","orientation":"landscape","resolution":"res2x"}'; } }

@media (min-width: 768px) and (max-width: 991px) and (orientation: landscape) and (min-resolution: 2dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":"md","orientation":"landscape","resolution":"res2x"}'; } }

@media (min-width: 992px) and (max-width: 1279px) and (orientation: landscape) and (min-resolution: 2dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":"lg","orientation":"landscape","resolution":"res2x"}'; } }

@media (min-width: 1280px) and (max-width: 1919px) and (orientation: landscape) and (min-resolution: 2dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":"xl","orientation":"landscape","resolution":"res2x"}'; } }

@media (min-width: 1920px) and (orientation: landscape) and (min-resolution: 2dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":"xxl","orientation":"landscape","resolution":"res2x"}'; } }

@media (min-resolution: 3dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":null,"orientation":null,"resolution":"res3x"}'; } }

@media (max-width: 374px) and (min-resolution: 3dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":"xxs","orientation":null,"resolution":"res3x"}'; } }

@media (min-width: 375px) and (max-width: 543px) and (min-resolution: 3dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":"xs","orientation":null,"resolution":"res3x"}'; } }

@media (min-width: 544px) and (max-width: 767px) and (min-resolution: 3dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":"sm","orientation":null,"resolution":"res3x"}'; } }

@media (min-width: 768px) and (max-width: 991px) and (min-resolution: 3dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":"md","orientation":null,"resolution":"res3x"}'; } }

@media (min-width: 992px) and (max-width: 1279px) and (min-resolution: 3dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":"lg","orientation":null,"resolution":"res3x"}'; } }

@media (min-width: 1280px) and (max-width: 1919px) and (min-resolution: 3dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":"xl","orientation":null,"resolution":"res3x"}'; } }

@media (min-width: 1920px) and (min-resolution: 3dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":"xxl","orientation":null,"resolution":"res3x"}'; } }

@media (orientation: portrait) and (min-resolution: 3dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":null,"orientation":"portrait","resolution":"res3x"}'; } }

@media (max-width: 374px) and (orientation: portrait) and (min-resolution: 3dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":"xxs","orientation":"portrait","resolution":"res3x"}'; } }

@media (min-width: 375px) and (max-width: 543px) and (orientation: portrait) and (min-resolution: 3dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":"xs","orientation":"portrait","resolution":"res3x"}'; } }

@media (min-width: 544px) and (max-width: 767px) and (orientation: portrait) and (min-resolution: 3dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":"sm","orientation":"portrait","resolution":"res3x"}'; } }

@media (min-width: 768px) and (max-width: 991px) and (orientation: portrait) and (min-resolution: 3dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":"md","orientation":"portrait","resolution":"res3x"}'; } }

@media (min-width: 992px) and (max-width: 1279px) and (orientation: portrait) and (min-resolution: 3dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":"lg","orientation":"portrait","resolution":"res3x"}'; } }

@media (min-width: 1280px) and (max-width: 1919px) and (orientation: portrait) and (min-resolution: 3dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":"xl","orientation":"portrait","resolution":"res3x"}'; } }

@media (min-width: 1920px) and (orientation: portrait) and (min-resolution: 3dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":"xxl","orientation":"portrait","resolution":"res3x"}'; } }

@media (orientation: landscape) and (min-resolution: 3dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":null,"orientation":"landscape","resolution":"res3x"}'; } }

@media (max-width: 374px) and (orientation: landscape) and (min-resolution: 3dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":"xxs","orientation":"landscape","resolution":"res3x"}'; } }

@media (min-width: 375px) and (max-width: 543px) and (orientation: landscape) and (min-resolution: 3dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":"xs","orientation":"landscape","resolution":"res3x"}'; } }

@media (min-width: 544px) and (max-width: 767px) and (orientation: landscape) and (min-resolution: 3dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":"sm","orientation":"landscape","resolution":"res3x"}'; } }

@media (min-width: 768px) and (max-width: 991px) and (orientation: landscape) and (min-resolution: 3dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":"md","orientation":"landscape","resolution":"res3x"}'; } }

@media (min-width: 992px) and (max-width: 1279px) and (orientation: landscape) and (min-resolution: 3dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":"lg","orientation":"landscape","resolution":"res3x"}'; } }

@media (min-width: 1280px) and (max-width: 1919px) and (orientation: landscape) and (min-resolution: 3dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":"xl","orientation":"landscape","resolution":"res3x"}'; } }

@media (min-width: 1920px) and (orientation: landscape) and (min-resolution: 3dppx) {
  .u-mq-spy-conditions::before {
    content: '{"width":"xxl","orientation":"landscape","resolution":"res3x"}'; } }

.u-mq-spy-expressions::before {
  content: '[{"name":"screen","query":"screen","type":"type"},{"name":"print","query":"print","type":"type"},{"name":"xxs","query":"(max-width: 374px)","type":"width"},{"name":"xs","query":"(min-width: 375px) and (max-width: 543px)","type":"width"},{"name":"sm","query":"(min-width: 544px) and (max-width: 767px)","type":"width"},{"name":"md","query":"(min-width: 768px) and (max-width: 991px)","type":"width"},{"name":"lg","query":"(min-width: 992px) and (max-width: 1279px)","type":"width"},{"name":"xl","query":"(min-width: 1280px) and (max-width: 1919px)","type":"width"},{"name":"xxl","query":"(min-width: 1920px)","type":"width"},{"name":"portrait","query":"(orientation: portrait)","type":"orientation"},{"name":"landscape","query":"(orientation: landscape)","type":"orientation"},{"name":"res2x","query":"(min-resolution: 2dppx)","type":"resolution"},{"name":"res3x","query":"(min-resolution: 3dppx)","type":"resolution"}]'; }

.sass-data-provider {
  /*
        .sass-data-provider--example {
            @include data-bridge($example-data);
        }
        */ }

/*
@font-face {font-family: 'TTNorms-BlackItalic';src: url('webfonts/3894D0_0_0.eot');src: url('webfonts/3894D0_0_0.eot?#iefix') format('embedded-opentype'),url('webfonts/3894D0_0_0.woff2') format('woff2'),url('webfonts/3894D0_0_0.woff') format('woff'),url('webfonts/3894D0_0_0.ttf') format('truetype');}


@font-face {font-family: 'TTNorms-ExtraBoldItalic';src: url('webfonts/3894D0_1_0.eot');src: url('webfonts/3894D0_1_0.eot?#iefix') format('embedded-opentype'),url('webfonts/3894D0_1_0.woff2') format('woff2'),url('webfonts/3894D0_1_0.woff') format('woff'),url('webfonts/3894D0_1_0.ttf') format('truetype');}


@font-face {font-family: 'TTNorms-Black';src: url('webfonts/3894D0_2_0.eot');src: url('webfonts/3894D0_2_0.eot?#iefix') format('embedded-opentype'),url('webfonts/3894D0_2_0.woff2') format('woff2'),url('webfonts/3894D0_2_0.woff') format('woff'),url('webfonts/3894D0_2_0.ttf') format('truetype');}


@font-face {font-family: 'TTNorms-BoldItalic';src: url('webfonts/3894D0_3_0.eot');src: url('webfonts/3894D0_3_0.eot?#iefix') format('embedded-opentype'),url('webfonts/3894D0_3_0.woff2') format('woff2'),url('webfonts/3894D0_3_0.woff') format('woff'),url('webfonts/3894D0_3_0.ttf') format('truetype');}


@font-face {font-family: 'TTNorms-Bold';src: url('webfonts/3894D0_4_0.eot');src: url('webfonts/3894D0_4_0.eot?#iefix') format('embedded-opentype'),url('webfonts/3894D0_4_0.woff2') format('woff2'),url('webfonts/3894D0_4_0.woff') format('woff'),url('webfonts/3894D0_4_0.ttf') format('truetype');}


@font-face {font-family: 'TTNorms-ExtraBold';src: url('webfonts/3894D0_5_0.eot');src: url('webfonts/3894D0_5_0.eot?#iefix') format('embedded-opentype'),url('webfonts/3894D0_5_0.woff2') format('woff2'),url('webfonts/3894D0_5_0.woff') format('woff'),url('webfonts/3894D0_5_0.ttf') format('truetype');}


@font-face {font-family: 'TTNorms-ExtraLightItalic';src: url('webfonts/3894D0_6_0.eot');src: url('webfonts/3894D0_6_0.eot?#iefix') format('embedded-opentype'),url('webfonts/3894D0_6_0.woff2') format('woff2'),url('webfonts/3894D0_6_0.woff') format('woff'),url('webfonts/3894D0_6_0.ttf') format('truetype');}


@font-face {font-family: 'TTNorms-ExtraLight';src: url('webfonts/3894D0_7_0.eot');src: url('webfonts/3894D0_7_0.eot?#iefix') format('embedded-opentype'),url('webfonts/3894D0_7_0.woff2') format('woff2'),url('webfonts/3894D0_7_0.woff') format('woff'),url('webfonts/3894D0_7_0.ttf') format('truetype');}


@font-face {font-family: 'TTNorms-HeavyItalic';src: url('webfonts/3894D0_8_0.eot');src: url('webfonts/3894D0_8_0.eot?#iefix') format('embedded-opentype'),url('webfonts/3894D0_8_0.woff2') format('woff2'),url('webfonts/3894D0_8_0.woff') format('woff'),url('webfonts/3894D0_8_0.ttf') format('truetype');}


@font-face {font-family: 'TTNorms-Heavy';src: url('webfonts/3894D0_9_0.eot');src: url('webfonts/3894D0_9_0.eot?#iefix') format('embedded-opentype'),url('webfonts/3894D0_9_0.woff2') format('woff2'),url('webfonts/3894D0_9_0.woff') format('woff'),url('webfonts/3894D0_9_0.ttf') format('truetype');}


@font-face {font-family: 'TTNorms-Italic';src: url('webfonts/3894D0_A_0.eot');src: url('webfonts/3894D0_A_0.eot?#iefix') format('embedded-opentype'),url('webfonts/3894D0_A_0.woff2') format('woff2'),url('webfonts/3894D0_A_0.woff') format('woff'),url('webfonts/3894D0_A_0.ttf') format('truetype');}


@font-face {font-family: 'TTNorms-LightItalic';src: url('webfonts/3894D0_B_0.eot');src: url('webfonts/3894D0_B_0.eot?#iefix') format('embedded-opentype'),url('webfonts/3894D0_B_0.woff2') format('woff2'),url('webfonts/3894D0_B_0.woff') format('woff'),url('webfonts/3894D0_B_0.ttf') format('truetype');}


@font-face {font-family: 'TTNorms-Light';src: url('webfonts/3894D0_C_0.eot');src: url('webfonts/3894D0_C_0.eot?#iefix') format('embedded-opentype'),url('webfonts/3894D0_C_0.woff2') format('woff2'),url('webfonts/3894D0_C_0.woff') format('woff'),url('webfonts/3894D0_C_0.ttf') format('truetype');}


@font-face {font-family: 'TTNorms-MediumItalic';src: url('webfonts/3894D0_D_0.eot');src: url('webfonts/3894D0_D_0.eot?#iefix') format('embedded-opentype'),url('webfonts/3894D0_D_0.woff2') format('woff2'),url('webfonts/3894D0_D_0.woff') format('woff'),url('webfonts/3894D0_D_0.ttf') format('truetype');}


@font-face {font-family: 'TTNorms-Medium';src: url('webfonts/3894D0_E_0.eot');src: url('webfonts/3894D0_E_0.eot?#iefix') format('embedded-opentype'),url('webfonts/3894D0_E_0.woff2') format('woff2'),url('webfonts/3894D0_E_0.woff') format('woff'),url('webfonts/3894D0_E_0.ttf') format('truetype');}


@font-face {font-family: 'TTNorms-Regular';src: url('webfonts/3894D0_F_0.eot');src: url('webfonts/3894D0_F_0.eot?#iefix') format('embedded-opentype'),url('webfonts/3894D0_F_0.woff2') format('woff2'),url('webfonts/3894D0_F_0.woff') format('woff'),url('webfonts/3894D0_F_0.ttf') format('truetype');}


@font-face {font-family: 'TTNorms-ThinItalic';src: url('webfonts/3894D0_10_0.eot');src: url('webfonts/3894D0_10_0.eot?#iefix') format('embedded-opentype'),url('webfonts/3894D0_10_0.woff2') format('woff2'),url('webfonts/3894D0_10_0.woff') format('woff'),url('webfonts/3894D0_10_0.ttf') format('truetype');}


@font-face {font-family: 'TTNorms-Thin';src: url('webfonts/3894D0_11_0.eot');src: url('webfonts/3894D0_11_0.eot?#iefix') format('embedded-opentype'),url('webfonts/3894D0_11_0.woff2') format('woff2'),url('webfonts/3894D0_11_0.woff') format('woff'),url('webfonts/3894D0_11_0.ttf') format('truetype');}
*/
@font-face {
  font-family: "TTNorms";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("../../fonts/TTNorms/3894D0_F_0.woff2") format("woff2"), url("../../fonts/TTNorms/3894D0_F_0.woff") format("woff"), url("../../fonts/TTNorms/3894D0_F_0.ttf") format("ttf"); }

@font-face {
  font-family: "TTNorms";
  font-weight: 400;
  font-style: italic;
  font-display: swap;
  src: url("../../fonts/TTNorms/3894D0_A_0.woff2") format("woff2"), url("../../fonts/TTNorms/3894D0_A_0.woff") format("woff"), url("../../fonts/TTNorms/3894D0_A_0.ttf") format("ttf"); }

@font-face {
  font-family: "TTNorms";
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: url("../../fonts/TTNorms/3894D0_E_0.woff2") format("woff2"), url("../../fonts/TTNorms/3894D0_E_0.woff") format("woff"), url("../../fonts/TTNorms/3894D0_E_0.ttf") format("ttf"); }

@font-face {
  font-family: "TTNorms";
  font-weight: 700;
  font-style: italic;
  font-display: swap;
  src: url("../../fonts/TTNorms/3894D0_3_0.woff2") format("woff2"), url("../../fonts/TTNorms/3894D0_3_0.woff") format("woff"), url("../../fonts/TTNorms/3894D0_3_0.ttf") format("ttf"); }

.templates, .template, .controllers, .controller {
  display: none; }

.u-icon-source, .u-icon-sprite, .u-svg-filter-source {
  position: absolute;
  overflow: hidden;
  max-width: 1px;
  max-height: 1px;
  padding: 0;
  border: 0;
  margin: -1px;
  clip: rect(0 0 0 0); }

html {
  box-sizing: border-box; }

*, *::before, *::after {
  box-sizing: inherit;
  text-decoration: none; }

* {
  -ms-touch-action: pan-y;
  -ms-content-zooming: none; }

html, body {
  width: 100%;
  height: 100%; }

html {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  overflow: visible; }

body {
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  overflow-y: scroll;
  z-index: 0;
  cursor: auto;
  background-color: transparent;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  overflow-wrap: break-word;
  -ms-overflow-style: -ms-autohiding-scrollbar; }
  html[data-theme="association"] body::selection, html[data-theme="association"] body ::selection {
    background-color: rgba(217, 33, 61, 0.25); }
  html[data-theme="safety"] body::selection, html[data-theme="safety"] body ::selection {
    background-color: rgba(230, 110, 0, 0.25); }
  html[data-theme="recycling"] body::selection, html[data-theme="recycling"] body ::selection {
    background-color: rgba(44, 131, 76, 0.25); }
  html[data-theme="knowledge"] body::selection, html[data-theme="knowledge"] body ::selection {
    background-color: rgba(20, 105, 201, 0.25); }
  html[data-theme="education"] body::selection, html[data-theme="education"] body ::selection {
    background-color: rgba(99, 30, 101, 0.25); }
  html[data-theme="sustainability"] body::selection, html[data-theme="sustainability"] body ::selection {
    background-color: rgba(29, 163, 33, 0.25); }
  html[data-theme="default"] body::selection, html[data-theme="default"] body ::selection {
    background-color: rgba(217, 33, 61, 0.25); }

details *, details *::before, details *::after {
  box-sizing: border-box; }

details:not([open]) > :not(summary) {
  display: none; }

main {
  display: block; }

[v-cloak] {
  position: absolute;
  overflow: hidden;
  max-width: 1px;
  max-height: 1px;
  padding: 0;
  border: 0;
  margin: -1px;
  clip: rect(0 0 0 0); }

html {
  font-size: 62.5%; }

body {
  font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
  font-weight: 500;
  font-style: normal;
  font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
  color: #555555; }
  @media (min-width: 1920px) {
    body {
      font-size: 21px;
      line-height: 1.44; } }
  @media (min-width: 1280px) and (max-width: 1919px) {
    body {
      font-size: 19px;
      line-height: 1.57; } }
  @media (min-width: 992px) and (max-width: 1279px) {
    body {
      font-size: 17px;
      line-height: 1.52; } }
  @media (min-width: 768px) and (max-width: 991px) {
    body {
      font-size: 17px;
      line-height: 1.52; } }
  @media (min-width: 544px) and (max-width: 767px) {
    body {
      font-size: 17px;
      line-height: 1.52; } }
  @media (min-width: 375px) and (max-width: 543px) {
    body {
      font-size: 17px;
      line-height: 1.52; } }
  @media (max-width: 374px) {
    body {
      font-size: 17px;
      line-height: 1.52; } }

.skel h1:not(.u-reset),
.skel .u-reset__h1 {
  color: #111111;
  font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
  font-weight: 500;
  font-style: normal; }
  @media (min-width: 1920px) {
    .skel h1:not(.u-reset),
    .skel .u-reset__h1 {
      font-size: 36px;
      line-height: 1.25; } }
  @media (min-width: 1280px) and (max-width: 1919px) {
    .skel h1:not(.u-reset),
    .skel .u-reset__h1 {
      font-size: 35px;
      line-height: 1.25; } }
  @media (min-width: 992px) and (max-width: 1279px) {
    .skel h1:not(.u-reset),
    .skel .u-reset__h1 {
      font-size: 30px;
      line-height: 1.25; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .skel h1:not(.u-reset),
    .skel .u-reset__h1 {
      font-size: 28px;
      line-height: 1.25; } }
  @media (min-width: 544px) and (max-width: 767px) {
    .skel h1:not(.u-reset),
    .skel .u-reset__h1 {
      font-size: 27px;
      line-height: 1.25; } }
  @media (min-width: 375px) and (max-width: 543px) {
    .skel h1:not(.u-reset),
    .skel .u-reset__h1 {
      font-size: 26px;
      line-height: 1.25; } }
  @media (max-width: 374px) {
    .skel h1:not(.u-reset),
    .skel .u-reset__h1 {
      font-size: 25px;
      line-height: 1.25; } }

.skel h2:not(.u-reset),
.skel .u-reset__h2 {
  color: #111111;
  font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
  font-weight: 500;
  font-style: normal; }
  @media (min-width: 1920px) {
    .skel h2:not(.u-reset),
    .skel .u-reset__h2 {
      font-size: 29px;
      line-height: 1.25; } }
  @media (min-width: 1280px) and (max-width: 1919px) {
    .skel h2:not(.u-reset),
    .skel .u-reset__h2 {
      font-size: 25px;
      line-height: 1.25; } }
  @media (min-width: 992px) and (max-width: 1279px) {
    .skel h2:not(.u-reset),
    .skel .u-reset__h2 {
      font-size: 25px;
      line-height: 1.25; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .skel h2:not(.u-reset),
    .skel .u-reset__h2 {
      font-size: 24px;
      line-height: 1.25; } }
  @media (min-width: 544px) and (max-width: 767px) {
    .skel h2:not(.u-reset),
    .skel .u-reset__h2 {
      font-size: 24px;
      line-height: 1.25; } }
  @media (min-width: 375px) and (max-width: 543px) {
    .skel h2:not(.u-reset),
    .skel .u-reset__h2 {
      font-size: 22px;
      line-height: 1.3; } }
  @media (max-width: 374px) {
    .skel h2:not(.u-reset),
    .skel .u-reset__h2 {
      font-size: 21px;
      line-height: 1.4; } }

.skel h3:not(.u-reset),
.skel .u-reset__h3 {
  color: #111111;
  font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
  font-weight: 500;
  font-style: normal; }
  @media (min-width: 1920px) {
    .skel h3:not(.u-reset),
    .skel .u-reset__h3 {
      font-size: 21px;
      line-height: 1.25; } }
  @media (min-width: 1280px) and (max-width: 1919px) {
    .skel h3:not(.u-reset),
    .skel .u-reset__h3 {
      font-size: 19px;
      line-height: 1.3; } }
  @media (min-width: 992px) and (max-width: 1279px) {
    .skel h3:not(.u-reset),
    .skel .u-reset__h3 {
      font-size: 17px;
      line-height: 1.3; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .skel h3:not(.u-reset),
    .skel .u-reset__h3 {
      font-size: 17px;
      line-height: 1.3; } }
  @media (min-width: 544px) and (max-width: 767px) {
    .skel h3:not(.u-reset),
    .skel .u-reset__h3 {
      font-size: 17px;
      line-height: 1.3; } }
  @media (min-width: 375px) and (max-width: 543px) {
    .skel h3:not(.u-reset),
    .skel .u-reset__h3 {
      font-size: 17px;
      line-height: 1.3; } }
  @media (max-width: 374px) {
    .skel h3:not(.u-reset),
    .skel .u-reset__h3 {
      font-size: 17px;
      line-height: 1.4; } }

.skel h4:not(.u-reset),
.skel .u-reset__h4 {
  color: #111111;
  font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
  font-weight: 500;
  font-style: normal; }
  @media (min-width: 1920px) {
    .skel h4:not(.u-reset),
    .skel .u-reset__h4 {
      font-size: 19px;
      line-height: 1.4; } }
  @media (min-width: 1280px) and (max-width: 1919px) {
    .skel h4:not(.u-reset),
    .skel .u-reset__h4 {
      font-size: 17px;
      line-height: 1.4; } }
  @media (min-width: 992px) and (max-width: 1279px) {
    .skel h4:not(.u-reset),
    .skel .u-reset__h4 {
      font-size: 15px;
      line-height: 1.4; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .skel h4:not(.u-reset),
    .skel .u-reset__h4 {
      font-size: 15px;
      line-height: 1.4; } }
  @media (min-width: 544px) and (max-width: 767px) {
    .skel h4:not(.u-reset),
    .skel .u-reset__h4 {
      font-size: 15px;
      line-height: 1.4; } }
  @media (min-width: 375px) and (max-width: 543px) {
    .skel h4:not(.u-reset),
    .skel .u-reset__h4 {
      font-size: 15px;
      line-height: 1.4; } }
  @media (max-width: 374px) {
    .skel h4:not(.u-reset),
    .skel .u-reset__h4 {
      font-size: 15px;
      line-height: 1.4; } }

.skel h5:not(.u-reset),
.skel .u-reset__h5 {
  color: #111111;
  font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
  font-weight: 500;
  font-style: normal; }
  @media (min-width: 1920px) {
    .skel h5:not(.u-reset),
    .skel .u-reset__h5 {
      font-size: 17px;
      line-height: 1.4; } }
  @media (min-width: 1280px) and (max-width: 1919px) {
    .skel h5:not(.u-reset),
    .skel .u-reset__h5 {
      font-size: 15px;
      line-height: 1.4; } }
  @media (min-width: 992px) and (max-width: 1279px) {
    .skel h5:not(.u-reset),
    .skel .u-reset__h5 {
      font-size: 13px;
      line-height: 1.4; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .skel h5:not(.u-reset),
    .skel .u-reset__h5 {
      font-size: 13px;
      line-height: 1.4; } }
  @media (min-width: 544px) and (max-width: 767px) {
    .skel h5:not(.u-reset),
    .skel .u-reset__h5 {
      font-size: 13px;
      line-height: 1.4; } }
  @media (min-width: 375px) and (max-width: 543px) {
    .skel h5:not(.u-reset),
    .skel .u-reset__h5 {
      font-size: 13px;
      line-height: 1.4; } }
  @media (max-width: 374px) {
    .skel h5:not(.u-reset),
    .skel .u-reset__h5 {
      font-size: 13px;
      line-height: 1.4; } }

.skel h6:not(.u-reset),
.skel .u-reset__h6 {
  color: #111111;
  font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
  font-weight: 500;
  font-style: normal; }
  @media (min-width: 1920px) {
    .skel h6:not(.u-reset),
    .skel .u-reset__h6 {
      font-size: 15px;
      line-height: 1.4; } }
  @media (min-width: 1280px) and (max-width: 1919px) {
    .skel h6:not(.u-reset),
    .skel .u-reset__h6 {
      font-size: 13px;
      line-height: 1.4; } }
  @media (min-width: 992px) and (max-width: 1279px) {
    .skel h6:not(.u-reset),
    .skel .u-reset__h6 {
      font-size: 11px;
      line-height: 1.4; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .skel h6:not(.u-reset),
    .skel .u-reset__h6 {
      font-size: 11px;
      line-height: 1.4; } }
  @media (min-width: 544px) and (max-width: 767px) {
    .skel h6:not(.u-reset),
    .skel .u-reset__h6 {
      font-size: 11px;
      line-height: 1.4; } }
  @media (min-width: 375px) and (max-width: 543px) {
    .skel h6:not(.u-reset),
    .skel .u-reset__h6 {
      font-size: 11px;
      line-height: 1.4; } }
  @media (max-width: 374px) {
    .skel h6:not(.u-reset),
    .skel .u-reset__h6 {
      font-size: 11px;
      line-height: 1.4; } }

.skel p:not(.u-reset),
.skel .u-reset__p {
  font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal; }
  @media (min-width: 1920px) {
    .skel p:not(.u-reset),
    .skel .u-reset__p {
      font-size: 21px;
      line-height: 1.44; } }
  @media (min-width: 1280px) and (max-width: 1919px) {
    .skel p:not(.u-reset),
    .skel .u-reset__p {
      font-size: 19px;
      line-height: 1.57; } }
  @media (min-width: 992px) and (max-width: 1279px) {
    .skel p:not(.u-reset),
    .skel .u-reset__p {
      font-size: 17px;
      line-height: 1.52; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .skel p:not(.u-reset),
    .skel .u-reset__p {
      font-size: 17px;
      line-height: 1.52; } }
  @media (min-width: 544px) and (max-width: 767px) {
    .skel p:not(.u-reset),
    .skel .u-reset__p {
      font-size: 17px;
      line-height: 1.52; } }
  @media (min-width: 375px) and (max-width: 543px) {
    .skel p:not(.u-reset),
    .skel .u-reset__p {
      font-size: 17px;
      line-height: 1.52; } }
  @media (max-width: 374px) {
    .skel p:not(.u-reset),
    .skel .u-reset__p {
      font-size: 17px;
      line-height: 1.52; } }

.skel ul.u-reset, .skel ul.u-reset > li, .skel ol.u-reset, .skel ol.u-reset > li {
  list-style: none; }

.skel ul:not(.u-reset),
.skel .u-reset__ul {
  padding-left: 1.4em;
  list-style: none; }
  .skel ul:not(.u-reset) > li,
  .skel .u-reset__ul > li {
    position: relative;
    display: inline-block;
    width: 100%; }
    .skel ul:not(.u-reset) > li > *,
    .skel .u-reset__ul > li > * {
      margin-bottom: 0; }
    .skel ul:not(.u-reset) > li ~ li,
    .skel .u-reset__ul > li ~ li {
      margin-top: 8px; }
    .skel ul:not(.u-reset) > li::before,
    .skel .u-reset__ul > li::before {
      position: absolute;
      top: 0;
      right: 100%;
      display: inline-block;
      width: 1.4em;
      height: auto;
      font-size: inherit;
      color: currentColor; }
    .skel ul:not(.u-reset) > li > ul,
    .skel .u-reset__ul > li > ul {
      margin-top: 8px; }
      .skel ul:not(.u-reset) > li > ul > li::before,
      .skel .u-reset__ul > li > ul > li::before {
        content: '\25CB';
        transform: none; }
    .skel ul:not(.u-reset) > li > ol,
    .skel .u-reset__ul > li > ol {
      margin-top: 8px;
      padding-left: 1.75em; }
      .skel ul:not(.u-reset) > li > ol > li,
      .skel .u-reset__ul > li > ol > li {
        padding-left: 0.7em; }
        .skel ul:not(.u-reset) > li > ol > li::before,
        .skel .u-reset__ul > li > ol > li::before {
          width: 1.68em;
          font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
          font-weight: 400;
          font-style: normal; }
  .skel ul:not(.u-reset) > li::before,
  .skel .u-reset__ul > li::before {
    line-height: 1.31;
    content: '\2022';
    text-align: left;
    transform-origin: left center;
    transform: scale(2); }
    html[data-theme="association"] .skel ul:not(.u-reset) > li::before, html[data-theme="association"]
    .skel .u-reset__ul > li::before {
      color: #d9213d; }
    html[data-theme="safety"] .skel ul:not(.u-reset) > li::before, html[data-theme="safety"]
    .skel .u-reset__ul > li::before {
      color: #e66e00; }
    html[data-theme="recycling"] .skel ul:not(.u-reset) > li::before, html[data-theme="recycling"]
    .skel .u-reset__ul > li::before {
      color: #2c834c; }
    html[data-theme="knowledge"] .skel ul:not(.u-reset) > li::before, html[data-theme="knowledge"]
    .skel .u-reset__ul > li::before {
      color: #1469c9; }
    html[data-theme="education"] .skel ul:not(.u-reset) > li::before, html[data-theme="education"]
    .skel .u-reset__ul > li::before {
      color: #631e65; }
    html[data-theme="sustainability"] .skel ul:not(.u-reset) > li::before, html[data-theme="sustainability"]
    .skel .u-reset__ul > li::before {
      color: #1da321; }
    html[data-theme="default"] .skel ul:not(.u-reset) > li::before, html[data-theme="default"]
    .skel .u-reset__ul > li::before {
      color: #d9213d; }
  .skel ul:not(.u-reset) > li > ul > li::before,
  .skel .u-reset__ul > li > ul > li::before {
    line-height: 1.5; }

.skel ol:not(.u-reset),
.skel .u-reset__ol {
  padding-left: 1.4em;
  list-style: none;
  counter-reset: li; }
  .skel ol:not(.u-reset) > li,
  .skel .u-reset__ol > li {
    position: relative;
    display: inline-block;
    width: 100%; }
    .skel ol:not(.u-reset) > li > *,
    .skel .u-reset__ol > li > * {
      margin-bottom: 0; }
    .skel ol:not(.u-reset) > li ~ li,
    .skel .u-reset__ol > li ~ li {
      margin-top: 8px; }
    .skel ol:not(.u-reset) > li::before,
    .skel .u-reset__ol > li::before {
      position: absolute;
      top: 0;
      right: 100%;
      display: inline-block;
      width: 1.4em;
      height: auto;
      font-size: inherit;
      color: currentColor; }
    .skel ol:not(.u-reset) > li > ul,
    .skel .u-reset__ol > li > ul {
      margin-top: 8px; }
      .skel ol:not(.u-reset) > li > ul > li::before,
      .skel .u-reset__ol > li > ul > li::before {
        content: '\25CB';
        transform: none; }
    .skel ol:not(.u-reset) > li > ol,
    .skel .u-reset__ol > li > ol {
      margin-top: 8px;
      padding-left: 1.75em; }
      .skel ol:not(.u-reset) > li > ol > li,
      .skel .u-reset__ol > li > ol > li {
        padding-left: 0.7em; }
        .skel ol:not(.u-reset) > li > ol > li::before,
        .skel .u-reset__ol > li > ol > li::before {
          width: 1.68em;
          font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
          font-weight: 400;
          font-style: normal; }
  .skel ol:not(.u-reset) > li::before,
  .skel .u-reset__ol > li::before {
    content: counters(li, ".") ".";
    counter-increment: li;
    text-align: left;
    font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
    font-weight: 500;
    font-style: normal; }
    html[data-theme="association"] .skel ol:not(.u-reset) > li::before, html[data-theme="association"]
    .skel .u-reset__ol > li::before {
      color: #d9213d; }
    html[data-theme="safety"] .skel ol:not(.u-reset) > li::before, html[data-theme="safety"]
    .skel .u-reset__ol > li::before {
      color: #e66e00; }
    html[data-theme="recycling"] .skel ol:not(.u-reset) > li::before, html[data-theme="recycling"]
    .skel .u-reset__ol > li::before {
      color: #2c834c; }
    html[data-theme="knowledge"] .skel ol:not(.u-reset) > li::before, html[data-theme="knowledge"]
    .skel .u-reset__ol > li::before {
      color: #1469c9; }
    html[data-theme="education"] .skel ol:not(.u-reset) > li::before, html[data-theme="education"]
    .skel .u-reset__ol > li::before {
      color: #631e65; }
    html[data-theme="sustainability"] .skel ol:not(.u-reset) > li::before, html[data-theme="sustainability"]
    .skel .u-reset__ol > li::before {
      color: #1da321; }
    html[data-theme="default"] .skel ol:not(.u-reset) > li::before, html[data-theme="default"]
    .skel .u-reset__ol > li::before {
      color: #d9213d; }

.skel b, .skel strong {
  font-weight: 500; }

.skel i, .skel em {
  font-style: italic; }

.skel sub {
  font-size: 1.3rem;
  vertical-align: sub; }

.skel sup {
  font-size: 1.3rem;
  vertical-align: super; }

.skel a, .skel .u-link {
  color: currentColor;
  text-decoration: none; }
  @media all {
    .skel a:not(.u-reset):not(.btn), .skel .u-link:not(.u-reset):not(.btn) {
      text-decoration: none; }
      html[data-theme="association"] .skel a:not(.u-reset):not(.btn), html[data-theme="association"] .skel .u-link:not(.u-reset):not(.btn) {
        color: #d9213d; }
      html[data-theme="safety"] .skel a:not(.u-reset):not(.btn), html[data-theme="safety"] .skel .u-link:not(.u-reset):not(.btn) {
        color: #e66e00; }
      html[data-theme="recycling"] .skel a:not(.u-reset):not(.btn), html[data-theme="recycling"] .skel .u-link:not(.u-reset):not(.btn) {
        color: #2c834c; }
      html[data-theme="knowledge"] .skel a:not(.u-reset):not(.btn), html[data-theme="knowledge"] .skel .u-link:not(.u-reset):not(.btn) {
        color: #1469c9; }
      html[data-theme="education"] .skel a:not(.u-reset):not(.btn), html[data-theme="education"] .skel .u-link:not(.u-reset):not(.btn) {
        color: #631e65; }
      html[data-theme="sustainability"] .skel a:not(.u-reset):not(.btn), html[data-theme="sustainability"] .skel .u-link:not(.u-reset):not(.btn) {
        color: #1da321; }
      html[data-theme="default"] .skel a:not(.u-reset):not(.btn), html[data-theme="default"] .skel .u-link:not(.u-reset):not(.btn) {
        color: #d9213d; } }
  @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    html[data-theme="association"] .skel a:not(.u-reset):not(.btn):hover, html[data-theme="association"] .skel a:not(.u-reset):not(.btn):focus, html[data-theme="association"] .skel .u-link:not(.u-reset):not(.btn):hover, html[data-theme="association"] .skel .u-link:not(.u-reset):not(.btn):focus {
      color: #a00017; }
    html[data-theme="safety"] .skel a:not(.u-reset):not(.btn):hover, html[data-theme="safety"] .skel a:not(.u-reset):not(.btn):focus, html[data-theme="safety"] .skel .u-link:not(.u-reset):not(.btn):hover, html[data-theme="safety"] .skel .u-link:not(.u-reset):not(.btn):focus {
      color: #ad4000; }
    html[data-theme="recycling"] .skel a:not(.u-reset):not(.btn):hover, html[data-theme="recycling"] .skel a:not(.u-reset):not(.btn):focus, html[data-theme="recycling"] .skel .u-link:not(.u-reset):not(.btn):hover, html[data-theme="recycling"] .skel .u-link:not(.u-reset):not(.btn):focus {
      color: #005523; }
    html[data-theme="knowledge"] .skel a:not(.u-reset):not(.btn):hover, html[data-theme="knowledge"] .skel a:not(.u-reset):not(.btn):focus, html[data-theme="knowledge"] .skel .u-link:not(.u-reset):not(.btn):hover, html[data-theme="knowledge"] .skel .u-link:not(.u-reset):not(.btn):focus {
      color: #003f97; }
    html[data-theme="education"] .skel a:not(.u-reset):not(.btn):hover, html[data-theme="education"] .skel a:not(.u-reset):not(.btn):focus, html[data-theme="education"] .skel .u-link:not(.u-reset):not(.btn):hover, html[data-theme="education"] .skel .u-link:not(.u-reset):not(.btn):focus {
      color: #36003a; }
    html[data-theme="sustainability"] .skel a:not(.u-reset):not(.btn):hover, html[data-theme="sustainability"] .skel a:not(.u-reset):not(.btn):focus, html[data-theme="sustainability"] .skel .u-link:not(.u-reset):not(.btn):hover, html[data-theme="sustainability"] .skel .u-link:not(.u-reset):not(.btn):focus {
      color: #116214; }
    html[data-theme="default"] .skel a:not(.u-reset):not(.btn):hover, html[data-theme="default"] .skel a:not(.u-reset):not(.btn):focus, html[data-theme="default"] .skel .u-link:not(.u-reset):not(.btn):hover, html[data-theme="default"] .skel .u-link:not(.u-reset):not(.btn):focus {
      color: #a00017; } }
  @media screen {
    html[data-theme="association"] .skel a:not(.u-reset):not(.btn):active, html[data-theme="association"] .skel .u-link:not(.u-reset):not(.btn):active {
      color: #a00017; }
    html[data-theme="safety"] .skel a:not(.u-reset):not(.btn):active, html[data-theme="safety"] .skel .u-link:not(.u-reset):not(.btn):active {
      color: #ad4000; }
    html[data-theme="recycling"] .skel a:not(.u-reset):not(.btn):active, html[data-theme="recycling"] .skel .u-link:not(.u-reset):not(.btn):active {
      color: #005523; }
    html[data-theme="knowledge"] .skel a:not(.u-reset):not(.btn):active, html[data-theme="knowledge"] .skel .u-link:not(.u-reset):not(.btn):active {
      color: #003f97; }
    html[data-theme="education"] .skel a:not(.u-reset):not(.btn):active, html[data-theme="education"] .skel .u-link:not(.u-reset):not(.btn):active {
      color: #36003a; }
    html[data-theme="sustainability"] .skel a:not(.u-reset):not(.btn):active, html[data-theme="sustainability"] .skel .u-link:not(.u-reset):not(.btn):active {
      color: #116214; }
    html[data-theme="default"] .skel a:not(.u-reset):not(.btn):active, html[data-theme="default"] .skel .u-link:not(.u-reset):not(.btn):active {
      color: #a00017; } }

.skel hr {
  display: block;
  border: 0;
  height: 1px;
  color: inherit;
  background-color: currentColor; }

.skel pre, .skel code {
  font-family: monospace; }

input::-ms-clear,
input::-ms-reveal {
  display: none; }

select::-ms-expand {
  display: none; }

input::-webkit-input-placeholder {
  line-height: normal; }

input::-webkit-search-decoration,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button,
input::-webkit-search-results-decoration {
  display: none; }

* {
  -webkit-focus-ring-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
  outline: none; }

input, textarea, keygen, select, button {
  background: none;
  color: inherit;
  cursor: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  border: 0;
  margin: 0; }

input[type='text'],
input[type='email'],
input[type='search'],
input[type='password'],
input[type='submit'],
input[type='reset'],
input[type='button'],
button,
select {
  -moz-appearance: none;
  /* Firefox mobile */
  -webkit-appearance: none;
  /* iPad */ }

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

select {
  border-radius: 0; }

input::-moz-focus-inner,
button::-moz-focus-inner {
  border: 0;
  padding: 0; }

@page {
  size: A4;
  margin: 24mm 0; }

@media print {
  html, body {
    width: 210mm;
    height: 297mm; } }

.cms-entity {
  width: 100%;
  padding: 64px 0; }
  @media (max-width: 991px) {
    .cms-entity {
      padding: 48px 0; } }
  @media print {
    .cms-entity {
      padding: 24px 0; } }

.cms-entity .cms-entity--col.cms-entity--col__full-width {
  flex: 0 0 100%; }
  html.ie11 .cms-entity .cms-entity--col.cms-entity--col__full-width {
    flex-basis: auto;
    width: 100%; }
  html.ie10 .cms-entity .cms-entity--col.cms-entity--col__full-width {
    flex-basis: auto;
    width: 100%; }

@media screen {
  .cms-entity .cms-entity--col.cms-entity--col__right-aligned {
    margin-left: auto; } }
  @media screen and (min-width: 768px) {
    .cms-entity .cms-entity--col.cms-entity--col__right-aligned {
      flex: 0 0 66.66667%; }
      html.ie11 .cms-entity .cms-entity--col.cms-entity--col__right-aligned {
        flex-basis: auto;
        width: 66.66667%; }
      html.ie10 .cms-entity .cms-entity--col.cms-entity--col__right-aligned {
        flex-basis: auto;
        width: 66.66667%; } }
  @media screen and (max-width: 767px) {
    .cms-entity .cms-entity--col.cms-entity--col__right-aligned {
      flex: 0 0 100%; }
      html.ie11 .cms-entity .cms-entity--col.cms-entity--col__right-aligned {
        flex-basis: auto;
        width: 100%; }
      html.ie10 .cms-entity .cms-entity--col.cms-entity--col__right-aligned {
        flex-basis: auto;
        width: 100%; } }

@media print {
  .cms-entity .cms-entity--col.cms-entity--col__right-aligned {
    flex: 0 0 100%; }
    html.ie11 .cms-entity .cms-entity--col.cms-entity--col__right-aligned {
      flex-basis: auto;
      width: 100%; }
    html.ie10 .cms-entity .cms-entity--col.cms-entity--col__right-aligned {
      flex-basis: auto;
      width: 100%; } }

@media screen and (min-width: 992px) {
  .cms-entity .cms-entity--col.cms-entity--col__pull {
    flex: 0 0 8.33333%; }
    html.ie11 .cms-entity .cms-entity--col.cms-entity--col__pull {
      flex-basis: auto;
      width: 8.33333%; }
    html.ie10 .cms-entity .cms-entity--col.cms-entity--col__pull {
      flex-basis: auto;
      width: 8.33333%; } }

.collection-points-panel {
  pointer-events: auto;
  display: flex;
  flex-flow: column nowrap;
  max-height: 100%;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 20px 32px 0 rgba(0, 0, 0, 0.2), 0 12px 16px 0 rgba(0, 0, 0, 0.12);
  overflow: hidden; }
  .collection-points-panel .collection-points-panel--header {
    border-bottom: 1px solid #f1f1f2; }
  .collection-points-panel .collection-points-panel--footer {
    border-top: 1px solid #f1f1f2; }
  .collection-points-panel .collection-points-panel--map {
    border-bottom: 1px solid #f1f1f2; }
  .collection-points-panel .collection-points-panel--content {
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;
    overflow-y: scroll;
    flex: 0 1 auto;
    width: 100%;
    overflow-y: auto;
    max-height: 100%; }

.collection-points-panel.collection-points-panel__search .collection-points-panel--header {
  flex: 0 0 65px;
  height: 65px; }

.collection-points-panel.collection-points-panel__details .collection-points-panel--header {
  flex: 0 0 65px;
  height: 65px; }

.form .form--container {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  margin: auto; }

.form .form--fieldset-row {
  display: flex;
  flex-flow: row wrap; }
  html.ie11 .form .form--fieldset-row {
    min-height: 1px; }

.form .form--fieldset {
  position: relative;
  min-height: 1px;
  max-width: 100%; }

.form .form--container {
  padding-top: 44px;
  padding-left: 16px;
  padding-bottom: 44px;
  padding-right: 16px; }
  .form .form--container .form--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important; }
    .form .form--container .form--container:not(.u-iknowwhatimdoing)::before {
      visibility: visible;
      content: 'Nested `grid--container` detected!';
      font-size: 20px;
      line-height: 1.2;
      color: red; }

.form .form--fieldset-row {
  margin-top: -22px;
  margin-left: -8px;
  margin-bottom: -22px;
  margin-right: -8px; }

.form .form--fieldset {
  padding-top: 22px;
  padding-left: 8px;
  padding-bottom: 22px;
  padding-right: 8px; }

.form .form--fieldset {
  flex: 0 0 100%; }
  html.ie11 .form .form--fieldset {
    flex-basis: auto;
    width: 100%; }
  html.ie10 .form .form--fieldset {
    flex-basis: auto;
    width: 100%; }

.form .form--container {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  margin: auto; }

.form .form--field-row {
  display: flex;
  flex-flow: row wrap; }
  html.ie11 .form .form--field-row {
    min-height: 1px; }

.form .form--field {
  position: relative;
  min-height: 1px;
  max-width: 100%; }

.form .form--container {
  padding-top: 24px;
  padding-left: 16px;
  padding-bottom: 24px;
  padding-right: 16px; }
  .form .form--container .form--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important; }
    .form .form--container .form--container:not(.u-iknowwhatimdoing)::before {
      visibility: visible;
      content: 'Nested `grid--container` detected!';
      font-size: 20px;
      line-height: 1.2;
      color: red; }

.form .form--field-row {
  margin-top: -12px;
  margin-left: -8px;
  margin-bottom: -12px;
  margin-right: -8px; }

.form .form--field {
  padding-top: 12px;
  padding-left: 8px;
  padding-bottom: 12px;
  padding-right: 8px; }

.form .form--field-row ~ .form--field-row {
  padding-top: 24px 16px/2; }

.form .form--field {
  flex: 0 0 100%;
  align-self: flex-start; }
  html.ie11 .form .form--field {
    flex-basis: auto;
    width: 100%; }
  html.ie10 .form .form--field {
    flex-basis: auto;
    width: 100%; }

.page {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  flex: 1 0 auto; }
  .page .page--main {
    flex: 1 0 auto; }

html.scroll-root.scroll-root__is-locked {
  width: 100%;
  height: 100%;
  overflow: hidden; }
  html.scroll-root.scroll-root__is-locked > body {
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: visible; }

.skel {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  min-height: 100%;
  overflow: hidden; }
  @media print {
    .skel {
      display: block; } }
  html[data-theme="association"] .skel .skel--header {
    border-top: 4px solid #d9213d; }
  html[data-theme="safety"] .skel .skel--header {
    border-top: 4px solid #e66e00; }
  html[data-theme="recycling"] .skel .skel--header {
    border-top: 4px solid #2c834c; }
  html[data-theme="knowledge"] .skel .skel--header {
    border-top: 4px solid #1469c9; }
  html[data-theme="education"] .skel .skel--header {
    border-top: 4px solid #631e65; }
  html[data-theme="sustainability"] .skel .skel--header {
    border-top: 4px solid #1da321; }
  html[data-theme="default"] .skel .skel--header {
    border-top: 4px solid #d9213d; }
  html[data-theme="default"] .skel .skel--header {
    border-top-color: transparent; }
  .skel .skel--main {
    display: flex;
    flex-flow: column nowrap;
    flex: 1 0 auto; }
    @media print {
      .skel .skel--main {
        display: block; } }
  .skel .skel--footer > *,
  .skel .skel--main > * {
    transition: opacity 200ms ease; }
    @media print {
      .skel .skel--footer > *,
      .skel .skel--main > * {
        transition: none; } }

@media (min-width: 992px) {
  .skel.skel__is-menu-open .skel--footer > *,
  .skel.skel__is-menu-open .skel--main > * {
    opacity: 0.3; } }

.ui-icon {
  display: block;
  width: 100%;
  height: 100%;
  fill: currentColor; }

.ui-icon.ui-icon__small {
  width: 16px;
  height: 16px; }

.ui-icon.ui-icon__medium {
  width: 24px;
  height: 24px; }

.ui-icon.ui-icon__large {
  width: 32px;
  height: 32px; }

.ui-icon.ui-icon__x-large {
  width: 48px;
  height: 48px; }

.btn, ._form-content button {
  line-height: 1;
  border: 2px solid transparent;
  border-radius: 4px;
  transition: color 200ms ease, border-color 200ms ease, background-color 200ms ease, opacity 200ms ease, transform 200ms ease, box-shadow 200ms ease;
  display: inline-block;
  font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
  font-weight: 500;
  font-style: normal; }

.btn, ._form-content button {
  text-decoration: none; }
  @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .btn:not(:disabled):hover, ._form-content button:not(:disabled):hover, .btn:not(:disabled):focus, ._form-content button:not(:disabled):focus {
      text-decoration: none;
      cursor: pointer; } }
  @media screen {
    .btn:not(:disabled):active, ._form-content button:not(:disabled):active {
      text-decoration: none;
      cursor: pointer; } }
  @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .btn:not(:disabled):hover:active, ._form-content button:not(:disabled):hover:active, .btn:not(:disabled):focus:active, ._form-content button:not(:disabled):focus:active {
      text-decoration: none;
      cursor: pointer; } }
  @media screen {
    .btn:not(:disabled):active:active, ._form-content button:not(:disabled):active:active {
      text-decoration: none;
      cursor: pointer; } }
  @media all {
    .btn:not(:disabled):active, ._form-content button:not(:disabled):active {
      text-decoration: none;
      cursor: pointer; } }
  @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .btn:disabled:hover, ._form-content button:disabled:hover, .btn:disabled:focus, ._form-content button:disabled:focus {
      text-decoration: none;
      cursor: default; } }
  @media screen {
    .btn:disabled:active, ._form-content button:disabled:active {
      text-decoration: none;
      cursor: default; } }
  @media all {
    .btn:disabled, ._form-content button:disabled {
      text-decoration: none;
      cursor: default; } }

.btn.btn__small, ._form-content button.btn__small {
  padding: 19px 24px 18px;
  font-size: 17px; }

.btn.btn__medium, ._form-content button.btn__medium {
  font-size: 19px;
  padding: 21px 24px 20px; }
  @media (max-width: 767px) {
    .btn.btn__medium, ._form-content button.btn__medium {
      padding: 19px 24px 18px;
      font-size: 17px; } }

.btn.btn__large, ._form-content button {
  font-size: 21px;
  padding: 24px 32px 23px; }
  @media (min-width: 1280px) and (max-width: 1919px) {
    .btn.btn__large, ._form-content button {
      font-size: 19px;
      padding: 21px 24px 20px; } }
  @media (min-width: 1280px) and (max-width: 1919px) and (max-width: 767px) {
    .btn.btn__large, ._form-content button {
      padding: 19px 24px 18px;
      font-size: 17px; } }
  @media (max-width: 1279px) {
    .btn.btn__large, ._form-content button {
      padding: 19px 24px 18px;
      font-size: 17px; } }

.btn.btn__primary:not(:disabled), ._form-content button:not(:disabled) {
  color: #ffffff;
  border-color: transparent;
  box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.08), 0 8px 16px 0 rgba(0, 0, 0, 0.12); }
  html[data-theme="association"] .btn.btn__primary:not(:disabled), html[data-theme="association"] ._form-content button:not(:disabled), ._form-content html[data-theme="association"] button:not(:disabled) {
    background: #d9213d; }
  html[data-theme="safety"] .btn.btn__primary:not(:disabled), html[data-theme="safety"] ._form-content button:not(:disabled), ._form-content html[data-theme="safety"] button:not(:disabled) {
    background: #e66e00; }
  html[data-theme="recycling"] .btn.btn__primary:not(:disabled), html[data-theme="recycling"] ._form-content button:not(:disabled), ._form-content html[data-theme="recycling"] button:not(:disabled) {
    background: #2c834c; }
  html[data-theme="knowledge"] .btn.btn__primary:not(:disabled), html[data-theme="knowledge"] ._form-content button:not(:disabled), ._form-content html[data-theme="knowledge"] button:not(:disabled) {
    background: #1469c9; }
  html[data-theme="education"] .btn.btn__primary:not(:disabled), html[data-theme="education"] ._form-content button:not(:disabled), ._form-content html[data-theme="education"] button:not(:disabled) {
    background: #631e65; }
  html[data-theme="sustainability"] .btn.btn__primary:not(:disabled), html[data-theme="sustainability"] ._form-content button:not(:disabled), ._form-content html[data-theme="sustainability"] button:not(:disabled) {
    background: #1da321; }
  html[data-theme="default"] .btn.btn__primary:not(:disabled), html[data-theme="default"] ._form-content button:not(:disabled), ._form-content html[data-theme="default"] button:not(:disabled) {
    background: #d9213d; }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .btn.btn__primary:not(:disabled):hover, ._form-content button:not(:disabled):hover, .btn.btn__primary:not(:disabled):focus, ._form-content button:not(:disabled):focus {
    box-shadow: 0 20px 32px 0 rgba(0, 0, 0, 0.2), 0 12px 16px 0 rgba(0, 0, 0, 0.12); }
    html[data-theme="association"] .btn.btn__primary:not(:disabled):hover, html[data-theme="association"] ._form-content button:not(:disabled):hover, ._form-content html[data-theme="association"] button:not(:disabled):hover, html[data-theme="association"] .btn.btn__primary:not(:disabled):focus, html[data-theme="association"] ._form-content button:not(:disabled):focus, ._form-content html[data-theme="association"] button:not(:disabled):focus {
      background: #c31e37; }
    html[data-theme="safety"] .btn.btn__primary:not(:disabled):hover, html[data-theme="safety"] ._form-content button:not(:disabled):hover, ._form-content html[data-theme="safety"] button:not(:disabled):hover, html[data-theme="safety"] .btn.btn__primary:not(:disabled):focus, html[data-theme="safety"] ._form-content button:not(:disabled):focus, ._form-content html[data-theme="safety"] button:not(:disabled):focus {
      background: #cd6200; }
    html[data-theme="recycling"] .btn.btn__primary:not(:disabled):hover, html[data-theme="recycling"] ._form-content button:not(:disabled):hover, ._form-content html[data-theme="recycling"] button:not(:disabled):hover, html[data-theme="recycling"] .btn.btn__primary:not(:disabled):focus, html[data-theme="recycling"] ._form-content button:not(:disabled):focus, ._form-content html[data-theme="recycling"] button:not(:disabled):focus {
      background: #267041; }
    html[data-theme="knowledge"] .btn.btn__primary:not(:disabled):hover, html[data-theme="knowledge"] ._form-content button:not(:disabled):hover, ._form-content html[data-theme="knowledge"] button:not(:disabled):hover, html[data-theme="knowledge"] .btn.btn__primary:not(:disabled):focus, html[data-theme="knowledge"] ._form-content button:not(:disabled):focus, ._form-content html[data-theme="knowledge"] button:not(:disabled):focus {
      background: #125db2; }
    html[data-theme="education"] .btn.btn__primary:not(:disabled):hover, html[data-theme="education"] ._form-content button:not(:disabled):hover, ._form-content html[data-theme="education"] button:not(:disabled):hover, html[data-theme="education"] .btn.btn__primary:not(:disabled):focus, html[data-theme="education"] ._form-content button:not(:disabled):focus, ._form-content html[data-theme="education"] button:not(:disabled):focus {
      background: #501851; }
    html[data-theme="sustainability"] .btn.btn__primary:not(:disabled):hover, html[data-theme="sustainability"] ._form-content button:not(:disabled):hover, ._form-content html[data-theme="sustainability"] button:not(:disabled):hover, html[data-theme="sustainability"] .btn.btn__primary:not(:disabled):focus, html[data-theme="sustainability"] ._form-content button:not(:disabled):focus, ._form-content html[data-theme="sustainability"] button:not(:disabled):focus {
      background: #198d1d; }
    html[data-theme="default"] .btn.btn__primary:not(:disabled):hover, html[data-theme="default"] ._form-content button:not(:disabled):hover, ._form-content html[data-theme="default"] button:not(:disabled):hover, html[data-theme="default"] .btn.btn__primary:not(:disabled):focus, html[data-theme="default"] ._form-content button:not(:disabled):focus, ._form-content html[data-theme="default"] button:not(:disabled):focus {
      background: #c31e37; } }

@media screen {
  .btn.btn__primary:not(:disabled):active, ._form-content button:not(:disabled):active {
    box-shadow: 0 20px 32px 0 rgba(0, 0, 0, 0.2), 0 12px 16px 0 rgba(0, 0, 0, 0.12); }
    html[data-theme="association"] .btn.btn__primary:not(:disabled):active, html[data-theme="association"] ._form-content button:not(:disabled):active, ._form-content html[data-theme="association"] button:not(:disabled):active {
      background: #c31e37; }
    html[data-theme="safety"] .btn.btn__primary:not(:disabled):active, html[data-theme="safety"] ._form-content button:not(:disabled):active, ._form-content html[data-theme="safety"] button:not(:disabled):active {
      background: #cd6200; }
    html[data-theme="recycling"] .btn.btn__primary:not(:disabled):active, html[data-theme="recycling"] ._form-content button:not(:disabled):active, ._form-content html[data-theme="recycling"] button:not(:disabled):active {
      background: #267041; }
    html[data-theme="knowledge"] .btn.btn__primary:not(:disabled):active, html[data-theme="knowledge"] ._form-content button:not(:disabled):active, ._form-content html[data-theme="knowledge"] button:not(:disabled):active {
      background: #125db2; }
    html[data-theme="education"] .btn.btn__primary:not(:disabled):active, html[data-theme="education"] ._form-content button:not(:disabled):active, ._form-content html[data-theme="education"] button:not(:disabled):active {
      background: #501851; }
    html[data-theme="sustainability"] .btn.btn__primary:not(:disabled):active, html[data-theme="sustainability"] ._form-content button:not(:disabled):active, ._form-content html[data-theme="sustainability"] button:not(:disabled):active {
      background: #198d1d; }
    html[data-theme="default"] .btn.btn__primary:not(:disabled):active, html[data-theme="default"] ._form-content button:not(:disabled):active, ._form-content html[data-theme="default"] button:not(:disabled):active {
      background: #c31e37; } }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .btn.btn__primary:not(:disabled):hover:active, ._form-content button:not(:disabled):hover:active, .btn.btn__primary:not(:disabled):focus:active, ._form-content button:not(:disabled):focus:active {
    box-shadow: none;
    color: #ffffff; }
    html[data-theme="association"] .btn.btn__primary:not(:disabled):hover:active, html[data-theme="association"] ._form-content button:not(:disabled):hover:active, ._form-content html[data-theme="association"] button:not(:disabled):hover:active, html[data-theme="association"] .btn.btn__primary:not(:disabled):focus:active, html[data-theme="association"] ._form-content button:not(:disabled):focus:active, ._form-content html[data-theme="association"] button:not(:disabled):focus:active {
      background: #a00017; }
    html[data-theme="safety"] .btn.btn__primary:not(:disabled):hover:active, html[data-theme="safety"] ._form-content button:not(:disabled):hover:active, ._form-content html[data-theme="safety"] button:not(:disabled):hover:active, html[data-theme="safety"] .btn.btn__primary:not(:disabled):focus:active, html[data-theme="safety"] ._form-content button:not(:disabled):focus:active, ._form-content html[data-theme="safety"] button:not(:disabled):focus:active {
      background: #ad4000; }
    html[data-theme="recycling"] .btn.btn__primary:not(:disabled):hover:active, html[data-theme="recycling"] ._form-content button:not(:disabled):hover:active, ._form-content html[data-theme="recycling"] button:not(:disabled):hover:active, html[data-theme="recycling"] .btn.btn__primary:not(:disabled):focus:active, html[data-theme="recycling"] ._form-content button:not(:disabled):focus:active, ._form-content html[data-theme="recycling"] button:not(:disabled):focus:active {
      background: #005523; }
    html[data-theme="knowledge"] .btn.btn__primary:not(:disabled):hover:active, html[data-theme="knowledge"] ._form-content button:not(:disabled):hover:active, ._form-content html[data-theme="knowledge"] button:not(:disabled):hover:active, html[data-theme="knowledge"] .btn.btn__primary:not(:disabled):focus:active, html[data-theme="knowledge"] ._form-content button:not(:disabled):focus:active, ._form-content html[data-theme="knowledge"] button:not(:disabled):focus:active {
      background: #003f97; }
    html[data-theme="education"] .btn.btn__primary:not(:disabled):hover:active, html[data-theme="education"] ._form-content button:not(:disabled):hover:active, ._form-content html[data-theme="education"] button:not(:disabled):hover:active, html[data-theme="education"] .btn.btn__primary:not(:disabled):focus:active, html[data-theme="education"] ._form-content button:not(:disabled):focus:active, ._form-content html[data-theme="education"] button:not(:disabled):focus:active {
      background: #36003a; }
    html[data-theme="sustainability"] .btn.btn__primary:not(:disabled):hover:active, html[data-theme="sustainability"] ._form-content button:not(:disabled):hover:active, ._form-content html[data-theme="sustainability"] button:not(:disabled):hover:active, html[data-theme="sustainability"] .btn.btn__primary:not(:disabled):focus:active, html[data-theme="sustainability"] ._form-content button:not(:disabled):focus:active, ._form-content html[data-theme="sustainability"] button:not(:disabled):focus:active {
      background: #116214; }
    html[data-theme="default"] .btn.btn__primary:not(:disabled):hover:active, html[data-theme="default"] ._form-content button:not(:disabled):hover:active, ._form-content html[data-theme="default"] button:not(:disabled):hover:active, html[data-theme="default"] .btn.btn__primary:not(:disabled):focus:active, html[data-theme="default"] ._form-content button:not(:disabled):focus:active, ._form-content html[data-theme="default"] button:not(:disabled):focus:active {
      background: #a00017; } }

@media screen {
  .btn.btn__primary:not(:disabled):active:active, ._form-content button:not(:disabled):active:active {
    box-shadow: none;
    color: #ffffff; }
    html[data-theme="association"] .btn.btn__primary:not(:disabled):active:active, html[data-theme="association"] ._form-content button:not(:disabled):active:active, ._form-content html[data-theme="association"] button:not(:disabled):active:active {
      background: #a00017; }
    html[data-theme="safety"] .btn.btn__primary:not(:disabled):active:active, html[data-theme="safety"] ._form-content button:not(:disabled):active:active, ._form-content html[data-theme="safety"] button:not(:disabled):active:active {
      background: #ad4000; }
    html[data-theme="recycling"] .btn.btn__primary:not(:disabled):active:active, html[data-theme="recycling"] ._form-content button:not(:disabled):active:active, ._form-content html[data-theme="recycling"] button:not(:disabled):active:active {
      background: #005523; }
    html[data-theme="knowledge"] .btn.btn__primary:not(:disabled):active:active, html[data-theme="knowledge"] ._form-content button:not(:disabled):active:active, ._form-content html[data-theme="knowledge"] button:not(:disabled):active:active {
      background: #003f97; }
    html[data-theme="education"] .btn.btn__primary:not(:disabled):active:active, html[data-theme="education"] ._form-content button:not(:disabled):active:active, ._form-content html[data-theme="education"] button:not(:disabled):active:active {
      background: #36003a; }
    html[data-theme="sustainability"] .btn.btn__primary:not(:disabled):active:active, html[data-theme="sustainability"] ._form-content button:not(:disabled):active:active, ._form-content html[data-theme="sustainability"] button:not(:disabled):active:active {
      background: #116214; }
    html[data-theme="default"] .btn.btn__primary:not(:disabled):active:active, html[data-theme="default"] ._form-content button:not(:disabled):active:active, ._form-content html[data-theme="default"] button:not(:disabled):active:active {
      background: #a00017; } }

@media all {
  .btn.btn__primary:not(:disabled):active, ._form-content button:not(:disabled):active {
    box-shadow: none;
    color: #ffffff; }
    html[data-theme="association"] .btn.btn__primary:not(:disabled):active, html[data-theme="association"] ._form-content button:not(:disabled):active, ._form-content html[data-theme="association"] button:not(:disabled):active {
      background: #a00017; }
    html[data-theme="safety"] .btn.btn__primary:not(:disabled):active, html[data-theme="safety"] ._form-content button:not(:disabled):active, ._form-content html[data-theme="safety"] button:not(:disabled):active {
      background: #ad4000; }
    html[data-theme="recycling"] .btn.btn__primary:not(:disabled):active, html[data-theme="recycling"] ._form-content button:not(:disabled):active, ._form-content html[data-theme="recycling"] button:not(:disabled):active {
      background: #005523; }
    html[data-theme="knowledge"] .btn.btn__primary:not(:disabled):active, html[data-theme="knowledge"] ._form-content button:not(:disabled):active, ._form-content html[data-theme="knowledge"] button:not(:disabled):active {
      background: #003f97; }
    html[data-theme="education"] .btn.btn__primary:not(:disabled):active, html[data-theme="education"] ._form-content button:not(:disabled):active, ._form-content html[data-theme="education"] button:not(:disabled):active {
      background: #36003a; }
    html[data-theme="sustainability"] .btn.btn__primary:not(:disabled):active, html[data-theme="sustainability"] ._form-content button:not(:disabled):active, ._form-content html[data-theme="sustainability"] button:not(:disabled):active {
      background: #116214; }
    html[data-theme="default"] .btn.btn__primary:not(:disabled):active, html[data-theme="default"] ._form-content button:not(:disabled):active, ._form-content html[data-theme="default"] button:not(:disabled):active {
      background: #a00017; } }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .btn.btn__primary:disabled:hover, ._form-content button:disabled:hover, .btn.btn__primary:disabled:focus, ._form-content button:disabled:focus {
    color: #a5a3a3;
    background-color: #eeeeee; } }

@media screen {
  .btn.btn__primary:disabled:active, ._form-content button:disabled:active {
    color: #a5a3a3;
    background-color: #eeeeee; } }

@media all {
  .btn.btn__primary:disabled, ._form-content button:disabled {
    color: #a5a3a3;
    background-color: #eeeeee; } }

.btn.btn__secondary:not(:disabled), ._form-content button.btn__secondary:not(:disabled) {
  background: #ffffff; }
  html[data-theme="association"] .btn.btn__secondary:not(:disabled), html[data-theme="association"] ._form-content button.btn__secondary:not(:disabled), ._form-content html[data-theme="association"] button.btn__secondary:not(:disabled) {
    color: #d9213d;
    border-color: #d9213d; }
  html[data-theme="safety"] .btn.btn__secondary:not(:disabled), html[data-theme="safety"] ._form-content button.btn__secondary:not(:disabled), ._form-content html[data-theme="safety"] button.btn__secondary:not(:disabled) {
    color: #e66e00;
    border-color: #e66e00; }
  html[data-theme="recycling"] .btn.btn__secondary:not(:disabled), html[data-theme="recycling"] ._form-content button.btn__secondary:not(:disabled), ._form-content html[data-theme="recycling"] button.btn__secondary:not(:disabled) {
    color: #2c834c;
    border-color: #2c834c; }
  html[data-theme="knowledge"] .btn.btn__secondary:not(:disabled), html[data-theme="knowledge"] ._form-content button.btn__secondary:not(:disabled), ._form-content html[data-theme="knowledge"] button.btn__secondary:not(:disabled) {
    color: #1469c9;
    border-color: #1469c9; }
  html[data-theme="education"] .btn.btn__secondary:not(:disabled), html[data-theme="education"] ._form-content button.btn__secondary:not(:disabled), ._form-content html[data-theme="education"] button.btn__secondary:not(:disabled) {
    color: #631e65;
    border-color: #631e65; }
  html[data-theme="sustainability"] .btn.btn__secondary:not(:disabled), html[data-theme="sustainability"] ._form-content button.btn__secondary:not(:disabled), ._form-content html[data-theme="sustainability"] button.btn__secondary:not(:disabled) {
    color: #1da321;
    border-color: #1da321; }
  html[data-theme="default"] .btn.btn__secondary:not(:disabled), html[data-theme="default"] ._form-content button.btn__secondary:not(:disabled), ._form-content html[data-theme="default"] button.btn__secondary:not(:disabled) {
    color: #d9213d;
    border-color: #d9213d; }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .btn.btn__secondary:not(:disabled):hover:not(:active), ._form-content button.btn__secondary:not(:disabled):hover:not(:active), .btn.btn__secondary:not(:disabled):focus:not(:active), ._form-content button.btn__secondary:not(:disabled):focus:not(:active) {
    box-shadow: 0 20px 32px 0 rgba(0, 0, 0, 0.2), 0 12px 16px 0 rgba(0, 0, 0, 0.12);
    color: #ffffff; }
    html[data-theme="association"] .btn.btn__secondary:not(:disabled):hover:not(:active), html[data-theme="association"] ._form-content button.btn__secondary:not(:disabled):hover:not(:active), ._form-content html[data-theme="association"] button.btn__secondary:not(:disabled):hover:not(:active), html[data-theme="association"] .btn.btn__secondary:not(:disabled):focus:not(:active), html[data-theme="association"] ._form-content button.btn__secondary:not(:disabled):focus:not(:active), ._form-content html[data-theme="association"] button.btn__secondary:not(:disabled):focus:not(:active) {
      background: #d9213d; }
    html[data-theme="safety"] .btn.btn__secondary:not(:disabled):hover:not(:active), html[data-theme="safety"] ._form-content button.btn__secondary:not(:disabled):hover:not(:active), ._form-content html[data-theme="safety"] button.btn__secondary:not(:disabled):hover:not(:active), html[data-theme="safety"] .btn.btn__secondary:not(:disabled):focus:not(:active), html[data-theme="safety"] ._form-content button.btn__secondary:not(:disabled):focus:not(:active), ._form-content html[data-theme="safety"] button.btn__secondary:not(:disabled):focus:not(:active) {
      background: #e66e00; }
    html[data-theme="recycling"] .btn.btn__secondary:not(:disabled):hover:not(:active), html[data-theme="recycling"] ._form-content button.btn__secondary:not(:disabled):hover:not(:active), ._form-content html[data-theme="recycling"] button.btn__secondary:not(:disabled):hover:not(:active), html[data-theme="recycling"] .btn.btn__secondary:not(:disabled):focus:not(:active), html[data-theme="recycling"] ._form-content button.btn__secondary:not(:disabled):focus:not(:active), ._form-content html[data-theme="recycling"] button.btn__secondary:not(:disabled):focus:not(:active) {
      background: #2c834c; }
    html[data-theme="knowledge"] .btn.btn__secondary:not(:disabled):hover:not(:active), html[data-theme="knowledge"] ._form-content button.btn__secondary:not(:disabled):hover:not(:active), ._form-content html[data-theme="knowledge"] button.btn__secondary:not(:disabled):hover:not(:active), html[data-theme="knowledge"] .btn.btn__secondary:not(:disabled):focus:not(:active), html[data-theme="knowledge"] ._form-content button.btn__secondary:not(:disabled):focus:not(:active), ._form-content html[data-theme="knowledge"] button.btn__secondary:not(:disabled):focus:not(:active) {
      background: #1469c9; }
    html[data-theme="education"] .btn.btn__secondary:not(:disabled):hover:not(:active), html[data-theme="education"] ._form-content button.btn__secondary:not(:disabled):hover:not(:active), ._form-content html[data-theme="education"] button.btn__secondary:not(:disabled):hover:not(:active), html[data-theme="education"] .btn.btn__secondary:not(:disabled):focus:not(:active), html[data-theme="education"] ._form-content button.btn__secondary:not(:disabled):focus:not(:active), ._form-content html[data-theme="education"] button.btn__secondary:not(:disabled):focus:not(:active) {
      background: #631e65; }
    html[data-theme="sustainability"] .btn.btn__secondary:not(:disabled):hover:not(:active), html[data-theme="sustainability"] ._form-content button.btn__secondary:not(:disabled):hover:not(:active), ._form-content html[data-theme="sustainability"] button.btn__secondary:not(:disabled):hover:not(:active), html[data-theme="sustainability"] .btn.btn__secondary:not(:disabled):focus:not(:active), html[data-theme="sustainability"] ._form-content button.btn__secondary:not(:disabled):focus:not(:active), ._form-content html[data-theme="sustainability"] button.btn__secondary:not(:disabled):focus:not(:active) {
      background: #1da321; }
    html[data-theme="default"] .btn.btn__secondary:not(:disabled):hover:not(:active), html[data-theme="default"] ._form-content button.btn__secondary:not(:disabled):hover:not(:active), ._form-content html[data-theme="default"] button.btn__secondary:not(:disabled):hover:not(:active), html[data-theme="default"] .btn.btn__secondary:not(:disabled):focus:not(:active), html[data-theme="default"] ._form-content button.btn__secondary:not(:disabled):focus:not(:active), ._form-content html[data-theme="default"] button.btn__secondary:not(:disabled):focus:not(:active) {
      background: #d9213d; } }

@media screen {
  .btn.btn__secondary:not(:disabled):active:not(:active), ._form-content button.btn__secondary:not(:disabled):active:not(:active) {
    box-shadow: 0 20px 32px 0 rgba(0, 0, 0, 0.2), 0 12px 16px 0 rgba(0, 0, 0, 0.12);
    color: #ffffff; }
    html[data-theme="association"] .btn.btn__secondary:not(:disabled):active:not(:active), html[data-theme="association"] ._form-content button.btn__secondary:not(:disabled):active:not(:active), ._form-content html[data-theme="association"] button.btn__secondary:not(:disabled):active:not(:active) {
      background: #d9213d; }
    html[data-theme="safety"] .btn.btn__secondary:not(:disabled):active:not(:active), html[data-theme="safety"] ._form-content button.btn__secondary:not(:disabled):active:not(:active), ._form-content html[data-theme="safety"] button.btn__secondary:not(:disabled):active:not(:active) {
      background: #e66e00; }
    html[data-theme="recycling"] .btn.btn__secondary:not(:disabled):active:not(:active), html[data-theme="recycling"] ._form-content button.btn__secondary:not(:disabled):active:not(:active), ._form-content html[data-theme="recycling"] button.btn__secondary:not(:disabled):active:not(:active) {
      background: #2c834c; }
    html[data-theme="knowledge"] .btn.btn__secondary:not(:disabled):active:not(:active), html[data-theme="knowledge"] ._form-content button.btn__secondary:not(:disabled):active:not(:active), ._form-content html[data-theme="knowledge"] button.btn__secondary:not(:disabled):active:not(:active) {
      background: #1469c9; }
    html[data-theme="education"] .btn.btn__secondary:not(:disabled):active:not(:active), html[data-theme="education"] ._form-content button.btn__secondary:not(:disabled):active:not(:active), ._form-content html[data-theme="education"] button.btn__secondary:not(:disabled):active:not(:active) {
      background: #631e65; }
    html[data-theme="sustainability"] .btn.btn__secondary:not(:disabled):active:not(:active), html[data-theme="sustainability"] ._form-content button.btn__secondary:not(:disabled):active:not(:active), ._form-content html[data-theme="sustainability"] button.btn__secondary:not(:disabled):active:not(:active) {
      background: #1da321; }
    html[data-theme="default"] .btn.btn__secondary:not(:disabled):active:not(:active), html[data-theme="default"] ._form-content button.btn__secondary:not(:disabled):active:not(:active), ._form-content html[data-theme="default"] button.btn__secondary:not(:disabled):active:not(:active) {
      background: #d9213d; } }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .btn.btn__secondary:not(:disabled):hover:active, ._form-content button.btn__secondary:not(:disabled):hover:active, .btn.btn__secondary:not(:disabled):focus:active, ._form-content button.btn__secondary:not(:disabled):focus:active {
    box-shadow: none; }
    html[data-theme="association"] .btn.btn__secondary:not(:disabled):hover:active, html[data-theme="association"] ._form-content button.btn__secondary:not(:disabled):hover:active, ._form-content html[data-theme="association"] button.btn__secondary:not(:disabled):hover:active, html[data-theme="association"] .btn.btn__secondary:not(:disabled):focus:active, html[data-theme="association"] ._form-content button.btn__secondary:not(:disabled):focus:active, ._form-content html[data-theme="association"] button.btn__secondary:not(:disabled):focus:active {
      background: #a00017;
      border-color: #a00017; }
    html[data-theme="safety"] .btn.btn__secondary:not(:disabled):hover:active, html[data-theme="safety"] ._form-content button.btn__secondary:not(:disabled):hover:active, ._form-content html[data-theme="safety"] button.btn__secondary:not(:disabled):hover:active, html[data-theme="safety"] .btn.btn__secondary:not(:disabled):focus:active, html[data-theme="safety"] ._form-content button.btn__secondary:not(:disabled):focus:active, ._form-content html[data-theme="safety"] button.btn__secondary:not(:disabled):focus:active {
      background: #ad4000;
      border-color: #ad4000; }
    html[data-theme="recycling"] .btn.btn__secondary:not(:disabled):hover:active, html[data-theme="recycling"] ._form-content button.btn__secondary:not(:disabled):hover:active, ._form-content html[data-theme="recycling"] button.btn__secondary:not(:disabled):hover:active, html[data-theme="recycling"] .btn.btn__secondary:not(:disabled):focus:active, html[data-theme="recycling"] ._form-content button.btn__secondary:not(:disabled):focus:active, ._form-content html[data-theme="recycling"] button.btn__secondary:not(:disabled):focus:active {
      background: #005523;
      border-color: #005523; }
    html[data-theme="knowledge"] .btn.btn__secondary:not(:disabled):hover:active, html[data-theme="knowledge"] ._form-content button.btn__secondary:not(:disabled):hover:active, ._form-content html[data-theme="knowledge"] button.btn__secondary:not(:disabled):hover:active, html[data-theme="knowledge"] .btn.btn__secondary:not(:disabled):focus:active, html[data-theme="knowledge"] ._form-content button.btn__secondary:not(:disabled):focus:active, ._form-content html[data-theme="knowledge"] button.btn__secondary:not(:disabled):focus:active {
      background: #003f97;
      border-color: #003f97; }
    html[data-theme="education"] .btn.btn__secondary:not(:disabled):hover:active, html[data-theme="education"] ._form-content button.btn__secondary:not(:disabled):hover:active, ._form-content html[data-theme="education"] button.btn__secondary:not(:disabled):hover:active, html[data-theme="education"] .btn.btn__secondary:not(:disabled):focus:active, html[data-theme="education"] ._form-content button.btn__secondary:not(:disabled):focus:active, ._form-content html[data-theme="education"] button.btn__secondary:not(:disabled):focus:active {
      background: #36003a;
      border-color: #36003a; }
    html[data-theme="sustainability"] .btn.btn__secondary:not(:disabled):hover:active, html[data-theme="sustainability"] ._form-content button.btn__secondary:not(:disabled):hover:active, ._form-content html[data-theme="sustainability"] button.btn__secondary:not(:disabled):hover:active, html[data-theme="sustainability"] .btn.btn__secondary:not(:disabled):focus:active, html[data-theme="sustainability"] ._form-content button.btn__secondary:not(:disabled):focus:active, ._form-content html[data-theme="sustainability"] button.btn__secondary:not(:disabled):focus:active {
      background: #116214;
      border-color: #116214; }
    html[data-theme="default"] .btn.btn__secondary:not(:disabled):hover:active, html[data-theme="default"] ._form-content button.btn__secondary:not(:disabled):hover:active, ._form-content html[data-theme="default"] button.btn__secondary:not(:disabled):hover:active, html[data-theme="default"] .btn.btn__secondary:not(:disabled):focus:active, html[data-theme="default"] ._form-content button.btn__secondary:not(:disabled):focus:active, ._form-content html[data-theme="default"] button.btn__secondary:not(:disabled):focus:active {
      background: #a00017;
      border-color: #a00017; } }

@media screen {
  .btn.btn__secondary:not(:disabled):active:active, ._form-content button.btn__secondary:not(:disabled):active:active {
    box-shadow: none; }
    html[data-theme="association"] .btn.btn__secondary:not(:disabled):active:active, html[data-theme="association"] ._form-content button.btn__secondary:not(:disabled):active:active, ._form-content html[data-theme="association"] button.btn__secondary:not(:disabled):active:active {
      background: #a00017;
      border-color: #a00017; }
    html[data-theme="safety"] .btn.btn__secondary:not(:disabled):active:active, html[data-theme="safety"] ._form-content button.btn__secondary:not(:disabled):active:active, ._form-content html[data-theme="safety"] button.btn__secondary:not(:disabled):active:active {
      background: #ad4000;
      border-color: #ad4000; }
    html[data-theme="recycling"] .btn.btn__secondary:not(:disabled):active:active, html[data-theme="recycling"] ._form-content button.btn__secondary:not(:disabled):active:active, ._form-content html[data-theme="recycling"] button.btn__secondary:not(:disabled):active:active {
      background: #005523;
      border-color: #005523; }
    html[data-theme="knowledge"] .btn.btn__secondary:not(:disabled):active:active, html[data-theme="knowledge"] ._form-content button.btn__secondary:not(:disabled):active:active, ._form-content html[data-theme="knowledge"] button.btn__secondary:not(:disabled):active:active {
      background: #003f97;
      border-color: #003f97; }
    html[data-theme="education"] .btn.btn__secondary:not(:disabled):active:active, html[data-theme="education"] ._form-content button.btn__secondary:not(:disabled):active:active, ._form-content html[data-theme="education"] button.btn__secondary:not(:disabled):active:active {
      background: #36003a;
      border-color: #36003a; }
    html[data-theme="sustainability"] .btn.btn__secondary:not(:disabled):active:active, html[data-theme="sustainability"] ._form-content button.btn__secondary:not(:disabled):active:active, ._form-content html[data-theme="sustainability"] button.btn__secondary:not(:disabled):active:active {
      background: #116214;
      border-color: #116214; }
    html[data-theme="default"] .btn.btn__secondary:not(:disabled):active:active, html[data-theme="default"] ._form-content button.btn__secondary:not(:disabled):active:active, ._form-content html[data-theme="default"] button.btn__secondary:not(:disabled):active:active {
      background: #a00017;
      border-color: #a00017; } }

@media all {
  .btn.btn__secondary:not(:disabled):active, ._form-content button.btn__secondary:not(:disabled):active {
    box-shadow: none; }
    html[data-theme="association"] .btn.btn__secondary:not(:disabled):active, html[data-theme="association"] ._form-content button.btn__secondary:not(:disabled):active, ._form-content html[data-theme="association"] button.btn__secondary:not(:disabled):active {
      background: #a00017;
      border-color: #a00017; }
    html[data-theme="safety"] .btn.btn__secondary:not(:disabled):active, html[data-theme="safety"] ._form-content button.btn__secondary:not(:disabled):active, ._form-content html[data-theme="safety"] button.btn__secondary:not(:disabled):active {
      background: #ad4000;
      border-color: #ad4000; }
    html[data-theme="recycling"] .btn.btn__secondary:not(:disabled):active, html[data-theme="recycling"] ._form-content button.btn__secondary:not(:disabled):active, ._form-content html[data-theme="recycling"] button.btn__secondary:not(:disabled):active {
      background: #005523;
      border-color: #005523; }
    html[data-theme="knowledge"] .btn.btn__secondary:not(:disabled):active, html[data-theme="knowledge"] ._form-content button.btn__secondary:not(:disabled):active, ._form-content html[data-theme="knowledge"] button.btn__secondary:not(:disabled):active {
      background: #003f97;
      border-color: #003f97; }
    html[data-theme="education"] .btn.btn__secondary:not(:disabled):active, html[data-theme="education"] ._form-content button.btn__secondary:not(:disabled):active, ._form-content html[data-theme="education"] button.btn__secondary:not(:disabled):active {
      background: #36003a;
      border-color: #36003a; }
    html[data-theme="sustainability"] .btn.btn__secondary:not(:disabled):active, html[data-theme="sustainability"] ._form-content button.btn__secondary:not(:disabled):active, ._form-content html[data-theme="sustainability"] button.btn__secondary:not(:disabled):active {
      background: #116214;
      border-color: #116214; }
    html[data-theme="default"] .btn.btn__secondary:not(:disabled):active, html[data-theme="default"] ._form-content button.btn__secondary:not(:disabled):active, ._form-content html[data-theme="default"] button.btn__secondary:not(:disabled):active {
      background: #a00017;
      border-color: #a00017; } }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .btn.btn__secondary:disabled:hover, ._form-content button.btn__secondary:disabled:hover, .btn.btn__secondary:disabled:focus, ._form-content button.btn__secondary:disabled:focus {
    color: #eeeeee; } }

@media screen {
  .btn.btn__secondary:disabled:active, ._form-content button.btn__secondary:disabled:active {
    color: #eeeeee; } }

@media all {
  .btn.btn__secondary:disabled, ._form-content button.btn__secondary:disabled {
    color: #eeeeee; } }

.btn.btn__transparent:not(:disabled), ._form-content button.btn__transparent:not(:disabled) {
  border-color: transparent;
  background: transparent; }
  html[data-theme="association"] .btn.btn__transparent:not(:disabled), html[data-theme="association"] ._form-content button.btn__transparent:not(:disabled), ._form-content html[data-theme="association"] button.btn__transparent:not(:disabled) {
    color: #d9213d; }
  html[data-theme="safety"] .btn.btn__transparent:not(:disabled), html[data-theme="safety"] ._form-content button.btn__transparent:not(:disabled), ._form-content html[data-theme="safety"] button.btn__transparent:not(:disabled) {
    color: #e66e00; }
  html[data-theme="recycling"] .btn.btn__transparent:not(:disabled), html[data-theme="recycling"] ._form-content button.btn__transparent:not(:disabled), ._form-content html[data-theme="recycling"] button.btn__transparent:not(:disabled) {
    color: #2c834c; }
  html[data-theme="knowledge"] .btn.btn__transparent:not(:disabled), html[data-theme="knowledge"] ._form-content button.btn__transparent:not(:disabled), ._form-content html[data-theme="knowledge"] button.btn__transparent:not(:disabled) {
    color: #1469c9; }
  html[data-theme="education"] .btn.btn__transparent:not(:disabled), html[data-theme="education"] ._form-content button.btn__transparent:not(:disabled), ._form-content html[data-theme="education"] button.btn__transparent:not(:disabled) {
    color: #631e65; }
  html[data-theme="sustainability"] .btn.btn__transparent:not(:disabled), html[data-theme="sustainability"] ._form-content button.btn__transparent:not(:disabled), ._form-content html[data-theme="sustainability"] button.btn__transparent:not(:disabled) {
    color: #1da321; }
  html[data-theme="default"] .btn.btn__transparent:not(:disabled), html[data-theme="default"] ._form-content button.btn__transparent:not(:disabled), ._form-content html[data-theme="default"] button.btn__transparent:not(:disabled) {
    color: #d9213d; }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  html[data-theme="association"] .btn.btn__transparent:not(:disabled):hover, html[data-theme="association"] ._form-content button.btn__transparent:not(:disabled):hover, ._form-content html[data-theme="association"] button.btn__transparent:not(:disabled):hover, html[data-theme="association"] .btn.btn__transparent:not(:disabled):focus, html[data-theme="association"] ._form-content button.btn__transparent:not(:disabled):focus, ._form-content html[data-theme="association"] button.btn__transparent:not(:disabled):focus {
    color: #a00017; }
  html[data-theme="safety"] .btn.btn__transparent:not(:disabled):hover, html[data-theme="safety"] ._form-content button.btn__transparent:not(:disabled):hover, ._form-content html[data-theme="safety"] button.btn__transparent:not(:disabled):hover, html[data-theme="safety"] .btn.btn__transparent:not(:disabled):focus, html[data-theme="safety"] ._form-content button.btn__transparent:not(:disabled):focus, ._form-content html[data-theme="safety"] button.btn__transparent:not(:disabled):focus {
    color: #ad4000; }
  html[data-theme="recycling"] .btn.btn__transparent:not(:disabled):hover, html[data-theme="recycling"] ._form-content button.btn__transparent:not(:disabled):hover, ._form-content html[data-theme="recycling"] button.btn__transparent:not(:disabled):hover, html[data-theme="recycling"] .btn.btn__transparent:not(:disabled):focus, html[data-theme="recycling"] ._form-content button.btn__transparent:not(:disabled):focus, ._form-content html[data-theme="recycling"] button.btn__transparent:not(:disabled):focus {
    color: #005523; }
  html[data-theme="knowledge"] .btn.btn__transparent:not(:disabled):hover, html[data-theme="knowledge"] ._form-content button.btn__transparent:not(:disabled):hover, ._form-content html[data-theme="knowledge"] button.btn__transparent:not(:disabled):hover, html[data-theme="knowledge"] .btn.btn__transparent:not(:disabled):focus, html[data-theme="knowledge"] ._form-content button.btn__transparent:not(:disabled):focus, ._form-content html[data-theme="knowledge"] button.btn__transparent:not(:disabled):focus {
    color: #003f97; }
  html[data-theme="education"] .btn.btn__transparent:not(:disabled):hover, html[data-theme="education"] ._form-content button.btn__transparent:not(:disabled):hover, ._form-content html[data-theme="education"] button.btn__transparent:not(:disabled):hover, html[data-theme="education"] .btn.btn__transparent:not(:disabled):focus, html[data-theme="education"] ._form-content button.btn__transparent:not(:disabled):focus, ._form-content html[data-theme="education"] button.btn__transparent:not(:disabled):focus {
    color: #36003a; }
  html[data-theme="sustainability"] .btn.btn__transparent:not(:disabled):hover, html[data-theme="sustainability"] ._form-content button.btn__transparent:not(:disabled):hover, ._form-content html[data-theme="sustainability"] button.btn__transparent:not(:disabled):hover, html[data-theme="sustainability"] .btn.btn__transparent:not(:disabled):focus, html[data-theme="sustainability"] ._form-content button.btn__transparent:not(:disabled):focus, ._form-content html[data-theme="sustainability"] button.btn__transparent:not(:disabled):focus {
    color: #116214; }
  html[data-theme="default"] .btn.btn__transparent:not(:disabled):hover, html[data-theme="default"] ._form-content button.btn__transparent:not(:disabled):hover, ._form-content html[data-theme="default"] button.btn__transparent:not(:disabled):hover, html[data-theme="default"] .btn.btn__transparent:not(:disabled):focus, html[data-theme="default"] ._form-content button.btn__transparent:not(:disabled):focus, ._form-content html[data-theme="default"] button.btn__transparent:not(:disabled):focus {
    color: #a00017; } }

@media screen {
  html[data-theme="association"] .btn.btn__transparent:not(:disabled):active, html[data-theme="association"] ._form-content button.btn__transparent:not(:disabled):active, ._form-content html[data-theme="association"] button.btn__transparent:not(:disabled):active {
    color: #a00017; }
  html[data-theme="safety"] .btn.btn__transparent:not(:disabled):active, html[data-theme="safety"] ._form-content button.btn__transparent:not(:disabled):active, ._form-content html[data-theme="safety"] button.btn__transparent:not(:disabled):active {
    color: #ad4000; }
  html[data-theme="recycling"] .btn.btn__transparent:not(:disabled):active, html[data-theme="recycling"] ._form-content button.btn__transparent:not(:disabled):active, ._form-content html[data-theme="recycling"] button.btn__transparent:not(:disabled):active {
    color: #005523; }
  html[data-theme="knowledge"] .btn.btn__transparent:not(:disabled):active, html[data-theme="knowledge"] ._form-content button.btn__transparent:not(:disabled):active, ._form-content html[data-theme="knowledge"] button.btn__transparent:not(:disabled):active {
    color: #003f97; }
  html[data-theme="education"] .btn.btn__transparent:not(:disabled):active, html[data-theme="education"] ._form-content button.btn__transparent:not(:disabled):active, ._form-content html[data-theme="education"] button.btn__transparent:not(:disabled):active {
    color: #36003a; }
  html[data-theme="sustainability"] .btn.btn__transparent:not(:disabled):active, html[data-theme="sustainability"] ._form-content button.btn__transparent:not(:disabled):active, ._form-content html[data-theme="sustainability"] button.btn__transparent:not(:disabled):active {
    color: #116214; }
  html[data-theme="default"] .btn.btn__transparent:not(:disabled):active, html[data-theme="default"] ._form-content button.btn__transparent:not(:disabled):active, ._form-content html[data-theme="default"] button.btn__transparent:not(:disabled):active {
    color: #a00017; } }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  html[data-theme="association"] .btn.btn__transparent:not(:disabled):hover:active, html[data-theme="association"] ._form-content button.btn__transparent:not(:disabled):hover:active, ._form-content html[data-theme="association"] button.btn__transparent:not(:disabled):hover:active, html[data-theme="association"] .btn.btn__transparent:not(:disabled):focus:active, html[data-theme="association"] ._form-content button.btn__transparent:not(:disabled):focus:active, ._form-content html[data-theme="association"] button.btn__transparent:not(:disabled):focus:active {
    color: #a00017; }
  html[data-theme="safety"] .btn.btn__transparent:not(:disabled):hover:active, html[data-theme="safety"] ._form-content button.btn__transparent:not(:disabled):hover:active, ._form-content html[data-theme="safety"] button.btn__transparent:not(:disabled):hover:active, html[data-theme="safety"] .btn.btn__transparent:not(:disabled):focus:active, html[data-theme="safety"] ._form-content button.btn__transparent:not(:disabled):focus:active, ._form-content html[data-theme="safety"] button.btn__transparent:not(:disabled):focus:active {
    color: #ad4000; }
  html[data-theme="recycling"] .btn.btn__transparent:not(:disabled):hover:active, html[data-theme="recycling"] ._form-content button.btn__transparent:not(:disabled):hover:active, ._form-content html[data-theme="recycling"] button.btn__transparent:not(:disabled):hover:active, html[data-theme="recycling"] .btn.btn__transparent:not(:disabled):focus:active, html[data-theme="recycling"] ._form-content button.btn__transparent:not(:disabled):focus:active, ._form-content html[data-theme="recycling"] button.btn__transparent:not(:disabled):focus:active {
    color: #005523; }
  html[data-theme="knowledge"] .btn.btn__transparent:not(:disabled):hover:active, html[data-theme="knowledge"] ._form-content button.btn__transparent:not(:disabled):hover:active, ._form-content html[data-theme="knowledge"] button.btn__transparent:not(:disabled):hover:active, html[data-theme="knowledge"] .btn.btn__transparent:not(:disabled):focus:active, html[data-theme="knowledge"] ._form-content button.btn__transparent:not(:disabled):focus:active, ._form-content html[data-theme="knowledge"] button.btn__transparent:not(:disabled):focus:active {
    color: #003f97; }
  html[data-theme="education"] .btn.btn__transparent:not(:disabled):hover:active, html[data-theme="education"] ._form-content button.btn__transparent:not(:disabled):hover:active, ._form-content html[data-theme="education"] button.btn__transparent:not(:disabled):hover:active, html[data-theme="education"] .btn.btn__transparent:not(:disabled):focus:active, html[data-theme="education"] ._form-content button.btn__transparent:not(:disabled):focus:active, ._form-content html[data-theme="education"] button.btn__transparent:not(:disabled):focus:active {
    color: #36003a; }
  html[data-theme="sustainability"] .btn.btn__transparent:not(:disabled):hover:active, html[data-theme="sustainability"] ._form-content button.btn__transparent:not(:disabled):hover:active, ._form-content html[data-theme="sustainability"] button.btn__transparent:not(:disabled):hover:active, html[data-theme="sustainability"] .btn.btn__transparent:not(:disabled):focus:active, html[data-theme="sustainability"] ._form-content button.btn__transparent:not(:disabled):focus:active, ._form-content html[data-theme="sustainability"] button.btn__transparent:not(:disabled):focus:active {
    color: #116214; }
  html[data-theme="default"] .btn.btn__transparent:not(:disabled):hover:active, html[data-theme="default"] ._form-content button.btn__transparent:not(:disabled):hover:active, ._form-content html[data-theme="default"] button.btn__transparent:not(:disabled):hover:active, html[data-theme="default"] .btn.btn__transparent:not(:disabled):focus:active, html[data-theme="default"] ._form-content button.btn__transparent:not(:disabled):focus:active, ._form-content html[data-theme="default"] button.btn__transparent:not(:disabled):focus:active {
    color: #a00017; } }

@media screen {
  html[data-theme="association"] .btn.btn__transparent:not(:disabled):active:active, html[data-theme="association"] ._form-content button.btn__transparent:not(:disabled):active:active, ._form-content html[data-theme="association"] button.btn__transparent:not(:disabled):active:active {
    color: #a00017; }
  html[data-theme="safety"] .btn.btn__transparent:not(:disabled):active:active, html[data-theme="safety"] ._form-content button.btn__transparent:not(:disabled):active:active, ._form-content html[data-theme="safety"] button.btn__transparent:not(:disabled):active:active {
    color: #ad4000; }
  html[data-theme="recycling"] .btn.btn__transparent:not(:disabled):active:active, html[data-theme="recycling"] ._form-content button.btn__transparent:not(:disabled):active:active, ._form-content html[data-theme="recycling"] button.btn__transparent:not(:disabled):active:active {
    color: #005523; }
  html[data-theme="knowledge"] .btn.btn__transparent:not(:disabled):active:active, html[data-theme="knowledge"] ._form-content button.btn__transparent:not(:disabled):active:active, ._form-content html[data-theme="knowledge"] button.btn__transparent:not(:disabled):active:active {
    color: #003f97; }
  html[data-theme="education"] .btn.btn__transparent:not(:disabled):active:active, html[data-theme="education"] ._form-content button.btn__transparent:not(:disabled):active:active, ._form-content html[data-theme="education"] button.btn__transparent:not(:disabled):active:active {
    color: #36003a; }
  html[data-theme="sustainability"] .btn.btn__transparent:not(:disabled):active:active, html[data-theme="sustainability"] ._form-content button.btn__transparent:not(:disabled):active:active, ._form-content html[data-theme="sustainability"] button.btn__transparent:not(:disabled):active:active {
    color: #116214; }
  html[data-theme="default"] .btn.btn__transparent:not(:disabled):active:active, html[data-theme="default"] ._form-content button.btn__transparent:not(:disabled):active:active, ._form-content html[data-theme="default"] button.btn__transparent:not(:disabled):active:active {
    color: #a00017; } }

@media all {
  html[data-theme="association"] .btn.btn__transparent:not(:disabled):active, html[data-theme="association"] ._form-content button.btn__transparent:not(:disabled):active, ._form-content html[data-theme="association"] button.btn__transparent:not(:disabled):active {
    color: #a00017; }
  html[data-theme="safety"] .btn.btn__transparent:not(:disabled):active, html[data-theme="safety"] ._form-content button.btn__transparent:not(:disabled):active, ._form-content html[data-theme="safety"] button.btn__transparent:not(:disabled):active {
    color: #ad4000; }
  html[data-theme="recycling"] .btn.btn__transparent:not(:disabled):active, html[data-theme="recycling"] ._form-content button.btn__transparent:not(:disabled):active, ._form-content html[data-theme="recycling"] button.btn__transparent:not(:disabled):active {
    color: #005523; }
  html[data-theme="knowledge"] .btn.btn__transparent:not(:disabled):active, html[data-theme="knowledge"] ._form-content button.btn__transparent:not(:disabled):active, ._form-content html[data-theme="knowledge"] button.btn__transparent:not(:disabled):active {
    color: #003f97; }
  html[data-theme="education"] .btn.btn__transparent:not(:disabled):active, html[data-theme="education"] ._form-content button.btn__transparent:not(:disabled):active, ._form-content html[data-theme="education"] button.btn__transparent:not(:disabled):active {
    color: #36003a; }
  html[data-theme="sustainability"] .btn.btn__transparent:not(:disabled):active, html[data-theme="sustainability"] ._form-content button.btn__transparent:not(:disabled):active, ._form-content html[data-theme="sustainability"] button.btn__transparent:not(:disabled):active {
    color: #116214; }
  html[data-theme="default"] .btn.btn__transparent:not(:disabled):active, html[data-theme="default"] ._form-content button.btn__transparent:not(:disabled):active, ._form-content html[data-theme="default"] button.btn__transparent:not(:disabled):active {
    color: #a00017; } }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  html .btn.btn__transparent:disabled:hover, html ._form-content button.btn__transparent:disabled:hover, ._form-content html button.btn__transparent:disabled:hover, html .btn.btn__transparent:disabled:focus, html ._form-content button.btn__transparent:disabled:focus, ._form-content html button.btn__transparent:disabled:focus {
    color: #f1f1f2; } }

@media screen {
  html .btn.btn__transparent:disabled:active, html ._form-content button.btn__transparent:disabled:active, ._form-content html button.btn__transparent:disabled:active {
    color: #f1f1f2; } }

@media all {
  html .btn.btn__transparent:disabled, html ._form-content button.btn__transparent:disabled, ._form-content html button.btn__transparent:disabled {
    color: #f1f1f2; } }

.btn.btn__has-grid, ._form-content button.btn__has-grid {
  display: inline-flex;
  align-items: center;
  justify-content: center; }
  .btn.btn__has-grid .btn--container, ._form-content button.btn__has-grid .btn--container {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    margin: auto; }
  .btn.btn__has-grid .btn--wrap, ._form-content button.btn__has-grid .btn--wrap {
    display: flex;
    flex-flow: row wrap; }
    html.ie11 .btn.btn__has-grid .btn--wrap, html.ie11 ._form-content button.btn__has-grid .btn--wrap, ._form-content html.ie11 button.btn__has-grid .btn--wrap {
      min-height: 1px; }
  .btn.btn__has-grid .btn--icon, ._form-content button.btn__has-grid .btn--icon, .btn.btn__has-grid .btn--text, ._form-content button.btn__has-grid .btn--text {
    position: relative;
    min-height: 1px;
    max-width: 100%; }
  .btn.btn__has-grid .btn--container, ._form-content button.btn__has-grid .btn--container {
    padding-left: 8px;
    padding-right: 8px; }
    .btn.btn__has-grid .btn--container .btn--container:not(.u-iknowwhatimdoing), ._form-content button.btn__has-grid .btn--container .btn--container:not(.u-iknowwhatimdoing) {
      visibility: hidden !important; }
      .btn.btn__has-grid .btn--container .btn--container:not(.u-iknowwhatimdoing)::before, ._form-content button.btn__has-grid .btn--container .btn--container:not(.u-iknowwhatimdoing)::before {
        visibility: visible;
        content: 'Nested `grid--container` detected!';
        font-size: 20px;
        line-height: 1.2;
        color: red; }
  .btn.btn__has-grid .btn--wrap, ._form-content button.btn__has-grid .btn--wrap {
    margin-left: -4px;
    margin-right: -4px; }
  .btn.btn__has-grid .btn--icon, ._form-content button.btn__has-grid .btn--icon, .btn.btn__has-grid .btn--text, ._form-content button.btn__has-grid .btn--text {
    padding-left: 4px;
    padding-right: 4px; }
  .btn.btn__has-grid .btn--wrap, ._form-content button.btn__has-grid .btn--wrap {
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center; }
  .btn.btn__has-grid .btn--text, ._form-content button.btn__has-grid .btn--text {
    position: relative;
    text-align: left; }

.btn.btn__bubble, ._form-content button.btn__bubble {
  min-width: 0;
  border-radius: 50%; }
  .btn.btn__bubble .btn--icon, ._form-content button.btn__bubble .btn--icon {
    margin: 0; }

.btn.btn__bubble.btn__small, ._form-content button.btn__bubble.btn__small {
  padding: 4px; }

.btn.btn__bubble.btn__medium, ._form-content button.btn__bubble.btn__medium {
  padding: 8px; }

.btn.btn__bubble.btn__large, ._form-content button.btn__bubble {
  padding: 12px; }

.grid.grid__custom-1 .grid--container {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  margin: auto; }

.grid.grid__custom-1 .grid--row {
  display: flex;
  flex-flow: row wrap; }
  html.ie11 .grid.grid__custom-1 .grid--row {
    min-height: 1px; }

.grid.grid__custom-1 .grid--col {
  position: relative;
  min-height: 1px;
  max-width: 100%; }

@media (max-width: 374px) {
  .grid.grid__custom-1 .grid--container {
    max-width: none; } }

@media (min-width: 375px) and (max-width: 543px) {
  .grid.grid__custom-1 .grid--container {
    max-width: none; } }

@media (min-width: 544px) and (max-width: 767px) {
  .grid.grid__custom-1 .grid--container {
    max-width: 544px; } }

@media (min-width: 768px) and (max-width: 991px) {
  .grid.grid__custom-1 .grid--container {
    max-width: 744px; } }

@media (min-width: 992px) and (max-width: 1279px) {
  .grid.grid__custom-1 .grid--container {
    max-width: 968px; } }

@media (min-width: 1280px) and (max-width: 1919px) {
  .grid.grid__custom-1 .grid--container {
    max-width: 1244px; } }

@media (min-width: 1920px) {
  .grid.grid__custom-1 .grid--container {
    max-width: 1576px; } }

@media (max-width: 374px) {
  .grid.grid__custom-1 .grid--container {
    padding-left: 16px;
    padding-right: 16px; }
    .grid.grid__custom-1 .grid--container .grid--container:not(.u-iknowwhatimdoing) {
      visibility: hidden !important; }
      .grid.grid__custom-1 .grid--container .grid--container:not(.u-iknowwhatimdoing)::before {
        visibility: visible;
        content: 'Nested `grid--container` detected!';
        font-size: 20px;
        line-height: 1.2;
        color: red; }
  .grid.grid__custom-1 .grid--row {
    margin-left: -8px;
    margin-right: -8px; }
  .grid.grid__custom-1 .grid--col {
    padding-left: 8px;
    padding-right: 8px; } }

@media (min-width: 375px) and (max-width: 543px) {
  .grid.grid__custom-1 .grid--container {
    padding-left: 24px;
    padding-right: 24px; }
    .grid.grid__custom-1 .grid--container .grid--container:not(.u-iknowwhatimdoing) {
      visibility: hidden !important; }
      .grid.grid__custom-1 .grid--container .grid--container:not(.u-iknowwhatimdoing)::before {
        visibility: visible;
        content: 'Nested `grid--container` detected!';
        font-size: 20px;
        line-height: 1.2;
        color: red; }
  .grid.grid__custom-1 .grid--row {
    margin-left: -8px;
    margin-right: -8px; }
  .grid.grid__custom-1 .grid--col {
    padding-left: 8px;
    padding-right: 8px; } }

@media (min-width: 544px) and (max-width: 767px) {
  .grid.grid__custom-1 .grid--container {
    padding-left: 28px;
    padding-right: 28px; }
    .grid.grid__custom-1 .grid--container .grid--container:not(.u-iknowwhatimdoing) {
      visibility: hidden !important; }
      .grid.grid__custom-1 .grid--container .grid--container:not(.u-iknowwhatimdoing)::before {
        visibility: visible;
        content: 'Nested `grid--container` detected!';
        font-size: 20px;
        line-height: 1.2;
        color: red; }
  .grid.grid__custom-1 .grid--row {
    margin-left: -12px;
    margin-right: -12px; }
  .grid.grid__custom-1 .grid--col {
    padding-left: 12px;
    padding-right: 12px; } }

@media (min-width: 768px) and (max-width: 991px) {
  .grid.grid__custom-1 .grid--container {
    padding-left: 32px;
    padding-right: 32px; }
    .grid.grid__custom-1 .grid--container .grid--container:not(.u-iknowwhatimdoing) {
      visibility: hidden !important; }
      .grid.grid__custom-1 .grid--container .grid--container:not(.u-iknowwhatimdoing)::before {
        visibility: visible;
        content: 'Nested `grid--container` detected!';
        font-size: 20px;
        line-height: 1.2;
        color: red; }
  .grid.grid__custom-1 .grid--row {
    margin-left: -12px;
    margin-right: -12px; }
  .grid.grid__custom-1 .grid--col {
    padding-left: 12px;
    padding-right: 12px; } }

@media (min-width: 992px) and (max-width: 1279px) {
  .grid.grid__custom-1 .grid--container {
    padding-left: 32px;
    padding-right: 32px; }
    .grid.grid__custom-1 .grid--container .grid--container:not(.u-iknowwhatimdoing) {
      visibility: hidden !important; }
      .grid.grid__custom-1 .grid--container .grid--container:not(.u-iknowwhatimdoing)::before {
        visibility: visible;
        content: 'Nested `grid--container` detected!';
        font-size: 20px;
        line-height: 1.2;
        color: red; }
  .grid.grid__custom-1 .grid--row {
    margin-left: -16px;
    margin-right: -16px; }
  .grid.grid__custom-1 .grid--col {
    padding-left: 16px;
    padding-right: 16px; } }

@media (min-width: 1280px) and (max-width: 1919px) {
  .grid.grid__custom-1 .grid--container {
    padding-left: 32px;
    padding-right: 32px; }
    .grid.grid__custom-1 .grid--container .grid--container:not(.u-iknowwhatimdoing) {
      visibility: hidden !important; }
      .grid.grid__custom-1 .grid--container .grid--container:not(.u-iknowwhatimdoing)::before {
        visibility: visible;
        content: 'Nested `grid--container` detected!';
        font-size: 20px;
        line-height: 1.2;
        color: red; }
  .grid.grid__custom-1 .grid--row {
    margin-left: -16px;
    margin-right: -16px; }
  .grid.grid__custom-1 .grid--col {
    padding-left: 16px;
    padding-right: 16px; } }

@media (min-width: 1920px) {
  .grid.grid__custom-1 .grid--container {
    padding-left: 40px;
    padding-right: 40px; }
    .grid.grid__custom-1 .grid--container .grid--container:not(.u-iknowwhatimdoing) {
      visibility: hidden !important; }
      .grid.grid__custom-1 .grid--container .grid--container:not(.u-iknowwhatimdoing)::before {
        visibility: visible;
        content: 'Nested `grid--container` detected!';
        font-size: 20px;
        line-height: 1.2;
        color: red; }
  .grid.grid__custom-1 .grid--row {
    margin-left: -20px;
    margin-right: -20px; }
  .grid.grid__custom-1 .grid--col {
    padding-left: 20px;
    padding-right: 20px; } }

.grid.grid__custom-1 .grid--container.grid--container__fluid {
  max-width: none; }

.grid.grid__custom-2 .grid--container {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  margin: auto; }

.grid.grid__custom-2 .grid--row {
  display: flex;
  flex-flow: row wrap; }
  html.ie11 .grid.grid__custom-2 .grid--row {
    min-height: 1px; }

.grid.grid__custom-2 .grid--col {
  position: relative;
  min-height: 1px;
  max-width: 100%; }

@media (max-width: 374px) {
  .grid.grid__custom-2 .grid--container {
    max-width: none; } }

@media (min-width: 375px) and (max-width: 543px) {
  .grid.grid__custom-2 .grid--container {
    max-width: none; } }

@media (min-width: 544px) and (max-width: 767px) {
  .grid.grid__custom-2 .grid--container {
    max-width: 544px; } }

@media (min-width: 768px) and (max-width: 991px) {
  .grid.grid__custom-2 .grid--container {
    max-width: 744px; } }

@media (min-width: 992px) and (max-width: 1279px) {
  .grid.grid__custom-2 .grid--container {
    max-width: 968px; } }

@media (min-width: 1280px) and (max-width: 1919px) {
  .grid.grid__custom-2 .grid--container {
    max-width: 1244px; } }

@media (min-width: 1920px) {
  .grid.grid__custom-2 .grid--container {
    max-width: 1576px; } }

@media (max-width: 374px) {
  .grid.grid__custom-2 .grid--container {
    padding-left: 8px;
    padding-right: 8px; }
    .grid.grid__custom-2 .grid--container .grid--container:not(.u-iknowwhatimdoing) {
      visibility: hidden !important; }
      .grid.grid__custom-2 .grid--container .grid--container:not(.u-iknowwhatimdoing)::before {
        visibility: visible;
        content: 'Nested `grid--container` detected!';
        font-size: 20px;
        line-height: 1.2;
        color: red; }
  .grid.grid__custom-2 .grid--row {
    margin-left: -8px;
    margin-right: -8px; }
  .grid.grid__custom-2 .grid--col {
    padding-left: 8px;
    padding-right: 8px; } }

@media (min-width: 375px) and (max-width: 543px) {
  .grid.grid__custom-2 .grid--container {
    padding-left: 8px;
    padding-right: 8px; }
    .grid.grid__custom-2 .grid--container .grid--container:not(.u-iknowwhatimdoing) {
      visibility: hidden !important; }
      .grid.grid__custom-2 .grid--container .grid--container:not(.u-iknowwhatimdoing)::before {
        visibility: visible;
        content: 'Nested `grid--container` detected!';
        font-size: 20px;
        line-height: 1.2;
        color: red; }
  .grid.grid__custom-2 .grid--row {
    margin-left: -8px;
    margin-right: -8px; }
  .grid.grid__custom-2 .grid--col {
    padding-left: 8px;
    padding-right: 8px; } }

@media (min-width: 544px) and (max-width: 767px) {
  .grid.grid__custom-2 .grid--container {
    padding-left: 12px;
    padding-right: 12px; }
    .grid.grid__custom-2 .grid--container .grid--container:not(.u-iknowwhatimdoing) {
      visibility: hidden !important; }
      .grid.grid__custom-2 .grid--container .grid--container:not(.u-iknowwhatimdoing)::before {
        visibility: visible;
        content: 'Nested `grid--container` detected!';
        font-size: 20px;
        line-height: 1.2;
        color: red; }
  .grid.grid__custom-2 .grid--row {
    margin-left: -12px;
    margin-right: -12px; }
  .grid.grid__custom-2 .grid--col {
    padding-left: 12px;
    padding-right: 12px; } }

@media (min-width: 768px) and (max-width: 991px) {
  .grid.grid__custom-2 .grid--container {
    padding-left: 16px;
    padding-right: 16px; }
    .grid.grid__custom-2 .grid--container .grid--container:not(.u-iknowwhatimdoing) {
      visibility: hidden !important; }
      .grid.grid__custom-2 .grid--container .grid--container:not(.u-iknowwhatimdoing)::before {
        visibility: visible;
        content: 'Nested `grid--container` detected!';
        font-size: 20px;
        line-height: 1.2;
        color: red; }
  .grid.grid__custom-2 .grid--row {
    margin-left: -8px;
    margin-right: -8px; }
  .grid.grid__custom-2 .grid--col {
    padding-left: 8px;
    padding-right: 8px; } }

@media (min-width: 992px) and (max-width: 1279px) {
  .grid.grid__custom-2 .grid--container {
    padding-left: 16px;
    padding-right: 16px; }
    .grid.grid__custom-2 .grid--container .grid--container:not(.u-iknowwhatimdoing) {
      visibility: hidden !important; }
      .grid.grid__custom-2 .grid--container .grid--container:not(.u-iknowwhatimdoing)::before {
        visibility: visible;
        content: 'Nested `grid--container` detected!';
        font-size: 20px;
        line-height: 1.2;
        color: red; }
  .grid.grid__custom-2 .grid--row {
    margin-left: -12px;
    margin-right: -12px; }
  .grid.grid__custom-2 .grid--col {
    padding-left: 12px;
    padding-right: 12px; } }

@media (min-width: 1280px) and (max-width: 1919px) {
  .grid.grid__custom-2 .grid--container {
    padding-left: 16px;
    padding-right: 16px; }
    .grid.grid__custom-2 .grid--container .grid--container:not(.u-iknowwhatimdoing) {
      visibility: hidden !important; }
      .grid.grid__custom-2 .grid--container .grid--container:not(.u-iknowwhatimdoing)::before {
        visibility: visible;
        content: 'Nested `grid--container` detected!';
        font-size: 20px;
        line-height: 1.2;
        color: red; }
  .grid.grid__custom-2 .grid--row {
    margin-left: -12px;
    margin-right: -12px; }
  .grid.grid__custom-2 .grid--col {
    padding-left: 12px;
    padding-right: 12px; } }

@media (min-width: 1920px) {
  .grid.grid__custom-2 .grid--container {
    padding-left: 24px;
    padding-right: 24px; }
    .grid.grid__custom-2 .grid--container .grid--container:not(.u-iknowwhatimdoing) {
      visibility: hidden !important; }
      .grid.grid__custom-2 .grid--container .grid--container:not(.u-iknowwhatimdoing)::before {
        visibility: visible;
        content: 'Nested `grid--container` detected!';
        font-size: 20px;
        line-height: 1.2;
        color: red; }
  .grid.grid__custom-2 .grid--row {
    margin-left: -16px;
    margin-right: -16px; }
  .grid.grid__custom-2 .grid--col {
    padding-left: 16px;
    padding-right: 16px; } }

.grid.grid__custom-2 .grid--container.grid--container__fluid {
  max-width: none; }

.trs-drill-down-enter-active, .trs-drill-down-leave-active {
  transition: transform 200ms ease, opacity 200ms ease; }

.trs-drill-down-enter, .trs-drill-down-leave-to {
  opacity: 0; }

.trs-drill-down-enter {
  transform: translate(100%, 0); }

.trs-drill-down-leave-to {
  transform: translate(-100%, 0); }

.trs-drill-down-leave-active {
  position: absolute;
  width: 100%; }

.trs-drill-up-enter-active, .trs-drill-up-leave-active {
  transition: transform 200ms ease, opacity 200ms ease; }

.trs-drill-up-enter, .trs-drill-up-leave-to {
  opacity: 0; }

.trs-drill-up-enter {
  transform: translate(-100%, 0); }

.trs-drill-up-leave-to {
  transform: translate(100%, 0); }

.trs-drill-up-leave-active {
  position: absolute;
  width: 100%; }

.trs-generic-page-fade-enter-active, .trs-generic-page-fade-leave-active {
  transition: opacity 200ms ease; }

.trs-generic-page-fade-enter, .trs-generic-page-fade-leave-to {
  opacity: 0; }

.trs-info-panel-fade-enter-active, .trs-info-panel-fade-leave-active {
  transition: opacity 200ms ease; }

.trs-info-panel-fade-leave-active {
  position: absolute;
  top: 0;
  left: 0; }

.trs-info-panel-fade-enter, .trs-info-panel-fade-leave-to {
  opacity: 0; }

.trs-side-panel-enter-active, .trs-side-panel-leave-active {
  transition: opacity 200ms ease; }

.trs-side-panel-enter, .trs-side-panel-leave-to {
  opacity: 0; }

.trs-simple-fade-enter-active, .trs-simple-fade-leave-active {
  transition: opacity 200ms ease; }

.trs-simple-fade-enter, .trs-simple-fade-leave-to {
  opacity: 0; }

.ui-form-field.ui-form-field__base {
  display: flex;
  flex-flow: column nowrap; }
  .ui-form-field.ui-form-field__base .ui-form-field--input-container {
    position: relative;
    display: flex; }
  .ui-form-field.ui-form-field__base .ui-form-field--title-label {
    font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
    font-weight: 400;
    font-style: normal;
    display: flex;
    margin-bottom: 4px;
    font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
    font-weight: 400;
    font-style: normal; }
    @media (min-width: 1920px) {
      .ui-form-field.ui-form-field__base .ui-form-field--title-label {
        font-size: 17px;
        line-height: 1.4; } }
    @media (min-width: 1280px) and (max-width: 1919px) {
      .ui-form-field.ui-form-field__base .ui-form-field--title-label {
        font-size: 15px;
        line-height: 1.4; } }
    @media (min-width: 992px) and (max-width: 1279px) {
      .ui-form-field.ui-form-field__base .ui-form-field--title-label {
        font-size: 13px;
        line-height: 1.4; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .ui-form-field.ui-form-field__base .ui-form-field--title-label {
        font-size: 13px;
        line-height: 1.4; } }
    @media (min-width: 544px) and (max-width: 767px) {
      .ui-form-field.ui-form-field__base .ui-form-field--title-label {
        font-size: 13px;
        line-height: 1.4; } }
    @media (min-width: 375px) and (max-width: 543px) {
      .ui-form-field.ui-form-field__base .ui-form-field--title-label {
        font-size: 13px;
        line-height: 1.4; } }
    @media (max-width: 374px) {
      .ui-form-field.ui-form-field__base .ui-form-field--title-label {
        font-size: 13px;
        line-height: 1.4; } }

.ui-form-field.ui-form-field__base .ui-form-field--title-label {
  color: #555555; }

.ui-form-field.ui-form-field__base.ui-form-field__is-required .ui-form-field--title-label::after {
  content: '\200A*'; }

.ui-form-field.ui-form-field__base.ui-form-field__hidden {
  display: none; }

.ui-form-field.ui-form-field__base.ui-form-field__input .ui-form-field--input-container {
  position: relative; }

.ui-form-field.ui-form-field__base.ui-form-field__input .ui-form-field--title-label {
  cursor: pointer;
  transition: color 200ms ease, border-color 200ms ease, background-color 200ms ease, opacity 200ms ease, transform 200ms ease, box-shadow 200ms ease; }

.ui-form-field.ui-form-field__base.ui-form-field__input .ui-form-field--input {
  display: inline-block;
  width: 100%;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  line-height: 1;
  cursor: text;
  transition: color 200ms ease, border-color 200ms ease, background-color 200ms ease, opacity 200ms ease, transform 200ms ease, box-shadow 200ms ease; }
  .ui-form-field.ui-form-field__base.ui-form-field__input .ui-form-field--input::-moz-placeholder {
    transition: color 200ms ease, border-color 200ms ease, background-color 200ms ease, opacity 200ms ease, transform 200ms ease, box-shadow 200ms ease; }
  .ui-form-field.ui-form-field__base.ui-form-field__input .ui-form-field--input:-ms-input-placeholder {
    transition: color 200ms ease, border-color 200ms ease, background-color 200ms ease, opacity 200ms ease, transform 200ms ease, box-shadow 200ms ease; }
  .ui-form-field.ui-form-field__base.ui-form-field__input .ui-form-field--input::-webkit-input-placeholder {
    transition: color 200ms ease, border-color 200ms ease, background-color 200ms ease, opacity 200ms ease, transform 200ms ease, box-shadow 200ms ease; }
  .ui-form-field.ui-form-field__base.ui-form-field__input .ui-form-field--input::placeholder {
    transition: color 200ms ease, border-color 200ms ease, background-color 200ms ease, opacity 200ms ease, transform 200ms ease, box-shadow 200ms ease; }

.ui-form-field.ui-form-field__base.ui-form-field__input .ui-form-field--input {
  color: #555555;
  background-color: #ffffff;
  border-color: #555555; }
  .ui-form-field.ui-form-field__base.ui-form-field__input .ui-form-field--input::-moz-placeholder {
    opacity: 1;
    color: #a5a3a3; }
  .ui-form-field.ui-form-field__base.ui-form-field__input .ui-form-field--input:-ms-input-placeholder {
    opacity: 1;
    color: #a5a3a3; }
  .ui-form-field.ui-form-field__base.ui-form-field__input .ui-form-field--input::-webkit-input-placeholder {
    opacity: 1;
    color: #a5a3a3; }
  .ui-form-field.ui-form-field__base.ui-form-field__input .ui-form-field--input::placeholder {
    opacity: 1;
    color: #a5a3a3; }

.ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__is-filled:not(.ui-form-field__has-focus) .ui-form-field--input {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09), 0 1px 2px rgba(0, 0, 0, 0.2);
  border-color: transparent; }

.ui-form-field.ui-form-field__base.ui-form-field__input:not(.ui-form-field__is-disabled).ui-form-field__has-focus .ui-form-field--title-label {
  color: #555555; }

.ui-form-field.ui-form-field__base.ui-form-field__input:not(.ui-form-field__is-disabled).ui-form-field__has-focus .ui-form-field--input {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 3px 6px 0 rgba(0, 0, 0, 0.12);
  border-color: transparent; }

.ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__is-disabled.ui-form-field .ui-form-field--title-label {
  cursor: default; }
  .ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__is-disabled.ui-form-field .ui-form-field--title-label {
    -webkit-touch-callout: none;
    user-select: none; }
    .ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__is-disabled.ui-form-field .ui-form-field--title-label::selection, .ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__is-disabled.ui-form-field .ui-form-field--title-label ::selection {
      background-color: transparent; }

.ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__is-disabled.ui-form-field .ui-form-field--input {
  color: #a5a3a3;
  background-color: #f1f1f2;
  border-color: #eeeeee;
  cursor: default; }
  .ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__is-disabled.ui-form-field .ui-form-field--input {
    -webkit-touch-callout: none;
    user-select: none; }
    .ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__is-disabled.ui-form-field .ui-form-field--input::selection, .ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__is-disabled.ui-form-field .ui-form-field--input ::selection {
      background-color: transparent; }
  .ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__is-disabled.ui-form-field .ui-form-field--input::-moz-placeholder {
    color: transparent; }
  .ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__is-disabled.ui-form-field .ui-form-field--input:-ms-input-placeholder {
    color: transparent; }
  .ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__is-disabled.ui-form-field .ui-form-field--input::-webkit-input-placeholder {
    color: transparent; }
  .ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__is-disabled.ui-form-field .ui-form-field--input::placeholder {
    color: transparent; }

.ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__has-error.ui-form-field .ui-form-field--input {
  color: #ff0033; }

.ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__textarea .ui-form-field--input {
  max-width: 100%;
  min-height: 50px;
  resize: vertical;
  -ms-overflow-style: -ms-autohiding-scrollbar; }

.ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__select .ui-form-field--input {
  font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal; }

.ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__select .ui-form-field--action.ui-form-field--action {
  pointer-events: none; }

.ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__select .ui-form-field--input {
  line-height: 1;
  cursor: pointer; }

.ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__select .ui-form-field--action {
  color: #555555; }

.ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__select:not(.ui-form-field__is-disabled).ui-form-field__has-focus .ui-form-field--action {
  color: #a5a3a3; }

.ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__select.ui-form-field__is-disabled.ui-form-field .ui-form-field--action {
  color: #eeeeee;
  cursor: default; }

.ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__select:not(.ui-form-field__is-filled) .ui-form-field--input {
  color: #a5a3a3; }
  .ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__select:not(.ui-form-field__is-filled) .ui-form-field--input option, .ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__select:not(.ui-form-field__is-filled) .ui-form-field--input optgroup {
    color: #000000; }
  .ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__select:not(.ui-form-field__is-filled) .ui-form-field--input::-ms-value {
    color: #eeeeee; }

.ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__select:not(.ui-form-field__is-filled).ui-form-field__is-disabled .ui-form-field--input {
  color: transparent; }

.ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__select .ui-form-field--input-container[data-action-count='1'] .ui-form-field--input {
  padding-right: 44px; }

.ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__select .ui-form-field--input-container[data-action-count='2'] .ui-form-field--input {
  padding-right: 72px; }

.ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__select .ui-form-field--input-container[data-action-count='3'] .ui-form-field--input {
  padding-right: 100px; }

.ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__select .ui-form-field--actions {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-right: 8px;
  padding-left: 16px; }

.ui-form-field.ui-form-field__base.ui-form-field__input.ui-form-field__select .ui-form-field--action {
  pointer-events: auto;
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 100%;
  padding: 13px 0 11px;
  cursor: pointer;
  transition: color 200ms ease; }

.ui-form-field.ui-form-field__base.ui-form-field__bool {
  font-size: 17px;
  line-height: 0; }
  .ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input {
    position: absolute;
    overflow: hidden;
    max-width: 1px;
    max-height: 1px;
    padding: 0;
    border: 0;
    margin: -1px;
    clip: rect(0 0 0 0); }
  .ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input-container {
    display: flex;
    flex: 0 0 auto; }
  .ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--title-label {
    cursor: auto; }
  .ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--label-wrap {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    cursor: pointer;
    max-height: 18px;
    transition: color 200ms ease, border-color 200ms ease, background-color 200ms ease, opacity 200ms ease, transform 200ms ease, box-shadow 200ms ease;
    font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
    font-weight: 500;
    font-style: normal; }
  .ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--label {
    padding: 0 0 0 8px;
    transform: translateY(-3px); }
  .ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--box {
    position: relative;
    display: block;
    flex: 0 0 auto;
    width: 18px;
    height: 18px;
    transition: color 200ms ease, border-color 200ms ease, background-color 200ms ease, opacity 200ms ease, transform 200ms ease, box-shadow 200ms ease; }
    .ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--box {
      -webkit-touch-callout: none;
      user-select: none; }
      .ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--box::selection, .ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--box ::selection {
        background-color: transparent; }
    .ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--box::before, .ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--box::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: color 200ms ease, border-color 200ms ease, background-color 200ms ease, opacity 200ms ease, transform 200ms ease, box-shadow 200ms ease; }
    .ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--box::before {
      z-index: 1; }
    .ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--box::after {
      z-index: 2;
      border-width: 2px;
      border-style: solid; }
  .ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--box-icon {
    pointer-events: none;
    z-index: 3;
    opacity: 0;
    transition: color 200ms ease, border-color 200ms ease, background-color 200ms ease, opacity 200ms ease, transform 200ms ease, box-shadow 200ms ease; }

.ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__multiline .ui-form-field--label-wrap {
  max-height: none; }

.ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__multiline .ui-form-field--label {
  flex: 0 1 auto;
  line-height: 1.4; }

.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--title-label {
  color: #555555; }

.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input .ui-form-field--box::before,
.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input ~ .ui-form-field--label-wrap .ui-form-field--box::before {
  opacity: 0; }

.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input .ui-form-field--box,
.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input ~ .ui-form-field--label-wrap .ui-form-field--box {
  background-color: #ffffff; }

.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input .ui-form-field--box::after,
.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input ~ .ui-form-field--label-wrap .ui-form-field--box::after {
  border-color: #555555; }

.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input .ui-form-field--box-icon,
.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input ~ .ui-form-field--label-wrap .ui-form-field--box-icon {
  color: #555555; }

.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:not(:disabled):hover:not(:checked) .ui-form-field--box::after,
.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:not(:disabled):hover:not(:checked) ~ .ui-form-field--label-wrap .ui-form-field--box::after,
.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:not(:disabled) ~ .ui-form-field--label-wrap:hover:not(:checked) .ui-form-field--box::after,
.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:not(:disabled) ~ .ui-form-field--label-wrap:hover:not(:checked) ~ .ui-form-field--label-wrap .ui-form-field--box::after {
  border-color: #111111; }

.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:not(:disabled):hover:not(:checked) .ui-form-field--label,
.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:not(:disabled):hover:not(:checked) ~ .ui-form-field--label-wrap .ui-form-field--label,
.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:not(:disabled) ~ .ui-form-field--label-wrap:hover:not(:checked) .ui-form-field--label,
.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:not(:disabled) ~ .ui-form-field--label-wrap:hover:not(:checked) ~ .ui-form-field--label-wrap .ui-form-field--label {
  color: #111111; }

.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:not(:disabled):hover:checked .ui-form-field--box-icon,
.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:not(:disabled):hover:checked ~ .ui-form-field--label-wrap .ui-form-field--box-icon,
.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:not(:disabled) ~ .ui-form-field--label-wrap:hover:checked .ui-form-field--box-icon,
.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:not(:disabled) ~ .ui-form-field--label-wrap:hover:checked ~ .ui-form-field--label-wrap .ui-form-field--box-icon {
  opacity: 0.8;
  color: #ffffff;
  background: #111111; }

.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:not(:disabled):hover:checked .ui-form-field--label,
.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:not(:disabled):hover:checked ~ .ui-form-field--label-wrap .ui-form-field--label,
.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:not(:disabled) ~ .ui-form-field--label-wrap:hover:checked .ui-form-field--label,
.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:not(:disabled) ~ .ui-form-field--label-wrap:hover:checked ~ .ui-form-field--label-wrap .ui-form-field--label {
  color: #111111; }

.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:not(:disabled):focus .ui-form-field--box::before,
.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:not(:disabled):focus ~ .ui-form-field--label-wrap .ui-form-field--box::before,
.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:not(:disabled) ~ .ui-form-field--label-wrap:focus .ui-form-field--box::before,
.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:not(:disabled) ~ .ui-form-field--label-wrap:focus ~ .ui-form-field--label-wrap .ui-form-field--box::before {
  opacity: 1; }

.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:not(:disabled):active .ui-form-field--box::before,
.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:not(:disabled):active ~ .ui-form-field--label-wrap .ui-form-field--box::before,
.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:not(:disabled) ~ .ui-form-field--label-wrap:active .ui-form-field--box::before,
.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:not(:disabled) ~ .ui-form-field--label-wrap:active ~ .ui-form-field--label-wrap .ui-form-field--box::before {
  opacity: 1; }

.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:checked:nth-child(1n) .ui-form-field--box-icon.ui-form-field--box-icon,
.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:checked:nth-child(1n) ~ .ui-form-field--label-wrap .ui-form-field--box-icon.ui-form-field--box-icon,
.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:checked ~ .ui-form-field--label-wrap:nth-child(1n) .ui-form-field--box-icon.ui-form-field--box-icon,
.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:checked ~ .ui-form-field--label-wrap:nth-child(1n) ~ .ui-form-field--label-wrap .ui-form-field--box-icon.ui-form-field--box-icon {
  opacity: 1; }

.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:checked:nth-child(1n) .ui-form-field--box-icon,
.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:checked:nth-child(1n) ~ .ui-form-field--label-wrap .ui-form-field--box-icon,
.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:checked ~ .ui-form-field--label-wrap:nth-child(1n) .ui-form-field--box-icon,
.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:checked ~ .ui-form-field--label-wrap:nth-child(1n) ~ .ui-form-field--label-wrap .ui-form-field--box-icon {
  color: #ffffff; }

.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:checked:nth-child(1n) .ui-form-field--box-icon,
.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:checked:nth-child(1n) ~ .ui-form-field--label-wrap .ui-form-field--box-icon,
.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:checked ~ .ui-form-field--label-wrap:nth-child(1n) .ui-form-field--box-icon,
.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:checked ~ .ui-form-field--label-wrap:nth-child(1n) ~ .ui-form-field--label-wrap .ui-form-field--box-icon {
  background: #555555; }

.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:checked:nth-child(1n) .ui-form-field--box::before,
.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:checked:nth-child(1n) ~ .ui-form-field--label-wrap .ui-form-field--box::before,
.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:checked ~ .ui-form-field--label-wrap:nth-child(1n) .ui-form-field--box::before,
.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:checked ~ .ui-form-field--label-wrap:nth-child(1n) ~ .ui-form-field--label-wrap .ui-form-field--box::before {
  background: #555555; }

.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:checked:nth-child(1n) .ui-form-field--label,
.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:checked:nth-child(1n) ~ .ui-form-field--label-wrap .ui-form-field--label,
.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:checked ~ .ui-form-field--label-wrap:nth-child(1n) .ui-form-field--label,
.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:checked ~ .ui-form-field--label-wrap:nth-child(1n) ~ .ui-form-field--label-wrap .ui-form-field--label {
  color: #555555; }

.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:disabled,
.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:disabled ~ .ui-form-field--label-wrap {
  cursor: default; }
  .ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:disabled .ui-form-field--label,
  .ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:disabled ~ .ui-form-field--label-wrap .ui-form-field--label {
    cursor: auto;
    color: #a5a3a3; }
  .ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:disabled .ui-form-field--box,
  .ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:disabled ~ .ui-form-field--label-wrap .ui-form-field--box {
    background-color: #f1f1f2; }
  .ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:disabled .ui-form-field--box::after,
  .ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:disabled ~ .ui-form-field--label-wrap .ui-form-field--box::after {
    border-color: #eeeeee; }
  .ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:disabled .ui-form-field--box-icon,
  .ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:disabled ~ .ui-form-field--label-wrap .ui-form-field--box-icon {
    color: #a5a3a3; }

.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:disabled:checked .ui-form-field--label,
.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:disabled:checked ~ .ui-form-field--label-wrap .ui-form-field--label {
  color: #a5a3a3; }

.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:disabled:checked .ui-form-field--box-icon,
.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:disabled:checked ~ .ui-form-field--label-wrap .ui-form-field--box-icon {
  opacity: 1;
  color: #ffffff;
  background: #f1f1f2; }

.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:disabled:checked .ui-form-field--box::after,
.ui-form-field.ui-form-field__base.ui-form-field__bool .ui-form-field--input:disabled:checked ~ .ui-form-field--label-wrap .ui-form-field--box::after {
  background: #f1f1f2; }

.ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__has-error .ui-form-field--title-label {
  color: #ff0033; }

.ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__has-error .ui-form-field--input .ui-form-field--box::after,
.ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__has-error .ui-form-field--input ~ .ui-form-field--label-wrap .ui-form-field--box::after {
  border-color: #ff0033; }

.ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__has-error .ui-form-field--input .ui-form-field--box-icon,
.ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__has-error .ui-form-field--input ~ .ui-form-field--label-wrap .ui-form-field--box-icon {
  background: #ff0033; }

.ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__has-error .ui-form-field--input .ui-form-field--label,
.ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__has-error .ui-form-field--input ~ .ui-form-field--label-wrap .ui-form-field--label {
  color: #ff0033; }

.ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__has-error .ui-form-field--input:checked:nth-child(1n) .ui-form-field--box::after,
.ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__has-error .ui-form-field--input:checked:nth-child(1n) ~ .ui-form-field--label-wrap .ui-form-field--box::after,
.ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__has-error .ui-form-field--input:checked ~ .ui-form-field--label-wrap:nth-child(1n) .ui-form-field--box::after,
.ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__has-error .ui-form-field--input:checked ~ .ui-form-field--label-wrap:nth-child(1n) ~ .ui-form-field--label-wrap .ui-form-field--box::after {
  border-color: #ff0033; }

.ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__has-error .ui-form-field--input:checked:nth-child(1n) .ui-form-field--box-icon,
.ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__has-error .ui-form-field--input:checked:nth-child(1n) ~ .ui-form-field--label-wrap .ui-form-field--box-icon,
.ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__has-error .ui-form-field--input:checked ~ .ui-form-field--label-wrap:nth-child(1n) .ui-form-field--box-icon,
.ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__has-error .ui-form-field--input:checked ~ .ui-form-field--label-wrap:nth-child(1n) ~ .ui-form-field--label-wrap .ui-form-field--box-icon {
  background: #ff0033; }

.ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__has-error .ui-form-field--input:checked:nth-child(1n) .ui-form-field--label,
.ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__has-error .ui-form-field--input:checked:nth-child(1n) ~ .ui-form-field--label-wrap .ui-form-field--label,
.ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__has-error .ui-form-field--input:checked ~ .ui-form-field--label-wrap:nth-child(1n) .ui-form-field--label,
.ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__has-error .ui-form-field--input:checked ~ .ui-form-field--label-wrap:nth-child(1n) ~ .ui-form-field--label-wrap .ui-form-field--label {
  color: #ff0033; }

.ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__has-error .ui-form-field--input:disabled,
.ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__has-error .ui-form-field--input:disabled ~ .ui-form-field--label-wrap {
  cursor: default; }
  .ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__has-error .ui-form-field--input:disabled .ui-form-field--label,
  .ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__has-error .ui-form-field--input:disabled ~ .ui-form-field--label-wrap .ui-form-field--label {
    cursor: auto;
    color: #a5a3a3; }
  .ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__has-error .ui-form-field--input:disabled .ui-form-field--box,
  .ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__has-error .ui-form-field--input:disabled ~ .ui-form-field--label-wrap .ui-form-field--box {
    background-color: #f1f1f2; }
  .ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__has-error .ui-form-field--input:disabled .ui-form-field--box::after,
  .ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__has-error .ui-form-field--input:disabled ~ .ui-form-field--label-wrap .ui-form-field--box::after {
    border-color: #eeeeee; }
  .ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__has-error .ui-form-field--input:disabled .ui-form-field--box-icon,
  .ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__has-error .ui-form-field--input:disabled ~ .ui-form-field--label-wrap .ui-form-field--box-icon {
    color: #a5a3a3; }

.ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__has-error .ui-form-field--input:disabled:checked .ui-form-field--label,
.ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__has-error .ui-form-field--input:disabled:checked ~ .ui-form-field--label-wrap .ui-form-field--label {
  color: #a5a3a3; }

.ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__has-error .ui-form-field--input:disabled:checked .ui-form-field--box-icon,
.ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__has-error .ui-form-field--input:disabled:checked ~ .ui-form-field--label-wrap .ui-form-field--box-icon {
  opacity: 1;
  color: #ffffff;
  background: #f1f1f2; }

.ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__has-error .ui-form-field--input:disabled:checked .ui-form-field--box::after,
.ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__has-error .ui-form-field--input:disabled:checked ~ .ui-form-field--label-wrap .ui-form-field--box::after {
  background: #f1f1f2; }

.ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__radio .ui-form-field--input ~ .ui-form-field--label-wrap .ui-form-field--box, .ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__radio .ui-form-field--input ~ .ui-form-field--label-wrap .ui-form-field--box::after, .ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__radio .ui-form-field--input ~ .ui-form-field--label-wrap .ui-form-field--box::before {
  border-radius: 18px; }

.ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__radio .ui-form-field--input ~ .ui-form-field--label-wrap .ui-form-field--box-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  border-radius: 18px;
  transform: translate(-50%, -50%);
  background-color: currentColor; }

.ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__checkbox .ui-form-field--input ~ .ui-form-field--label-wrap .ui-form-field--box, .ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__checkbox .ui-form-field--input ~ .ui-form-field--label-wrap .ui-form-field--box::after, .ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__checkbox .ui-form-field--input ~ .ui-form-field--label-wrap .ui-form-field--box::before {
  border-radius: 4px; }

.ui-form-field.ui-form-field__base.ui-form-field__bool.ui-form-field__checkbox .ui-form-field--input ~ .ui-form-field--label-wrap .ui-form-field--box-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -8px;
  margin-top: -8px;
  width: 16px;
  height: 16px; }

.ui-form-field.ui-form-field__base.ui-form-field__has-actions .ui-form-field--input-container[data-action-count='1'] .ui-form-field--input {
  padding-right: 44px; }

.ui-form-field.ui-form-field__base.ui-form-field__has-actions .ui-form-field--input-container[data-action-count='2'] .ui-form-field--input {
  padding-right: 72px; }

.ui-form-field.ui-form-field__base.ui-form-field__has-actions .ui-form-field--input-container[data-action-count='3'] .ui-form-field--input {
  padding-right: 100px; }

.ui-form-field.ui-form-field__base.ui-form-field__has-actions .ui-form-field--actions {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-right: 8px;
  padding-left: 16px; }

.ui-form-field.ui-form-field__base.ui-form-field__has-actions .ui-form-field--action {
  pointer-events: auto;
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 100%;
  padding: 13px 0 11px;
  cursor: pointer;
  transition: color 200ms ease; }

.ui-form-field.ui-form-field__base.ui-form-field__list .ui-form-field--container {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  margin: auto; }

.ui-form-field.ui-form-field__base.ui-form-field__list .ui-form-field--row {
  display: flex;
  flex-flow: row wrap; }
  html.ie11 .ui-form-field.ui-form-field__base.ui-form-field__list .ui-form-field--row {
    min-height: 1px; }

.ui-form-field.ui-form-field__base.ui-form-field__list .ui-form-field--input-container {
  position: relative;
  min-height: 1px;
  max-width: 100%; }

.ui-form-field.ui-form-field__base.ui-form-field__list .ui-form-field--container {
  padding-top: 8px;
  padding-left: 20px;
  padding-bottom: 8px;
  padding-right: 20px; }
  .ui-form-field.ui-form-field__base.ui-form-field__list .ui-form-field--container .ui-form-field--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important; }
    .ui-form-field.ui-form-field__base.ui-form-field__list .ui-form-field--container .ui-form-field--container:not(.u-iknowwhatimdoing)::before {
      visibility: visible;
      content: 'Nested `grid--container` detected!';
      font-size: 20px;
      line-height: 1.2;
      color: red; }

.ui-form-field.ui-form-field__base.ui-form-field__list .ui-form-field--row {
  margin-top: -4px;
  margin-left: -10px;
  margin-bottom: -4px;
  margin-right: -10px; }

.ui-form-field.ui-form-field__base.ui-form-field__list .ui-form-field--input-container {
  padding-top: 4px;
  padding-left: 10px;
  padding-bottom: 4px;
  padding-right: 10px; }

.ui-form-field.ui-form-field__base.ui-form-field__list .ui-form-field--list-item {
  width: 100%; }

.ui-form-field.ui-form-field__base.ui-form-field__list.ui-form-field__vertical .ui-form-field--row {
  flex-flow: column nowrap; }

.ui-form-field.ui-form-field__base.ui-form-field__collection-points-search .ui-form-field--input {
  font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
  font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
  font-weight: 500;
  font-style: normal;
  color: #2c834c;
  border: none;
  padding-right: 52px !important;
  padding-left: 52px !important; }
  @media (min-width: 1920px) {
    .ui-form-field.ui-form-field__base.ui-form-field__collection-points-search .ui-form-field--input {
      font-size: 19px;
      line-height: 1.4; } }
  @media (min-width: 1280px) and (max-width: 1919px) {
    .ui-form-field.ui-form-field__base.ui-form-field__collection-points-search .ui-form-field--input {
      font-size: 17px;
      line-height: 1.4; } }
  @media (min-width: 992px) and (max-width: 1279px) {
    .ui-form-field.ui-form-field__base.ui-form-field__collection-points-search .ui-form-field--input {
      font-size: 15px;
      line-height: 1.4; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .ui-form-field.ui-form-field__base.ui-form-field__collection-points-search .ui-form-field--input {
      font-size: 15px;
      line-height: 1.4; } }
  @media (min-width: 544px) and (max-width: 767px) {
    .ui-form-field.ui-form-field__base.ui-form-field__collection-points-search .ui-form-field--input {
      font-size: 15px;
      line-height: 1.4; } }
  @media (min-width: 375px) and (max-width: 543px) {
    .ui-form-field.ui-form-field__base.ui-form-field__collection-points-search .ui-form-field--input {
      font-size: 15px;
      line-height: 1.4; } }
  @media (max-width: 374px) {
    .ui-form-field.ui-form-field__base.ui-form-field__collection-points-search .ui-form-field--input {
      font-size: 15px;
      line-height: 1.4; } }

.ui-form-field.ui-form-field__base.ui-form-field__collection-points-search .ui-form-field--actions {
  width: 100%;
  padding: 0; }

.ui-form-field.ui-form-field__base.ui-form-field__collection-points-search .ui-form-field--action {
  pointer-events: none;
  align-items: stretch;
  flex: 1 0 auto;
  padding: 0;
  color: #2c834c; }

.ui-form-field.ui-form-field__base.ui-form-field__collection-points-search .ui-form-field--input {
  border-color: transparent; }
  .ui-form-field.ui-form-field__base.ui-form-field__collection-points-search .ui-form-field--input .ui-form-field--action {
    color: #2c834c; }
  .ui-form-field.ui-form-field__base.ui-form-field__collection-points-search .ui-form-field--input::-moz-placeholder {
    opacity: 1;
    color: #a5a3a3; }
  .ui-form-field.ui-form-field__base.ui-form-field__collection-points-search .ui-form-field--input:-ms-input-placeholder {
    opacity: 1;
    color: #a5a3a3; }
  .ui-form-field.ui-form-field__base.ui-form-field__collection-points-search .ui-form-field--input::-webkit-input-placeholder {
    opacity: 1;
    color: #a5a3a3; }
  .ui-form-field.ui-form-field__base.ui-form-field__collection-points-search .ui-form-field--input::placeholder {
    opacity: 1;
    color: #a5a3a3; }

.ui-form-field.ui-form-field__base.ui-form-field__collection-points-search.ui-form-field__is-filled:not(.ui-form-field__has-focus) .ui-form-field--input {
  box-shadow: none; }

.ui-form-field.ui-form-field__base.ui-form-field__collection-points-search:not(.ui-form-field__is-disabled).ui-form-field__has-focus .ui-form-field--input {
  box-shadow: none; }

.ui-form-field.ui-form-field__search .ui-form-field--input.ui-form-field--input {
  font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
  font-weight: 500;
  font-style: normal;
  padding-right: 56px;
  padding-left: 56px; }

.ui-form-field.ui-form-field__search .ui-form-field--actions.ui-form-field--actions {
  justify-content: space-between;
  width: 100%;
  padding-left: 16px;
  padding-right: 16px; }

.ui-form-field.ui-form-field__search .ui-form-field--action {
  pointer-events: none;
  flex: 0 0 auto;
  margin: 0;
  padding: 0; }

.ui-form-field.ui-form-field__search .ui-form-field--input::-moz-placeholder {
  opacity: 1;
  color: #a5a3a3; }

.ui-form-field.ui-form-field__search .ui-form-field--input:-ms-input-placeholder {
  opacity: 1;
  color: #a5a3a3; }

.ui-form-field.ui-form-field__search .ui-form-field--input::-webkit-input-placeholder {
  opacity: 1;
  color: #a5a3a3; }

.ui-form-field.ui-form-field__search .ui-form-field--input::placeholder {
  opacity: 1;
  color: #a5a3a3; }

.ui-form-field.ui-form-field__search.ui-form-field__is-filled:not(.ui-form-field__has-focus) .ui-form-field--input {
  box-shadow: none; }

.ui-form-field.ui-form-field__search:not(.ui-form-field__is-disabled).ui-form-field__has-focus .ui-form-field--input {
  box-shadow: none; }

.ui-form-field.ui-form-field__large .ui-form-field--input {
  font-size: 2.1rem;
  padding: 24px 24px 23px; }
  @media (min-width: 1280px) and (max-width: 1919px) {
    .ui-form-field.ui-form-field__large .ui-form-field--input {
      padding: 22px 12px 19px;
      font-size: 1.9rem; } }
  @media (max-width: 1279px) {
    .ui-form-field.ui-form-field__large .ui-form-field--input {
      padding: 20px 12px 17px;
      font-size: 1.7rem; } }

.ui-form-field.ui-form-field__medium .ui-form-field--input {
  padding: 22px 12px 19px;
  font-size: 1.9rem; }

.ui-form-field.ui-form-field__small .ui-form-field--input {
  padding: 20px 12px 17px;
  font-size: 1.7rem; }

.arrow-link {
  display: flex;
  align-items: center;
  font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
  font-weight: 500;
  font-style: normal; }
  .arrow-link .arrow-link--text {
    padding-right: 8px; }

.auth {
  margin: 120px 0 72px;
  text-align: center; }
  @media (min-width: 768px) and (max-width: 991px) {
    .auth {
      margin: 64px 0 16px; } }
  @media (max-width: 991px) {
    .auth {
      margin: 84px 0 36px; } }
  .auth .auth--form-wrap {
    padding: 32px 24px 24px;
    background-color: #ffffff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09), 0 1px 2px rgba(0, 0, 0, 0.2);
    transition: box-shadow 200ms ease;
    border-radius: 4px; }
    @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .auth .auth--form-wrap:hover, .auth .auth--form-wrap:focus {
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 3px 6px 0 rgba(0, 0, 0, 0.12); } }
    @media screen {
      .auth .auth--form-wrap:active {
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 3px 6px 0 rgba(0, 0, 0, 0.12); } }
  .auth .auth--col {
    margin: 0 auto; }
    @media (min-width: 992px) {
      .auth .auth--col {
        flex: 0 0 33.33333%; }
        html.ie11 .auth .auth--col {
          flex-basis: auto;
          width: 33.33333%; }
        html.ie10 .auth .auth--col {
          flex-basis: auto;
          width: 33.33333%; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .auth .auth--col {
        flex: 0 0 50%; }
        html.ie11 .auth .auth--col {
          flex-basis: auto;
          width: 50%; }
        html.ie10 .auth .auth--col {
          flex-basis: auto;
          width: 50%; } }
    @media (max-width: 767px) {
      .auth .auth--col {
        flex: 0 0 100%; }
        html.ie11 .auth .auth--col {
          flex-basis: auto;
          width: 100%; }
        html.ie10 .auth .auth--col {
          flex-basis: auto;
          width: 100%; } }
  .auth .auth--title {
    margin-bottom: 16px; }
  .auth .auth--description {
    margin-bottom: 8px; }
  .auth .auth--list {
    margin-bottom: 32px;
    text-align: left; }
  .auth .auth--form-row {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 16px; }

.auth .auth--form-row.auth--form-row__button {
  margin-bottom: 16px; }

.columns {
  display: flex;
  flex-wrap: wrap;
  padding: 64px 0; }
  @media (min-width: 1280px) {
    .columns {
      flex-wrap: nowrap;
      column-gap: 16px;
      justify-content: space-between; } }
  .columns p:last-child {
    margin-bottom: 0; }

.contact-person {
  display: flex;
  padding: 32px 0; }
  .contact-person .contact-person--image {
    overflow: hidden;
    width: 80px;
    height: 80px;
    border-radius: 100%;
    margin-right: 24px; }
    .contact-person .contact-person--image > img {
      width: 100%; }
      @supports (object-fit: cover) {
        .contact-person .contact-person--image > img {
          height: 100%;
          object-fit: cover; } }
  .contact-person .contact-person--name {
    color: #111111;
    font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
    font-weight: 500;
    font-style: normal; }
    @media (min-width: 1920px) {
      .contact-person .contact-person--name {
        font-size: 19px;
        line-height: 1.4; } }
    @media (min-width: 1280px) and (max-width: 1919px) {
      .contact-person .contact-person--name {
        font-size: 17px;
        line-height: 1.4; } }
    @media (min-width: 992px) and (max-width: 1279px) {
      .contact-person .contact-person--name {
        font-size: 15px;
        line-height: 1.4; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .contact-person .contact-person--name {
        font-size: 15px;
        line-height: 1.4; } }
    @media (min-width: 544px) and (max-width: 767px) {
      .contact-person .contact-person--name {
        font-size: 15px;
        line-height: 1.4; } }
    @media (min-width: 375px) and (max-width: 543px) {
      .contact-person .contact-person--name {
        font-size: 15px;
        line-height: 1.4; } }
    @media (max-width: 374px) {
      .contact-person .contact-person--name {
        font-size: 15px;
        line-height: 1.4; } }
  .contact-person .contact-person--description {
    font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
    font-weight: 400;
    font-style: normal; }
    @media (min-width: 1920px) {
      .contact-person .contact-person--description {
        font-size: 19px;
        line-height: 1.4; } }
    @media (min-width: 1280px) and (max-width: 1919px) {
      .contact-person .contact-person--description {
        font-size: 17px;
        line-height: 1.4; } }
    @media (min-width: 992px) and (max-width: 1279px) {
      .contact-person .contact-person--description {
        font-size: 15px;
        line-height: 1.4; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .contact-person .contact-person--description {
        font-size: 15px;
        line-height: 1.4; } }
    @media (min-width: 544px) and (max-width: 767px) {
      .contact-person .contact-person--description {
        font-size: 15px;
        line-height: 1.4; } }
    @media (min-width: 375px) and (max-width: 543px) {
      .contact-person .contact-person--description {
        font-size: 15px;
        line-height: 1.4; } }
    @media (max-width: 374px) {
      .contact-person .contact-person--description {
        font-size: 15px;
        line-height: 1.4; } }
  .contact-person .contact-person--link {
    font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
    font-weight: 400;
    font-style: normal; }
    @media (min-width: 1920px) {
      .contact-person .contact-person--link {
        font-size: 19px;
        line-height: 1.4; } }
    @media (min-width: 1280px) and (max-width: 1919px) {
      .contact-person .contact-person--link {
        font-size: 17px;
        line-height: 1.4; } }
    @media (min-width: 992px) and (max-width: 1279px) {
      .contact-person .contact-person--link {
        font-size: 15px;
        line-height: 1.4; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .contact-person .contact-person--link {
        font-size: 15px;
        line-height: 1.4; } }
    @media (min-width: 544px) and (max-width: 767px) {
      .contact-person .contact-person--link {
        font-size: 15px;
        line-height: 1.4; } }
    @media (min-width: 375px) and (max-width: 543px) {
      .contact-person .contact-person--link {
        font-size: 15px;
        line-height: 1.4; } }
    @media (max-width: 374px) {
      .contact-person .contact-person--link {
        font-size: 15px;
        line-height: 1.4; } }

.contact-person.contact-person__association .contact-person--link {
  color: #a00017; }
  @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .contact-person.contact-person__association .contact-person--link:hover, .contact-person.contact-person__association .contact-person--link:focus {
      color: #d9213d; } }
  @media screen {
    .contact-person.contact-person__association .contact-person--link:active {
      color: #d9213d; } }

.contact-person.contact-person__safety .contact-person--link {
  color: #ad4000; }
  @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .contact-person.contact-person__safety .contact-person--link:hover, .contact-person.contact-person__safety .contact-person--link:focus {
      color: #e66e00; } }
  @media screen {
    .contact-person.contact-person__safety .contact-person--link:active {
      color: #e66e00; } }

.contact-person.contact-person__recycling .contact-person--link {
  color: #005523; }
  @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .contact-person.contact-person__recycling .contact-person--link:hover, .contact-person.contact-person__recycling .contact-person--link:focus {
      color: #2c834c; } }
  @media screen {
    .contact-person.contact-person__recycling .contact-person--link:active {
      color: #2c834c; } }

.contact-person.contact-person__knowledge .contact-person--link {
  color: #003f97; }
  @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .contact-person.contact-person__knowledge .contact-person--link:hover, .contact-person.contact-person__knowledge .contact-person--link:focus {
      color: #1469c9; } }
  @media screen {
    .contact-person.contact-person__knowledge .contact-person--link:active {
      color: #1469c9; } }

.contact-person.contact-person__education .contact-person--link {
  color: #36003a; }
  @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .contact-person.contact-person__education .contact-person--link:hover, .contact-person.contact-person__education .contact-person--link:focus {
      color: #631e65; } }
  @media screen {
    .contact-person.contact-person__education .contact-person--link:active {
      color: #631e65; } }

.contact-person.contact-person__sustainability .contact-person--link {
  color: #116214; }
  @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .contact-person.contact-person__sustainability .contact-person--link:hover, .contact-person.contact-person__sustainability .contact-person--link:focus {
      color: #1da321; } }
  @media screen {
    .contact-person.contact-person__sustainability .contact-person--link:active {
      color: #1da321; } }

.contact-person.contact-person__default .contact-person--link {
  color: #a00017; }
  @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .contact-person.contact-person__default .contact-person--link:hover, .contact-person.contact-person__default .contact-person--link:focus {
      color: #d9213d; } }
  @media screen {
    .contact-person.contact-person__default .contact-person--link:active {
      color: #d9213d; } }

.deeplink-panel .deeplink-panel--container {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  margin: auto; }

.deeplink-panel .deeplink-panel--collection {
  display: flex;
  flex-flow: row wrap; }
  html.ie11 .deeplink-panel .deeplink-panel--collection {
    min-height: 1px; }

.deeplink-panel .deeplink-panel--item {
  position: relative;
  min-height: 1px;
  max-width: 100%; }

@media (min-width: 992px) {
  .deeplink-panel .deeplink-panel--container {
    padding-left: 0;
    padding-right: 0; }
    .deeplink-panel .deeplink-panel--container .deeplink-panel--container:not(.u-iknowwhatimdoing) {
      visibility: hidden !important; }
      .deeplink-panel .deeplink-panel--container .deeplink-panel--container:not(.u-iknowwhatimdoing)::before {
        visibility: visible;
        content: 'Nested `grid--container` detected!';
        font-size: 20px;
        line-height: 1.2;
        color: red; }
  .deeplink-panel .deeplink-panel--collection {
    margin-left: 0;
    margin-right: 0; }
  .deeplink-panel .deeplink-panel--item {
    padding-left: 0;
    padding-right: 0; } }

.deeplink-panel .deeplink-panel--collection {
  box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.08), 0 8px 16px 0 rgba(0, 0, 0, 0.12);
  transition: box-shadow 200ms ease;
  border-radius: 4px;
  overflow: hidden;
  background: #ffffff; }
  @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .deeplink-panel .deeplink-panel--collection:hover, .deeplink-panel .deeplink-panel--collection:focus {
      box-shadow: 0 20px 32px 0 rgba(0, 0, 0, 0.2), 0 12px 16px 0 rgba(0, 0, 0, 0.12); } }
  @media screen {
    .deeplink-panel .deeplink-panel--collection:active {
      box-shadow: 0 20px 32px 0 rgba(0, 0, 0, 0.2), 0 12px 16px 0 rgba(0, 0, 0, 0.12); } }

.deeplink-panel .deeplink-panel--title-col {
  text-align: center;
  margin: 0 auto 24px; }
  @media (min-width: 768px) {
    .deeplink-panel .deeplink-panel--title-col {
      flex: 0 0 66.66667%; }
      html.ie11 .deeplink-panel .deeplink-panel--title-col {
        flex-basis: auto;
        width: 66.66667%; }
      html.ie10 .deeplink-panel .deeplink-panel--title-col {
        flex-basis: auto;
        width: 66.66667%; } }
  @media (max-width: 767px) {
    .deeplink-panel .deeplink-panel--title-col {
      flex: 0 0 100%; }
      html.ie11 .deeplink-panel .deeplink-panel--title-col {
        flex-basis: auto;
        width: 100%; }
      html.ie10 .deeplink-panel .deeplink-panel--title-col {
        flex-basis: auto;
        width: 100%; } }

.deeplink-panel .deeplink-panel--title {
  color: #111111;
  font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
  font-weight: 500;
  font-style: normal; }
  @media (min-width: 1920px) {
    .deeplink-panel .deeplink-panel--title {
      font-size: 29px;
      line-height: 1.25; } }
  @media (min-width: 1280px) and (max-width: 1919px) {
    .deeplink-panel .deeplink-panel--title {
      font-size: 25px;
      line-height: 1.25; } }
  @media (min-width: 992px) and (max-width: 1279px) {
    .deeplink-panel .deeplink-panel--title {
      font-size: 25px;
      line-height: 1.25; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .deeplink-panel .deeplink-panel--title {
      font-size: 24px;
      line-height: 1.25; } }
  @media (min-width: 544px) and (max-width: 767px) {
    .deeplink-panel .deeplink-panel--title {
      font-size: 24px;
      line-height: 1.25; } }
  @media (min-width: 375px) and (max-width: 543px) {
    .deeplink-panel .deeplink-panel--title {
      font-size: 22px;
      line-height: 1.3; } }
  @media (max-width: 374px) {
    .deeplink-panel .deeplink-panel--title {
      font-size: 21px;
      line-height: 1.4; } }

.deeplink-panel .deeplink-panel--item {
  overflow: hidden;
  /*
            @include has-focus(true) {
                @each $name, $theme in $themes {
                    @include at-root($themes-root-el, theme-name-to-css-selector($name)) {
                        border-color: get-theme-color($name);
                    }
                }
            }
            */ }
  @media (min-width: 1280px) {
    .deeplink-panel .deeplink-panel--item {
      flex: 0 0 25%; }
      html.ie11 .deeplink-panel .deeplink-panel--item {
        flex-basis: auto;
        width: 25%; }
      html.ie10 .deeplink-panel .deeplink-panel--item {
        flex-basis: auto;
        width: 25%; } }
  @media (min-width: 992px) and (max-width: 1279px), (min-width: 768px) and (max-width: 991px) {
    .deeplink-panel .deeplink-panel--item {
      flex: 0 0 50%; }
      html.ie11 .deeplink-panel .deeplink-panel--item {
        flex-basis: auto;
        width: 50%; }
      html.ie10 .deeplink-panel .deeplink-panel--item {
        flex-basis: auto;
        width: 50%; } }
  @media (max-width: 767px) {
    .deeplink-panel .deeplink-panel--item {
      flex: 0 0 100%; }
      html.ie11 .deeplink-panel .deeplink-panel--item {
        flex-basis: auto;
        width: 100%; }
      html.ie10 .deeplink-panel .deeplink-panel--item {
        flex-basis: auto;
        width: 100%; } }
  @media (min-width: 1280px) {
    .deeplink-panel .deeplink-panel--item {
      border-bottom: 1px solid #f1f1f2;
      border-right: 1px solid #f1f1f2; }
      .deeplink-panel .deeplink-panel--item:nth-child(4n + 1):nth-last-child(-n + 4),
      .deeplink-panel .deeplink-panel--item:nth-child(4n + 1):nth-last-child(-n + 4) ~ .deeplink-panel--item {
        border-bottom: none; }
      .deeplink-panel .deeplink-panel--item:nth-child(4n+0) {
        border-right: none; } }
  @media (min-width: 992px) and (max-width: 1279px), (min-width: 768px) and (max-width: 991px), (min-width: 544px) and (max-width: 767px) {
    .deeplink-panel .deeplink-panel--item {
      border-bottom: 1px solid #f1f1f2;
      border-right: 1px solid #f1f1f2; }
      .deeplink-panel .deeplink-panel--item:nth-child(2n + 1):nth-last-child(-n + 2),
      .deeplink-panel .deeplink-panel--item:nth-child(2n + 1):nth-last-child(-n + 2) ~ .deeplink-panel--item {
        border-bottom: none; }
      .deeplink-panel .deeplink-panel--item:nth-child(2n+0) {
        border-right: none; } }
  @media (max-width: 543px) {
    .deeplink-panel .deeplink-panel--item {
      border-bottom: 1px solid #f1f1f2;
      border-right: none; }
      .deeplink-panel .deeplink-panel--item:last-child {
        border-bottom: none; } }

.desktop-menu-dropdown {
  border-top: 1px solid #eeeeee;
  background: #ffffff;
  padding: 24px 16px 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09), 0 1px 2px rgba(0, 0, 0, 0.2);
  border-radius: 0 0 4px 4px;
  transition: box-shadow 200ms ease; }
  .desktop-menu-dropdown .desktop-menu-dropdown--menu-list-col {
    flex: 0 0 75%; }
    html.ie11 .desktop-menu-dropdown .desktop-menu-dropdown--menu-list-col {
      flex-basis: auto;
      width: 75%; }
    html.ie10 .desktop-menu-dropdown .desktop-menu-dropdown--menu-list-col {
      flex-basis: auto;
      width: 75%; }
  .desktop-menu-dropdown .desktop-menu-dropdown--title-col {
    flex: 0 0 25%; }
    html.ie11 .desktop-menu-dropdown .desktop-menu-dropdown--title-col {
      flex-basis: auto;
      width: 25%; }
    html.ie10 .desktop-menu-dropdown .desktop-menu-dropdown--title-col {
      flex-basis: auto;
      width: 25%; }
  .desktop-menu-dropdown .desktop-menu-dropdown--person-col {
    display: flex;
    margin-bottom: 16px;
    flex: 0 0 75%; }
    html.ie11 .desktop-menu-dropdown .desktop-menu-dropdown--person-col {
      flex-basis: auto;
      width: 75%; }
    html.ie10 .desktop-menu-dropdown .desktop-menu-dropdown--person-col {
      flex-basis: auto;
      width: 75%; }
  .desktop-menu-dropdown .desktop-menu-dropdown--menu-col {
    padding-bottom: 24px;
    box-shadow: -1px 0 0 0 #eeeeee;
    flex: 0 0 33.33333%; }
    html.ie11 .desktop-menu-dropdown .desktop-menu-dropdown--menu-col {
      flex-basis: auto;
      width: 33.33333%; }
    html.ie10 .desktop-menu-dropdown .desktop-menu-dropdown--menu-col {
      flex-basis: auto;
      width: 33.33333%; }
    .desktop-menu-dropdown .desktop-menu-dropdown--menu-col:nth-child(3n + 1):nth-last-child(-n + 3),
    .desktop-menu-dropdown .desktop-menu-dropdown--menu-col:nth-child(3n + 1):nth-last-child(-n + 3) ~ .desktop-menu-dropdown--menu-col {
      margin-top: 16px; }
  .desktop-menu-dropdown .desktop-menu-dropdown--title {
    font-size: 2.4rem;
    color: #2c2c2c;
    color: #111111;
    font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
    font-weight: 500;
    font-style: normal; }
    @media (min-width: 1920px) {
      .desktop-menu-dropdown .desktop-menu-dropdown--title {
        font-size: 29px;
        line-height: 1.25; } }
    @media (min-width: 1280px) and (max-width: 1919px) {
      .desktop-menu-dropdown .desktop-menu-dropdown--title {
        font-size: 25px;
        line-height: 1.25; } }
    @media (min-width: 992px) and (max-width: 1279px) {
      .desktop-menu-dropdown .desktop-menu-dropdown--title {
        font-size: 25px;
        line-height: 1.25; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .desktop-menu-dropdown .desktop-menu-dropdown--title {
        font-size: 24px;
        line-height: 1.25; } }
    @media (min-width: 544px) and (max-width: 767px) {
      .desktop-menu-dropdown .desktop-menu-dropdown--title {
        font-size: 24px;
        line-height: 1.25; } }
    @media (min-width: 375px) and (max-width: 543px) {
      .desktop-menu-dropdown .desktop-menu-dropdown--title {
        font-size: 22px;
        line-height: 1.3; } }
    @media (max-width: 374px) {
      .desktop-menu-dropdown .desktop-menu-dropdown--title {
        font-size: 21px;
        line-height: 1.4; } }
  .desktop-menu-dropdown .desktop-menu-dropdown--subtitle {
    margin-bottom: 8px;
    padding: 14px 0 8px;
    color: #2c2c2c;
    color: #111111;
    font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
    font-weight: 500;
    font-style: normal;
    font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
    font-weight: 700;
    font-style: italic; }
    @media (min-width: 1920px) {
      .desktop-menu-dropdown .desktop-menu-dropdown--subtitle {
        font-size: 15px;
        line-height: 1.4; } }
    @media (min-width: 1280px) and (max-width: 1919px) {
      .desktop-menu-dropdown .desktop-menu-dropdown--subtitle {
        font-size: 13px;
        line-height: 1.4; } }
    @media (min-width: 992px) and (max-width: 1279px) {
      .desktop-menu-dropdown .desktop-menu-dropdown--subtitle {
        font-size: 11px;
        line-height: 1.4; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .desktop-menu-dropdown .desktop-menu-dropdown--subtitle {
        font-size: 11px;
        line-height: 1.4; } }
    @media (min-width: 544px) and (max-width: 767px) {
      .desktop-menu-dropdown .desktop-menu-dropdown--subtitle {
        font-size: 11px;
        line-height: 1.4; } }
    @media (min-width: 375px) and (max-width: 543px) {
      .desktop-menu-dropdown .desktop-menu-dropdown--subtitle {
        font-size: 11px;
        line-height: 1.4; } }
    @media (max-width: 374px) {
      .desktop-menu-dropdown .desktop-menu-dropdown--subtitle {
        font-size: 11px;
        line-height: 1.4; } }
  .desktop-menu-dropdown .desktop-menu-dropdown--link {
    color: #555555;
    display: inline-block;
    padding: 8px 0;
    font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
    font-weight: 400;
    font-style: normal;
    font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
    font-weight: 500;
    font-style: normal; }
    @media (min-width: 1920px) {
      .desktop-menu-dropdown .desktop-menu-dropdown--link {
        font-size: 19px;
        line-height: 1.4; } }
    @media (min-width: 1280px) and (max-width: 1919px) {
      .desktop-menu-dropdown .desktop-menu-dropdown--link {
        font-size: 17px;
        line-height: 1.4; } }
    @media (min-width: 992px) and (max-width: 1279px) {
      .desktop-menu-dropdown .desktop-menu-dropdown--link {
        font-size: 15px;
        line-height: 1.4; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .desktop-menu-dropdown .desktop-menu-dropdown--link {
        font-size: 15px;
        line-height: 1.4; } }
    @media (min-width: 544px) and (max-width: 767px) {
      .desktop-menu-dropdown .desktop-menu-dropdown--link {
        font-size: 15px;
        line-height: 1.4; } }
    @media (min-width: 375px) and (max-width: 543px) {
      .desktop-menu-dropdown .desktop-menu-dropdown--link {
        font-size: 15px;
        line-height: 1.4; } }
    @media (max-width: 374px) {
      .desktop-menu-dropdown .desktop-menu-dropdown--link {
        font-size: 15px;
        line-height: 1.4; } }

.desktop-menu.desktop-menu__big-shadow .desktop-menu-dropdown {
  box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.08), 0 8px 16px 0 rgba(0, 0, 0, 0.12); }

.desktop-menu-dropdown .desktop-menu-dropdown--link.desktop-menu-dropdown--link__team {
  margin-top: 16px; }

.desktop-menu-dropdown.desktop-menu-dropdown__association .desktop-menu-dropdown--link.desktop-menu-dropdown--link__active {
  color: #a00017; }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .desktop-menu-dropdown.desktop-menu-dropdown__association .desktop-menu-dropdown--link:hover, .desktop-menu-dropdown.desktop-menu-dropdown__association .desktop-menu-dropdown--link:focus {
    color: #a00017; } }

@media screen {
  .desktop-menu-dropdown.desktop-menu-dropdown__association .desktop-menu-dropdown--link:active {
    color: #a00017; } }

.desktop-menu-dropdown.desktop-menu-dropdown__safety .desktop-menu-dropdown--link.desktop-menu-dropdown--link__active {
  color: #ad4000; }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .desktop-menu-dropdown.desktop-menu-dropdown__safety .desktop-menu-dropdown--link:hover, .desktop-menu-dropdown.desktop-menu-dropdown__safety .desktop-menu-dropdown--link:focus {
    color: #ad4000; } }

@media screen {
  .desktop-menu-dropdown.desktop-menu-dropdown__safety .desktop-menu-dropdown--link:active {
    color: #ad4000; } }

.desktop-menu-dropdown.desktop-menu-dropdown__recycling .desktop-menu-dropdown--link.desktop-menu-dropdown--link__active {
  color: #005523; }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .desktop-menu-dropdown.desktop-menu-dropdown__recycling .desktop-menu-dropdown--link:hover, .desktop-menu-dropdown.desktop-menu-dropdown__recycling .desktop-menu-dropdown--link:focus {
    color: #005523; } }

@media screen {
  .desktop-menu-dropdown.desktop-menu-dropdown__recycling .desktop-menu-dropdown--link:active {
    color: #005523; } }

.desktop-menu-dropdown.desktop-menu-dropdown__knowledge .desktop-menu-dropdown--link.desktop-menu-dropdown--link__active {
  color: #003f97; }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .desktop-menu-dropdown.desktop-menu-dropdown__knowledge .desktop-menu-dropdown--link:hover, .desktop-menu-dropdown.desktop-menu-dropdown__knowledge .desktop-menu-dropdown--link:focus {
    color: #003f97; } }

@media screen {
  .desktop-menu-dropdown.desktop-menu-dropdown__knowledge .desktop-menu-dropdown--link:active {
    color: #003f97; } }

.desktop-menu-dropdown.desktop-menu-dropdown__education .desktop-menu-dropdown--link.desktop-menu-dropdown--link__active {
  color: #36003a; }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .desktop-menu-dropdown.desktop-menu-dropdown__education .desktop-menu-dropdown--link:hover, .desktop-menu-dropdown.desktop-menu-dropdown__education .desktop-menu-dropdown--link:focus {
    color: #36003a; } }

@media screen {
  .desktop-menu-dropdown.desktop-menu-dropdown__education .desktop-menu-dropdown--link:active {
    color: #36003a; } }

.desktop-menu-dropdown.desktop-menu-dropdown__sustainability .desktop-menu-dropdown--link.desktop-menu-dropdown--link__active {
  color: #116214; }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .desktop-menu-dropdown.desktop-menu-dropdown__sustainability .desktop-menu-dropdown--link:hover, .desktop-menu-dropdown.desktop-menu-dropdown__sustainability .desktop-menu-dropdown--link:focus {
    color: #116214; } }

@media screen {
  .desktop-menu-dropdown.desktop-menu-dropdown__sustainability .desktop-menu-dropdown--link:active {
    color: #116214; } }

.desktop-menu-dropdown.desktop-menu-dropdown__default .desktop-menu-dropdown--link.desktop-menu-dropdown--link__active {
  color: #a00017; }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .desktop-menu-dropdown.desktop-menu-dropdown__default .desktop-menu-dropdown--link:hover, .desktop-menu-dropdown.desktop-menu-dropdown__default .desktop-menu-dropdown--link:focus {
    color: #a00017; } }

@media screen {
  .desktop-menu-dropdown.desktop-menu-dropdown__default .desktop-menu-dropdown--link:active {
    color: #a00017; } }

.detail-intro {
  width: 100%;
  padding: 64px 0 0; }
  @media (max-width: 767px) {
    .detail-intro {
      padding: 48px 0 0; } }
  .detail-intro .detail-intro--img {
    width: 100%; }

@media screen {
  .detail-intro .detail-intro--col.detail-intro--col__main {
    margin-left: auto; } }
  @media screen and (min-width: 768px) {
    .detail-intro .detail-intro--col.detail-intro--col__main {
      flex: 0 0 66.66667%; }
      html.ie11 .detail-intro .detail-intro--col.detail-intro--col__main {
        flex-basis: auto;
        width: 66.66667%; }
      html.ie10 .detail-intro .detail-intro--col.detail-intro--col__main {
        flex-basis: auto;
        width: 66.66667%; } }
  @media screen and (max-width: 767px) {
    .detail-intro .detail-intro--col.detail-intro--col__main {
      flex: 0 0 100%; }
      html.ie11 .detail-intro .detail-intro--col.detail-intro--col__main {
        flex-basis: auto;
        width: 100%; }
      html.ie10 .detail-intro .detail-intro--col.detail-intro--col__main {
        flex-basis: auto;
        width: 100%; } }

@media print {
  .detail-intro .detail-intro--col.detail-intro--col__main {
    flex: 0 0 100%; }
    html.ie11 .detail-intro .detail-intro--col.detail-intro--col__main {
      flex-basis: auto;
      width: 100%; }
    html.ie10 .detail-intro .detail-intro--col.detail-intro--col__main {
      flex-basis: auto;
      width: 100%; } }

@media screen and (min-width: 992px) {
  .detail-intro .detail-intro--col.detail-intro--col__pull {
    flex: 0 0 8.33333%; }
    html.ie11 .detail-intro .detail-intro--col.detail-intro--col__pull {
      flex-basis: auto;
      width: 8.33333%; }
    html.ie10 .detail-intro .detail-intro--col.detail-intro--col__pull {
      flex-basis: auto;
      width: 8.33333%; } }

.document-link {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09), 0 1px 2px rgba(0, 0, 0, 0.2);
  transition: color 200ms ease, border-color 200ms ease, background-color 200ms ease, opacity 200ms ease, transform 200ms ease, box-shadow 200ms ease;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 16px; }
  @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .document-link:hover, .document-link:focus {
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 3px 6px 0 rgba(0, 0, 0, 0.12); } }
  @media screen {
    .document-link:active {
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 3px 6px 0 rgba(0, 0, 0, 0.12); } }

.event-card {
  padding: 24px;
  height: 100%; }
  @media (max-width: 543px) {
    .event-card {
      padding: 16px; } }
  .event-card .event-card--content {
    display: flex;
    flex-direction: column;
    height: 100%; }
  .event-card .event-card--intro {
    display: flex;
    margin-bottom: 24px;
    color: #111111; }
  .event-card .event-card--title {
    margin-bottom: 16px;
    color: #111111;
    font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
    font-weight: 500;
    font-style: normal; }
    @media (min-width: 1920px) {
      .event-card .event-card--title {
        font-size: 21px;
        line-height: 1.25; } }
    @media (min-width: 1280px) and (max-width: 1919px) {
      .event-card .event-card--title {
        font-size: 19px;
        line-height: 1.3; } }
    @media (min-width: 992px) and (max-width: 1279px) {
      .event-card .event-card--title {
        font-size: 17px;
        line-height: 1.3; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .event-card .event-card--title {
        font-size: 17px;
        line-height: 1.3; } }
    @media (min-width: 544px) and (max-width: 767px) {
      .event-card .event-card--title {
        font-size: 17px;
        line-height: 1.3; } }
    @media (min-width: 375px) and (max-width: 543px) {
      .event-card .event-card--title {
        font-size: 17px;
        line-height: 1.3; } }
    @media (max-width: 374px) {
      .event-card .event-card--title {
        font-size: 17px;
        line-height: 1.4; } }
  .event-card .event-card--paragraph {
    margin-bottom: 16px;
    color: #555555;
    font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
    font-weight: 400;
    font-style: normal; }
    @media (min-width: 1920px) {
      .event-card .event-card--paragraph {
        font-size: 19px;
        line-height: 1.4; } }
    @media (min-width: 1280px) and (max-width: 1919px) {
      .event-card .event-card--paragraph {
        font-size: 17px;
        line-height: 1.4; } }
    @media (min-width: 992px) and (max-width: 1279px) {
      .event-card .event-card--paragraph {
        font-size: 15px;
        line-height: 1.4; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .event-card .event-card--paragraph {
        font-size: 15px;
        line-height: 1.4; } }
    @media (min-width: 544px) and (max-width: 767px) {
      .event-card .event-card--paragraph {
        font-size: 15px;
        line-height: 1.4; } }
    @media (min-width: 375px) and (max-width: 543px) {
      .event-card .event-card--paragraph {
        font-size: 15px;
        line-height: 1.4; } }
    @media (max-width: 374px) {
      .event-card .event-card--paragraph {
        font-size: 15px;
        line-height: 1.4; } }
  .event-card .event-card--icon {
    margin-top: auto; }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .event-card.event-card__association:hover .event-card--icon, .event-card.event-card__association:focus .event-card--icon {
    color: #a00017; } }

@media screen {
  .event-card.event-card__association:active .event-card--icon {
    color: #a00017; } }

.event-card.event-card__association .event-card--icon {
  color: #d9213d; }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .event-card.event-card__safety:hover .event-card--icon, .event-card.event-card__safety:focus .event-card--icon {
    color: #ad4000; } }

@media screen {
  .event-card.event-card__safety:active .event-card--icon {
    color: #ad4000; } }

.event-card.event-card__safety .event-card--icon {
  color: #e66e00; }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .event-card.event-card__recycling:hover .event-card--icon, .event-card.event-card__recycling:focus .event-card--icon {
    color: #005523; } }

@media screen {
  .event-card.event-card__recycling:active .event-card--icon {
    color: #005523; } }

.event-card.event-card__recycling .event-card--icon {
  color: #2c834c; }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .event-card.event-card__knowledge:hover .event-card--icon, .event-card.event-card__knowledge:focus .event-card--icon {
    color: #003f97; } }

@media screen {
  .event-card.event-card__knowledge:active .event-card--icon {
    color: #003f97; } }

.event-card.event-card__knowledge .event-card--icon {
  color: #1469c9; }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .event-card.event-card__education:hover .event-card--icon, .event-card.event-card__education:focus .event-card--icon {
    color: #36003a; } }

@media screen {
  .event-card.event-card__education:active .event-card--icon {
    color: #36003a; } }

.event-card.event-card__education .event-card--icon {
  color: #631e65; }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .event-card.event-card__sustainability:hover .event-card--icon, .event-card.event-card__sustainability:focus .event-card--icon {
    color: #116214; } }

@media screen {
  .event-card.event-card__sustainability:active .event-card--icon {
    color: #116214; } }

.event-card.event-card__sustainability .event-card--icon {
  color: #1da321; }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .event-card.event-card__default:hover .event-card--icon, .event-card.event-card__default:focus .event-card--icon {
    color: #a00017; } }

@media screen {
  .event-card.event-card__default:active .event-card--icon {
    color: #a00017; } }

.event-card.event-card__default .event-card--icon {
  color: #d9213d; }

.event-date {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: right;
  padding-left: 8px;
  border-bottom: 4px solid currentColor;
  transform: translate(0, 1px); }
  @media (min-width: 1920px) {
    .event-date {
      min-width: 110px; } }
  @media (min-width: 1280px) and (max-width: 1919px) {
    .event-date {
      min-width: 96px; } }
  @media (min-width: 992px) and (max-width: 1279px), (min-width: 768px) and (max-width: 991px) {
    .event-date {
      min-width: 76px; } }
  @media (max-width: 767px) {
    .event-date {
      padding-left: 0;
      min-width: 68px;
      border-bottom-width: 3px; } }
  .event-date .event-date--day {
    color: #111111;
    font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
    font-weight: 500;
    font-style: normal;
    transform: translate(0.1em, 0.1em);
    color: currentColor; }
    @media (min-width: 1920px) {
      .event-date .event-date--day {
        font-size: 72px;
        line-height: 1.2; } }
    @media (min-width: 1280px) and (max-width: 1919px) {
      .event-date .event-date--day {
        font-size: 64px;
        line-height: 1.2; } }
    @media (min-width: 992px) and (max-width: 1279px) {
      .event-date .event-date--day {
        font-size: 48px;
        line-height: 1.2; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .event-date .event-date--day {
        font-size: 44px;
        line-height: 1.2; } }
    @media (min-width: 544px) and (max-width: 767px) {
      .event-date .event-date--day {
        font-size: 40px;
        line-height: 1.2; } }
    @media (min-width: 375px) and (max-width: 543px) {
      .event-date .event-date--day {
        font-size: 30px;
        line-height: 1.2; } }
    @media (max-width: 374px) {
      .event-date .event-date--day {
        font-size: 28px;
        line-height: 1.2; } }
    .event-date .event-date--day.event-date--day {
      line-height: 1.2; }
  .event-date .event-date--month,
  .event-date .event-date--year {
    white-space: nowrap;
    color: #111111;
    font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
    font-weight: 500;
    font-style: normal;
    font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
    font-weight: 500;
    font-style: normal;
    color: currentColor; }
    @media (min-width: 1920px) {
      .event-date .event-date--month,
      .event-date .event-date--year {
        font-size: 17px;
        line-height: 1.4; } }
    @media (min-width: 1280px) and (max-width: 1919px) {
      .event-date .event-date--month,
      .event-date .event-date--year {
        font-size: 15px;
        line-height: 1.4; } }
    @media (min-width: 992px) and (max-width: 1279px) {
      .event-date .event-date--month,
      .event-date .event-date--year {
        font-size: 13px;
        line-height: 1.4; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .event-date .event-date--month,
      .event-date .event-date--year {
        font-size: 13px;
        line-height: 1.4; } }
    @media (min-width: 544px) and (max-width: 767px) {
      .event-date .event-date--month,
      .event-date .event-date--year {
        font-size: 13px;
        line-height: 1.4; } }
    @media (min-width: 375px) and (max-width: 543px) {
      .event-date .event-date--month,
      .event-date .event-date--year {
        font-size: 13px;
        line-height: 1.4; } }
    @media (max-width: 374px) {
      .event-date .event-date--month,
      .event-date .event-date--year {
        font-size: 13px;
        line-height: 1.4; } }

.event-meta .event-meta--container {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  margin: auto; }

.event-meta .event-meta--collection {
  display: flex;
  flex-flow: row wrap; }
  html.ie11 .event-meta .event-meta--collection {
    min-height: 1px; }

.event-meta .event-meta--item {
  position: relative;
  min-height: 1px;
  max-width: 100%; }

.event-meta .event-meta--container {
  padding-top: 24px;
  padding-left: 0;
  padding-bottom: 24px;
  padding-right: 0; }
  .event-meta .event-meta--container .event-meta--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important; }
    .event-meta .event-meta--container .event-meta--container:not(.u-iknowwhatimdoing)::before {
      visibility: visible;
      content: 'Nested `grid--container` detected!';
      font-size: 20px;
      line-height: 1.2;
      color: red; }

.event-meta .event-meta--collection {
  margin-top: -12px;
  margin-left: 0;
  margin-bottom: -12px;
  margin-right: 0; }

.event-meta .event-meta--item {
  padding-top: 12px;
  padding-left: 0;
  padding-bottom: 12px;
  padding-right: 0; }

.event-meta .event-meta--title {
  position: absolute;
  overflow: hidden;
  max-width: 1px;
  max-height: 1px;
  padding: 0;
  border: 0;
  margin: -1px;
  clip: rect(0 0 0 0); }

.event-meta .event-meta--address {
  margin-bottom: 64px; }
  @media (max-width: 767px) {
    .event-meta .event-meta--address {
      margin-bottom: 48px; } }

.event-meta .event-meta--date,
.event-meta .event-meta--address {
  font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal; }
  @media (min-width: 1920px) {
    .event-meta .event-meta--date,
    .event-meta .event-meta--address {
      font-size: 19px;
      line-height: 1.4; } }
  @media (min-width: 1280px) and (max-width: 1919px) {
    .event-meta .event-meta--date,
    .event-meta .event-meta--address {
      font-size: 17px;
      line-height: 1.4; } }
  @media (min-width: 992px) and (max-width: 1279px) {
    .event-meta .event-meta--date,
    .event-meta .event-meta--address {
      font-size: 15px;
      line-height: 1.4; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .event-meta .event-meta--date,
    .event-meta .event-meta--address {
      font-size: 15px;
      line-height: 1.4; } }
  @media (min-width: 544px) and (max-width: 767px) {
    .event-meta .event-meta--date,
    .event-meta .event-meta--address {
      font-size: 15px;
      line-height: 1.4; } }
  @media (min-width: 375px) and (max-width: 543px) {
    .event-meta .event-meta--date,
    .event-meta .event-meta--address {
      font-size: 15px;
      line-height: 1.4; } }
  @media (max-width: 374px) {
    .event-meta .event-meta--date,
    .event-meta .event-meta--address {
      font-size: 15px;
      line-height: 1.4; } }

.event-meta .event-meta--date {
  display: inline-block;
  width: 100%;
  margin-bottom: 24px; }

.event-meta .event-meta--item {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center; }

.event-meta .event-meta--event-link {
  font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
  font-weight: 500;
  font-style: normal; }

/* stylelint-disable max-nesting-depth */
.facts-figures .facts-figures--container {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  margin: auto; }

.facts-figures .facts-figures--collection {
  display: flex;
  flex-flow: row wrap; }
  html.ie11 .facts-figures .facts-figures--collection {
    min-height: 1px; }

.facts-figures .facts-figures--item {
  position: relative;
  min-height: 1px;
  max-width: 100%; }

.facts-figures .facts-figures--container {
  padding-top: 48px;
  padding-left: 32px;
  padding-bottom: 48px;
  padding-right: 32px; }
  .facts-figures .facts-figures--container .facts-figures--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important; }
    .facts-figures .facts-figures--container .facts-figures--container:not(.u-iknowwhatimdoing)::before {
      visibility: visible;
      content: 'Nested `grid--container` detected!';
      font-size: 20px;
      line-height: 1.2;
      color: red; }

.facts-figures .facts-figures--collection {
  margin-top: -24px;
  margin-left: -16px;
  margin-bottom: -24px;
  margin-right: -16px; }

.facts-figures .facts-figures--item {
  padding-top: 24px;
  padding-left: 16px;
  padding-bottom: 24px;
  padding-right: 16px; }

.facts-figures .facts-figures--item {
  display: flex;
  align-items: stretch;
  justify-content: center;
  position: relative;
  min-height: 1px;
  max-width: 100%;
  position: relative;
  min-height: 1px;
  max-width: 100%; }
  @media (min-width: 992px) {
    .facts-figures .facts-figures--item {
      flex: 0 0 25%; }
      html.ie11 .facts-figures .facts-figures--item {
        flex-basis: auto;
        width: 25%; }
      html.ie10 .facts-figures .facts-figures--item {
        flex-basis: auto;
        width: 25%; } }
  @media (max-width: 991px) {
    .facts-figures .facts-figures--item {
      flex: 0 0 50%; }
      html.ie11 .facts-figures .facts-figures--item {
        flex-basis: auto;
        width: 50%; }
      html.ie10 .facts-figures .facts-figures--item {
        flex-basis: auto;
        width: 50%; } }

.facts-figures .facts-figures--link {
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  justify-content: stretch; }

.facts-figures .facts-figures--number {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.08), 0 8px 16px 0 rgba(0, 0, 0, 0.12);
  margin-bottom: 24px;
  transition: box-shadow 200ms ease;
  background: #ffffff;
  flex: 0 0 112px;
  width: 112px;
  height: 112px;
  color: #111111;
  font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
  font-weight: 500;
  font-style: normal; }
  .facts-figures .facts-figures--number::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    border-radius: 100%;
    border-width: 4px;
    border-style: solid;
    transform: translate(-50%, -50%) scale(0.8);
    opacity: 0;
    transition: opacity 200ms ease, transform 200ms ease;
    flex: 0 0 136px;
    width: 136px;
    height: 136px; }
  @media (min-width: 1920px) {
    .facts-figures .facts-figures--number {
      font-size: 29px;
      line-height: 1.25; } }
  @media (min-width: 1280px) and (max-width: 1919px) {
    .facts-figures .facts-figures--number {
      font-size: 25px;
      line-height: 1.25; } }
  @media (min-width: 992px) and (max-width: 1279px) {
    .facts-figures .facts-figures--number {
      font-size: 25px;
      line-height: 1.25; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .facts-figures .facts-figures--number {
      font-size: 24px;
      line-height: 1.25; } }
  @media (min-width: 544px) and (max-width: 767px) {
    .facts-figures .facts-figures--number {
      font-size: 24px;
      line-height: 1.25; } }
  @media (min-width: 375px) and (max-width: 543px) {
    .facts-figures .facts-figures--number {
      font-size: 22px;
      line-height: 1.3; } }
  @media (max-width: 374px) {
    .facts-figures .facts-figures--number {
      font-size: 21px;
      line-height: 1.4; } }

.facts-figures .facts-figures--paragraph {
  margin-bottom: 12px; }

.facts-figures .facts-figures--description {
  display: flex;
  flex: 1 1 auto;
  margin: 0;
  flex-direction: column;
  color: #555555;
  font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal; }
  @media (min-width: 1920px) {
    .facts-figures .facts-figures--description {
      font-size: 21px;
      line-height: 1.44; } }
  @media (min-width: 1280px) and (max-width: 1919px) {
    .facts-figures .facts-figures--description {
      font-size: 19px;
      line-height: 1.57; } }
  @media (min-width: 992px) and (max-width: 1279px) {
    .facts-figures .facts-figures--description {
      font-size: 17px;
      line-height: 1.52; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .facts-figures .facts-figures--description {
      font-size: 17px;
      line-height: 1.52; } }
  @media (min-width: 544px) and (max-width: 767px) {
    .facts-figures .facts-figures--description {
      font-size: 17px;
      line-height: 1.52; } }
  @media (min-width: 375px) and (max-width: 543px) {
    .facts-figures .facts-figures--description {
      font-size: 17px;
      line-height: 1.52; } }
  @media (max-width: 374px) {
    .facts-figures .facts-figures--description {
      font-size: 17px;
      line-height: 1.52; } }

.facts-figures .facts-figures--icon {
  display: flex;
  justify-content: center;
  margin-top: auto; }

.facts-figures .facts-figures--item.facts-figures--item__association .facts-figures--icon {
  color: #d9213d; }

.facts-figures .facts-figures--item.facts-figures--item__association .facts-figures--number::before {
  border-color: #d9213d; }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .facts-figures .facts-figures--item.facts-figures--item__association .facts-figures--link:hover .facts-figures--number::before, .facts-figures .facts-figures--item.facts-figures--item__association .facts-figures--link:focus .facts-figures--number::before {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0.25; }
  .facts-figures .facts-figures--item.facts-figures--item__association .facts-figures--link:hover .facts-figures--number, .facts-figures .facts-figures--item.facts-figures--item__association .facts-figures--link:focus .facts-figures--number {
    box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.08), 0 8px 16px 0 rgba(0, 0, 0, 0.12), 0 0 0 4px #ea7688; }
  .facts-figures .facts-figures--item.facts-figures--item__association .facts-figures--link:hover .facts-figures--description,
  .facts-figures .facts-figures--item.facts-figures--item__association .facts-figures--link:hover .facts-figures--icon, .facts-figures .facts-figures--item.facts-figures--item__association .facts-figures--link:focus .facts-figures--description,
  .facts-figures .facts-figures--item.facts-figures--item__association .facts-figures--link:focus .facts-figures--icon {
    color: #a00017; } }

@media screen {
  .facts-figures .facts-figures--item.facts-figures--item__association .facts-figures--link:active .facts-figures--number::before {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0.25; }
  .facts-figures .facts-figures--item.facts-figures--item__association .facts-figures--link:active .facts-figures--number {
    box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.08), 0 8px 16px 0 rgba(0, 0, 0, 0.12), 0 0 0 4px #ea7688; }
  .facts-figures .facts-figures--item.facts-figures--item__association .facts-figures--link:active .facts-figures--description,
  .facts-figures .facts-figures--item.facts-figures--item__association .facts-figures--link:active .facts-figures--icon {
    color: #a00017; } }

.facts-figures .facts-figures--item.facts-figures--item__safety .facts-figures--icon {
  color: #e66e00; }

.facts-figures .facts-figures--item.facts-figures--item__safety .facts-figures--number::before {
  border-color: #e66e00; }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .facts-figures .facts-figures--item.facts-figures--item__safety .facts-figures--link:hover .facts-figures--number::before, .facts-figures .facts-figures--item.facts-figures--item__safety .facts-figures--link:focus .facts-figures--number::before {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0.25; }
  .facts-figures .facts-figures--item.facts-figures--item__safety .facts-figures--link:hover .facts-figures--number, .facts-figures .facts-figures--item.facts-figures--item__safety .facts-figures--link:focus .facts-figures--number {
    box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.08), 0 8px 16px 0 rgba(0, 0, 0, 0.12), 0 0 0 4px #ffa24d; }
  .facts-figures .facts-figures--item.facts-figures--item__safety .facts-figures--link:hover .facts-figures--description,
  .facts-figures .facts-figures--item.facts-figures--item__safety .facts-figures--link:hover .facts-figures--icon, .facts-figures .facts-figures--item.facts-figures--item__safety .facts-figures--link:focus .facts-figures--description,
  .facts-figures .facts-figures--item.facts-figures--item__safety .facts-figures--link:focus .facts-figures--icon {
    color: #ad4000; } }

@media screen {
  .facts-figures .facts-figures--item.facts-figures--item__safety .facts-figures--link:active .facts-figures--number::before {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0.25; }
  .facts-figures .facts-figures--item.facts-figures--item__safety .facts-figures--link:active .facts-figures--number {
    box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.08), 0 8px 16px 0 rgba(0, 0, 0, 0.12), 0 0 0 4px #ffa24d; }
  .facts-figures .facts-figures--item.facts-figures--item__safety .facts-figures--link:active .facts-figures--description,
  .facts-figures .facts-figures--item.facts-figures--item__safety .facts-figures--link:active .facts-figures--icon {
    color: #ad4000; } }

.facts-figures .facts-figures--item.facts-figures--item__recycling .facts-figures--icon {
  color: #2c834c; }

.facts-figures .facts-figures--item.facts-figures--item__recycling .facts-figures--number::before {
  border-color: #2c834c; }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .facts-figures .facts-figures--item.facts-figures--item__recycling .facts-figures--link:hover .facts-figures--number::before, .facts-figures .facts-figures--item.facts-figures--item__recycling .facts-figures--link:focus .facts-figures--number::before {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0.25; }
  .facts-figures .facts-figures--item.facts-figures--item__recycling .facts-figures--link:hover .facts-figures--number, .facts-figures .facts-figures--item.facts-figures--item__recycling .facts-figures--link:focus .facts-figures--number {
    box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.08), 0 8px 16px 0 rgba(0, 0, 0, 0.12), 0 0 0 4px #51c47b; }
  .facts-figures .facts-figures--item.facts-figures--item__recycling .facts-figures--link:hover .facts-figures--description,
  .facts-figures .facts-figures--item.facts-figures--item__recycling .facts-figures--link:hover .facts-figures--icon, .facts-figures .facts-figures--item.facts-figures--item__recycling .facts-figures--link:focus .facts-figures--description,
  .facts-figures .facts-figures--item.facts-figures--item__recycling .facts-figures--link:focus .facts-figures--icon {
    color: #005523; } }

@media screen {
  .facts-figures .facts-figures--item.facts-figures--item__recycling .facts-figures--link:active .facts-figures--number::before {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0.25; }
  .facts-figures .facts-figures--item.facts-figures--item__recycling .facts-figures--link:active .facts-figures--number {
    box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.08), 0 8px 16px 0 rgba(0, 0, 0, 0.12), 0 0 0 4px #51c47b; }
  .facts-figures .facts-figures--item.facts-figures--item__recycling .facts-figures--link:active .facts-figures--description,
  .facts-figures .facts-figures--item.facts-figures--item__recycling .facts-figures--link:active .facts-figures--icon {
    color: #005523; } }

.facts-figures .facts-figures--item.facts-figures--item__knowledge .facts-figures--icon {
  color: #1469c9; }

.facts-figures .facts-figures--item.facts-figures--item__knowledge .facts-figures--number::before {
  border-color: #1469c9; }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .facts-figures .facts-figures--item.facts-figures--item__knowledge .facts-figures--link:hover .facts-figures--number::before, .facts-figures .facts-figures--item.facts-figures--item__knowledge .facts-figures--link:focus .facts-figures--number::before {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0.25; }
  .facts-figures .facts-figures--item.facts-figures--item__knowledge .facts-figures--link:hover .facts-figures--number, .facts-figures .facts-figures--item.facts-figures--item__knowledge .facts-figures--link:focus .facts-figures--number {
    box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.08), 0 8px 16px 0 rgba(0, 0, 0, 0.12), 0 0 0 4px #559dee; }
  .facts-figures .facts-figures--item.facts-figures--item__knowledge .facts-figures--link:hover .facts-figures--description,
  .facts-figures .facts-figures--item.facts-figures--item__knowledge .facts-figures--link:hover .facts-figures--icon, .facts-figures .facts-figures--item.facts-figures--item__knowledge .facts-figures--link:focus .facts-figures--description,
  .facts-figures .facts-figures--item.facts-figures--item__knowledge .facts-figures--link:focus .facts-figures--icon {
    color: #003f97; } }

@media screen {
  .facts-figures .facts-figures--item.facts-figures--item__knowledge .facts-figures--link:active .facts-figures--number::before {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0.25; }
  .facts-figures .facts-figures--item.facts-figures--item__knowledge .facts-figures--link:active .facts-figures--number {
    box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.08), 0 8px 16px 0 rgba(0, 0, 0, 0.12), 0 0 0 4px #559dee; }
  .facts-figures .facts-figures--item.facts-figures--item__knowledge .facts-figures--link:active .facts-figures--description,
  .facts-figures .facts-figures--item.facts-figures--item__knowledge .facts-figures--link:active .facts-figures--icon {
    color: #003f97; } }

.facts-figures .facts-figures--item.facts-figures--item__education .facts-figures--icon {
  color: #631e65; }

.facts-figures .facts-figures--item.facts-figures--item__education .facts-figures--number::before {
  border-color: #631e65; }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .facts-figures .facts-figures--item.facts-figures--item__education .facts-figures--link:hover .facts-figures--number::before, .facts-figures .facts-figures--item.facts-figures--item__education .facts-figures--link:focus .facts-figures--number::before {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0.25; }
  .facts-figures .facts-figures--item.facts-figures--item__education .facts-figures--link:hover .facts-figures--number, .facts-figures .facts-figures--item.facts-figures--item__education .facts-figures--link:focus .facts-figures--number {
    box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.08), 0 8px 16px 0 rgba(0, 0, 0, 0.12), 0 0 0 4px #b035b4; }
  .facts-figures .facts-figures--item.facts-figures--item__education .facts-figures--link:hover .facts-figures--description,
  .facts-figures .facts-figures--item.facts-figures--item__education .facts-figures--link:hover .facts-figures--icon, .facts-figures .facts-figures--item.facts-figures--item__education .facts-figures--link:focus .facts-figures--description,
  .facts-figures .facts-figures--item.facts-figures--item__education .facts-figures--link:focus .facts-figures--icon {
    color: #36003a; } }

@media screen {
  .facts-figures .facts-figures--item.facts-figures--item__education .facts-figures--link:active .facts-figures--number::before {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0.25; }
  .facts-figures .facts-figures--item.facts-figures--item__education .facts-figures--link:active .facts-figures--number {
    box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.08), 0 8px 16px 0 rgba(0, 0, 0, 0.12), 0 0 0 4px #b035b4; }
  .facts-figures .facts-figures--item.facts-figures--item__education .facts-figures--link:active .facts-figures--description,
  .facts-figures .facts-figures--item.facts-figures--item__education .facts-figures--link:active .facts-figures--icon {
    color: #36003a; } }

.facts-figures .facts-figures--item.facts-figures--item__sustainability .facts-figures--icon {
  color: #1da321; }

.facts-figures .facts-figures--item.facts-figures--item__sustainability .facts-figures--number::before {
  border-color: #1da321; }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .facts-figures .facts-figures--item.facts-figures--item__sustainability .facts-figures--link:hover .facts-figures--number::before, .facts-figures .facts-figures--item.facts-figures--item__sustainability .facts-figures--link:focus .facts-figures--number::before {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0.25; }
  .facts-figures .facts-figures--item.facts-figures--item__sustainability .facts-figures--link:hover .facts-figures--number, .facts-figures .facts-figures--item.facts-figures--item__sustainability .facts-figures--link:focus .facts-figures--number {
    box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.08), 0 8px 16px 0 rgba(0, 0, 0, 0.12), 0 0 0 4px #48de4c; }
  .facts-figures .facts-figures--item.facts-figures--item__sustainability .facts-figures--link:hover .facts-figures--description,
  .facts-figures .facts-figures--item.facts-figures--item__sustainability .facts-figures--link:hover .facts-figures--icon, .facts-figures .facts-figures--item.facts-figures--item__sustainability .facts-figures--link:focus .facts-figures--description,
  .facts-figures .facts-figures--item.facts-figures--item__sustainability .facts-figures--link:focus .facts-figures--icon {
    color: #116214; } }

@media screen {
  .facts-figures .facts-figures--item.facts-figures--item__sustainability .facts-figures--link:active .facts-figures--number::before {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0.25; }
  .facts-figures .facts-figures--item.facts-figures--item__sustainability .facts-figures--link:active .facts-figures--number {
    box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.08), 0 8px 16px 0 rgba(0, 0, 0, 0.12), 0 0 0 4px #48de4c; }
  .facts-figures .facts-figures--item.facts-figures--item__sustainability .facts-figures--link:active .facts-figures--description,
  .facts-figures .facts-figures--item.facts-figures--item__sustainability .facts-figures--link:active .facts-figures--icon {
    color: #116214; } }

.facts-figures .facts-figures--item.facts-figures--item__default .facts-figures--icon {
  color: #d9213d; }

.facts-figures .facts-figures--item.facts-figures--item__default .facts-figures--number::before {
  border-color: #d9213d; }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .facts-figures .facts-figures--item.facts-figures--item__default .facts-figures--link:hover .facts-figures--number::before, .facts-figures .facts-figures--item.facts-figures--item__default .facts-figures--link:focus .facts-figures--number::before {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0.25; }
  .facts-figures .facts-figures--item.facts-figures--item__default .facts-figures--link:hover .facts-figures--number, .facts-figures .facts-figures--item.facts-figures--item__default .facts-figures--link:focus .facts-figures--number {
    box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.08), 0 8px 16px 0 rgba(0, 0, 0, 0.12), 0 0 0 4px #ea7688; }
  .facts-figures .facts-figures--item.facts-figures--item__default .facts-figures--link:hover .facts-figures--description,
  .facts-figures .facts-figures--item.facts-figures--item__default .facts-figures--link:hover .facts-figures--icon, .facts-figures .facts-figures--item.facts-figures--item__default .facts-figures--link:focus .facts-figures--description,
  .facts-figures .facts-figures--item.facts-figures--item__default .facts-figures--link:focus .facts-figures--icon {
    color: #a00017; } }

@media screen {
  .facts-figures .facts-figures--item.facts-figures--item__default .facts-figures--link:active .facts-figures--number::before {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0.25; }
  .facts-figures .facts-figures--item.facts-figures--item__default .facts-figures--link:active .facts-figures--number {
    box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.08), 0 8px 16px 0 rgba(0, 0, 0, 0.12), 0 0 0 4px #ea7688; }
  .facts-figures .facts-figures--item.facts-figures--item__default .facts-figures--link:active .facts-figures--description,
  .facts-figures .facts-figures--item.facts-figures--item__default .facts-figures--link:active .facts-figures--icon {
    color: #a00017; } }

.focus-themes .focus-themes--container {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  margin: auto; }

.focus-themes .focus-themes--row {
  display: flex;
  flex-flow: row wrap; }
  html.ie11 .focus-themes .focus-themes--row {
    min-height: 1px; }

.focus-themes .focus-themes--col {
  position: relative;
  min-height: 1px;
  max-width: 100%; }

.focus-themes .focus-themes--container {
  padding-left: 0;
  padding-right: 0; }
  .focus-themes .focus-themes--container .focus-themes--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important; }
    .focus-themes .focus-themes--container .focus-themes--container:not(.u-iknowwhatimdoing)::before {
      visibility: visible;
      content: 'Nested `grid--container` detected!';
      font-size: 20px;
      line-height: 1.2;
      color: red; }

.focus-themes .focus-themes--row {
  margin-left: 0;
  margin-right: 0; }

.focus-themes .focus-themes--col {
  padding-left: 0;
  padding-right: 0; }

.focus-themes .focus-themes--title {
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 0 auto 24px;
  color: #111111;
  font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
  font-weight: 500;
  font-style: normal; }
  @media (min-width: 1920px) {
    .focus-themes .focus-themes--title {
      font-size: 36px;
      line-height: 1.25; } }
  @media (min-width: 1280px) and (max-width: 1919px) {
    .focus-themes .focus-themes--title {
      font-size: 35px;
      line-height: 1.25; } }
  @media (min-width: 992px) and (max-width: 1279px) {
    .focus-themes .focus-themes--title {
      font-size: 30px;
      line-height: 1.25; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .focus-themes .focus-themes--title {
      font-size: 28px;
      line-height: 1.25; } }
  @media (min-width: 544px) and (max-width: 767px) {
    .focus-themes .focus-themes--title {
      font-size: 27px;
      line-height: 1.25; } }
  @media (min-width: 375px) and (max-width: 543px) {
    .focus-themes .focus-themes--title {
      font-size: 26px;
      line-height: 1.25; } }
  @media (max-width: 374px) {
    .focus-themes .focus-themes--title {
      font-size: 25px;
      line-height: 1.25; } }
  @media (min-width: 768px) {
    .focus-themes .focus-themes--title {
      flex: 0 0 66.66667%; }
      html.ie11 .focus-themes .focus-themes--title {
        flex-basis: auto;
        width: 66.66667%; }
      html.ie10 .focus-themes .focus-themes--title {
        flex-basis: auto;
        width: 66.66667%; } }
  @media (max-width: 767px) {
    .focus-themes .focus-themes--title {
      flex: 0 0 100%; }
      html.ie11 .focus-themes .focus-themes--title {
        flex-basis: auto;
        width: 100%; }
      html.ie10 .focus-themes .focus-themes--title {
        flex-basis: auto;
        width: 100%; } }

.focus-themes .focus-themes--subtitle {
  margin-bottom: 24px;
  padding: 0 24px;
  color: #111111;
  font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
  font-weight: 500;
  font-style: normal; }
  @media (min-width: 1920px) {
    .focus-themes .focus-themes--subtitle {
      font-size: 29px;
      line-height: 1.25; } }
  @media (min-width: 1280px) and (max-width: 1919px) {
    .focus-themes .focus-themes--subtitle {
      font-size: 25px;
      line-height: 1.25; } }
  @media (min-width: 992px) and (max-width: 1279px) {
    .focus-themes .focus-themes--subtitle {
      font-size: 25px;
      line-height: 1.25; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .focus-themes .focus-themes--subtitle {
      font-size: 24px;
      line-height: 1.25; } }
  @media (min-width: 544px) and (max-width: 767px) {
    .focus-themes .focus-themes--subtitle {
      font-size: 24px;
      line-height: 1.25; } }
  @media (min-width: 375px) and (max-width: 543px) {
    .focus-themes .focus-themes--subtitle {
      font-size: 22px;
      line-height: 1.3; } }
  @media (max-width: 374px) {
    .focus-themes .focus-themes--subtitle {
      font-size: 21px;
      line-height: 1.4; } }
  @media (max-width: 1279px) {
    .focus-themes .focus-themes--subtitle {
      text-align: center; } }

.focus-themes .focus-themes--wrap {
  box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.08), 0 8px 16px 0 rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  transition: box-shadow 200ms ease;
  background: #ffffff; }
  @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .focus-themes .focus-themes--wrap:hover, .focus-themes .focus-themes--wrap:focus {
      box-shadow: 0 20px 32px 0 rgba(0, 0, 0, 0.2), 0 12px 16px 0 rgba(0, 0, 0, 0.12); } }
  @media screen {
    .focus-themes .focus-themes--wrap:active {
      box-shadow: 0 20px 32px 0 rgba(0, 0, 0, 0.2), 0 12px 16px 0 rgba(0, 0, 0, 0.12); } }

.focus-themes .focus-themes--item {
  width: 100%; }
  .focus-themes .focus-themes--item ~ .focus-themes--item {
    margin-top: 64px; }
    @media (max-width: 991px) {
      .focus-themes .focus-themes--item ~ .focus-themes--item {
        margin-top: 48px; } }

@media print {
  .focus-themes .focus-themes--row.focus-themes--row {
    display: block; } }

.focus-themes .focus-themes--col {
  display: flex;
  overflow: auto;
  position: relative;
  min-height: 1px;
  max-width: 100%;
  position: relative;
  min-height: 1px;
  max-width: 100%; }
  @media (min-width: 1280px) {
    .focus-themes .focus-themes--col {
      flex: 0 0 50%; }
      html.ie11 .focus-themes .focus-themes--col {
        flex-basis: auto;
        width: 50%; }
      html.ie10 .focus-themes .focus-themes--col {
        flex-basis: auto;
        width: 50%; } }
  @media (max-width: 1279px) {
    .focus-themes .focus-themes--col {
      flex: 0 0 100%; }
      html.ie11 .focus-themes .focus-themes--col {
        flex-basis: auto;
        width: 100%; }
      html.ie10 .focus-themes .focus-themes--col {
        flex-basis: auto;
        width: 100%; } }
  @media print {
    .focus-themes .focus-themes--col {
      position: relative;
      min-height: 1px;
      max-width: 100%;
      flex: 0 0 100%;
      display: block; }
      html.ie11 .focus-themes .focus-themes--col {
        flex-basis: auto;
        width: 100%; }
      html.ie10 .focus-themes .focus-themes--col {
        flex-basis: auto;
        width: 100%; } }

.focus-themes .focus-themes--collection {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  flex: 1 1 100%; }
  @media (max-width: 991px) {
    .focus-themes .focus-themes--collection {
      flex-direction: column; } }

.focus-themes .focus-themes--card {
  display: flex;
  border-right: 1px solid #f1f1f2;
  flex: 1 1 50%; }
  @media (max-width: 1279px) {
    .focus-themes .focus-themes--card {
      min-width: 256px;
      border-bottom: 1px solid #f1f1f2; } }
  @media print {
    .focus-themes .focus-themes--card {
      flex: 1 1 100%; } }

.focus-themes .focus-themes--link {
  height: 100%; }
  @media print {
    .focus-themes .focus-themes--link {
      width: 100%; } }

@media (min-width: 1280px) {
  .focus-themes .focus-themes--wrap.focus-themes--wrap__association {
    border-top: 4px solid #d9213d; } }

@media (max-width: 1279px) {
  .focus-themes .focus-themes--wrap.focus-themes--wrap__association {
    border-left: 4px solid #d9213d; } }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .focus-themes .focus-themes--wrap.focus-themes--wrap__association .focus-themes--link:hover, .focus-themes .focus-themes--wrap.focus-themes--wrap__association .focus-themes--link:focus {
    color: #a00017; }
    .focus-themes .focus-themes--wrap.focus-themes--wrap__association .focus-themes--link:hover *, .focus-themes .focus-themes--wrap.focus-themes--wrap__association .focus-themes--link:focus * {
      color: #a00017; }
    .focus-themes .focus-themes--wrap.focus-themes--wrap__association .focus-themes--link:hover figure, .focus-themes .focus-themes--wrap.focus-themes--wrap__association .focus-themes--link:focus figure {
      filter: saturate(80%); } }

@media screen {
  .focus-themes .focus-themes--wrap.focus-themes--wrap__association .focus-themes--link:active {
    color: #a00017; }
    .focus-themes .focus-themes--wrap.focus-themes--wrap__association .focus-themes--link:active * {
      color: #a00017; }
    .focus-themes .focus-themes--wrap.focus-themes--wrap__association .focus-themes--link:active figure {
      filter: saturate(80%); } }

@media (min-width: 1280px) {
  .focus-themes .focus-themes--wrap.focus-themes--wrap__safety {
    border-top: 4px solid #e66e00; } }

@media (max-width: 1279px) {
  .focus-themes .focus-themes--wrap.focus-themes--wrap__safety {
    border-left: 4px solid #e66e00; } }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .focus-themes .focus-themes--wrap.focus-themes--wrap__safety .focus-themes--link:hover, .focus-themes .focus-themes--wrap.focus-themes--wrap__safety .focus-themes--link:focus {
    color: #ad4000; }
    .focus-themes .focus-themes--wrap.focus-themes--wrap__safety .focus-themes--link:hover *, .focus-themes .focus-themes--wrap.focus-themes--wrap__safety .focus-themes--link:focus * {
      color: #ad4000; }
    .focus-themes .focus-themes--wrap.focus-themes--wrap__safety .focus-themes--link:hover figure, .focus-themes .focus-themes--wrap.focus-themes--wrap__safety .focus-themes--link:focus figure {
      filter: saturate(80%); } }

@media screen {
  .focus-themes .focus-themes--wrap.focus-themes--wrap__safety .focus-themes--link:active {
    color: #ad4000; }
    .focus-themes .focus-themes--wrap.focus-themes--wrap__safety .focus-themes--link:active * {
      color: #ad4000; }
    .focus-themes .focus-themes--wrap.focus-themes--wrap__safety .focus-themes--link:active figure {
      filter: saturate(80%); } }

@media (min-width: 1280px) {
  .focus-themes .focus-themes--wrap.focus-themes--wrap__recycling {
    border-top: 4px solid #2c834c; } }

@media (max-width: 1279px) {
  .focus-themes .focus-themes--wrap.focus-themes--wrap__recycling {
    border-left: 4px solid #2c834c; } }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .focus-themes .focus-themes--wrap.focus-themes--wrap__recycling .focus-themes--link:hover, .focus-themes .focus-themes--wrap.focus-themes--wrap__recycling .focus-themes--link:focus {
    color: #005523; }
    .focus-themes .focus-themes--wrap.focus-themes--wrap__recycling .focus-themes--link:hover *, .focus-themes .focus-themes--wrap.focus-themes--wrap__recycling .focus-themes--link:focus * {
      color: #005523; }
    .focus-themes .focus-themes--wrap.focus-themes--wrap__recycling .focus-themes--link:hover figure, .focus-themes .focus-themes--wrap.focus-themes--wrap__recycling .focus-themes--link:focus figure {
      filter: saturate(80%); } }

@media screen {
  .focus-themes .focus-themes--wrap.focus-themes--wrap__recycling .focus-themes--link:active {
    color: #005523; }
    .focus-themes .focus-themes--wrap.focus-themes--wrap__recycling .focus-themes--link:active * {
      color: #005523; }
    .focus-themes .focus-themes--wrap.focus-themes--wrap__recycling .focus-themes--link:active figure {
      filter: saturate(80%); } }

@media (min-width: 1280px) {
  .focus-themes .focus-themes--wrap.focus-themes--wrap__knowledge {
    border-top: 4px solid #1469c9; } }

@media (max-width: 1279px) {
  .focus-themes .focus-themes--wrap.focus-themes--wrap__knowledge {
    border-left: 4px solid #1469c9; } }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .focus-themes .focus-themes--wrap.focus-themes--wrap__knowledge .focus-themes--link:hover, .focus-themes .focus-themes--wrap.focus-themes--wrap__knowledge .focus-themes--link:focus {
    color: #003f97; }
    .focus-themes .focus-themes--wrap.focus-themes--wrap__knowledge .focus-themes--link:hover *, .focus-themes .focus-themes--wrap.focus-themes--wrap__knowledge .focus-themes--link:focus * {
      color: #003f97; }
    .focus-themes .focus-themes--wrap.focus-themes--wrap__knowledge .focus-themes--link:hover figure, .focus-themes .focus-themes--wrap.focus-themes--wrap__knowledge .focus-themes--link:focus figure {
      filter: saturate(80%); } }

@media screen {
  .focus-themes .focus-themes--wrap.focus-themes--wrap__knowledge .focus-themes--link:active {
    color: #003f97; }
    .focus-themes .focus-themes--wrap.focus-themes--wrap__knowledge .focus-themes--link:active * {
      color: #003f97; }
    .focus-themes .focus-themes--wrap.focus-themes--wrap__knowledge .focus-themes--link:active figure {
      filter: saturate(80%); } }

@media (min-width: 1280px) {
  .focus-themes .focus-themes--wrap.focus-themes--wrap__education {
    border-top: 4px solid #631e65; } }

@media (max-width: 1279px) {
  .focus-themes .focus-themes--wrap.focus-themes--wrap__education {
    border-left: 4px solid #631e65; } }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .focus-themes .focus-themes--wrap.focus-themes--wrap__education .focus-themes--link:hover, .focus-themes .focus-themes--wrap.focus-themes--wrap__education .focus-themes--link:focus {
    color: #36003a; }
    .focus-themes .focus-themes--wrap.focus-themes--wrap__education .focus-themes--link:hover *, .focus-themes .focus-themes--wrap.focus-themes--wrap__education .focus-themes--link:focus * {
      color: #36003a; }
    .focus-themes .focus-themes--wrap.focus-themes--wrap__education .focus-themes--link:hover figure, .focus-themes .focus-themes--wrap.focus-themes--wrap__education .focus-themes--link:focus figure {
      filter: saturate(80%); } }

@media screen {
  .focus-themes .focus-themes--wrap.focus-themes--wrap__education .focus-themes--link:active {
    color: #36003a; }
    .focus-themes .focus-themes--wrap.focus-themes--wrap__education .focus-themes--link:active * {
      color: #36003a; }
    .focus-themes .focus-themes--wrap.focus-themes--wrap__education .focus-themes--link:active figure {
      filter: saturate(80%); } }

@media (min-width: 1280px) {
  .focus-themes .focus-themes--wrap.focus-themes--wrap__sustainability {
    border-top: 4px solid #1da321; } }

@media (max-width: 1279px) {
  .focus-themes .focus-themes--wrap.focus-themes--wrap__sustainability {
    border-left: 4px solid #1da321; } }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .focus-themes .focus-themes--wrap.focus-themes--wrap__sustainability .focus-themes--link:hover, .focus-themes .focus-themes--wrap.focus-themes--wrap__sustainability .focus-themes--link:focus {
    color: #116214; }
    .focus-themes .focus-themes--wrap.focus-themes--wrap__sustainability .focus-themes--link:hover *, .focus-themes .focus-themes--wrap.focus-themes--wrap__sustainability .focus-themes--link:focus * {
      color: #116214; }
    .focus-themes .focus-themes--wrap.focus-themes--wrap__sustainability .focus-themes--link:hover figure, .focus-themes .focus-themes--wrap.focus-themes--wrap__sustainability .focus-themes--link:focus figure {
      filter: saturate(80%); } }

@media screen {
  .focus-themes .focus-themes--wrap.focus-themes--wrap__sustainability .focus-themes--link:active {
    color: #116214; }
    .focus-themes .focus-themes--wrap.focus-themes--wrap__sustainability .focus-themes--link:active * {
      color: #116214; }
    .focus-themes .focus-themes--wrap.focus-themes--wrap__sustainability .focus-themes--link:active figure {
      filter: saturate(80%); } }

@media (min-width: 1280px) {
  .focus-themes .focus-themes--wrap.focus-themes--wrap__default {
    border-top: 4px solid #d9213d; } }

@media (max-width: 1279px) {
  .focus-themes .focus-themes--wrap.focus-themes--wrap__default {
    border-left: 4px solid #d9213d; } }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .focus-themes .focus-themes--wrap.focus-themes--wrap__default .focus-themes--link:hover, .focus-themes .focus-themes--wrap.focus-themes--wrap__default .focus-themes--link:focus {
    color: #a00017; }
    .focus-themes .focus-themes--wrap.focus-themes--wrap__default .focus-themes--link:hover *, .focus-themes .focus-themes--wrap.focus-themes--wrap__default .focus-themes--link:focus * {
      color: #a00017; }
    .focus-themes .focus-themes--wrap.focus-themes--wrap__default .focus-themes--link:hover figure, .focus-themes .focus-themes--wrap.focus-themes--wrap__default .focus-themes--link:focus figure {
      filter: saturate(80%); } }

@media screen {
  .focus-themes .focus-themes--wrap.focus-themes--wrap__default .focus-themes--link:active {
    color: #a00017; }
    .focus-themes .focus-themes--wrap.focus-themes--wrap__default .focus-themes--link:active * {
      color: #a00017; }
    .focus-themes .focus-themes--wrap.focus-themes--wrap__default .focus-themes--link:active figure {
      filter: saturate(80%); } }

.freeform-image {
  padding: 32px 0; }
  @media (max-width: 991px) {
    .freeform-image {
      padding: 24px 0; } }

.freeform-image .freeform-image--img {
  width: auto;
  max-width: 100%; }

.freeform-image .freeform-image--caption {
  margin-top: 8px;
  color: #555555;
  font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal; }
  @media (min-width: 1920px) {
    .freeform-image .freeform-image--caption {
      font-size: 19px;
      line-height: 1.4; } }
  @media (min-width: 1280px) and (max-width: 1919px) {
    .freeform-image .freeform-image--caption {
      font-size: 17px;
      line-height: 1.4; } }
  @media (min-width: 992px) and (max-width: 1279px) {
    .freeform-image .freeform-image--caption {
      font-size: 15px;
      line-height: 1.4; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .freeform-image .freeform-image--caption {
      font-size: 15px;
      line-height: 1.4; } }
  @media (min-width: 544px) and (max-width: 767px) {
    .freeform-image .freeform-image--caption {
      font-size: 15px;
      line-height: 1.4; } }
  @media (min-width: 375px) and (max-width: 543px) {
    .freeform-image .freeform-image--caption {
      font-size: 15px;
      line-height: 1.4; } }
  @media (max-width: 374px) {
    .freeform-image .freeform-image--caption {
      font-size: 15px;
      line-height: 1.4; } }

.header-detail {
  padding: 24px 0 0; }
  @media (max-width: 767px) {
    .header-detail {
      padding: 12px 0 0; } }
  .header-detail .header-detail--breadcrumbs {
    display: flex;
    margin-bottom: 16px; }
  .header-detail .header-detail--breadcrumbs-item {
    display: flex;
    align-items: center; }
  .header-detail .header-detail--title {
    color: #111111;
    font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
    font-weight: 500;
    font-style: normal;
    transform: translate(0, -0.06em); }
    @media (min-width: 1920px) {
      .header-detail .header-detail--title {
        font-size: 72px;
        line-height: 1.2; } }
    @media (min-width: 1280px) and (max-width: 1919px) {
      .header-detail .header-detail--title {
        font-size: 64px;
        line-height: 1.2; } }
    @media (min-width: 992px) and (max-width: 1279px) {
      .header-detail .header-detail--title {
        font-size: 48px;
        line-height: 1.2; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .header-detail .header-detail--title {
        font-size: 44px;
        line-height: 1.2; } }
    @media (min-width: 544px) and (max-width: 767px) {
      .header-detail .header-detail--title {
        font-size: 40px;
        line-height: 1.2; } }
    @media (min-width: 375px) and (max-width: 543px) {
      .header-detail .header-detail--title {
        font-size: 30px;
        line-height: 1.2; } }
    @media (max-width: 374px) {
      .header-detail .header-detail--title {
        font-size: 28px;
        line-height: 1.2; } }
  @media (min-width: 768px) {
    .header-detail .header-detail--title-col {
      flex: 0 0 91.66667%; }
      html.ie11 .header-detail .header-detail--title-col {
        flex-basis: auto;
        width: 91.66667%; }
      html.ie10 .header-detail .header-detail--title-col {
        flex-basis: auto;
        width: 91.66667%; } }
  .header-detail .header-detail--breadcrumbs-link {
    color: #555555;
    font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
    font-weight: 400;
    font-style: normal;
    font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
    font-weight: 500;
    font-style: normal; }
    @media (min-width: 1920px) {
      .header-detail .header-detail--breadcrumbs-link {
        font-size: 17px;
        line-height: 1.4; } }
    @media (min-width: 1280px) and (max-width: 1919px) {
      .header-detail .header-detail--breadcrumbs-link {
        font-size: 15px;
        line-height: 1.4; } }
    @media (min-width: 992px) and (max-width: 1279px) {
      .header-detail .header-detail--breadcrumbs-link {
        font-size: 13px;
        line-height: 1.4; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .header-detail .header-detail--breadcrumbs-link {
        font-size: 13px;
        line-height: 1.4; } }
    @media (min-width: 544px) and (max-width: 767px) {
      .header-detail .header-detail--breadcrumbs-link {
        font-size: 13px;
        line-height: 1.4; } }
    @media (min-width: 375px) and (max-width: 543px) {
      .header-detail .header-detail--breadcrumbs-link {
        font-size: 13px;
        line-height: 1.4; } }
    @media (max-width: 374px) {
      .header-detail .header-detail--breadcrumbs-link {
        font-size: 13px;
        line-height: 1.4; } }
    @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .header-detail .header-detail--breadcrumbs-link[href]:hover, .header-detail .header-detail--breadcrumbs-link[href]:focus {
        color: #2c2c2c; } }
    @media screen {
      .header-detail .header-detail--breadcrumbs-link[href]:active {
        color: #2c2c2c; } }

.header-landing {
  position: relative;
  padding: 64px 0 0; }
  @media (max-width: 767px) {
    .header-landing {
      padding: 48px 0; } }
  .header-landing .header-landing--title {
    color: #111111;
    font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
    font-weight: 500;
    font-style: normal; }
    @media (min-width: 1920px) {
      .header-landing .header-landing--title {
        font-size: 36px;
        line-height: 1.25; } }
    @media (min-width: 1280px) and (max-width: 1919px) {
      .header-landing .header-landing--title {
        font-size: 35px;
        line-height: 1.25; } }
    @media (min-width: 992px) and (max-width: 1279px) {
      .header-landing .header-landing--title {
        font-size: 30px;
        line-height: 1.25; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .header-landing .header-landing--title {
        font-size: 28px;
        line-height: 1.25; } }
    @media (min-width: 544px) and (max-width: 767px) {
      .header-landing .header-landing--title {
        font-size: 27px;
        line-height: 1.25; } }
    @media (min-width: 375px) and (max-width: 543px) {
      .header-landing .header-landing--title {
        font-size: 26px;
        line-height: 1.25; } }
    @media (max-width: 374px) {
      .header-landing .header-landing--title {
        font-size: 25px;
        line-height: 1.25; } }
  .header-landing .header-landing--hook {
    position: absolute;
    top: 50%; }
  .header-landing .header-landing--description {
    color: #555555;
    margin-top: 24px; }
  .header-landing .header-landing--text-col {
    text-align: center;
    margin: 0 auto; }
    @media (min-width: 992px) {
      .header-landing .header-landing--text-col {
        flex: 0 0 66.66667%; }
        html.ie11 .header-landing .header-landing--text-col {
          flex-basis: auto;
          width: 66.66667%; }
        html.ie10 .header-landing .header-landing--text-col {
          flex-basis: auto;
          width: 66.66667%; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .header-landing .header-landing--text-col {
        flex: 0 0 83.33333%; }
        html.ie11 .header-landing .header-landing--text-col {
          flex-basis: auto;
          width: 83.33333%; }
        html.ie10 .header-landing .header-landing--text-col {
          flex-basis: auto;
          width: 83.33333%; } }
    @media (max-width: 767px) {
      .header-landing .header-landing--text-col {
        flex: 0 0 100%; }
        html.ie11 .header-landing .header-landing--text-col {
          flex-basis: auto;
          width: 100%; }
        html.ie10 .header-landing .header-landing--text-col {
          flex-basis: auto;
          width: 100%; } }
  .header-landing .header-landing--buttons-group {
    margin-top: 8px;
    justify-content: center; }
  .header-landing .header-landing--button {
    margin-top: 32px; }
    @media (max-width: 767px) {
      .header-landing .header-landing--button {
        flex: 0 0 100%; }
        html.ie11 .header-landing .header-landing--button {
          flex-basis: auto;
          width: 100%; }
        html.ie10 .header-landing .header-landing--button {
          flex-basis: auto;
          width: 100%; } }
    @media (max-width: 767px) {
      .header-landing .header-landing--button {
        display: flex;
        text-align: center;
        flex-direction: column; } }

.image .image--col {
  margin: 0 auto; }
  @media (min-width: 992px) {
    .image .image--col {
      flex: 0 0 66.66667%; }
      html.ie11 .image .image--col {
        flex-basis: auto;
        width: 66.66667%; }
      html.ie10 .image .image--col {
        flex-basis: auto;
        width: 66.66667%; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .image .image--col {
      flex: 0 0 83.33333%; }
      html.ie11 .image .image--col {
        flex-basis: auto;
        width: 83.33333%; }
      html.ie10 .image .image--col {
        flex-basis: auto;
        width: 83.33333%; } }
  @media (max-width: 767px) {
    .image .image--col {
      flex: 0 0 100%; }
      html.ie11 .image .image--col {
        flex-basis: auto;
        width: 100%; }
      html.ie10 .image .image--col {
        flex-basis: auto;
        width: 100%; } }

.image .image--img {
  width: 100%;
  margin-bottom: 16px; }

.image .image--caption {
  text-align: center;
  color: #555555;
  font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal; }
  @media (min-width: 1920px) {
    .image .image--caption {
      font-size: 19px;
      line-height: 1.4; } }
  @media (min-width: 1280px) and (max-width: 1919px) {
    .image .image--caption {
      font-size: 17px;
      line-height: 1.4; } }
  @media (min-width: 992px) and (max-width: 1279px) {
    .image .image--caption {
      font-size: 15px;
      line-height: 1.4; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .image .image--caption {
      font-size: 15px;
      line-height: 1.4; } }
  @media (min-width: 544px) and (max-width: 767px) {
    .image .image--caption {
      font-size: 15px;
      line-height: 1.4; } }
  @media (min-width: 375px) and (max-width: 543px) {
    .image .image--caption {
      font-size: 15px;
      line-height: 1.4; } }
  @media (max-width: 374px) {
    .image .image--caption {
      font-size: 15px;
      line-height: 1.4; } }

.infobox {
  margin: 64px 0;
  padding: 24px;
  background: #f1f1f2; }
  @media print {
    .infobox {
      padding: 0; } }
  @media (max-width: 991px) {
    .infobox {
      margin: 48px 0; } }
  .infobox .infobox--header {
    display: flex;
    align-items: center;
    margin-bottom: 24px; }
  .infobox .infobox--title {
    padding-left: 8px;
    transform: translate(0, 1px);
    color: #111111;
    font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
    font-weight: 500;
    font-style: normal; }
    @media (min-width: 1920px) {
      .infobox .infobox--title {
        font-size: 21px;
        line-height: 1.25; } }
    @media (min-width: 1280px) and (max-width: 1919px) {
      .infobox .infobox--title {
        font-size: 19px;
        line-height: 1.3; } }
    @media (min-width: 992px) and (max-width: 1279px) {
      .infobox .infobox--title {
        font-size: 17px;
        line-height: 1.3; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .infobox .infobox--title {
        font-size: 17px;
        line-height: 1.3; } }
    @media (min-width: 544px) and (max-width: 767px) {
      .infobox .infobox--title {
        font-size: 17px;
        line-height: 1.3; } }
    @media (min-width: 375px) and (max-width: 543px) {
      .infobox .infobox--title {
        font-size: 17px;
        line-height: 1.3; } }
    @media (max-width: 374px) {
      .infobox .infobox--title {
        font-size: 17px;
        line-height: 1.4; } }
  .infobox .infobox--content {
    color: #555555;
    margin-bottom: 16px; }

.inline-image {
  padding: 64px 0; }
  @media (max-width: 991px) {
    .inline-image {
      padding: 48px 0; } }
  .inline-image .inline-image--caption {
    text-align: center;
    margin-top: 16px;
    color: #555555;
    font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
    font-weight: 400;
    font-style: normal; }
    @media (min-width: 1920px) {
      .inline-image .inline-image--caption {
        font-size: 19px;
        line-height: 1.4; } }
    @media (min-width: 1280px) and (max-width: 1919px) {
      .inline-image .inline-image--caption {
        font-size: 17px;
        line-height: 1.4; } }
    @media (min-width: 992px) and (max-width: 1279px) {
      .inline-image .inline-image--caption {
        font-size: 15px;
        line-height: 1.4; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .inline-image .inline-image--caption {
        font-size: 15px;
        line-height: 1.4; } }
    @media (min-width: 544px) and (max-width: 767px) {
      .inline-image .inline-image--caption {
        font-size: 15px;
        line-height: 1.4; } }
    @media (min-width: 375px) and (max-width: 543px) {
      .inline-image .inline-image--caption {
        font-size: 15px;
        line-height: 1.4; } }
    @media (max-width: 374px) {
      .inline-image .inline-image--caption {
        font-size: 15px;
        line-height: 1.4; } }

.inline-video {
  margin-bottom: 48px; }
  @media print {
    .inline-video {
      display: none; } }
  .inline-video .inline-video--title {
    margin-bottom: 24px;
    color: #111111;
    font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
    font-weight: 500;
    font-style: normal;
    padding-top: 64px; }
    @media (min-width: 1920px) {
      .inline-video .inline-video--title {
        font-size: 29px;
        line-height: 1.25; } }
    @media (min-width: 1280px) and (max-width: 1919px) {
      .inline-video .inline-video--title {
        font-size: 25px;
        line-height: 1.25; } }
    @media (min-width: 992px) and (max-width: 1279px) {
      .inline-video .inline-video--title {
        font-size: 25px;
        line-height: 1.25; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .inline-video .inline-video--title {
        font-size: 24px;
        line-height: 1.25; } }
    @media (min-width: 544px) and (max-width: 767px) {
      .inline-video .inline-video--title {
        font-size: 24px;
        line-height: 1.25; } }
    @media (min-width: 375px) and (max-width: 543px) {
      .inline-video .inline-video--title {
        font-size: 22px;
        line-height: 1.3; } }
    @media (max-width: 374px) {
      .inline-video .inline-video--title {
        font-size: 21px;
        line-height: 1.4; } }
    @media (max-width: 991px) {
      .inline-video .inline-video--title {
        padding-top: 48px; } }
  .inline-video .inline-video--wrapper {
    position: relative;
    margin: 0 auto;
    height: 0;
    padding-top: 56.25%; }
  .inline-video .inline-video--frame {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09), 0 1px 2px rgba(0, 0, 0, 0.2); }

.lead {
  margin-bottom: 64px;
  font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal; }
  @media (min-width: 1920px) {
    .lead {
      font-size: 31px;
      line-height: 1.35; } }
  @media (min-width: 1280px) and (max-width: 1919px) {
    .lead {
      font-size: 29px;
      line-height: 1.35; } }
  @media (min-width: 992px) and (max-width: 1279px) {
    .lead {
      font-size: 25px;
      line-height: 1.35; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .lead {
      font-size: 24px;
      line-height: 1.35; } }
  @media (min-width: 544px) and (max-width: 767px) {
    .lead {
      font-size: 24px;
      line-height: 1.35; } }
  @media (min-width: 375px) and (max-width: 543px) {
    .lead {
      font-size: 22px;
      line-height: 1.35; } }
  @media (max-width: 374px) {
    .lead {
      font-size: 21px;
      line-height: 1.35; } }
  @media (max-width: 767px) {
    .lead {
      margin-bottom: 48px; } }

.legal {
  border-top: solid 1px #f1f1f2;
  padding: 24px 0;
  margin: 0; }
  @media (max-width: 543px) {
    .legal {
      margin-top: 0; } }
  @media print {
    .legal {
      padding: 12px; } }
  .legal .legal--content {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline; }
  .legal .legal--copyright {
    font-size: 1.3rem;
    line-height: 1.85;
    display: inline-flex;
    margin-right: 20px; }
    @media (max-width: 543px) {
      .legal .legal--copyright {
        margin-bottom: 12px; } }
  .legal .legal--collection {
    display: flex;
    align-items: baseline; }
    @media (max-width: 543px) {
      .legal .legal--collection {
        width: 100%;
        flex-direction: column; } }
  .legal .legal--link {
    font-size: 1.3rem;
    line-height: 1.85;
    display: inline-block;
    color: #555555;
    font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
    font-weight: 500;
    font-style: normal; }
    @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .legal .legal--link:hover, .legal .legal--link:focus {
        color: #111111; } }
    @media screen {
      .legal .legal--link:active {
        color: #111111; } }
    @media (min-width: 544px) {
      .legal .legal--link {
        padding: 0 20px; } }
    @media (max-width: 543px) {
      .legal .legal--link {
        padding: 12px 0; } }

.link-collection .link-collection--item ~ .link-collection--item {
  border-top: 1px solid #f1f1f2; }

@media (min-width: 1280px) {
  .link-collection.link-collection__focus-themes .link-collection--item:last-of-type {
    border-bottom: 1px solid #f1f1f2; } }

.link-collection.link-collection__single {
  margin-bottom: 16px; }

.link-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: 16px;
  background: #ffffff;
  transition: color 200ms ease, border-color 200ms ease, background-color 200ms ease, opacity 200ms ease, transform 200ms ease, box-shadow 200ms ease; }
  @media (max-width: 991px) {
    .link-item {
      padding: 8px 16px 8px 8px; } }
  @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .link-item:hover .link-item--figure, .link-item:focus .link-item--figure {
      filter: saturate(80%); } }
  @media screen {
    .link-item:active .link-item--figure {
      filter: saturate(80%); } }
  .link-item .link-item--title {
    color: #111111;
    font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
    font-weight: 500;
    font-style: normal;
    color: #555555; }
    @media (min-width: 1920px) {
      .link-item .link-item--title {
        font-size: 21px;
        line-height: 1.25; } }
    @media (min-width: 1280px) and (max-width: 1919px) {
      .link-item .link-item--title {
        font-size: 19px;
        line-height: 1.3; } }
    @media (min-width: 992px) and (max-width: 1279px) {
      .link-item .link-item--title {
        font-size: 17px;
        line-height: 1.3; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .link-item .link-item--title {
        font-size: 17px;
        line-height: 1.3; } }
    @media (min-width: 544px) and (max-width: 767px) {
      .link-item .link-item--title {
        font-size: 17px;
        line-height: 1.3; } }
    @media (min-width: 375px) and (max-width: 543px) {
      .link-item .link-item--title {
        font-size: 17px;
        line-height: 1.3; } }
    @media (max-width: 374px) {
      .link-item .link-item--title {
        font-size: 17px;
        line-height: 1.4; } }
  .link-item .link-item--figure {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 66px;
    width: 66px;
    height: 66px;
    margin-right: 16px;
    border-radius: 100%;
    overflow: hidden; }
    @media (max-width: 767px) {
      .link-item .link-item--figure {
        flex: 0 0 54px;
        width: 54px;
        height: 54px; } }
  .link-item .link-item--text {
    flex: 1 1 auto;
    padding-right: 16px;
    transform: translate(0, 1px); }
  .link-item .link-item--description {
    color: #555555;
    font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
    font-weight: 400;
    font-style: normal; }
    @media (min-width: 1920px) {
      .link-item .link-item--description {
        font-size: 19px;
        line-height: 1.4; } }
    @media (min-width: 1280px) and (max-width: 1919px) {
      .link-item .link-item--description {
        font-size: 17px;
        line-height: 1.4; } }
    @media (min-width: 992px) and (max-width: 1279px) {
      .link-item .link-item--description {
        font-size: 15px;
        line-height: 1.4; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .link-item .link-item--description {
        font-size: 15px;
        line-height: 1.4; } }
    @media (min-width: 544px) and (max-width: 767px) {
      .link-item .link-item--description {
        font-size: 15px;
        line-height: 1.4; } }
    @media (min-width: 375px) and (max-width: 543px) {
      .link-item .link-item--description {
        font-size: 15px;
        line-height: 1.4; } }
    @media (max-width: 374px) {
      .link-item .link-item--description {
        font-size: 15px;
        line-height: 1.4; } }
    @media (min-width: 768px) {
      .link-item .link-item--description {
        margin-top: 4px; } }
    @media (max-width: 767px) {
      .link-item .link-item--description {
        display: none; } }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .link-item.link-item__base:hover .link-item--arrow, .link-item.link-item__base:focus .link-item--arrow {
    color: currentColor; } }

@media screen {
  .link-item.link-item__base:active .link-item--arrow {
    color: currentColor; } }

.link-item.link-item__association {
  color: #d9213d; }
  .link-item.link-item__association .link-item--arrow,
  .link-item.link-item__association .link-item--title {
    color: currentColor; }
  @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .link-item.link-item__association:hover, .link-item.link-item__association:focus {
      color: #ffffff;
      background: #d9213d; }
      .link-item.link-item__association:hover .link-item--arrow,
      .link-item.link-item__association:hover .link-item--description,
      .link-item.link-item__association:hover .link-item--title, .link-item.link-item__association:focus .link-item--arrow,
      .link-item.link-item__association:focus .link-item--description,
      .link-item.link-item__association:focus .link-item--title {
        color: currentColor; } }
  @media screen {
    .link-item.link-item__association:active {
      color: #ffffff;
      background: #d9213d; }
      .link-item.link-item__association:active .link-item--arrow,
      .link-item.link-item__association:active .link-item--description,
      .link-item.link-item__association:active .link-item--title {
        color: currentColor; } }

.link-item.link-item__safety {
  color: #e66e00; }
  .link-item.link-item__safety .link-item--arrow,
  .link-item.link-item__safety .link-item--title {
    color: currentColor; }
  @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .link-item.link-item__safety:hover, .link-item.link-item__safety:focus {
      color: #ffffff;
      background: #e66e00; }
      .link-item.link-item__safety:hover .link-item--arrow,
      .link-item.link-item__safety:hover .link-item--description,
      .link-item.link-item__safety:hover .link-item--title, .link-item.link-item__safety:focus .link-item--arrow,
      .link-item.link-item__safety:focus .link-item--description,
      .link-item.link-item__safety:focus .link-item--title {
        color: currentColor; } }
  @media screen {
    .link-item.link-item__safety:active {
      color: #ffffff;
      background: #e66e00; }
      .link-item.link-item__safety:active .link-item--arrow,
      .link-item.link-item__safety:active .link-item--description,
      .link-item.link-item__safety:active .link-item--title {
        color: currentColor; } }

.link-item.link-item__recycling {
  color: #2c834c; }
  .link-item.link-item__recycling .link-item--arrow,
  .link-item.link-item__recycling .link-item--title {
    color: currentColor; }
  @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .link-item.link-item__recycling:hover, .link-item.link-item__recycling:focus {
      color: #ffffff;
      background: #2c834c; }
      .link-item.link-item__recycling:hover .link-item--arrow,
      .link-item.link-item__recycling:hover .link-item--description,
      .link-item.link-item__recycling:hover .link-item--title, .link-item.link-item__recycling:focus .link-item--arrow,
      .link-item.link-item__recycling:focus .link-item--description,
      .link-item.link-item__recycling:focus .link-item--title {
        color: currentColor; } }
  @media screen {
    .link-item.link-item__recycling:active {
      color: #ffffff;
      background: #2c834c; }
      .link-item.link-item__recycling:active .link-item--arrow,
      .link-item.link-item__recycling:active .link-item--description,
      .link-item.link-item__recycling:active .link-item--title {
        color: currentColor; } }

.link-item.link-item__knowledge {
  color: #1469c9; }
  .link-item.link-item__knowledge .link-item--arrow,
  .link-item.link-item__knowledge .link-item--title {
    color: currentColor; }
  @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .link-item.link-item__knowledge:hover, .link-item.link-item__knowledge:focus {
      color: #ffffff;
      background: #1469c9; }
      .link-item.link-item__knowledge:hover .link-item--arrow,
      .link-item.link-item__knowledge:hover .link-item--description,
      .link-item.link-item__knowledge:hover .link-item--title, .link-item.link-item__knowledge:focus .link-item--arrow,
      .link-item.link-item__knowledge:focus .link-item--description,
      .link-item.link-item__knowledge:focus .link-item--title {
        color: currentColor; } }
  @media screen {
    .link-item.link-item__knowledge:active {
      color: #ffffff;
      background: #1469c9; }
      .link-item.link-item__knowledge:active .link-item--arrow,
      .link-item.link-item__knowledge:active .link-item--description,
      .link-item.link-item__knowledge:active .link-item--title {
        color: currentColor; } }

.link-item.link-item__education {
  color: #631e65; }
  .link-item.link-item__education .link-item--arrow,
  .link-item.link-item__education .link-item--title {
    color: currentColor; }
  @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .link-item.link-item__education:hover, .link-item.link-item__education:focus {
      color: #ffffff;
      background: #631e65; }
      .link-item.link-item__education:hover .link-item--arrow,
      .link-item.link-item__education:hover .link-item--description,
      .link-item.link-item__education:hover .link-item--title, .link-item.link-item__education:focus .link-item--arrow,
      .link-item.link-item__education:focus .link-item--description,
      .link-item.link-item__education:focus .link-item--title {
        color: currentColor; } }
  @media screen {
    .link-item.link-item__education:active {
      color: #ffffff;
      background: #631e65; }
      .link-item.link-item__education:active .link-item--arrow,
      .link-item.link-item__education:active .link-item--description,
      .link-item.link-item__education:active .link-item--title {
        color: currentColor; } }

.link-item.link-item__sustainability {
  color: #1da321; }
  .link-item.link-item__sustainability .link-item--arrow,
  .link-item.link-item__sustainability .link-item--title {
    color: currentColor; }
  @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .link-item.link-item__sustainability:hover, .link-item.link-item__sustainability:focus {
      color: #ffffff;
      background: #1da321; }
      .link-item.link-item__sustainability:hover .link-item--arrow,
      .link-item.link-item__sustainability:hover .link-item--description,
      .link-item.link-item__sustainability:hover .link-item--title, .link-item.link-item__sustainability:focus .link-item--arrow,
      .link-item.link-item__sustainability:focus .link-item--description,
      .link-item.link-item__sustainability:focus .link-item--title {
        color: currentColor; } }
  @media screen {
    .link-item.link-item__sustainability:active {
      color: #ffffff;
      background: #1da321; }
      .link-item.link-item__sustainability:active .link-item--arrow,
      .link-item.link-item__sustainability:active .link-item--description,
      .link-item.link-item__sustainability:active .link-item--title {
        color: currentColor; } }

.link-item.link-item__default {
  color: #d9213d; }
  .link-item.link-item__default .link-item--arrow,
  .link-item.link-item__default .link-item--title {
    color: currentColor; }
  @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .link-item.link-item__default:hover, .link-item.link-item__default:focus {
      color: #ffffff;
      background: #d9213d; }
      .link-item.link-item__default:hover .link-item--arrow,
      .link-item.link-item__default:hover .link-item--description,
      .link-item.link-item__default:hover .link-item--title, .link-item.link-item__default:focus .link-item--arrow,
      .link-item.link-item__default:focus .link-item--description,
      .link-item.link-item__default:focus .link-item--title {
        color: currentColor; } }
  @media screen {
    .link-item.link-item__default:active {
      color: #ffffff;
      background: #d9213d; }
      .link-item.link-item__default:active .link-item--arrow,
      .link-item.link-item__default:active .link-item--description,
      .link-item.link-item__default:active .link-item--title {
        color: currentColor; } }

.link-item.link-item__focus-themes {
  min-height: 64px; }
  @media (max-width: 991px) {
    .link-item.link-item__focus-themes {
      padding: 8px 16px; } }
  @media (max-width: 767px) {
    .link-item.link-item__focus-themes .link-item--figure {
      display: none; } }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .link-item.link-item__deeplink:hover, .link-item.link-item__deeplink:focus {
    color: #ffffff; }
    html[data-theme="association"] .link-item.link-item__deeplink:hover, html[data-theme="association"] .link-item.link-item__deeplink:focus {
      background: #d9213d; }
    html[data-theme="safety"] .link-item.link-item__deeplink:hover, html[data-theme="safety"] .link-item.link-item__deeplink:focus {
      background: #e66e00; }
    html[data-theme="recycling"] .link-item.link-item__deeplink:hover, html[data-theme="recycling"] .link-item.link-item__deeplink:focus {
      background: #2c834c; }
    html[data-theme="knowledge"] .link-item.link-item__deeplink:hover, html[data-theme="knowledge"] .link-item.link-item__deeplink:focus {
      background: #1469c9; }
    html[data-theme="education"] .link-item.link-item__deeplink:hover, html[data-theme="education"] .link-item.link-item__deeplink:focus {
      background: #631e65; }
    html[data-theme="sustainability"] .link-item.link-item__deeplink:hover, html[data-theme="sustainability"] .link-item.link-item__deeplink:focus {
      background: #1da321; }
    html[data-theme="default"] .link-item.link-item__deeplink:hover, html[data-theme="default"] .link-item.link-item__deeplink:focus {
      background: #d9213d; }
    .link-item.link-item__deeplink:hover .link-item--arrow,
    .link-item.link-item__deeplink:hover .link-item--description,
    .link-item.link-item__deeplink:hover .link-item--title, .link-item.link-item__deeplink:focus .link-item--arrow,
    .link-item.link-item__deeplink:focus .link-item--description,
    .link-item.link-item__deeplink:focus .link-item--title {
      color: currentColor; }
    html[data-theme="association"] .link-item.link-item__deeplink:hover .link-item--figure, html[data-theme="association"] .link-item.link-item__deeplink:focus .link-item--figure {
      background: #a00017; }
    html[data-theme="safety"] .link-item.link-item__deeplink:hover .link-item--figure, html[data-theme="safety"] .link-item.link-item__deeplink:focus .link-item--figure {
      background: #ad4000; }
    html[data-theme="recycling"] .link-item.link-item__deeplink:hover .link-item--figure, html[data-theme="recycling"] .link-item.link-item__deeplink:focus .link-item--figure {
      background: #005523; }
    html[data-theme="knowledge"] .link-item.link-item__deeplink:hover .link-item--figure, html[data-theme="knowledge"] .link-item.link-item__deeplink:focus .link-item--figure {
      background: #003f97; }
    html[data-theme="education"] .link-item.link-item__deeplink:hover .link-item--figure, html[data-theme="education"] .link-item.link-item__deeplink:focus .link-item--figure {
      background: #36003a; }
    html[data-theme="sustainability"] .link-item.link-item__deeplink:hover .link-item--figure, html[data-theme="sustainability"] .link-item.link-item__deeplink:focus .link-item--figure {
      background: #116214; }
    html[data-theme="default"] .link-item.link-item__deeplink:hover .link-item--figure, html[data-theme="default"] .link-item.link-item__deeplink:focus .link-item--figure {
      background: #a00017; } }

@media screen {
  .link-item.link-item__deeplink:active {
    color: #ffffff; }
    html[data-theme="association"] .link-item.link-item__deeplink:active {
      background: #d9213d; }
    html[data-theme="safety"] .link-item.link-item__deeplink:active {
      background: #e66e00; }
    html[data-theme="recycling"] .link-item.link-item__deeplink:active {
      background: #2c834c; }
    html[data-theme="knowledge"] .link-item.link-item__deeplink:active {
      background: #1469c9; }
    html[data-theme="education"] .link-item.link-item__deeplink:active {
      background: #631e65; }
    html[data-theme="sustainability"] .link-item.link-item__deeplink:active {
      background: #1da321; }
    html[data-theme="default"] .link-item.link-item__deeplink:active {
      background: #d9213d; }
    .link-item.link-item__deeplink:active .link-item--arrow,
    .link-item.link-item__deeplink:active .link-item--description,
    .link-item.link-item__deeplink:active .link-item--title {
      color: currentColor; }
    html[data-theme="association"] .link-item.link-item__deeplink:active .link-item--figure {
      background: #a00017; }
    html[data-theme="safety"] .link-item.link-item__deeplink:active .link-item--figure {
      background: #ad4000; }
    html[data-theme="recycling"] .link-item.link-item__deeplink:active .link-item--figure {
      background: #005523; }
    html[data-theme="knowledge"] .link-item.link-item__deeplink:active .link-item--figure {
      background: #003f97; }
    html[data-theme="education"] .link-item.link-item__deeplink:active .link-item--figure {
      background: #36003a; }
    html[data-theme="sustainability"] .link-item.link-item__deeplink:active .link-item--figure {
      background: #116214; }
    html[data-theme="default"] .link-item.link-item__deeplink:active .link-item--figure {
      background: #a00017; } }

.link-list {
  padding: 16px 0 24px; }
  .link-list .link-list--title {
    margin-bottom: 24px;
    padding-top: 64px; }
    @media (max-width: 991px) {
      .link-list .link-list--title {
        padding-top: 48px; } }
  .link-list .link-list--collection {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09), 0 1px 2px rgba(0, 0, 0, 0.2);
    transition: box-shadow 200ms ease;
    border-radius: 4px;
    overflow: hidden; }
    @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .link-list .link-list--collection:hover, .link-list .link-list--collection:focus {
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 3px 6px 0 rgba(0, 0, 0, 0.12); } }
    @media screen {
      .link-list .link-list--collection:active {
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 3px 6px 0 rgba(0, 0, 0, 0.12); } }
  .link-list .link-list--description {
    color: #555555;
    margin-bottom: 24px; }

.login {
  margin: 120px 0 72px;
  text-align: center; }
  @media (min-width: 768px) and (max-width: 991px) {
    .login {
      margin: 64px 0 16px; } }
  @media (max-width: 991px) {
    .login {
      margin: 84px 0 36px; } }
  .login .login--form-wrap {
    padding: 32px 24px 24px;
    background-color: #ffffff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09), 0 1px 2px rgba(0, 0, 0, 0.2);
    transition: box-shadow 200ms ease;
    border-radius: 4px; }
    @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .login .login--form-wrap:hover, .login .login--form-wrap:focus {
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 3px 6px 0 rgba(0, 0, 0, 0.12); } }
    @media screen {
      .login .login--form-wrap:active {
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 3px 6px 0 rgba(0, 0, 0, 0.12); } }
  .login .login--col {
    margin: 0 auto; }
    @media (min-width: 992px) {
      .login .login--col {
        flex: 0 0 33.33333%; }
        html.ie11 .login .login--col {
          flex-basis: auto;
          width: 33.33333%; }
        html.ie10 .login .login--col {
          flex-basis: auto;
          width: 33.33333%; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .login .login--col {
        flex: 0 0 50%; }
        html.ie11 .login .login--col {
          flex-basis: auto;
          width: 50%; }
        html.ie10 .login .login--col {
          flex-basis: auto;
          width: 50%; } }
    @media (max-width: 767px) {
      .login .login--col {
        flex: 0 0 100%; }
        html.ie11 .login .login--col {
          flex-basis: auto;
          width: 100%; }
        html.ie10 .login .login--col {
          flex-basis: auto;
          width: 100%; } }
  .login .login--title {
    margin-bottom: 16px; }
  .login .login--description {
    margin-bottom: 32px; }
  .login .login--form-row {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 16px; }
  .login .login--link {
    font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
    font-weight: 500;
    font-style: normal; }
  .login .login--note {
    padding: 24px;
    margin: 32px 0 0;
    background: #f1f1f2; }

.login .login--form-row.login--form-row__checkbox {
  padding-top: 16px;
  margin-bottom: 32px; }

.login .login--form-row.login--form-row__button {
  margin-bottom: 32px; }

.main-footer .main-footer--divider-col {
  flex: 0 0 100%;
  margin: 48px 0; }
  html.ie11 .main-footer .main-footer--divider-col {
    flex-basis: auto;
    width: 100%; }
  html.ie10 .main-footer .main-footer--divider-col {
    flex-basis: auto;
    width: 100%; }

.main-footer .main-footer--divider {
  border-top: solid 4px #f1f1f2; }

.main-footer .main-footer--col {
  margin-bottom: 64px; }
  @media (min-width: 1280px) {
    .main-footer .main-footer--col {
      flex: 0 0 25%; }
      html.ie11 .main-footer .main-footer--col {
        flex-basis: auto;
        width: 25%; }
      html.ie10 .main-footer .main-footer--col {
        flex-basis: auto;
        width: 25%; } }
  @media (min-width: 992px) and (max-width: 1279px) {
    .main-footer .main-footer--col {
      flex: 0 0 33.33333%; }
      html.ie11 .main-footer .main-footer--col {
        flex-basis: auto;
        width: 33.33333%; }
      html.ie10 .main-footer .main-footer--col {
        flex-basis: auto;
        width: 33.33333%; } }
  @media (min-width: 768px) and (max-width: 991px), (min-width: 544px) and (max-width: 767px) {
    .main-footer .main-footer--col {
      flex: 0 0 50%; }
      html.ie11 .main-footer .main-footer--col {
        flex-basis: auto;
        width: 50%; }
      html.ie10 .main-footer .main-footer--col {
        flex-basis: auto;
        width: 50%; } }
  @media (max-width: 543px) {
    .main-footer .main-footer--col {
      flex: 0 0 100%; }
      html.ie11 .main-footer .main-footer--col {
        flex-basis: auto;
        width: 100%; }
      html.ie10 .main-footer .main-footer--col {
        flex-basis: auto;
        width: 100%; } }

.main-footer .main-footer--social {
  display: flex; }
  @media (min-width: 1280px) {
    .main-footer .main-footer--social {
      flex: 0 0 50%; }
      html.ie11 .main-footer .main-footer--social {
        flex-basis: auto;
        width: 50%; }
      html.ie10 .main-footer .main-footer--social {
        flex-basis: auto;
        width: 50%; } }
  @media (min-width: 992px) and (max-width: 1279px), (min-width: 768px) and (max-width: 991px) {
    .main-footer .main-footer--social {
      flex: 0 0 33.33333%; }
      html.ie11 .main-footer .main-footer--social {
        flex-basis: auto;
        width: 33.33333%; }
      html.ie10 .main-footer .main-footer--social {
        flex-basis: auto;
        width: 33.33333%; } }
  @media (max-width: 543px) {
    .main-footer .main-footer--social {
      flex: 0 0 100%; }
      html.ie11 .main-footer .main-footer--social {
        flex-basis: auto;
        width: 100%; }
      html.ie10 .main-footer .main-footer--social {
        flex-basis: auto;
        width: 100%; } }
  @media (min-width: 768px) and (max-width: 991px), (min-width: 544px) and (max-width: 767px) {
    .main-footer .main-footer--social {
      margin-bottom: 0; } }
  @media print {
    .main-footer .main-footer--social {
      display: none; } }

@media (min-width: 1280px) {
  .main-footer .main-footer--social-list {
    flex: 0 0 50%; }
    html.ie11 .main-footer .main-footer--social-list {
      flex-basis: auto;
      width: 50%; }
    html.ie10 .main-footer .main-footer--social-list {
      flex-basis: auto;
      width: 50%; } }

@media (max-width: 1279px) {
  .main-footer .main-footer--social-list {
    flex: 0 0 100%; }
    html.ie11 .main-footer .main-footer--social-list {
      flex-basis: auto;
      width: 100%; }
    html.ie10 .main-footer .main-footer--social-list {
      flex-basis: auto;
      width: 100%; } }

.main-footer .main-footer--social-row {
  flex: 1 1 100%;
  justify-content: space-between; }

@media (min-width: 768px) and (max-width: 991px), (min-width: 544px) and (max-width: 767px) {
  .main-footer .main-footer--links {
    order: 1; } }

@media print {
  .main-footer .main-footer--links {
    display: none; } }

.main-footer .main-footer--company-name {
  font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
  font-weight: 500;
  font-style: normal; }

.main-footer .main-footer--company-list {
  margin-top: 8px; }

.main-footer .main-footer--company-link {
  display: inline-block;
  padding: 8px 0;
  color: #d9213d;
  font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
  font-weight: 500;
  font-style: normal; }
  @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .main-footer .main-footer--company-link:hover, .main-footer .main-footer--company-link:focus {
      color: #a00017; } }
  @media screen {
    .main-footer .main-footer--company-link:active {
      color: #a00017; } }

.main-footer .main-footer--address-text {
  font-size: 1.5rem;
  color: #555555;
  font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal; }
  @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .main-footer .main-footer--address-text > p:hover, .main-footer .main-footer--address-text > p:focus {
      color: #111111; } }
  @media screen {
    .main-footer .main-footer--address-text > p:active {
      color: #111111; } }
  .main-footer .main-footer--address-text strong {
    color: #555555;
    line-height: 1.47; }
  .main-footer .main-footer--address-text a {
    font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
    font-weight: 500;
    font-style: normal; }
  @media print {
    .main-footer .main-footer--address-text {
      margin-bottom: 24px; } }

.main-footer .main-footer--link-list-item {
  margin-top: -16px; }
  @media (max-width: 991px) {
    .main-footer .main-footer--link-list-item {
      margin-top: -24px; } }
  .main-footer .main-footer--link-list-item .main-footer--link {
    display: inline-block;
    padding: 16px 0; }
    @media (max-width: 991px) {
      .main-footer .main-footer--link-list-item .main-footer--link {
        padding: 24px 0; } }
  .main-footer .main-footer--link-list-item:last-child {
    margin-bottom: 0; }

.main-footer .main-footer--link {
  font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
  font-weight: 500;
  font-style: normal;
  color: #555555;
  font-size: 1.5rem;
  line-height: 1.47; }
  @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .main-footer .main-footer--link:hover, .main-footer .main-footer--link:focus {
      color: #a00017; } }
  @media screen {
    .main-footer .main-footer--link:active {
      color: #a00017; } }

.main-header {
  display: none; }
  @media print {
    .main-header {
      display: block; } }
  .main-header .main-header--container {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    margin: auto; }
  .main-header .main-header--collection {
    display: flex;
    flex-flow: row wrap; }
    html.ie11 .main-header .main-header--collection {
      min-height: 1px; }
  .main-header .main-header--item {
    position: relative;
    min-height: 1px;
    max-width: 100%; }
  .main-header .main-header--container {
    padding-left: 32px;
    padding-right: 32px; }
    .main-header .main-header--container .main-header--container:not(.u-iknowwhatimdoing) {
      visibility: hidden !important; }
      .main-header .main-header--container .main-header--container:not(.u-iknowwhatimdoing)::before {
        visibility: visible;
        content: 'Nested `grid--container` detected!';
        font-size: 20px;
        line-height: 1.2;
        color: red; }
  .main-header .main-header--collection {
    margin-left: -16px;
    margin-right: -16px; }
  .main-header .main-header--item {
    padding-left: 16px;
    padding-right: 16px; }
  @media (min-width: 992px) {
    .main-header {
      display: block; } }
  .main-header .main-header--row {
    align-items: center;
    height: 72px; }
  .main-header .main-header--logo {
    display: flex;
    align-items: center; }
    @media (min-width: 992px) {
      .main-header .main-header--logo {
        flex: 0 0 33.33333%; }
        html.ie11 .main-header .main-header--logo {
          flex-basis: auto;
          width: 33.33333%; }
        html.ie10 .main-header .main-header--logo {
          flex-basis: auto;
          width: 33.33333%; } }
    @media (max-width: 991px) {
      .main-header .main-header--logo {
        flex: 0 0 100%; }
        html.ie11 .main-header .main-header--logo {
          flex-basis: auto;
          width: 100%; }
        html.ie10 .main-header .main-header--logo {
          flex-basis: auto;
          width: 100%; } }
    @media print {
      .main-header .main-header--logo {
        display: block; } }
  .main-header .main-header--nav {
    display: flex;
    justify-content: flex-end; }
    @media (min-width: 992px) {
      .main-header .main-header--nav {
        flex: 0 0 66.66667%; }
        html.ie11 .main-header .main-header--nav {
          flex-basis: auto;
          width: 66.66667%; }
        html.ie10 .main-header .main-header--nav {
          flex-basis: auto;
          width: 66.66667%; } }
    @media (max-width: 991px) {
      .main-header .main-header--nav {
        flex: 0 0 100%; }
        html.ie11 .main-header .main-header--nav {
          flex-basis: auto;
          width: 100%; }
        html.ie10 .main-header .main-header--nav {
          flex-basis: auto;
          width: 100%; } }
    @media print {
      .main-header .main-header--nav {
        display: none; } }
  .main-header .main-header--collection {
    display: flex;
    align-items: center; }
  .main-header .main-header--link {
    display: flex;
    align-items: center;
    color: #555555;
    font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
    font-weight: 500;
    font-style: normal; }
    @media (min-width: 1920px) {
      .main-header .main-header--link {
        font-size: 15px;
        line-height: 1.4; } }
    @media (min-width: 1280px) and (max-width: 1919px) {
      .main-header .main-header--link {
        font-size: 15px;
        line-height: 1.4; } }
    @media (min-width: 992px) and (max-width: 1279px) {
      .main-header .main-header--link {
        font-size: 14px;
        line-height: 1.4; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .main-header .main-header--link {
        font-size: 14px;
        line-height: 1.4; } }
    @media (min-width: 544px) and (max-width: 767px) {
      .main-header .main-header--link {
        font-size: 14px;
        line-height: 1.4; } }
    @media (min-width: 375px) and (max-width: 543px) {
      .main-header .main-header--link {
        font-size: 14px;
        line-height: 1.4; } }
    @media (max-width: 374px) {
      .main-header .main-header--link {
        font-size: 14px;
        line-height: 1.4; } }
    @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .main-header .main-header--link:hover, .main-header .main-header--link:focus {
        color: #d9213d; } }
    @media screen {
      .main-header .main-header--link:active {
        color: #d9213d; } }

.main-header .main-header--link.main-header--link__highlight {
  font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
  font-weight: 500;
  font-style: normal;
  color: #d9213d; }

@media print {
  .main-navigation {
    display: none; } }

.main-navigation .main-navigation--desktop {
  display: none; }
  @media (min-width: 992px) {
    .main-navigation .main-navigation--desktop {
      display: block; } }

.main-navigation .main-navigation--mobile {
  display: none; }
  @media (max-width: 991px) {
    .main-navigation .main-navigation--mobile {
      display: block; } }

.news-card {
  height: 100%;
  padding: 24px; }
  @media (max-width: 767px) {
    .news-card {
      padding: 16px; } }
  .news-card .news-card--figure {
    display: block;
    width: 100%; }
    @media print {
      .news-card .news-card--figure {
        display: none; } }
  .news-card .news-card--content {
    z-index: 1;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    background: #ffffff;
    border-radius: 4px;
    min-width: 276px;
    min-height: 268px; }
    @media (max-width: 767px) {
      .news-card .news-card--content {
        min-width: 224px;
        min-height: 236px; } }
    @media print {
      .news-card .news-card--content {
        min-height: 0; } }
  .news-card .news-card--date {
    width: 100%;
    color: #555555;
    font-size: 1.3rem; }
  .news-card .news-card--title {
    width: 100%;
    margin: 0 0 24px;
    color: #2c2c2c;
    color: #111111;
    font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
    font-weight: 500;
    font-style: normal;
    font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
    font-weight: 500;
    font-style: normal; }
    @media (min-width: 1920px) {
      .news-card .news-card--title {
        font-size: 21px;
        line-height: 1.25; } }
    @media (min-width: 1280px) and (max-width: 1919px) {
      .news-card .news-card--title {
        font-size: 19px;
        line-height: 1.3; } }
    @media (min-width: 992px) and (max-width: 1279px) {
      .news-card .news-card--title {
        font-size: 17px;
        line-height: 1.3; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .news-card .news-card--title {
        font-size: 17px;
        line-height: 1.3; } }
    @media (min-width: 544px) and (max-width: 767px) {
      .news-card .news-card--title {
        font-size: 17px;
        line-height: 1.3; } }
    @media (min-width: 375px) and (max-width: 543px) {
      .news-card .news-card--title {
        font-size: 17px;
        line-height: 1.3; } }
    @media (max-width: 374px) {
      .news-card .news-card--title {
        font-size: 17px;
        line-height: 1.4; } }
  .news-card .news-card--author {
    display: flex;
    align-items: center;
    flex: 0 0 100%;
    margin: auto 0 0; }
  .news-card .news-card--author-image {
    width: 100%; }
  .news-card .news-card--author-figure {
    flex: 0 0 32px;
    width: 32px;
    height: 32px;
    border-radius: 100%;
    overflow: hidden; }
  .news-card .news-card--author-description {
    font-size: 1.4rem;
    color: #555555;
    margin-left: 12px; }

.news-card.news-card__next {
  display: flex;
  height: 100%;
  width: 100%;
  color: #ffffff;
  padding: 0;
  font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
  font-weight: 500;
  font-style: normal;
  /* stylelint-disable max-nesting-depth */
  /* stylelint-enable max-nesting-depth */ }
  @media print {
    .news-card.news-card__next {
      display: none; } }
  .news-card.news-card__next .news-card--content {
    display: flex;
    flex: 1 1 100%;
    align-items: center;
    justify-content: center;
    min-height: 100%; }
  .news-card.news-card__next.news-card__association .news-card--content {
    border: 2px solid #d9213d;
    color: #d9213d; }
    @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .news-card.news-card__next.news-card__association .news-card--content:hover, .news-card.news-card__next.news-card__association .news-card--content:focus {
        color: #ffffff;
        background: #d9213d;
        box-shadow: 0 20px 32px 0 rgba(0, 0, 0, 0.2), 0 12px 16px 0 rgba(0, 0, 0, 0.12); } }
    @media screen {
      .news-card.news-card__next.news-card__association .news-card--content:active {
        color: #ffffff;
        background: #d9213d;
        box-shadow: 0 20px 32px 0 rgba(0, 0, 0, 0.2), 0 12px 16px 0 rgba(0, 0, 0, 0.12); } }
  .news-card.news-card__next.news-card__safety .news-card--content {
    border: 2px solid #e66e00;
    color: #e66e00; }
    @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .news-card.news-card__next.news-card__safety .news-card--content:hover, .news-card.news-card__next.news-card__safety .news-card--content:focus {
        color: #ffffff;
        background: #e66e00;
        box-shadow: 0 20px 32px 0 rgba(0, 0, 0, 0.2), 0 12px 16px 0 rgba(0, 0, 0, 0.12); } }
    @media screen {
      .news-card.news-card__next.news-card__safety .news-card--content:active {
        color: #ffffff;
        background: #e66e00;
        box-shadow: 0 20px 32px 0 rgba(0, 0, 0, 0.2), 0 12px 16px 0 rgba(0, 0, 0, 0.12); } }
  .news-card.news-card__next.news-card__recycling .news-card--content {
    border: 2px solid #2c834c;
    color: #2c834c; }
    @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .news-card.news-card__next.news-card__recycling .news-card--content:hover, .news-card.news-card__next.news-card__recycling .news-card--content:focus {
        color: #ffffff;
        background: #2c834c;
        box-shadow: 0 20px 32px 0 rgba(0, 0, 0, 0.2), 0 12px 16px 0 rgba(0, 0, 0, 0.12); } }
    @media screen {
      .news-card.news-card__next.news-card__recycling .news-card--content:active {
        color: #ffffff;
        background: #2c834c;
        box-shadow: 0 20px 32px 0 rgba(0, 0, 0, 0.2), 0 12px 16px 0 rgba(0, 0, 0, 0.12); } }
  .news-card.news-card__next.news-card__knowledge .news-card--content {
    border: 2px solid #1469c9;
    color: #1469c9; }
    @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .news-card.news-card__next.news-card__knowledge .news-card--content:hover, .news-card.news-card__next.news-card__knowledge .news-card--content:focus {
        color: #ffffff;
        background: #1469c9;
        box-shadow: 0 20px 32px 0 rgba(0, 0, 0, 0.2), 0 12px 16px 0 rgba(0, 0, 0, 0.12); } }
    @media screen {
      .news-card.news-card__next.news-card__knowledge .news-card--content:active {
        color: #ffffff;
        background: #1469c9;
        box-shadow: 0 20px 32px 0 rgba(0, 0, 0, 0.2), 0 12px 16px 0 rgba(0, 0, 0, 0.12); } }
  .news-card.news-card__next.news-card__education .news-card--content {
    border: 2px solid #631e65;
    color: #631e65; }
    @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .news-card.news-card__next.news-card__education .news-card--content:hover, .news-card.news-card__next.news-card__education .news-card--content:focus {
        color: #ffffff;
        background: #631e65;
        box-shadow: 0 20px 32px 0 rgba(0, 0, 0, 0.2), 0 12px 16px 0 rgba(0, 0, 0, 0.12); } }
    @media screen {
      .news-card.news-card__next.news-card__education .news-card--content:active {
        color: #ffffff;
        background: #631e65;
        box-shadow: 0 20px 32px 0 rgba(0, 0, 0, 0.2), 0 12px 16px 0 rgba(0, 0, 0, 0.12); } }
  .news-card.news-card__next.news-card__sustainability .news-card--content {
    border: 2px solid #1da321;
    color: #1da321; }
    @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .news-card.news-card__next.news-card__sustainability .news-card--content:hover, .news-card.news-card__next.news-card__sustainability .news-card--content:focus {
        color: #ffffff;
        background: #1da321;
        box-shadow: 0 20px 32px 0 rgba(0, 0, 0, 0.2), 0 12px 16px 0 rgba(0, 0, 0, 0.12); } }
    @media screen {
      .news-card.news-card__next.news-card__sustainability .news-card--content:active {
        color: #ffffff;
        background: #1da321;
        box-shadow: 0 20px 32px 0 rgba(0, 0, 0, 0.2), 0 12px 16px 0 rgba(0, 0, 0, 0.12); } }
  .news-card.news-card__next.news-card__default .news-card--content {
    border: 2px solid #d9213d;
    color: #d9213d; }
    @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .news-card.news-card__next.news-card__default .news-card--content:hover, .news-card.news-card__next.news-card__default .news-card--content:focus {
        color: #ffffff;
        background: #d9213d;
        box-shadow: 0 20px 32px 0 rgba(0, 0, 0, 0.2), 0 12px 16px 0 rgba(0, 0, 0, 0.12); } }
    @media screen {
      .news-card.news-card__next.news-card__default .news-card--content:active {
        color: #ffffff;
        background: #d9213d;
        box-shadow: 0 20px 32px 0 rgba(0, 0, 0, 0.2), 0 12px 16px 0 rgba(0, 0, 0, 0.12); } }

.news-card.news-card__slider {
  padding: 0; }
  @media print {
    .news-card.news-card__slider {
      width: 100%; } }
  @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .news-card.news-card__slider:hover .news-card--content, .news-card.news-card__slider:focus .news-card--content {
      box-shadow: 0 20px 32px 0 rgba(0, 0, 0, 0.2), 0 12px 16px 0 rgba(0, 0, 0, 0.12); } }
  @media screen {
    .news-card.news-card__slider:active .news-card--content {
      box-shadow: 0 20px 32px 0 rgba(0, 0, 0, 0.2), 0 12px 16px 0 rgba(0, 0, 0, 0.12); } }
  .news-card.news-card__slider .news-card--content {
    box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.08), 0 8px 16px 0 rgba(0, 0, 0, 0.12);
    transition: box-shadow 200ms ease;
    padding: 24px; }
    @media (max-width: 767px) {
      .news-card.news-card__slider .news-card--content {
        padding: 16px; } }
  @media (max-width: 374px) {
    .news-card.news-card__slider .news-card--figure {
      margin-top: -8px;
      transform: translate(8px, 0); } }
  @media (min-width: 375px) and (max-width: 543px) {
    .news-card.news-card__slider .news-card--figure {
      margin-top: -8px;
      transform: translate(8px, 0); } }
  @media (min-width: 544px) and (max-width: 767px) {
    .news-card.news-card__slider .news-card--figure {
      margin-top: -12px;
      transform: translate(12px, 0); } }
  @media (min-width: 768px) and (max-width: 991px) {
    .news-card.news-card__slider .news-card--figure {
      margin-top: -12px;
      transform: translate(12px, 0); } }
  @media (min-width: 992px) and (max-width: 1279px) {
    .news-card.news-card__slider .news-card--figure {
      margin-top: -16px;
      transform: translate(16px, 0); } }
  @media (min-width: 1280px) and (max-width: 1919px) {
    .news-card.news-card__slider .news-card--figure {
      margin-top: -16px;
      transform: translate(16px, 0); } }
  @media (min-width: 1920px) {
    .news-card.news-card__slider .news-card--figure {
      margin-top: -20px;
      transform: translate(20px, 0); } }

.news-card.news-card__focus {
  display: flex;
  flex-direction: column; }
  .news-card.news-card__focus .news-card--content {
    flex: 1 1 auto;
    min-height: inherit;
    padding-bottom: 24px;
    min-width: 224px; }
    @media (max-width: 767px) {
      .news-card.news-card__focus .news-card--content {
        padding-bottom: 16px;
        width: 224px; } }
  .news-card.news-card__focus .news-card--figure {
    align-self: flex-end; }

.news-card.news-card__horizontal, .news-card.news-card__news-big, .news-card.news-card__news {
  display: flex;
  flex-direction: column; }
  .news-card.news-card__horizontal .news-card--content, .news-card.news-card__news-big .news-card--content, .news-card.news-card__news .news-card--content {
    flex: 1 1 100%;
    min-height: inherit;
    min-width: inherit;
    padding-top: 24px; }
    @media (max-width: 767px) {
      .news-card.news-card__horizontal .news-card--content, .news-card.news-card__news-big .news-card--content, .news-card.news-card__news .news-card--content {
        padding-top: 16px; } }
  .news-card.news-card__horizontal .news-card--figure, .news-card.news-card__news-big .news-card--figure, .news-card.news-card__news .news-card--figure {
    order: -1; }

@media (min-width: 1280px) {
  .news-card.news-card__news-big .news-card--title {
    color: #111111;
    font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
    font-weight: 500;
    font-style: normal; } }
  @media (min-width: 1280px) and (min-width: 1920px) {
    .news-card.news-card__news-big .news-card--title {
      font-size: 29px;
      line-height: 1.25; } }
  @media (min-width: 1280px) and (min-width: 1280px) and (max-width: 1919px) {
    .news-card.news-card__news-big .news-card--title {
      font-size: 25px;
      line-height: 1.25; } }
  @media (min-width: 1280px) and (min-width: 992px) and (max-width: 1279px) {
    .news-card.news-card__news-big .news-card--title {
      font-size: 25px;
      line-height: 1.25; } }
  @media (min-width: 1280px) and (min-width: 768px) and (max-width: 991px) {
    .news-card.news-card__news-big .news-card--title {
      font-size: 24px;
      line-height: 1.25; } }
  @media (min-width: 1280px) and (min-width: 544px) and (max-width: 767px) {
    .news-card.news-card__news-big .news-card--title {
      font-size: 24px;
      line-height: 1.25; } }
  @media (min-width: 1280px) and (min-width: 375px) and (max-width: 543px) {
    .news-card.news-card__news-big .news-card--title {
      font-size: 22px;
      line-height: 1.3; } }
  @media (min-width: 1280px) and (max-width: 374px) {
    .news-card.news-card__news-big .news-card--title {
      font-size: 21px;
      line-height: 1.4; } }

.news-card.news-card__horizontal {
  display: flex;
  flex: 1 1 auto; }
  @media (min-width: 768px) {
    .news-card.news-card__horizontal {
      flex-direction: row; }
      .news-card.news-card__horizontal .news-card--title {
        color: #111111;
        font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
        font-weight: 500;
        font-style: normal; } }
    @media (min-width: 768px) and (min-width: 1920px) {
      .news-card.news-card__horizontal .news-card--title {
        font-size: 36px;
        line-height: 1.25; } }
    @media (min-width: 768px) and (min-width: 1280px) and (max-width: 1919px) {
      .news-card.news-card__horizontal .news-card--title {
        font-size: 35px;
        line-height: 1.25; } }
    @media (min-width: 768px) and (min-width: 992px) and (max-width: 1279px) {
      .news-card.news-card__horizontal .news-card--title {
        font-size: 30px;
        line-height: 1.25; } }
    @media (min-width: 768px) and (min-width: 768px) and (max-width: 991px) {
      .news-card.news-card__horizontal .news-card--title {
        font-size: 28px;
        line-height: 1.25; } }
    @media (min-width: 768px) and (min-width: 544px) and (max-width: 767px) {
      .news-card.news-card__horizontal .news-card--title {
        font-size: 27px;
        line-height: 1.25; } }
    @media (min-width: 768px) and (min-width: 375px) and (max-width: 543px) {
      .news-card.news-card__horizontal .news-card--title {
        font-size: 26px;
        line-height: 1.25; } }
    @media (min-width: 768px) and (max-width: 374px) {
      .news-card.news-card__horizontal .news-card--title {
        font-size: 25px;
        line-height: 1.25; } }
  @media (min-width: 768px) {
      .news-card.news-card__horizontal .news-card--content {
        padding-top: 0;
        padding-right: 24px; }
      .news-card.news-card__horizontal .news-card--figure {
        order: 1; }
      .news-card.news-card__horizontal .news-card--content,
      .news-card.news-card__horizontal .news-card--figure {
        flex: 0 0 50%; } }

.news-card.news-card__list {
  display: flex;
  padding: 8px 48px 8px 8px; }
  @media (max-width: 767px) {
    .news-card.news-card__list {
      padding-right: 8px; } }
  .news-card.news-card__list .news-card--figure {
    order: -1;
    flex: 0 0 108px;
    width: 108px;
    height: 72px; }
    @media (max-width: 991px) {
      .news-card.news-card__list .news-card--figure {
        display: none; } }
  .news-card.news-card__list .news-card--title {
    order: -1;
    margin-bottom: 0; }
  .news-card.news-card__list .news-card--content {
    display: flex;
    margin-left: 16px;
    flex: 1 1 auto;
    align-items: center;
    min-height: inherit;
    min-width: inherit; }
    @media (max-width: 991px) {
      .news-card.news-card__list .news-card--content {
        margin-left: 8px; } }
  .news-card.news-card__list .news-card--intro {
    display: flex;
    flex-direction: column;
    flex: 1 1 75%; }
    @media (max-width: 991px) {
      .news-card.news-card__list .news-card--intro {
        flex: 1 1 100%; } }
  .news-card.news-card__list .news-card--author {
    flex: 1 1 25%;
    padding-left: 24px;
    align-items: center;
    justify-content: flex-end;
    margin: auto; }
    @media (max-width: 991px) {
      .news-card.news-card__list .news-card--author {
        display: none; } }

.news-filters {
  margin-bottom: 68px; }
  @media (max-width: 991px) {
    .news-filters {
      margin-bottom: 8px; } }
  @media (min-width: 768px) and (max-width: 991px), (min-width: 544px) and (max-width: 767px) {
    .news-filters .news-filters--row {
      align-items: center;
      justify-content: space-between; } }
  @media (min-width: 992px) {
    .news-filters .news-filters--tags {
      flex: 0 0 83.33333%; }
      html.ie11 .news-filters .news-filters--tags {
        flex-basis: auto;
        width: 83.33333%; }
      html.ie10 .news-filters .news-filters--tags {
        flex-basis: auto;
        width: 83.33333%; } }
  @media (max-width: 543px) {
    .news-filters .news-filters--tags {
      flex: 0 0 66.66667%; }
      html.ie11 .news-filters .news-filters--tags {
        flex-basis: auto;
        width: 66.66667%; }
      html.ie10 .news-filters .news-filters--tags {
        flex-basis: auto;
        width: 66.66667%; } }
  @media (max-width: 543px) {
    .news-filters .news-filters--tags {
      display: flex;
      justify-content: flex-start; } }
  .news-filters .news-filters--buttons {
    display: flex;
    justify-content: flex-end;
    margin: -8px -8px 0 0; }
    @media (min-width: 992px) {
      .news-filters .news-filters--buttons {
        flex: 0 0 16.66667%; }
        html.ie11 .news-filters .news-filters--buttons {
          flex-basis: auto;
          width: 16.66667%; }
        html.ie10 .news-filters .news-filters--buttons {
          flex-basis: auto;
          width: 16.66667%; } }
    @media (max-width: 543px) {
      .news-filters .news-filters--buttons {
        flex: 0 0 33.33333%; }
        html.ie11 .news-filters .news-filters--buttons {
          flex-basis: auto;
          width: 33.33333%; }
        html.ie10 .news-filters .news-filters--buttons {
          flex-basis: auto;
          width: 33.33333%; } }
    @media (min-width: 768px) and (max-width: 991px), (min-width: 544px) and (max-width: 767px) {
      .news-filters .news-filters--buttons {
        margin-top: 0;
        margin-right: -16px; } }
  .news-filters .news-filters--button {
    padding: 8px;
    color: #555555; }
    @media (max-width: 991px) {
      .news-filters .news-filters--button {
        margin-top: 0;
        padding: 16px; } }

.news-filters .news-filters--button.news-filters--button__active {
  color: #2c2c2c; }

.news-list .news-list--collection {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09), 0 1px 2px rgba(0, 0, 0, 0.2);
  transition: box-shadow 200ms ease;
  border-radius: 4px; }
  @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .news-list .news-list--collection:hover, .news-list .news-list--collection:focus {
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 3px 6px 0 rgba(0, 0, 0, 0.12); } }
  @media screen {
    .news-list .news-list--collection:active {
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 3px 6px 0 rgba(0, 0, 0, 0.12); } }

.news-list .news-list--item ~ .news-list--item {
  border-top: 1px solid #f1f1f2; }

.news-list .news-list--link {
  display: flex;
  align-items: center; }

.news-list .news-list--card {
  flex: 1 1 auto; }

.news-list .news-list--icon {
  padding-right: 24px; }

.news-list .news-list--item.news-list--item__association .news-list--link {
  color: #d9213d; }
  @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .news-list .news-list--item.news-list--item__association .news-list--link:hover, .news-list .news-list--item.news-list--item__association .news-list--link:focus {
      color: #a00017; }
      .news-list .news-list--item.news-list--item__association .news-list--link:hover *, .news-list .news-list--item.news-list--item__association .news-list--link:focus * {
        color: #a00017; } }
  @media screen {
    .news-list .news-list--item.news-list--item__association .news-list--link:active {
      color: #a00017; }
      .news-list .news-list--item.news-list--item__association .news-list--link:active * {
        color: #a00017; } }

.news-list .news-list--item.news-list--item__safety .news-list--link {
  color: #e66e00; }
  @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .news-list .news-list--item.news-list--item__safety .news-list--link:hover, .news-list .news-list--item.news-list--item__safety .news-list--link:focus {
      color: #ad4000; }
      .news-list .news-list--item.news-list--item__safety .news-list--link:hover *, .news-list .news-list--item.news-list--item__safety .news-list--link:focus * {
        color: #ad4000; } }
  @media screen {
    .news-list .news-list--item.news-list--item__safety .news-list--link:active {
      color: #ad4000; }
      .news-list .news-list--item.news-list--item__safety .news-list--link:active * {
        color: #ad4000; } }

.news-list .news-list--item.news-list--item__recycling .news-list--link {
  color: #2c834c; }
  @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .news-list .news-list--item.news-list--item__recycling .news-list--link:hover, .news-list .news-list--item.news-list--item__recycling .news-list--link:focus {
      color: #005523; }
      .news-list .news-list--item.news-list--item__recycling .news-list--link:hover *, .news-list .news-list--item.news-list--item__recycling .news-list--link:focus * {
        color: #005523; } }
  @media screen {
    .news-list .news-list--item.news-list--item__recycling .news-list--link:active {
      color: #005523; }
      .news-list .news-list--item.news-list--item__recycling .news-list--link:active * {
        color: #005523; } }

.news-list .news-list--item.news-list--item__knowledge .news-list--link {
  color: #1469c9; }
  @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .news-list .news-list--item.news-list--item__knowledge .news-list--link:hover, .news-list .news-list--item.news-list--item__knowledge .news-list--link:focus {
      color: #003f97; }
      .news-list .news-list--item.news-list--item__knowledge .news-list--link:hover *, .news-list .news-list--item.news-list--item__knowledge .news-list--link:focus * {
        color: #003f97; } }
  @media screen {
    .news-list .news-list--item.news-list--item__knowledge .news-list--link:active {
      color: #003f97; }
      .news-list .news-list--item.news-list--item__knowledge .news-list--link:active * {
        color: #003f97; } }

.news-list .news-list--item.news-list--item__education .news-list--link {
  color: #631e65; }
  @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .news-list .news-list--item.news-list--item__education .news-list--link:hover, .news-list .news-list--item.news-list--item__education .news-list--link:focus {
      color: #36003a; }
      .news-list .news-list--item.news-list--item__education .news-list--link:hover *, .news-list .news-list--item.news-list--item__education .news-list--link:focus * {
        color: #36003a; } }
  @media screen {
    .news-list .news-list--item.news-list--item__education .news-list--link:active {
      color: #36003a; }
      .news-list .news-list--item.news-list--item__education .news-list--link:active * {
        color: #36003a; } }

.news-list .news-list--item.news-list--item__sustainability .news-list--link {
  color: #1da321; }
  @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .news-list .news-list--item.news-list--item__sustainability .news-list--link:hover, .news-list .news-list--item.news-list--item__sustainability .news-list--link:focus {
      color: #116214; }
      .news-list .news-list--item.news-list--item__sustainability .news-list--link:hover *, .news-list .news-list--item.news-list--item__sustainability .news-list--link:focus * {
        color: #116214; } }
  @media screen {
    .news-list .news-list--item.news-list--item__sustainability .news-list--link:active {
      color: #116214; }
      .news-list .news-list--item.news-list--item__sustainability .news-list--link:active * {
        color: #116214; } }

.news-list .news-list--item.news-list--item__default .news-list--link {
  color: #d9213d; }
  @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .news-list .news-list--item.news-list--item__default .news-list--link:hover, .news-list .news-list--item.news-list--item__default .news-list--link:focus {
      color: #a00017; }
      .news-list .news-list--item.news-list--item__default .news-list--link:hover *, .news-list .news-list--item.news-list--item__default .news-list--link:focus * {
        color: #a00017; } }
  @media screen {
    .news-list .news-list--item.news-list--item__default .news-list--link:active {
      color: #a00017; }
      .news-list .news-list--item.news-list--item__default .news-list--link:active * {
        color: #a00017; } }

.news-meta .news-meta--container {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  margin: auto; }

.news-meta .news-meta--collection {
  display: flex;
  flex-flow: row wrap; }
  html.ie11 .news-meta .news-meta--collection {
    min-height: 1px; }

.news-meta .news-meta--item {
  position: relative;
  min-height: 1px;
  max-width: 100%; }

.news-meta .news-meta--container {
  padding-top: 8px;
  padding-left: 8px;
  padding-bottom: 8px;
  padding-right: 8px; }
  .news-meta .news-meta--container .news-meta--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important; }
    .news-meta .news-meta--container .news-meta--container:not(.u-iknowwhatimdoing)::before {
      visibility: visible;
      content: 'Nested `grid--container` detected!';
      font-size: 20px;
      line-height: 1.2;
      color: red; }

.news-meta .news-meta--collection {
  margin-top: -4px;
  margin-left: -4px;
  margin-bottom: -4px;
  margin-right: -4px; }

.news-meta .news-meta--item {
  padding-top: 4px;
  padding-left: 4px;
  padding-bottom: 4px;
  padding-right: 4px; }

.news-meta .news-meta--author {
  display: flex;
  margin-bottom: 24px; }

.news-meta .news-meta--figure {
  border-radius: 100%;
  overflow: hidden;
  margin-right: 12px; }
  @media (min-width: 768px) {
    .news-meta .news-meta--figure {
      flex: 0 0 48px;
      width: 48px;
      height: 48px; } }
  @media (max-width: 767px) {
    .news-meta .news-meta--figure {
      flex: 0 0 24px;
      width: 24px;
      height: 24px; } }

.news-meta .news-meta--figcaption {
  position: absolute;
  overflow: hidden;
  max-width: 1px;
  max-height: 1px;
  padding: 0;
  border: 0;
  margin: -1px;
  clip: rect(0 0 0 0); }

.news-meta .news-meta--text {
  display: flex;
  font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal; }
  @media (min-width: 768px) {
    .news-meta .news-meta--text {
      flex-direction: column;
      justify-content: center; } }
  @media (max-width: 767px) {
    .news-meta .news-meta--text {
      align-items: center; } }
  @media (min-width: 1920px) {
    .news-meta .news-meta--text {
      font-size: 19px;
      line-height: 1.4; } }
  @media (min-width: 1280px) and (max-width: 1919px) {
    .news-meta .news-meta--text {
      font-size: 17px;
      line-height: 1.4; } }
  @media (min-width: 992px) and (max-width: 1279px) {
    .news-meta .news-meta--text {
      font-size: 15px;
      line-height: 1.4; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .news-meta .news-meta--text {
      font-size: 15px;
      line-height: 1.4; } }
  @media (min-width: 544px) and (max-width: 767px) {
    .news-meta .news-meta--text {
      font-size: 15px;
      line-height: 1.4; } }
  @media (min-width: 375px) and (max-width: 543px) {
    .news-meta .news-meta--text {
      font-size: 15px;
      line-height: 1.4; } }
  @media (max-width: 374px) {
    .news-meta .news-meta--text {
      font-size: 15px;
      line-height: 1.4; } }

.news-meta .news-meta--text-divider {
  display: none; }
  @media (max-width: 767px) {
    .news-meta .news-meta--text-divider {
      margin-right: 4px;
      display: inline-block; } }

.news-meta .news-meta--image {
  height: 100%; }

.news-meta .news-meta--title {
  position: absolute;
  overflow: hidden;
  max-width: 1px;
  max-height: 1px;
  padding: 0;
  border: 0;
  margin: -1px;
  clip: rect(0 0 0 0); }

.news-meta .news-meta--tag {
  padding: 9px 16px 7px;
  color: #ffffff;
  border-radius: 16px;
  font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
  font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
  font-weight: 500;
  font-style: normal; }
  @media (min-width: 1920px) {
    .news-meta .news-meta--tag {
      font-size: 17px;
      line-height: 1.4; } }
  @media (min-width: 1280px) and (max-width: 1919px) {
    .news-meta .news-meta--tag {
      font-size: 15px;
      line-height: 1.4; } }
  @media (min-width: 992px) and (max-width: 1279px) {
    .news-meta .news-meta--tag {
      font-size: 13px;
      line-height: 1.4; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .news-meta .news-meta--tag {
      font-size: 13px;
      line-height: 1.4; } }
  @media (min-width: 544px) and (max-width: 767px) {
    .news-meta .news-meta--tag {
      font-size: 13px;
      line-height: 1.4; } }
  @media (min-width: 375px) and (max-width: 543px) {
    .news-meta .news-meta--tag {
      font-size: 13px;
      line-height: 1.4; } }
  @media (max-width: 374px) {
    .news-meta .news-meta--tag {
      font-size: 13px;
      line-height: 1.4; } }

.news-meta .news-meta--tag.news-meta--tag__association {
  background: #d9213d; }
  @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .news-meta .news-meta--tag.news-meta--tag__association:hover, .news-meta .news-meta--tag.news-meta--tag__association:focus {
      background: #a00017; } }
  @media screen {
    .news-meta .news-meta--tag.news-meta--tag__association:active {
      background: #a00017; } }

.news-meta .news-meta--tag.news-meta--tag__safety {
  background: #e66e00; }
  @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .news-meta .news-meta--tag.news-meta--tag__safety:hover, .news-meta .news-meta--tag.news-meta--tag__safety:focus {
      background: #ad4000; } }
  @media screen {
    .news-meta .news-meta--tag.news-meta--tag__safety:active {
      background: #ad4000; } }

.news-meta .news-meta--tag.news-meta--tag__recycling {
  background: #2c834c; }
  @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .news-meta .news-meta--tag.news-meta--tag__recycling:hover, .news-meta .news-meta--tag.news-meta--tag__recycling:focus {
      background: #005523; } }
  @media screen {
    .news-meta .news-meta--tag.news-meta--tag__recycling:active {
      background: #005523; } }

.news-meta .news-meta--tag.news-meta--tag__knowledge {
  background: #1469c9; }
  @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .news-meta .news-meta--tag.news-meta--tag__knowledge:hover, .news-meta .news-meta--tag.news-meta--tag__knowledge:focus {
      background: #003f97; } }
  @media screen {
    .news-meta .news-meta--tag.news-meta--tag__knowledge:active {
      background: #003f97; } }

.news-meta .news-meta--tag.news-meta--tag__education {
  background: #631e65; }
  @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .news-meta .news-meta--tag.news-meta--tag__education:hover, .news-meta .news-meta--tag.news-meta--tag__education:focus {
      background: #36003a; } }
  @media screen {
    .news-meta .news-meta--tag.news-meta--tag__education:active {
      background: #36003a; } }

.news-meta .news-meta--tag.news-meta--tag__sustainability {
  background: #1da321; }
  @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .news-meta .news-meta--tag.news-meta--tag__sustainability:hover, .news-meta .news-meta--tag.news-meta--tag__sustainability:focus {
      background: #116214; } }
  @media screen {
    .news-meta .news-meta--tag.news-meta--tag__sustainability:active {
      background: #116214; } }

.news-meta .news-meta--tag.news-meta--tag__default {
  background: #d9213d; }
  @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .news-meta .news-meta--tag.news-meta--tag__default:hover, .news-meta .news-meta--tag.news-meta--tag__default:focus {
      background: #a00017; } }
  @media screen {
    .news-meta .news-meta--tag.news-meta--tag__default:active {
      background: #a00017; } }

.news-wall .news-wall--container {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  margin: auto; }

.news-wall .news-wall--collection {
  display: flex;
  flex-flow: row wrap; }
  html.ie11 .news-wall .news-wall--collection {
    min-height: 1px; }

.news-wall .news-wall--item {
  position: relative;
  min-height: 1px;
  max-width: 100%; }

.news-wall .news-wall--container {
  padding-left: 0;
  padding-right: 0; }
  .news-wall .news-wall--container .news-wall--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important; }
    .news-wall .news-wall--container .news-wall--container:not(.u-iknowwhatimdoing)::before {
      visibility: visible;
      content: 'Nested `grid--container` detected!';
      font-size: 20px;
      line-height: 1.2;
      color: red; }

.news-wall .news-wall--collection {
  margin-left: 0;
  margin-right: 0; }

.news-wall .news-wall--item {
  padding-left: 0;
  padding-right: 0; }

.news-wall .news-wall--collection {
  border-radius: 4px;
  transition: box-shadow 200ms ease;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09), 0 1px 2px rgba(0, 0, 0, 0.2); }
  @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .news-wall .news-wall--collection:hover, .news-wall .news-wall--collection:focus {
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 3px 6px 0 rgba(0, 0, 0, 0.12); } }
  @media screen {
    .news-wall .news-wall--collection:active {
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 3px 6px 0 rgba(0, 0, 0, 0.12); } }

.news-wall .news-wall--item {
  overflow: hidden;
  position: relative;
  min-height: 1px;
  max-width: 100%;
  position: relative;
  min-height: 1px;
  max-width: 100%;
  position: relative;
  min-height: 1px;
  max-width: 100%; }
  @media (min-width: 992px) {
    .news-wall .news-wall--item {
      flex: 0 0 25%; }
      html.ie11 .news-wall .news-wall--item {
        flex-basis: auto;
        width: 25%; }
      html.ie10 .news-wall .news-wall--item {
        flex-basis: auto;
        width: 25%; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .news-wall .news-wall--item {
      flex: 0 0 33.33333%; }
      html.ie11 .news-wall .news-wall--item {
        flex-basis: auto;
        width: 33.33333%; }
      html.ie10 .news-wall .news-wall--item {
        flex-basis: auto;
        width: 33.33333%; } }
  @media (max-width: 767px) {
    .news-wall .news-wall--item {
      flex: 0 0 100%; }
      html.ie11 .news-wall .news-wall--item {
        flex-basis: auto;
        width: 100%; }
      html.ie10 .news-wall .news-wall--item {
        flex-basis: auto;
        width: 100%; } }
  @media (min-width: 992px) {
    .news-wall .news-wall--item {
      border-bottom: 1px solid #f1f1f2;
      border-right: 1px solid #f1f1f2; }
      .news-wall .news-wall--item:nth-child(4n + 3):nth-last-child(-n + 4),
      .news-wall .news-wall--item:nth-child(4n + 3):nth-last-child(-n + 4) ~ .news-wall--item {
        border-bottom: none; }
      .news-wall .news-wall--item:nth-child(4n+2) {
        border-right: none; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .news-wall .news-wall--item {
      border-bottom: 1px solid #f1f1f2;
      border-right: 1px solid #f1f1f2; }
      .news-wall .news-wall--item:nth-child(3n + 2):nth-last-child(-n + 3),
      .news-wall .news-wall--item:nth-child(3n + 2):nth-last-child(-n + 3) ~ .news-wall--item {
        border-bottom: none; }
      .news-wall .news-wall--item:nth-child(3n+1) {
        border-right: none; } }
  @media (max-width: 767px) {
    .news-wall .news-wall--item {
      border-bottom: 1px solid #f1f1f2;
      border-right: none; }
      .news-wall .news-wall--item:last-child {
        border-bottom: none; } }
  .news-wall .news-wall--item:first-child {
    position: relative;
    min-height: 1px;
    max-width: 100%;
    position: relative;
    min-height: 1px;
    max-width: 100%; }
    @media (min-width: 992px) {
      .news-wall .news-wall--item:first-child {
        flex: 0 0 66.66667%; }
        html.ie11 .news-wall .news-wall--item:first-child {
          flex-basis: auto;
          width: 66.66667%; }
        html.ie10 .news-wall .news-wall--item:first-child {
          flex-basis: auto;
          width: 66.66667%; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .news-wall .news-wall--item:first-child {
        flex: 0 0 100%; }
        html.ie11 .news-wall .news-wall--item:first-child {
          flex-basis: auto;
          width: 100%; }
        html.ie10 .news-wall .news-wall--item:first-child {
          flex-basis: auto;
          width: 100%; } }
  .news-wall .news-wall--item:nth-child(2) {
    position: relative;
    min-height: 1px;
    max-width: 100%; }
    @media (min-width: 992px) {
      .news-wall .news-wall--item:nth-child(2) {
        flex: 0 0 33.33333%; }
        html.ie11 .news-wall .news-wall--item:nth-child(2) {
          flex-basis: auto;
          width: 33.33333%; }
        html.ie10 .news-wall .news-wall--item:nth-child(2) {
          flex-basis: auto;
          width: 33.33333%; } }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .news-wall .news-wall--link.news-wall--link__association:hover, .news-wall .news-wall--link.news-wall--link__association:focus {
    color: #a00017; }
    .news-wall .news-wall--link.news-wall--link__association:hover *, .news-wall .news-wall--link.news-wall--link__association:focus * {
      color: #a00017; }
    .news-wall .news-wall--link.news-wall--link__association:hover figure, .news-wall .news-wall--link.news-wall--link__association:focus figure {
      filter: saturate(80%); } }

@media screen {
  .news-wall .news-wall--link.news-wall--link__association:active {
    color: #a00017; }
    .news-wall .news-wall--link.news-wall--link__association:active * {
      color: #a00017; }
    .news-wall .news-wall--link.news-wall--link__association:active figure {
      filter: saturate(80%); } }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .news-wall .news-wall--link.news-wall--link__safety:hover, .news-wall .news-wall--link.news-wall--link__safety:focus {
    color: #ad4000; }
    .news-wall .news-wall--link.news-wall--link__safety:hover *, .news-wall .news-wall--link.news-wall--link__safety:focus * {
      color: #ad4000; }
    .news-wall .news-wall--link.news-wall--link__safety:hover figure, .news-wall .news-wall--link.news-wall--link__safety:focus figure {
      filter: saturate(80%); } }

@media screen {
  .news-wall .news-wall--link.news-wall--link__safety:active {
    color: #ad4000; }
    .news-wall .news-wall--link.news-wall--link__safety:active * {
      color: #ad4000; }
    .news-wall .news-wall--link.news-wall--link__safety:active figure {
      filter: saturate(80%); } }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .news-wall .news-wall--link.news-wall--link__recycling:hover, .news-wall .news-wall--link.news-wall--link__recycling:focus {
    color: #005523; }
    .news-wall .news-wall--link.news-wall--link__recycling:hover *, .news-wall .news-wall--link.news-wall--link__recycling:focus * {
      color: #005523; }
    .news-wall .news-wall--link.news-wall--link__recycling:hover figure, .news-wall .news-wall--link.news-wall--link__recycling:focus figure {
      filter: saturate(80%); } }

@media screen {
  .news-wall .news-wall--link.news-wall--link__recycling:active {
    color: #005523; }
    .news-wall .news-wall--link.news-wall--link__recycling:active * {
      color: #005523; }
    .news-wall .news-wall--link.news-wall--link__recycling:active figure {
      filter: saturate(80%); } }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .news-wall .news-wall--link.news-wall--link__knowledge:hover, .news-wall .news-wall--link.news-wall--link__knowledge:focus {
    color: #003f97; }
    .news-wall .news-wall--link.news-wall--link__knowledge:hover *, .news-wall .news-wall--link.news-wall--link__knowledge:focus * {
      color: #003f97; }
    .news-wall .news-wall--link.news-wall--link__knowledge:hover figure, .news-wall .news-wall--link.news-wall--link__knowledge:focus figure {
      filter: saturate(80%); } }

@media screen {
  .news-wall .news-wall--link.news-wall--link__knowledge:active {
    color: #003f97; }
    .news-wall .news-wall--link.news-wall--link__knowledge:active * {
      color: #003f97; }
    .news-wall .news-wall--link.news-wall--link__knowledge:active figure {
      filter: saturate(80%); } }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .news-wall .news-wall--link.news-wall--link__education:hover, .news-wall .news-wall--link.news-wall--link__education:focus {
    color: #36003a; }
    .news-wall .news-wall--link.news-wall--link__education:hover *, .news-wall .news-wall--link.news-wall--link__education:focus * {
      color: #36003a; }
    .news-wall .news-wall--link.news-wall--link__education:hover figure, .news-wall .news-wall--link.news-wall--link__education:focus figure {
      filter: saturate(80%); } }

@media screen {
  .news-wall .news-wall--link.news-wall--link__education:active {
    color: #36003a; }
    .news-wall .news-wall--link.news-wall--link__education:active * {
      color: #36003a; }
    .news-wall .news-wall--link.news-wall--link__education:active figure {
      filter: saturate(80%); } }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .news-wall .news-wall--link.news-wall--link__sustainability:hover, .news-wall .news-wall--link.news-wall--link__sustainability:focus {
    color: #116214; }
    .news-wall .news-wall--link.news-wall--link__sustainability:hover *, .news-wall .news-wall--link.news-wall--link__sustainability:focus * {
      color: #116214; }
    .news-wall .news-wall--link.news-wall--link__sustainability:hover figure, .news-wall .news-wall--link.news-wall--link__sustainability:focus figure {
      filter: saturate(80%); } }

@media screen {
  .news-wall .news-wall--link.news-wall--link__sustainability:active {
    color: #116214; }
    .news-wall .news-wall--link.news-wall--link__sustainability:active * {
      color: #116214; }
    .news-wall .news-wall--link.news-wall--link__sustainability:active figure {
      filter: saturate(80%); } }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .news-wall .news-wall--link.news-wall--link__default:hover, .news-wall .news-wall--link.news-wall--link__default:focus {
    color: #a00017; }
    .news-wall .news-wall--link.news-wall--link__default:hover *, .news-wall .news-wall--link.news-wall--link__default:focus * {
      color: #a00017; }
    .news-wall .news-wall--link.news-wall--link__default:hover figure, .news-wall .news-wall--link.news-wall--link__default:focus figure {
      filter: saturate(80%); } }

@media screen {
  .news-wall .news-wall--link.news-wall--link__default:active {
    color: #a00017; }
    .news-wall .news-wall--link.news-wall--link__default:active * {
      color: #a00017; }
    .news-wall .news-wall--link.news-wall--link__default:active figure {
      filter: saturate(80%); } }

/* stylelint-disable max-nesting-depth */
@media print {
  .next-best-action {
    display: none; } }

.next-best-action .next-best-action--title {
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 0 auto 24px;
  color: #111111;
  font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
  font-weight: 500;
  font-style: normal; }
  @media (min-width: 1920px) {
    .next-best-action .next-best-action--title {
      font-size: 36px;
      line-height: 1.25; } }
  @media (min-width: 1280px) and (max-width: 1919px) {
    .next-best-action .next-best-action--title {
      font-size: 35px;
      line-height: 1.25; } }
  @media (min-width: 992px) and (max-width: 1279px) {
    .next-best-action .next-best-action--title {
      font-size: 30px;
      line-height: 1.25; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .next-best-action .next-best-action--title {
      font-size: 28px;
      line-height: 1.25; } }
  @media (min-width: 544px) and (max-width: 767px) {
    .next-best-action .next-best-action--title {
      font-size: 27px;
      line-height: 1.25; } }
  @media (min-width: 375px) and (max-width: 543px) {
    .next-best-action .next-best-action--title {
      font-size: 26px;
      line-height: 1.25; } }
  @media (max-width: 374px) {
    .next-best-action .next-best-action--title {
      font-size: 25px;
      line-height: 1.25; } }
  @media (min-width: 768px) {
    .next-best-action .next-best-action--title {
      flex: 0 0 66.66667%; }
      html.ie11 .next-best-action .next-best-action--title {
        flex-basis: auto;
        width: 66.66667%; }
      html.ie10 .next-best-action .next-best-action--title {
        flex-basis: auto;
        width: 66.66667%; } }
  @media (max-width: 767px) {
    .next-best-action .next-best-action--title {
      flex: 0 0 100%; }
      html.ie11 .next-best-action .next-best-action--title {
        flex-basis: auto;
        width: 100%; }
      html.ie10 .next-best-action .next-best-action--title {
        flex-basis: auto;
        width: 100%; } }

.next-best-action .next-best-action--title,
.next-best-action .next-best-action--block-title {
  color: #2c2c2c; }

.next-best-action .next-best-action--block-title {
  margin-bottom: 24px; }

.next-best-action .next-best-action--articles {
  display: flex;
  flex-wrap: wrap;
  box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.08), 0 8px 16px 0 rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  overflow: hidden;
  transition: box-shadow 200ms ease;
  background: #ffffff; }
  @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .next-best-action .next-best-action--articles:hover, .next-best-action .next-best-action--articles:focus {
      box-shadow: 0 20px 32px 0 rgba(0, 0, 0, 0.2), 0 12px 16px 0 rgba(0, 0, 0, 0.12); } }
  @media screen {
    .next-best-action .next-best-action--articles:active {
      box-shadow: 0 20px 32px 0 rgba(0, 0, 0, 0.2), 0 12px 16px 0 rgba(0, 0, 0, 0.12); } }

.next-best-action .next-best-action--block {
  display: flex;
  flex: 0 0 100%; }
  @media (min-width: 768px) {
    .next-best-action .next-best-action--block {
      flex: 0 0 50%; } }
  @media (max-width: 767px) {
    .next-best-action .next-best-action--block ~ .next-best-action--block .next-best-action--link {
      border-top: 1px solid #f1f1f2; } }
  @media (min-width: 768px) {
    .next-best-action .next-best-action--block ~ .next-best-action--block .next-best-action--link {
      border-left: 1px solid #f1f1f2; } }

.next-best-action .next-best-action--link {
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 40px; }
  @media (min-width: 768px) and (max-width: 991px) {
    .next-best-action .next-best-action--link {
      padding: 32px; } }
  @media (max-width: 767px) {
    .next-best-action .next-best-action--link {
      padding: 32px 20px; } }

.next-best-action .next-best-action--icon {
  margin-top: auto; }

.next-best-action .next-best-action--description {
  color: #555555;
  margin-bottom: 32px; }

@media (max-width: 767px) {
  .next-best-action .next-best-action--block.next-best-action--block__association {
    border-left: 4px solid #d9213d; } }

@media (min-width: 768px) {
  .next-best-action .next-best-action--block.next-best-action--block__association {
    border-top: 4px solid #d9213d; } }

.next-best-action .next-best-action--block.next-best-action--block__association .next-best-action--icon {
  color: #d9213d; }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .next-best-action .next-best-action--block.next-best-action--block__association .next-best-action--link:hover, .next-best-action .next-best-action--block.next-best-action--block__association .next-best-action--link:focus {
    background: #d9213d; }
    .next-best-action .next-best-action--block.next-best-action--block__association .next-best-action--link:hover .next-best-action--description,
    .next-best-action .next-best-action--block.next-best-action--block__association .next-best-action--link:hover .next-best-action--block-title,
    .next-best-action .next-best-action--block.next-best-action--block__association .next-best-action--link:hover .next-best-action--icon, .next-best-action .next-best-action--block.next-best-action--block__association .next-best-action--link:focus .next-best-action--description,
    .next-best-action .next-best-action--block.next-best-action--block__association .next-best-action--link:focus .next-best-action--block-title,
    .next-best-action .next-best-action--block.next-best-action--block__association .next-best-action--link:focus .next-best-action--icon {
      color: #ffffff; } }

@media screen {
  .next-best-action .next-best-action--block.next-best-action--block__association .next-best-action--link:active {
    background: #d9213d; }
    .next-best-action .next-best-action--block.next-best-action--block__association .next-best-action--link:active .next-best-action--description,
    .next-best-action .next-best-action--block.next-best-action--block__association .next-best-action--link:active .next-best-action--block-title,
    .next-best-action .next-best-action--block.next-best-action--block__association .next-best-action--link:active .next-best-action--icon {
      color: #ffffff; } }

@media (max-width: 767px) {
  .next-best-action .next-best-action--block.next-best-action--block__safety {
    border-left: 4px solid #e66e00; } }

@media (min-width: 768px) {
  .next-best-action .next-best-action--block.next-best-action--block__safety {
    border-top: 4px solid #e66e00; } }

.next-best-action .next-best-action--block.next-best-action--block__safety .next-best-action--icon {
  color: #e66e00; }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .next-best-action .next-best-action--block.next-best-action--block__safety .next-best-action--link:hover, .next-best-action .next-best-action--block.next-best-action--block__safety .next-best-action--link:focus {
    background: #e66e00; }
    .next-best-action .next-best-action--block.next-best-action--block__safety .next-best-action--link:hover .next-best-action--description,
    .next-best-action .next-best-action--block.next-best-action--block__safety .next-best-action--link:hover .next-best-action--block-title,
    .next-best-action .next-best-action--block.next-best-action--block__safety .next-best-action--link:hover .next-best-action--icon, .next-best-action .next-best-action--block.next-best-action--block__safety .next-best-action--link:focus .next-best-action--description,
    .next-best-action .next-best-action--block.next-best-action--block__safety .next-best-action--link:focus .next-best-action--block-title,
    .next-best-action .next-best-action--block.next-best-action--block__safety .next-best-action--link:focus .next-best-action--icon {
      color: #ffffff; } }

@media screen {
  .next-best-action .next-best-action--block.next-best-action--block__safety .next-best-action--link:active {
    background: #e66e00; }
    .next-best-action .next-best-action--block.next-best-action--block__safety .next-best-action--link:active .next-best-action--description,
    .next-best-action .next-best-action--block.next-best-action--block__safety .next-best-action--link:active .next-best-action--block-title,
    .next-best-action .next-best-action--block.next-best-action--block__safety .next-best-action--link:active .next-best-action--icon {
      color: #ffffff; } }

@media (max-width: 767px) {
  .next-best-action .next-best-action--block.next-best-action--block__recycling {
    border-left: 4px solid #2c834c; } }

@media (min-width: 768px) {
  .next-best-action .next-best-action--block.next-best-action--block__recycling {
    border-top: 4px solid #2c834c; } }

.next-best-action .next-best-action--block.next-best-action--block__recycling .next-best-action--icon {
  color: #2c834c; }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .next-best-action .next-best-action--block.next-best-action--block__recycling .next-best-action--link:hover, .next-best-action .next-best-action--block.next-best-action--block__recycling .next-best-action--link:focus {
    background: #2c834c; }
    .next-best-action .next-best-action--block.next-best-action--block__recycling .next-best-action--link:hover .next-best-action--description,
    .next-best-action .next-best-action--block.next-best-action--block__recycling .next-best-action--link:hover .next-best-action--block-title,
    .next-best-action .next-best-action--block.next-best-action--block__recycling .next-best-action--link:hover .next-best-action--icon, .next-best-action .next-best-action--block.next-best-action--block__recycling .next-best-action--link:focus .next-best-action--description,
    .next-best-action .next-best-action--block.next-best-action--block__recycling .next-best-action--link:focus .next-best-action--block-title,
    .next-best-action .next-best-action--block.next-best-action--block__recycling .next-best-action--link:focus .next-best-action--icon {
      color: #ffffff; } }

@media screen {
  .next-best-action .next-best-action--block.next-best-action--block__recycling .next-best-action--link:active {
    background: #2c834c; }
    .next-best-action .next-best-action--block.next-best-action--block__recycling .next-best-action--link:active .next-best-action--description,
    .next-best-action .next-best-action--block.next-best-action--block__recycling .next-best-action--link:active .next-best-action--block-title,
    .next-best-action .next-best-action--block.next-best-action--block__recycling .next-best-action--link:active .next-best-action--icon {
      color: #ffffff; } }

@media (max-width: 767px) {
  .next-best-action .next-best-action--block.next-best-action--block__knowledge {
    border-left: 4px solid #1469c9; } }

@media (min-width: 768px) {
  .next-best-action .next-best-action--block.next-best-action--block__knowledge {
    border-top: 4px solid #1469c9; } }

.next-best-action .next-best-action--block.next-best-action--block__knowledge .next-best-action--icon {
  color: #1469c9; }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .next-best-action .next-best-action--block.next-best-action--block__knowledge .next-best-action--link:hover, .next-best-action .next-best-action--block.next-best-action--block__knowledge .next-best-action--link:focus {
    background: #1469c9; }
    .next-best-action .next-best-action--block.next-best-action--block__knowledge .next-best-action--link:hover .next-best-action--description,
    .next-best-action .next-best-action--block.next-best-action--block__knowledge .next-best-action--link:hover .next-best-action--block-title,
    .next-best-action .next-best-action--block.next-best-action--block__knowledge .next-best-action--link:hover .next-best-action--icon, .next-best-action .next-best-action--block.next-best-action--block__knowledge .next-best-action--link:focus .next-best-action--description,
    .next-best-action .next-best-action--block.next-best-action--block__knowledge .next-best-action--link:focus .next-best-action--block-title,
    .next-best-action .next-best-action--block.next-best-action--block__knowledge .next-best-action--link:focus .next-best-action--icon {
      color: #ffffff; } }

@media screen {
  .next-best-action .next-best-action--block.next-best-action--block__knowledge .next-best-action--link:active {
    background: #1469c9; }
    .next-best-action .next-best-action--block.next-best-action--block__knowledge .next-best-action--link:active .next-best-action--description,
    .next-best-action .next-best-action--block.next-best-action--block__knowledge .next-best-action--link:active .next-best-action--block-title,
    .next-best-action .next-best-action--block.next-best-action--block__knowledge .next-best-action--link:active .next-best-action--icon {
      color: #ffffff; } }

@media (max-width: 767px) {
  .next-best-action .next-best-action--block.next-best-action--block__education {
    border-left: 4px solid #631e65; } }

@media (min-width: 768px) {
  .next-best-action .next-best-action--block.next-best-action--block__education {
    border-top: 4px solid #631e65; } }

.next-best-action .next-best-action--block.next-best-action--block__education .next-best-action--icon {
  color: #631e65; }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .next-best-action .next-best-action--block.next-best-action--block__education .next-best-action--link:hover, .next-best-action .next-best-action--block.next-best-action--block__education .next-best-action--link:focus {
    background: #631e65; }
    .next-best-action .next-best-action--block.next-best-action--block__education .next-best-action--link:hover .next-best-action--description,
    .next-best-action .next-best-action--block.next-best-action--block__education .next-best-action--link:hover .next-best-action--block-title,
    .next-best-action .next-best-action--block.next-best-action--block__education .next-best-action--link:hover .next-best-action--icon, .next-best-action .next-best-action--block.next-best-action--block__education .next-best-action--link:focus .next-best-action--description,
    .next-best-action .next-best-action--block.next-best-action--block__education .next-best-action--link:focus .next-best-action--block-title,
    .next-best-action .next-best-action--block.next-best-action--block__education .next-best-action--link:focus .next-best-action--icon {
      color: #ffffff; } }

@media screen {
  .next-best-action .next-best-action--block.next-best-action--block__education .next-best-action--link:active {
    background: #631e65; }
    .next-best-action .next-best-action--block.next-best-action--block__education .next-best-action--link:active .next-best-action--description,
    .next-best-action .next-best-action--block.next-best-action--block__education .next-best-action--link:active .next-best-action--block-title,
    .next-best-action .next-best-action--block.next-best-action--block__education .next-best-action--link:active .next-best-action--icon {
      color: #ffffff; } }

@media (max-width: 767px) {
  .next-best-action .next-best-action--block.next-best-action--block__sustainability {
    border-left: 4px solid #1da321; } }

@media (min-width: 768px) {
  .next-best-action .next-best-action--block.next-best-action--block__sustainability {
    border-top: 4px solid #1da321; } }

.next-best-action .next-best-action--block.next-best-action--block__sustainability .next-best-action--icon {
  color: #1da321; }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .next-best-action .next-best-action--block.next-best-action--block__sustainability .next-best-action--link:hover, .next-best-action .next-best-action--block.next-best-action--block__sustainability .next-best-action--link:focus {
    background: #1da321; }
    .next-best-action .next-best-action--block.next-best-action--block__sustainability .next-best-action--link:hover .next-best-action--description,
    .next-best-action .next-best-action--block.next-best-action--block__sustainability .next-best-action--link:hover .next-best-action--block-title,
    .next-best-action .next-best-action--block.next-best-action--block__sustainability .next-best-action--link:hover .next-best-action--icon, .next-best-action .next-best-action--block.next-best-action--block__sustainability .next-best-action--link:focus .next-best-action--description,
    .next-best-action .next-best-action--block.next-best-action--block__sustainability .next-best-action--link:focus .next-best-action--block-title,
    .next-best-action .next-best-action--block.next-best-action--block__sustainability .next-best-action--link:focus .next-best-action--icon {
      color: #ffffff; } }

@media screen {
  .next-best-action .next-best-action--block.next-best-action--block__sustainability .next-best-action--link:active {
    background: #1da321; }
    .next-best-action .next-best-action--block.next-best-action--block__sustainability .next-best-action--link:active .next-best-action--description,
    .next-best-action .next-best-action--block.next-best-action--block__sustainability .next-best-action--link:active .next-best-action--block-title,
    .next-best-action .next-best-action--block.next-best-action--block__sustainability .next-best-action--link:active .next-best-action--icon {
      color: #ffffff; } }

@media (max-width: 767px) {
  .next-best-action .next-best-action--block.next-best-action--block__default {
    border-left: 4px solid #d9213d; } }

@media (min-width: 768px) {
  .next-best-action .next-best-action--block.next-best-action--block__default {
    border-top: 4px solid #d9213d; } }

.next-best-action .next-best-action--block.next-best-action--block__default .next-best-action--icon {
  color: #d9213d; }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .next-best-action .next-best-action--block.next-best-action--block__default .next-best-action--link:hover, .next-best-action .next-best-action--block.next-best-action--block__default .next-best-action--link:focus {
    background: #d9213d; }
    .next-best-action .next-best-action--block.next-best-action--block__default .next-best-action--link:hover .next-best-action--description,
    .next-best-action .next-best-action--block.next-best-action--block__default .next-best-action--link:hover .next-best-action--block-title,
    .next-best-action .next-best-action--block.next-best-action--block__default .next-best-action--link:hover .next-best-action--icon, .next-best-action .next-best-action--block.next-best-action--block__default .next-best-action--link:focus .next-best-action--description,
    .next-best-action .next-best-action--block.next-best-action--block__default .next-best-action--link:focus .next-best-action--block-title,
    .next-best-action .next-best-action--block.next-best-action--block__default .next-best-action--link:focus .next-best-action--icon {
      color: #ffffff; } }

@media screen {
  .next-best-action .next-best-action--block.next-best-action--block__default .next-best-action--link:active {
    background: #d9213d; }
    .next-best-action .next-best-action--block.next-best-action--block__default .next-best-action--link:active .next-best-action--description,
    .next-best-action .next-best-action--block.next-best-action--block__default .next-best-action--link:active .next-best-action--block-title,
    .next-best-action .next-best-action--block.next-best-action--block__default .next-best-action--link:active .next-best-action--icon {
      color: #ffffff; } }

.opacc-iframe {
  padding: 64px 0; }
  @media (max-width: 767px) {
    .opacc-iframe {
      padding: 48px 0; } }
  .opacc-iframe .opacc-iframe--col {
    margin: 0 auto;
    position: relative;
    min-height: 1px;
    max-width: 100%;
    position: relative;
    min-height: 1px;
    max-width: 100%;
    position: relative;
    min-height: 1px;
    max-width: 100%;
    position: relative;
    min-height: 1px;
    max-width: 100%; }
    @media (min-width: 1280px) {
      .opacc-iframe .opacc-iframe--col {
        flex: 0 0 50%; }
        html.ie11 .opacc-iframe .opacc-iframe--col {
          flex-basis: auto;
          width: 50%; }
        html.ie10 .opacc-iframe .opacc-iframe--col {
          flex-basis: auto;
          width: 50%; } }
    @media (min-width: 992px) and (max-width: 1279px) {
      .opacc-iframe .opacc-iframe--col {
        flex: 0 0 66.66667%; }
        html.ie11 .opacc-iframe .opacc-iframe--col {
          flex-basis: auto;
          width: 66.66667%; }
        html.ie10 .opacc-iframe .opacc-iframe--col {
          flex-basis: auto;
          width: 66.66667%; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .opacc-iframe .opacc-iframe--col {
        flex: 0 0 66.66667%; }
        html.ie11 .opacc-iframe .opacc-iframe--col {
          flex-basis: auto;
          width: 66.66667%; }
        html.ie10 .opacc-iframe .opacc-iframe--col {
          flex-basis: auto;
          width: 66.66667%; } }
    @media (max-width: 767px) {
      .opacc-iframe .opacc-iframe--col {
        flex: 0 0 100%; }
        html.ie11 .opacc-iframe .opacc-iframe--col {
          flex-basis: auto;
          width: 100%; }
        html.ie10 .opacc-iframe .opacc-iframe--col {
          flex-basis: auto;
          width: 100%; } }
    @media (max-width: 543px) {
      .opacc-iframe .opacc-iframe--col {
        overflow: auto;
        width: 100%; } }

.page-not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 1 100%;
  padding-top: 60px;
  padding-bottom: 20px; }
  .page-not-found .page-not-found--title {
    margin-bottom: 12px;
    color: #111111;
    font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
    font-weight: 500;
    font-style: normal; }
    @media (min-width: 1920px) {
      .page-not-found .page-not-found--title {
        font-size: 29px;
        line-height: 1.25; } }
    @media (min-width: 1280px) and (max-width: 1919px) {
      .page-not-found .page-not-found--title {
        font-size: 25px;
        line-height: 1.25; } }
    @media (min-width: 992px) and (max-width: 1279px) {
      .page-not-found .page-not-found--title {
        font-size: 25px;
        line-height: 1.25; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .page-not-found .page-not-found--title {
        font-size: 24px;
        line-height: 1.25; } }
    @media (min-width: 544px) and (max-width: 767px) {
      .page-not-found .page-not-found--title {
        font-size: 24px;
        line-height: 1.25; } }
    @media (min-width: 375px) and (max-width: 543px) {
      .page-not-found .page-not-found--title {
        font-size: 22px;
        line-height: 1.3; } }
    @media (max-width: 374px) {
      .page-not-found .page-not-found--title {
        font-size: 21px;
        line-height: 1.4; } }
  .page-not-found .page-not-found--text {
    text-align: center;
    margin: 0 auto; }
    @media (min-width: 768px) {
      .page-not-found .page-not-found--text {
        flex: 0 0 66.66667%; }
        html.ie11 .page-not-found .page-not-found--text {
          flex-basis: auto;
          width: 66.66667%; }
        html.ie10 .page-not-found .page-not-found--text {
          flex-basis: auto;
          width: 66.66667%; } }
    @media (max-width: 767px) {
      .page-not-found .page-not-found--text {
        flex: 0 0 100%; }
        html.ie11 .page-not-found .page-not-found--text {
          flex-basis: auto;
          width: 100%; }
        html.ie10 .page-not-found .page-not-found--text {
          flex-basis: auto;
          width: 100%; } }

.pagination {
  margin-top: 64px; }
  @media (max-width: 767px) {
    .pagination {
      margin-top: 48px; } }
  .pagination .pagination--container {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    margin: auto; }
  .pagination .pagination--collection {
    display: flex;
    flex-flow: row wrap; }
    html.ie11 .pagination .pagination--collection {
      min-height: 1px; }
  .pagination .pagination--item {
    position: relative;
    min-height: 1px;
    max-width: 100%; }
  .pagination .pagination--container {
    padding-left: 16px;
    padding-right: 16px; }
    .pagination .pagination--container .pagination--container:not(.u-iknowwhatimdoing) {
      visibility: hidden !important; }
      .pagination .pagination--container .pagination--container:not(.u-iknowwhatimdoing)::before {
        visibility: visible;
        content: 'Nested `grid--container` detected!';
        font-size: 20px;
        line-height: 1.2;
        color: red; }
  .pagination .pagination--collection {
    margin-left: -8px;
    margin-right: -8px; }
  .pagination .pagination--item {
    padding-left: 8px;
    padding-right: 8px; }
  .pagination .pagination--collection {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
    font-weight: 500;
    font-style: normal; }
  .pagination .pagination--link {
    color: #555555; }
    @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .pagination .pagination--link:hover, .pagination .pagination--link:focus {
        color: #111111; } }
    @media screen {
      .pagination .pagination--link:active {
        color: #111111; } }

.pagination .pagination--item.pagination--item__disabled {
  color: #a5a3a3; }

.program-list {
  margin: 0 0 24px; }
  .program-list .program-list--title {
    margin-bottom: 24px;
    padding-top: 64px; }
    @media (max-width: 991px) {
      .program-list .program-list--title {
        padding-top: 48px; } }
  .program-list .program-list--item {
    display: flex; }
    .program-list .program-list--item ~ .program-list--item {
      margin-top: 24px; }
  .program-list .program-list--date {
    flex: 0 0 auto;
    padding-right: 16px;
    color: #111111;
    font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
    font-weight: 500;
    font-style: normal;
    color: #555555; }
    @media (min-width: 1920px) {
      .program-list .program-list--date {
        font-size: 21px;
        line-height: 1.25; } }
    @media (min-width: 1280px) and (max-width: 1919px) {
      .program-list .program-list--date {
        font-size: 19px;
        line-height: 1.3; } }
    @media (min-width: 992px) and (max-width: 1279px) {
      .program-list .program-list--date {
        font-size: 17px;
        line-height: 1.3; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .program-list .program-list--date {
        font-size: 17px;
        line-height: 1.3; } }
    @media (min-width: 544px) and (max-width: 767px) {
      .program-list .program-list--date {
        font-size: 17px;
        line-height: 1.3; } }
    @media (min-width: 375px) and (max-width: 543px) {
      .program-list .program-list--date {
        font-size: 17px;
        line-height: 1.3; } }
    @media (max-width: 374px) {
      .program-list .program-list--date {
        font-size: 17px;
        line-height: 1.4; } }
  .program-list .program-list--info {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column; }
  .program-list .program-list--item-title {
    color: #111111;
    font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
    font-weight: 500;
    font-style: normal;
    color: #555555; }
    @media (min-width: 1920px) {
      .program-list .program-list--item-title {
        font-size: 21px;
        line-height: 1.25; } }
    @media (min-width: 1280px) and (max-width: 1919px) {
      .program-list .program-list--item-title {
        font-size: 19px;
        line-height: 1.3; } }
    @media (min-width: 992px) and (max-width: 1279px) {
      .program-list .program-list--item-title {
        font-size: 17px;
        line-height: 1.3; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .program-list .program-list--item-title {
        font-size: 17px;
        line-height: 1.3; } }
    @media (min-width: 544px) and (max-width: 767px) {
      .program-list .program-list--item-title {
        font-size: 17px;
        line-height: 1.3; } }
    @media (min-width: 375px) and (max-width: 543px) {
      .program-list .program-list--item-title {
        font-size: 17px;
        line-height: 1.3; } }
    @media (max-width: 374px) {
      .program-list .program-list--item-title {
        font-size: 17px;
        line-height: 1.4; } }

.protected {
  margin: 64px 0;
  position: relative; }
  @media print {
    .protected {
      display: none; } }
  @media (max-width: 991px) {
    .protected {
      margin: 48px 0; } }
  .protected::before {
    content: '';
    position: absolute;
    left: -18px;
    top: 0;
    display: block;
    width: 4px;
    height: 100%;
    border-radius: 4px;
    background: transparent; }
    html[data-theme="association"] .protected::before {
      background: #ff5e67; }
    html[data-theme="safety"] .protected::before {
      background: #ff9e3e; }
    html[data-theme="recycling"] .protected::before {
      background: #dbebdb; }
    html[data-theme="knowledge"] .protected::before {
      background: #6096fd; }
    html[data-theme="education"] .protected::before {
      background: #934b93; }
    html[data-theme="sustainability"] .protected::before {
      background: #c3eac4; }
    html[data-theme="default"] .protected::before {
      background: #ff5e67; }
    @media (max-width: 543px) {
      .protected::before {
        left: -11px;
        width: 2px; } }
  .protected .protected--title {
    display: flex;
    align-items: center;
    padding-bottom: 16px; }
  .protected .protected--icon {
    padding-right: 8px; }
    html[data-theme="association"] .protected .protected--icon {
      color: #ff5e67; }
    html[data-theme="safety"] .protected .protected--icon {
      color: #ff9e3e; }
    html[data-theme="recycling"] .protected .protected--icon {
      color: #dbebdb; }
    html[data-theme="knowledge"] .protected .protected--icon {
      color: #6096fd; }
    html[data-theme="education"] .protected .protected--icon {
      color: #934b93; }
    html[data-theme="sustainability"] .protected .protected--icon {
      color: #c3eac4; }
    html[data-theme="default"] .protected .protected--icon {
      color: #ff5e67; }
    @media (max-width: 543px) {
      .protected .protected--icon {
        transform: scale(0.8); } }
  .protected .protected--cta {
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09), 0 1px 2px rgba(0, 0, 0, 0.2); }
  .protected .protected--actions {
    display: flex;
    flex-wrap: wrap; }
  .protected .protected--action {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 32px 24px;
    text-align: center;
    color: #555555; }
    @media (min-width: 768px) {
      .protected .protected--action {
        flex: 1 1 50%; }
        .protected .protected--action ~ .protected--action {
          border-left: 1px solid #f1f1f2; } }
    @media (max-width: 767px) {
      .protected .protected--action {
        flex: 1 1 100%; }
        .protected .protected--action ~ .protected--action {
          border-top: 1px solid #f1f1f2; } }
  .protected .protected--button {
    min-width: 224px;
    margin-top: auto; }
  .protected .protected--action-description {
    margin-bottom: 24px; }
  .protected .protected--cta-title {
    text-align: center;
    padding: 24px;
    border-bottom: 1px solid #f1f1f2; }

.richtext {
  /* stylelint-disable string-quotes */
  /* stylelint-enable string-quotes */ }
  .richtext h1, .richtext h2, .richtext h3, .richtext h4 {
    display: inline-block;
    padding-bottom: 24px; }
  .richtext h1, .richtext h2 {
    padding-top: 64px; }
    @media (max-width: 991px) {
      .richtext h1, .richtext h2 {
        padding-top: 48px; } }
  .richtext h3 {
    padding-top: 40px;
    padding-bottom: 24px; }
  .richtext h4 {
    padding-top: 16px;
    padding-bottom: 16px; }
  .richtext h1, .richtext h2, .richtext h3, .richtext h4, .richtext p, .richtext ul, .richtext ol {
    width: 100%; }
  .richtext p {
    display: inline-block;
    margin-bottom: 16px; }
  .richtext ul, .richtext ol {
    margin-bottom: 16px; }
  .richtext table {
    font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
    font-weight: 400;
    font-style: normal;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: auto;
    margin-bottom: 16px;
    margin-left: -8px;
    margin-right: -8px; }
    @media (min-width: 1920px) {
      .richtext table {
        font-size: 17px;
        line-height: 1.4; } }
    @media (min-width: 1280px) and (max-width: 1919px) {
      .richtext table {
        font-size: 15px;
        line-height: 1.4; } }
    @media (min-width: 992px) and (max-width: 1279px) {
      .richtext table {
        font-size: 13px;
        line-height: 1.4; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .richtext table {
        font-size: 13px;
        line-height: 1.4; } }
    @media (min-width: 544px) and (max-width: 767px) {
      .richtext table {
        font-size: 13px;
        line-height: 1.4; } }
    @media (min-width: 375px) and (max-width: 543px) {
      .richtext table {
        font-size: 13px;
        line-height: 1.4; } }
    @media (max-width: 374px) {
      .richtext table {
        font-size: 13px;
        line-height: 1.4; } }
  .richtext table p {
    margin-bottom: 0; }
  .richtext table thead th {
    font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
    font-weight: 400;
    font-style: normal;
    font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
    font-weight: 500;
    font-style: normal; }
    @media (min-width: 1920px) {
      .richtext table thead th {
        font-size: 17px;
        line-height: 1.4; } }
    @media (min-width: 1280px) and (max-width: 1919px) {
      .richtext table thead th {
        font-size: 15px;
        line-height: 1.4; } }
    @media (min-width: 992px) and (max-width: 1279px) {
      .richtext table thead th {
        font-size: 13px;
        line-height: 1.4; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .richtext table thead th {
        font-size: 13px;
        line-height: 1.4; } }
    @media (min-width: 544px) and (max-width: 767px) {
      .richtext table thead th {
        font-size: 13px;
        line-height: 1.4; } }
    @media (min-width: 375px) and (max-width: 543px) {
      .richtext table thead th {
        font-size: 13px;
        line-height: 1.4; } }
    @media (max-width: 374px) {
      .richtext table thead th {
        font-size: 13px;
        line-height: 1.4; } }
  .richtext table tr {
    border-bottom: 1px solid #eeeeee;
    padding: 4px; }
  .richtext table th,
  .richtext table td {
    padding: 8px; }
  .richtext table th {
    text-align: left; }
  @media (max-width: 991px) {
    .richtext table {
      border: 0;
      margin-left: -12px;
      margin-right: -12px; }
    .richtext table thead {
      position: absolute;
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      width: 1px; }
    .richtext table tr {
      display: block;
      margin-bottom: 32px;
      border-bottom: 0; }
    .richtext table td {
      border-bottom: 1px solid #eeeeee;
      display: block;
      text-align: right; }
    .richtext table td::before {
      content: attr(data-label);
      float: left; }
    .richtext table td:last-child {
      border-bottom: 0; } }
  .richtext .richtext--arrow-link,
  .richtext .richtext--external-link {
    display: inline-flex;
    align-items: center;
    margin-bottom: 24px;
    font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
    font-weight: 500;
    font-style: normal; }
    .richtext .richtext--arrow-link::before, .richtext .richtext--arrow-link::after,
    .richtext .richtext--external-link::before,
    .richtext .richtext--external-link::after {
      content: '';
      display: block;
      flex: 0 0 24px;
      width: 24px;
      height: 24px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover; }
  .richtext .richtext--external-link::after {
    display: none; }
  .richtext .richtext--external-link::before {
    margin-right: 8px; }
    html[data-theme="association"] .richtext .richtext--external-link::before {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSIjZDkyMTNkIiBkPSJNMTksMTkgTDUsMTkgTDUsNSBMMTIsNSBMMTIsMyBMNSwzIEMzLjg5LDMgMywzLjkgMyw1IEwzLDE5IEMzLDIwLjEgMy44OSwyMSA1LDIxIEwxOSwyMSBDMjAuMSwyMSAyMSwyMC4xIDIxLDE5IEwyMSwxMiBMMTksMTIgTDE5LDE5IFogTTE0LDMgTDE0LDUgTDE3LjU5LDUgTDcuNzYsMTQuODMgTDkuMTcsMTYuMjQgTDE5LDYuNDEgTDE5LDEwIEwyMSwxMCBMMjEsMyBMMTQsMyBaIi8+PC9zdmc+"); }
    html[data-theme="safety"] .richtext .richtext--external-link::before {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSIjZTY2ZTAwIiBkPSJNMTksMTkgTDUsMTkgTDUsNSBMMTIsNSBMMTIsMyBMNSwzIEMzLjg5LDMgMywzLjkgMyw1IEwzLDE5IEMzLDIwLjEgMy44OSwyMSA1LDIxIEwxOSwyMSBDMjAuMSwyMSAyMSwyMC4xIDIxLDE5IEwyMSwxMiBMMTksMTIgTDE5LDE5IFogTTE0LDMgTDE0LDUgTDE3LjU5LDUgTDcuNzYsMTQuODMgTDkuMTcsMTYuMjQgTDE5LDYuNDEgTDE5LDEwIEwyMSwxMCBMMjEsMyBMMTQsMyBaIi8+PC9zdmc+"); }
    html[data-theme="recycling"] .richtext .richtext--external-link::before {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSIjMmM4MzRjIiBkPSJNMTksMTkgTDUsMTkgTDUsNSBMMTIsNSBMMTIsMyBMNSwzIEMzLjg5LDMgMywzLjkgMyw1IEwzLDE5IEMzLDIwLjEgMy44OSwyMSA1LDIxIEwxOSwyMSBDMjAuMSwyMSAyMSwyMC4xIDIxLDE5IEwyMSwxMiBMMTksMTIgTDE5LDE5IFogTTE0LDMgTDE0LDUgTDE3LjU5LDUgTDcuNzYsMTQuODMgTDkuMTcsMTYuMjQgTDE5LDYuNDEgTDE5LDEwIEwyMSwxMCBMMjEsMyBMMTQsMyBaIi8+PC9zdmc+"); }
    html[data-theme="knowledge"] .richtext .richtext--external-link::before {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSIjMTQ2OWM5IiBkPSJNMTksMTkgTDUsMTkgTDUsNSBMMTIsNSBMMTIsMyBMNSwzIEMzLjg5LDMgMywzLjkgMyw1IEwzLDE5IEMzLDIwLjEgMy44OSwyMSA1LDIxIEwxOSwyMSBDMjAuMSwyMSAyMSwyMC4xIDIxLDE5IEwyMSwxMiBMMTksMTIgTDE5LDE5IFogTTE0LDMgTDE0LDUgTDE3LjU5LDUgTDcuNzYsMTQuODMgTDkuMTcsMTYuMjQgTDE5LDYuNDEgTDE5LDEwIEwyMSwxMCBMMjEsMyBMMTQsMyBaIi8+PC9zdmc+"); }
    html[data-theme="education"] .richtext .richtext--external-link::before {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSIjNjMxZTY1IiBkPSJNMTksMTkgTDUsMTkgTDUsNSBMMTIsNSBMMTIsMyBMNSwzIEMzLjg5LDMgMywzLjkgMyw1IEwzLDE5IEMzLDIwLjEgMy44OSwyMSA1LDIxIEwxOSwyMSBDMjAuMSwyMSAyMSwyMC4xIDIxLDE5IEwyMSwxMiBMMTksMTIgTDE5LDE5IFogTTE0LDMgTDE0LDUgTDE3LjU5LDUgTDcuNzYsMTQuODMgTDkuMTcsMTYuMjQgTDE5LDYuNDEgTDE5LDEwIEwyMSwxMCBMMjEsMyBMMTQsMyBaIi8+PC9zdmc+"); }
    html[data-theme="sustainability"] .richtext .richtext--external-link::before {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSIjMWRhMzIxIiBkPSJNMTksMTkgTDUsMTkgTDUsNSBMMTIsNSBMMTIsMyBMNSwzIEMzLjg5LDMgMywzLjkgMyw1IEwzLDE5IEMzLDIwLjEgMy44OSwyMSA1LDIxIEwxOSwyMSBDMjAuMSwyMSAyMSwyMC4xIDIxLDE5IEwyMSwxMiBMMTksMTIgTDE5LDE5IFogTTE0LDMgTDE0LDUgTDE3LjU5LDUgTDcuNzYsMTQuODMgTDkuMTcsMTYuMjQgTDE5LDYuNDEgTDE5LDEwIEwyMSwxMCBMMjEsMyBMMTQsMyBaIi8+PC9zdmc+"); }
    html[data-theme="default"] .richtext .richtext--external-link::before {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSIjZDkyMTNkIiBkPSJNMTksMTkgTDUsMTkgTDUsNSBMMTIsNSBMMTIsMyBMNSwzIEMzLjg5LDMgMywzLjkgMyw1IEwzLDE5IEMzLDIwLjEgMy44OSwyMSA1LDIxIEwxOSwyMSBDMjAuMSwyMSAyMSwyMC4xIDIxLDE5IEwyMSwxMiBMMTksMTIgTDE5LDE5IFogTTE0LDMgTDE0LDUgTDE3LjU5LDUgTDcuNzYsMTQuODMgTDkuMTcsMTYuMjQgTDE5LDYuNDEgTDE5LDEwIEwyMSwxMCBMMjEsMyBMMTQsMyBaIi8+PC9zdmc+"); }
  @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    html[data-theme="association"] .richtext .richtext--external-link:hover::before, html[data-theme="association"] .richtext .richtext--external-link:focus::before {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSIjYTAwMDE3IiBkPSJNMTksMTkgTDUsMTkgTDUsNSBMMTIsNSBMMTIsMyBMNSwzIEMzLjg5LDMgMywzLjkgMyw1IEwzLDE5IEMzLDIwLjEgMy44OSwyMSA1LDIxIEwxOSwyMSBDMjAuMSwyMSAyMSwyMC4xIDIxLDE5IEwyMSwxMiBMMTksMTIgTDE5LDE5IFogTTE0LDMgTDE0LDUgTDE3LjU5LDUgTDcuNzYsMTQuODMgTDkuMTcsMTYuMjQgTDE5LDYuNDEgTDE5LDEwIEwyMSwxMCBMMjEsMyBMMTQsMyBaIi8+PC9zdmc+"); }
    html[data-theme="safety"] .richtext .richtext--external-link:hover::before, html[data-theme="safety"] .richtext .richtext--external-link:focus::before {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSIjYWQ0MDAwIiBkPSJNMTksMTkgTDUsMTkgTDUsNSBMMTIsNSBMMTIsMyBMNSwzIEMzLjg5LDMgMywzLjkgMyw1IEwzLDE5IEMzLDIwLjEgMy44OSwyMSA1LDIxIEwxOSwyMSBDMjAuMSwyMSAyMSwyMC4xIDIxLDE5IEwyMSwxMiBMMTksMTIgTDE5LDE5IFogTTE0LDMgTDE0LDUgTDE3LjU5LDUgTDcuNzYsMTQuODMgTDkuMTcsMTYuMjQgTDE5LDYuNDEgTDE5LDEwIEwyMSwxMCBMMjEsMyBMMTQsMyBaIi8+PC9zdmc+"); }
    html[data-theme="recycling"] .richtext .richtext--external-link:hover::before, html[data-theme="recycling"] .richtext .richtext--external-link:focus::before {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSIjMDA1NTIzIiBkPSJNMTksMTkgTDUsMTkgTDUsNSBMMTIsNSBMMTIsMyBMNSwzIEMzLjg5LDMgMywzLjkgMyw1IEwzLDE5IEMzLDIwLjEgMy44OSwyMSA1LDIxIEwxOSwyMSBDMjAuMSwyMSAyMSwyMC4xIDIxLDE5IEwyMSwxMiBMMTksMTIgTDE5LDE5IFogTTE0LDMgTDE0LDUgTDE3LjU5LDUgTDcuNzYsMTQuODMgTDkuMTcsMTYuMjQgTDE5LDYuNDEgTDE5LDEwIEwyMSwxMCBMMjEsMyBMMTQsMyBaIi8+PC9zdmc+"); }
    html[data-theme="knowledge"] .richtext .richtext--external-link:hover::before, html[data-theme="knowledge"] .richtext .richtext--external-link:focus::before {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSIjMDAzZjk3IiBkPSJNMTksMTkgTDUsMTkgTDUsNSBMMTIsNSBMMTIsMyBMNSwzIEMzLjg5LDMgMywzLjkgMyw1IEwzLDE5IEMzLDIwLjEgMy44OSwyMSA1LDIxIEwxOSwyMSBDMjAuMSwyMSAyMSwyMC4xIDIxLDE5IEwyMSwxMiBMMTksMTIgTDE5LDE5IFogTTE0LDMgTDE0LDUgTDE3LjU5LDUgTDcuNzYsMTQuODMgTDkuMTcsMTYuMjQgTDE5LDYuNDEgTDE5LDEwIEwyMSwxMCBMMjEsMyBMMTQsMyBaIi8+PC9zdmc+"); }
    html[data-theme="education"] .richtext .richtext--external-link:hover::before, html[data-theme="education"] .richtext .richtext--external-link:focus::before {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSIjMzYwMDNhIiBkPSJNMTksMTkgTDUsMTkgTDUsNSBMMTIsNSBMMTIsMyBMNSwzIEMzLjg5LDMgMywzLjkgMyw1IEwzLDE5IEMzLDIwLjEgMy44OSwyMSA1LDIxIEwxOSwyMSBDMjAuMSwyMSAyMSwyMC4xIDIxLDE5IEwyMSwxMiBMMTksMTIgTDE5LDE5IFogTTE0LDMgTDE0LDUgTDE3LjU5LDUgTDcuNzYsMTQuODMgTDkuMTcsMTYuMjQgTDE5LDYuNDEgTDE5LDEwIEwyMSwxMCBMMjEsMyBMMTQsMyBaIi8+PC9zdmc+"); }
    html[data-theme="sustainability"] .richtext .richtext--external-link:hover::before, html[data-theme="sustainability"] .richtext .richtext--external-link:focus::before {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSIjMTE2MjE0IiBkPSJNMTksMTkgTDUsMTkgTDUsNSBMMTIsNSBMMTIsMyBMNSwzIEMzLjg5LDMgMywzLjkgMyw1IEwzLDE5IEMzLDIwLjEgMy44OSwyMSA1LDIxIEwxOSwyMSBDMjAuMSwyMSAyMSwyMC4xIDIxLDE5IEwyMSwxMiBMMTksMTIgTDE5LDE5IFogTTE0LDMgTDE0LDUgTDE3LjU5LDUgTDcuNzYsMTQuODMgTDkuMTcsMTYuMjQgTDE5LDYuNDEgTDE5LDEwIEwyMSwxMCBMMjEsMyBMMTQsMyBaIi8+PC9zdmc+"); }
    html[data-theme="default"] .richtext .richtext--external-link:hover::before, html[data-theme="default"] .richtext .richtext--external-link:focus::before {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSIjYTAwMDE3IiBkPSJNMTksMTkgTDUsMTkgTDUsNSBMMTIsNSBMMTIsMyBMNSwzIEMzLjg5LDMgMywzLjkgMyw1IEwzLDE5IEMzLDIwLjEgMy44OSwyMSA1LDIxIEwxOSwyMSBDMjAuMSwyMSAyMSwyMC4xIDIxLDE5IEwyMSwxMiBMMTksMTIgTDE5LDE5IFogTTE0LDMgTDE0LDUgTDE3LjU5LDUgTDcuNzYsMTQuODMgTDkuMTcsMTYuMjQgTDE5LDYuNDEgTDE5LDEwIEwyMSwxMCBMMjEsMyBMMTQsMyBaIi8+PC9zdmc+"); } }
  @media screen {
    html[data-theme="association"] .richtext .richtext--external-link:active::before {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSIjYTAwMDE3IiBkPSJNMTksMTkgTDUsMTkgTDUsNSBMMTIsNSBMMTIsMyBMNSwzIEMzLjg5LDMgMywzLjkgMyw1IEwzLDE5IEMzLDIwLjEgMy44OSwyMSA1LDIxIEwxOSwyMSBDMjAuMSwyMSAyMSwyMC4xIDIxLDE5IEwyMSwxMiBMMTksMTIgTDE5LDE5IFogTTE0LDMgTDE0LDUgTDE3LjU5LDUgTDcuNzYsMTQuODMgTDkuMTcsMTYuMjQgTDE5LDYuNDEgTDE5LDEwIEwyMSwxMCBMMjEsMyBMMTQsMyBaIi8+PC9zdmc+"); }
    html[data-theme="safety"] .richtext .richtext--external-link:active::before {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSIjYWQ0MDAwIiBkPSJNMTksMTkgTDUsMTkgTDUsNSBMMTIsNSBMMTIsMyBMNSwzIEMzLjg5LDMgMywzLjkgMyw1IEwzLDE5IEMzLDIwLjEgMy44OSwyMSA1LDIxIEwxOSwyMSBDMjAuMSwyMSAyMSwyMC4xIDIxLDE5IEwyMSwxMiBMMTksMTIgTDE5LDE5IFogTTE0LDMgTDE0LDUgTDE3LjU5LDUgTDcuNzYsMTQuODMgTDkuMTcsMTYuMjQgTDE5LDYuNDEgTDE5LDEwIEwyMSwxMCBMMjEsMyBMMTQsMyBaIi8+PC9zdmc+"); }
    html[data-theme="recycling"] .richtext .richtext--external-link:active::before {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSIjMDA1NTIzIiBkPSJNMTksMTkgTDUsMTkgTDUsNSBMMTIsNSBMMTIsMyBMNSwzIEMzLjg5LDMgMywzLjkgMyw1IEwzLDE5IEMzLDIwLjEgMy44OSwyMSA1LDIxIEwxOSwyMSBDMjAuMSwyMSAyMSwyMC4xIDIxLDE5IEwyMSwxMiBMMTksMTIgTDE5LDE5IFogTTE0LDMgTDE0LDUgTDE3LjU5LDUgTDcuNzYsMTQuODMgTDkuMTcsMTYuMjQgTDE5LDYuNDEgTDE5LDEwIEwyMSwxMCBMMjEsMyBMMTQsMyBaIi8+PC9zdmc+"); }
    html[data-theme="knowledge"] .richtext .richtext--external-link:active::before {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSIjMDAzZjk3IiBkPSJNMTksMTkgTDUsMTkgTDUsNSBMMTIsNSBMMTIsMyBMNSwzIEMzLjg5LDMgMywzLjkgMyw1IEwzLDE5IEMzLDIwLjEgMy44OSwyMSA1LDIxIEwxOSwyMSBDMjAuMSwyMSAyMSwyMC4xIDIxLDE5IEwyMSwxMiBMMTksMTIgTDE5LDE5IFogTTE0LDMgTDE0LDUgTDE3LjU5LDUgTDcuNzYsMTQuODMgTDkuMTcsMTYuMjQgTDE5LDYuNDEgTDE5LDEwIEwyMSwxMCBMMjEsMyBMMTQsMyBaIi8+PC9zdmc+"); }
    html[data-theme="education"] .richtext .richtext--external-link:active::before {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSIjMzYwMDNhIiBkPSJNMTksMTkgTDUsMTkgTDUsNSBMMTIsNSBMMTIsMyBMNSwzIEMzLjg5LDMgMywzLjkgMyw1IEwzLDE5IEMzLDIwLjEgMy44OSwyMSA1LDIxIEwxOSwyMSBDMjAuMSwyMSAyMSwyMC4xIDIxLDE5IEwyMSwxMiBMMTksMTIgTDE5LDE5IFogTTE0LDMgTDE0LDUgTDE3LjU5LDUgTDcuNzYsMTQuODMgTDkuMTcsMTYuMjQgTDE5LDYuNDEgTDE5LDEwIEwyMSwxMCBMMjEsMyBMMTQsMyBaIi8+PC9zdmc+"); }
    html[data-theme="sustainability"] .richtext .richtext--external-link:active::before {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSIjMTE2MjE0IiBkPSJNMTksMTkgTDUsMTkgTDUsNSBMMTIsNSBMMTIsMyBMNSwzIEMzLjg5LDMgMywzLjkgMyw1IEwzLDE5IEMzLDIwLjEgMy44OSwyMSA1LDIxIEwxOSwyMSBDMjAuMSwyMSAyMSwyMC4xIDIxLDE5IEwyMSwxMiBMMTksMTIgTDE5LDE5IFogTTE0LDMgTDE0LDUgTDE3LjU5LDUgTDcuNzYsMTQuODMgTDkuMTcsMTYuMjQgTDE5LDYuNDEgTDE5LDEwIEwyMSwxMCBMMjEsMyBMMTQsMyBaIi8+PC9zdmc+"); }
    html[data-theme="default"] .richtext .richtext--external-link:active::before {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSIjYTAwMDE3IiBkPSJNMTksMTkgTDUsMTkgTDUsNSBMMTIsNSBMMTIsMyBMNSwzIEMzLjg5LDMgMywzLjkgMyw1IEwzLDE5IEMzLDIwLjEgMy44OSwyMSA1LDIxIEwxOSwyMSBDMjAuMSwyMSAyMSwyMC4xIDIxLDE5IEwyMSwxMiBMMTksMTIgTDE5LDE5IFogTTE0LDMgTDE0LDUgTDE3LjU5LDUgTDcuNzYsMTQuODMgTDkuMTcsMTYuMjQgTDE5LDYuNDEgTDE5LDEwIEwyMSwxMCBMMjEsMyBMMTQsMyBaIi8+PC9zdmc+"); } }
  .richtext .richtext--arrow-link::before {
    display: none; }
  .richtext .richtext--arrow-link::after {
    margin-left: 4px; }
    html[data-theme="association"] .richtext .richtext--arrow-link::after {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSIjZDkyMTNkIiBkPSJNMTUuODE0IDdsLTEuMTIgMS4xNzIgMi45MzggMi45ODhINXYxLjY4aDEyLjYzMmwtMi45MzggMi45ODhMMTUuODE0IDE3bDQuODY2LTV6Ii8+PC9zdmc+"); }
    html[data-theme="safety"] .richtext .richtext--arrow-link::after {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSIjZTY2ZTAwIiBkPSJNMTUuODE0IDdsLTEuMTIgMS4xNzIgMi45MzggMi45ODhINXYxLjY4aDEyLjYzMmwtMi45MzggMi45ODhMMTUuODE0IDE3bDQuODY2LTV6Ii8+PC9zdmc+"); }
    html[data-theme="recycling"] .richtext .richtext--arrow-link::after {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSIjMmM4MzRjIiBkPSJNMTUuODE0IDdsLTEuMTIgMS4xNzIgMi45MzggMi45ODhINXYxLjY4aDEyLjYzMmwtMi45MzggMi45ODhMMTUuODE0IDE3bDQuODY2LTV6Ii8+PC9zdmc+"); }
    html[data-theme="knowledge"] .richtext .richtext--arrow-link::after {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSIjMTQ2OWM5IiBkPSJNMTUuODE0IDdsLTEuMTIgMS4xNzIgMi45MzggMi45ODhINXYxLjY4aDEyLjYzMmwtMi45MzggMi45ODhMMTUuODE0IDE3bDQuODY2LTV6Ii8+PC9zdmc+"); }
    html[data-theme="education"] .richtext .richtext--arrow-link::after {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSIjNjMxZTY1IiBkPSJNMTUuODE0IDdsLTEuMTIgMS4xNzIgMi45MzggMi45ODhINXYxLjY4aDEyLjYzMmwtMi45MzggMi45ODhMMTUuODE0IDE3bDQuODY2LTV6Ii8+PC9zdmc+"); }
    html[data-theme="sustainability"] .richtext .richtext--arrow-link::after {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSIjMWRhMzIxIiBkPSJNMTUuODE0IDdsLTEuMTIgMS4xNzIgMi45MzggMi45ODhINXYxLjY4aDEyLjYzMmwtMi45MzggMi45ODhMMTUuODE0IDE3bDQuODY2LTV6Ii8+PC9zdmc+"); }
    html[data-theme="default"] .richtext .richtext--arrow-link::after {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSIjZDkyMTNkIiBkPSJNMTUuODE0IDdsLTEuMTIgMS4xNzIgMi45MzggMi45ODhINXYxLjY4aDEyLjYzMmwtMi45MzggMi45ODhMMTUuODE0IDE3bDQuODY2LTV6Ii8+PC9zdmc+"); }
  @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    html[data-theme="association"] .richtext .richtext--arrow-link:hover::after, html[data-theme="association"] .richtext .richtext--arrow-link:focus::after {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSIjYTAwMDE3IiBkPSJNMTUuODE0IDdsLTEuMTIgMS4xNzIgMi45MzggMi45ODhINXYxLjY4aDEyLjYzMmwtMi45MzggMi45ODhMMTUuODE0IDE3bDQuODY2LTV6Ii8+PC9zdmc+"); }
    html[data-theme="safety"] .richtext .richtext--arrow-link:hover::after, html[data-theme="safety"] .richtext .richtext--arrow-link:focus::after {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSIjYWQ0MDAwIiBkPSJNMTUuODE0IDdsLTEuMTIgMS4xNzIgMi45MzggMi45ODhINXYxLjY4aDEyLjYzMmwtMi45MzggMi45ODhMMTUuODE0IDE3bDQuODY2LTV6Ii8+PC9zdmc+"); }
    html[data-theme="recycling"] .richtext .richtext--arrow-link:hover::after, html[data-theme="recycling"] .richtext .richtext--arrow-link:focus::after {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSIjMDA1NTIzIiBkPSJNMTUuODE0IDdsLTEuMTIgMS4xNzIgMi45MzggMi45ODhINXYxLjY4aDEyLjYzMmwtMi45MzggMi45ODhMMTUuODE0IDE3bDQuODY2LTV6Ii8+PC9zdmc+"); }
    html[data-theme="knowledge"] .richtext .richtext--arrow-link:hover::after, html[data-theme="knowledge"] .richtext .richtext--arrow-link:focus::after {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSIjMDAzZjk3IiBkPSJNMTUuODE0IDdsLTEuMTIgMS4xNzIgMi45MzggMi45ODhINXYxLjY4aDEyLjYzMmwtMi45MzggMi45ODhMMTUuODE0IDE3bDQuODY2LTV6Ii8+PC9zdmc+"); }
    html[data-theme="education"] .richtext .richtext--arrow-link:hover::after, html[data-theme="education"] .richtext .richtext--arrow-link:focus::after {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSIjMzYwMDNhIiBkPSJNMTUuODE0IDdsLTEuMTIgMS4xNzIgMi45MzggMi45ODhINXYxLjY4aDEyLjYzMmwtMi45MzggMi45ODhMMTUuODE0IDE3bDQuODY2LTV6Ii8+PC9zdmc+"); }
    html[data-theme="sustainability"] .richtext .richtext--arrow-link:hover::after, html[data-theme="sustainability"] .richtext .richtext--arrow-link:focus::after {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSIjMTE2MjE0IiBkPSJNMTUuODE0IDdsLTEuMTIgMS4xNzIgMi45MzggMi45ODhINXYxLjY4aDEyLjYzMmwtMi45MzggMi45ODhMMTUuODE0IDE3bDQuODY2LTV6Ii8+PC9zdmc+"); }
    html[data-theme="default"] .richtext .richtext--arrow-link:hover::after, html[data-theme="default"] .richtext .richtext--arrow-link:focus::after {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSIjYTAwMDE3IiBkPSJNMTUuODE0IDdsLTEuMTIgMS4xNzIgMi45MzggMi45ODhINXYxLjY4aDEyLjYzMmwtMi45MzggMi45ODhMMTUuODE0IDE3bDQuODY2LTV6Ii8+PC9zdmc+"); } }
  @media screen {
    html[data-theme="association"] .richtext .richtext--arrow-link:active::after {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSIjYTAwMDE3IiBkPSJNMTUuODE0IDdsLTEuMTIgMS4xNzIgMi45MzggMi45ODhINXYxLjY4aDEyLjYzMmwtMi45MzggMi45ODhMMTUuODE0IDE3bDQuODY2LTV6Ii8+PC9zdmc+"); }
    html[data-theme="safety"] .richtext .richtext--arrow-link:active::after {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSIjYWQ0MDAwIiBkPSJNMTUuODE0IDdsLTEuMTIgMS4xNzIgMi45MzggMi45ODhINXYxLjY4aDEyLjYzMmwtMi45MzggMi45ODhMMTUuODE0IDE3bDQuODY2LTV6Ii8+PC9zdmc+"); }
    html[data-theme="recycling"] .richtext .richtext--arrow-link:active::after {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSIjMDA1NTIzIiBkPSJNMTUuODE0IDdsLTEuMTIgMS4xNzIgMi45MzggMi45ODhINXYxLjY4aDEyLjYzMmwtMi45MzggMi45ODhMMTUuODE0IDE3bDQuODY2LTV6Ii8+PC9zdmc+"); }
    html[data-theme="knowledge"] .richtext .richtext--arrow-link:active::after {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSIjMDAzZjk3IiBkPSJNMTUuODE0IDdsLTEuMTIgMS4xNzIgMi45MzggMi45ODhINXYxLjY4aDEyLjYzMmwtMi45MzggMi45ODhMMTUuODE0IDE3bDQuODY2LTV6Ii8+PC9zdmc+"); }
    html[data-theme="education"] .richtext .richtext--arrow-link:active::after {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSIjMzYwMDNhIiBkPSJNMTUuODE0IDdsLTEuMTIgMS4xNzIgMi45MzggMi45ODhINXYxLjY4aDEyLjYzMmwtMi45MzggMi45ODhMMTUuODE0IDE3bDQuODY2LTV6Ii8+PC9zdmc+"); }
    html[data-theme="sustainability"] .richtext .richtext--arrow-link:active::after {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSIjMTE2MjE0IiBkPSJNMTUuODE0IDdsLTEuMTIgMS4xNzIgMi45MzggMi45ODhINXYxLjY4aDEyLjYzMmwtMi45MzggMi45ODhMMTUuODE0IDE3bDQuODY2LTV6Ii8+PC9zdmc+"); }
    html[data-theme="default"] .richtext .richtext--arrow-link:active::after {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSIjYTAwMDE3IiBkPSJNMTUuODE0IDdsLTEuMTIgMS4xNzIgMi45MzggMi45ODhINXYxLjY4aDEyLjYzMmwtMi45MzggMi45ODhMMTUuODE0IDE3bDQuODY2LTV6Ii8+PC9zdmc+"); } }

/* stylelint-disable max-nesting-depth */
.search-results {
  padding: 40px 0 0; }
  @media (max-width: 767px) {
    .search-results {
      padding: 24px 0 0; } }
  .search-results .search-results--col {
    margin: 0 auto; }
    @media (min-width: 992px) {
      .search-results .search-results--col {
        flex: 0 0 66.66667%; }
        html.ie11 .search-results .search-results--col {
          flex-basis: auto;
          width: 66.66667%; }
        html.ie10 .search-results .search-results--col {
          flex-basis: auto;
          width: 66.66667%; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .search-results .search-results--col {
        flex: 0 0 83.33333%; }
        html.ie11 .search-results .search-results--col {
          flex-basis: auto;
          width: 83.33333%; }
        html.ie10 .search-results .search-results--col {
          flex-basis: auto;
          width: 83.33333%; } }
    @media (max-width: 767px) {
      .search-results .search-results--col {
        flex: 0 0 100%; }
        html.ie11 .search-results .search-results--col {
          flex-basis: auto;
          width: 100%; }
        html.ie10 .search-results .search-results--col {
          flex-basis: auto;
          width: 100%; } }
  .search-results .search-results--collection {
    padding: 64px 0 0; }
    @media (max-width: 767px) {
      .search-results .search-results--collection {
        padding: 48px 0 0; } }
  .search-results .search-results--item ~ .search-results--item {
    padding: 64px 0 0; }
    @media (max-width: 767px) {
      .search-results .search-results--item ~ .search-results--item {
        padding: 32px 0 0; } }
  .search-results .search-results--cite {
    margin-bottom: 8px;
    display: inline-block;
    font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
    font-weight: 400;
    font-style: normal;
    font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
    font-weight: 500;
    font-style: normal; }
    @media (min-width: 1920px) {
      .search-results .search-results--cite {
        font-size: 19px;
        line-height: 1.4; } }
    @media (min-width: 1280px) and (max-width: 1919px) {
      .search-results .search-results--cite {
        font-size: 17px;
        line-height: 1.4; } }
    @media (min-width: 992px) and (max-width: 1279px) {
      .search-results .search-results--cite {
        font-size: 15px;
        line-height: 1.4; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .search-results .search-results--cite {
        font-size: 15px;
        line-height: 1.4; } }
    @media (min-width: 544px) and (max-width: 767px) {
      .search-results .search-results--cite {
        font-size: 15px;
        line-height: 1.4; } }
    @media (min-width: 375px) and (max-width: 543px) {
      .search-results .search-results--cite {
        font-size: 15px;
        line-height: 1.4; } }
    @media (max-width: 374px) {
      .search-results .search-results--cite {
        font-size: 15px;
        line-height: 1.4; } }

.search-results .search-results--item.search-results--item__association .search-results--description > span {
  background: rgba(217, 33, 61, 0.3); }

.search-results .search-results--item.search-results--item__association .search-results--cite {
  color: #d9213d; }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .search-results .search-results--item.search-results--item__association .search-results--link:hover .search-results--cite, .search-results .search-results--item.search-results--item__association .search-results--link:focus .search-results--cite {
    color: #a00017; }
  .search-results .search-results--item.search-results--item__association .search-results--link:hover .search-results--description > span, .search-results .search-results--item.search-results--item__association .search-results--link:focus .search-results--description > span {
    background: rgba(217, 33, 61, 0.4); } }

@media screen {
  .search-results .search-results--item.search-results--item__association .search-results--link:active .search-results--cite {
    color: #a00017; }
  .search-results .search-results--item.search-results--item__association .search-results--link:active .search-results--description > span {
    background: rgba(217, 33, 61, 0.4); } }

.search-results .search-results--item.search-results--item__safety .search-results--description > span {
  background: rgba(230, 110, 0, 0.3); }

.search-results .search-results--item.search-results--item__safety .search-results--cite {
  color: #e66e00; }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .search-results .search-results--item.search-results--item__safety .search-results--link:hover .search-results--cite, .search-results .search-results--item.search-results--item__safety .search-results--link:focus .search-results--cite {
    color: #ad4000; }
  .search-results .search-results--item.search-results--item__safety .search-results--link:hover .search-results--description > span, .search-results .search-results--item.search-results--item__safety .search-results--link:focus .search-results--description > span {
    background: rgba(230, 110, 0, 0.4); } }

@media screen {
  .search-results .search-results--item.search-results--item__safety .search-results--link:active .search-results--cite {
    color: #ad4000; }
  .search-results .search-results--item.search-results--item__safety .search-results--link:active .search-results--description > span {
    background: rgba(230, 110, 0, 0.4); } }

.search-results .search-results--item.search-results--item__recycling .search-results--description > span {
  background: rgba(44, 131, 76, 0.3); }

.search-results .search-results--item.search-results--item__recycling .search-results--cite {
  color: #2c834c; }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .search-results .search-results--item.search-results--item__recycling .search-results--link:hover .search-results--cite, .search-results .search-results--item.search-results--item__recycling .search-results--link:focus .search-results--cite {
    color: #005523; }
  .search-results .search-results--item.search-results--item__recycling .search-results--link:hover .search-results--description > span, .search-results .search-results--item.search-results--item__recycling .search-results--link:focus .search-results--description > span {
    background: rgba(44, 131, 76, 0.4); } }

@media screen {
  .search-results .search-results--item.search-results--item__recycling .search-results--link:active .search-results--cite {
    color: #005523; }
  .search-results .search-results--item.search-results--item__recycling .search-results--link:active .search-results--description > span {
    background: rgba(44, 131, 76, 0.4); } }

.search-results .search-results--item.search-results--item__knowledge .search-results--description > span {
  background: rgba(20, 105, 201, 0.3); }

.search-results .search-results--item.search-results--item__knowledge .search-results--cite {
  color: #1469c9; }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .search-results .search-results--item.search-results--item__knowledge .search-results--link:hover .search-results--cite, .search-results .search-results--item.search-results--item__knowledge .search-results--link:focus .search-results--cite {
    color: #003f97; }
  .search-results .search-results--item.search-results--item__knowledge .search-results--link:hover .search-results--description > span, .search-results .search-results--item.search-results--item__knowledge .search-results--link:focus .search-results--description > span {
    background: rgba(20, 105, 201, 0.4); } }

@media screen {
  .search-results .search-results--item.search-results--item__knowledge .search-results--link:active .search-results--cite {
    color: #003f97; }
  .search-results .search-results--item.search-results--item__knowledge .search-results--link:active .search-results--description > span {
    background: rgba(20, 105, 201, 0.4); } }

.search-results .search-results--item.search-results--item__education .search-results--description > span {
  background: rgba(99, 30, 101, 0.3); }

.search-results .search-results--item.search-results--item__education .search-results--cite {
  color: #631e65; }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .search-results .search-results--item.search-results--item__education .search-results--link:hover .search-results--cite, .search-results .search-results--item.search-results--item__education .search-results--link:focus .search-results--cite {
    color: #36003a; }
  .search-results .search-results--item.search-results--item__education .search-results--link:hover .search-results--description > span, .search-results .search-results--item.search-results--item__education .search-results--link:focus .search-results--description > span {
    background: rgba(99, 30, 101, 0.4); } }

@media screen {
  .search-results .search-results--item.search-results--item__education .search-results--link:active .search-results--cite {
    color: #36003a; }
  .search-results .search-results--item.search-results--item__education .search-results--link:active .search-results--description > span {
    background: rgba(99, 30, 101, 0.4); } }

.search-results .search-results--item.search-results--item__sustainability .search-results--description > span {
  background: rgba(29, 163, 33, 0.3); }

.search-results .search-results--item.search-results--item__sustainability .search-results--cite {
  color: #1da321; }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .search-results .search-results--item.search-results--item__sustainability .search-results--link:hover .search-results--cite, .search-results .search-results--item.search-results--item__sustainability .search-results--link:focus .search-results--cite {
    color: #116214; }
  .search-results .search-results--item.search-results--item__sustainability .search-results--link:hover .search-results--description > span, .search-results .search-results--item.search-results--item__sustainability .search-results--link:focus .search-results--description > span {
    background: rgba(29, 163, 33, 0.4); } }

@media screen {
  .search-results .search-results--item.search-results--item__sustainability .search-results--link:active .search-results--cite {
    color: #116214; }
  .search-results .search-results--item.search-results--item__sustainability .search-results--link:active .search-results--description > span {
    background: rgba(29, 163, 33, 0.4); } }

.search-results .search-results--item.search-results--item__default .search-results--description > span {
  background: rgba(217, 33, 61, 0.3); }

.search-results .search-results--item.search-results--item__default .search-results--cite {
  color: #d9213d; }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .search-results .search-results--item.search-results--item__default .search-results--link:hover .search-results--cite, .search-results .search-results--item.search-results--item__default .search-results--link:focus .search-results--cite {
    color: #a00017; }
  .search-results .search-results--item.search-results--item__default .search-results--link:hover .search-results--description > span, .search-results .search-results--item.search-results--item__default .search-results--link:focus .search-results--description > span {
    background: rgba(217, 33, 61, 0.4); } }

@media screen {
  .search-results .search-results--item.search-results--item__default .search-results--link:active .search-results--cite {
    color: #a00017; }
  .search-results .search-results--item.search-results--item__default .search-results--link:active .search-results--description > span {
    background: rgba(217, 33, 61, 0.4); } }

.simple-card {
  padding: 24px;
  height: 100%; }
  @media (max-width: 543px) {
    .simple-card {
      padding: 16px; } }
  .simple-card .simple-card--content {
    display: flex;
    flex-direction: column;
    height: 100%; }
  .simple-card .simple-card--title {
    margin-bottom: 24px;
    color: #111111;
    font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
    font-weight: 500;
    font-style: normal; }
    @media (min-width: 1920px) {
      .simple-card .simple-card--title {
        font-size: 21px;
        line-height: 1.25; } }
    @media (min-width: 1280px) and (max-width: 1919px) {
      .simple-card .simple-card--title {
        font-size: 19px;
        line-height: 1.3; } }
    @media (min-width: 992px) and (max-width: 1279px) {
      .simple-card .simple-card--title {
        font-size: 17px;
        line-height: 1.3; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .simple-card .simple-card--title {
        font-size: 17px;
        line-height: 1.3; } }
    @media (min-width: 544px) and (max-width: 767px) {
      .simple-card .simple-card--title {
        font-size: 17px;
        line-height: 1.3; } }
    @media (min-width: 375px) and (max-width: 543px) {
      .simple-card .simple-card--title {
        font-size: 17px;
        line-height: 1.3; } }
    @media (max-width: 374px) {
      .simple-card .simple-card--title {
        font-size: 17px;
        line-height: 1.4; } }
  .simple-card .simple-card--paragraph {
    margin-bottom: 16px;
    color: #555555;
    font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
    font-weight: 400;
    font-style: normal; }
    @media (min-width: 1920px) {
      .simple-card .simple-card--paragraph {
        font-size: 19px;
        line-height: 1.4; } }
    @media (min-width: 1280px) and (max-width: 1919px) {
      .simple-card .simple-card--paragraph {
        font-size: 17px;
        line-height: 1.4; } }
    @media (min-width: 992px) and (max-width: 1279px) {
      .simple-card .simple-card--paragraph {
        font-size: 15px;
        line-height: 1.4; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .simple-card .simple-card--paragraph {
        font-size: 15px;
        line-height: 1.4; } }
    @media (min-width: 544px) and (max-width: 767px) {
      .simple-card .simple-card--paragraph {
        font-size: 15px;
        line-height: 1.4; } }
    @media (min-width: 375px) and (max-width: 543px) {
      .simple-card .simple-card--paragraph {
        font-size: 15px;
        line-height: 1.4; } }
    @media (max-width: 374px) {
      .simple-card .simple-card--paragraph {
        font-size: 15px;
        line-height: 1.4; } }
  .simple-card .simple-card--icon {
    margin-top: auto; }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .simple-card.simple-card__association:hover .simple-card--icon, .simple-card.simple-card__association:focus .simple-card--icon {
    color: #a00017; } }

@media screen {
  .simple-card.simple-card__association:active .simple-card--icon {
    color: #a00017; } }

.simple-card.simple-card__association .simple-card--icon {
  color: #d9213d; }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .simple-card.simple-card__safety:hover .simple-card--icon, .simple-card.simple-card__safety:focus .simple-card--icon {
    color: #ad4000; } }

@media screen {
  .simple-card.simple-card__safety:active .simple-card--icon {
    color: #ad4000; } }

.simple-card.simple-card__safety .simple-card--icon {
  color: #e66e00; }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .simple-card.simple-card__recycling:hover .simple-card--icon, .simple-card.simple-card__recycling:focus .simple-card--icon {
    color: #005523; } }

@media screen {
  .simple-card.simple-card__recycling:active .simple-card--icon {
    color: #005523; } }

.simple-card.simple-card__recycling .simple-card--icon {
  color: #2c834c; }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .simple-card.simple-card__knowledge:hover .simple-card--icon, .simple-card.simple-card__knowledge:focus .simple-card--icon {
    color: #003f97; } }

@media screen {
  .simple-card.simple-card__knowledge:active .simple-card--icon {
    color: #003f97; } }

.simple-card.simple-card__knowledge .simple-card--icon {
  color: #1469c9; }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .simple-card.simple-card__education:hover .simple-card--icon, .simple-card.simple-card__education:focus .simple-card--icon {
    color: #36003a; } }

@media screen {
  .simple-card.simple-card__education:active .simple-card--icon {
    color: #36003a; } }

.simple-card.simple-card__education .simple-card--icon {
  color: #631e65; }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .simple-card.simple-card__sustainability:hover .simple-card--icon, .simple-card.simple-card__sustainability:focus .simple-card--icon {
    color: #116214; } }

@media screen {
  .simple-card.simple-card__sustainability:active .simple-card--icon {
    color: #116214; } }

.simple-card.simple-card__sustainability .simple-card--icon {
  color: #1da321; }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .simple-card.simple-card__default:hover .simple-card--icon, .simple-card.simple-card__default:focus .simple-card--icon {
    color: #a00017; } }

@media screen {
  .simple-card.simple-card__default:active .simple-card--icon {
    color: #a00017; } }

.simple-card.simple-card__default .simple-card--icon {
  color: #d9213d; }

.skip-to-content {
  position: absolute;
  overflow: hidden;
  max-width: 1px;
  max-height: 1px;
  padding: 0;
  border: 0;
  margin: -1px;
  clip: rect(0 0 0 0); }
  @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .skip-to-content:hover, .skip-to-content:focus {
      top: 0;
      left: 0;
      max-width: initial;
      max-height: initial;
      padding: 16px;
      border: 0;
      margin: auto;
      clip: initial;
      background: #d9213d;
      color: #ffffff;
      font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
      font-weight: 500;
      font-style: normal; } }
  @media screen {
    .skip-to-content:active {
      top: 0;
      left: 0;
      max-width: initial;
      max-height: initial;
      padding: 16px;
      border: 0;
      margin: auto;
      clip: initial;
      background: #d9213d;
      color: #ffffff;
      font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
      font-weight: 500;
      font-style: normal; } }

.social-list {
  width: 100%; }
  .social-list .social-list--title {
    font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
    font-weight: 700;
    font-style: italic;
    color: #111111;
    font-size: 1.2rem;
    line-height: 2.17;
    margin-bottom: 16px; }
  .social-list .social-list--list-item {
    display: inline-block;
    color: #555555; }
    @media (min-width: 768px) {
      .social-list .social-list--list-item {
        padding-right: 24px; } }
    @media (min-width: 544px) and (max-width: 767px) {
      .social-list .social-list--list-item {
        padding-right: 18px; } }
    @media (max-width: 543px) {
      .social-list .social-list--list-item {
        padding-right: 10%; } }
    .social-list .social-list--list-item:last-child {
      padding-right: 0; }

.social-list.social-list__association {
  margin-bottom: 48px; }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .social-list.social-list__association .social-list--list-item:hover, .social-list.social-list__association .social-list--list-item:focus {
    color: #a00017; } }

@media screen {
  .social-list.social-list__association .social-list--list-item:active {
    color: #a00017; } }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .social-list.social-list__safety .social-list--list-item:hover, .social-list.social-list__safety .social-list--list-item:focus {
    color: #ad4000; } }

@media screen {
  .social-list.social-list__safety .social-list--list-item:active {
    color: #ad4000; } }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .social-list.social-list__recycling .social-list--list-item:hover, .social-list.social-list__recycling .social-list--list-item:focus {
    color: #005523; } }

@media screen {
  .social-list.social-list__recycling .social-list--list-item:active {
    color: #005523; } }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .social-list.social-list__knowledge .social-list--list-item:hover, .social-list.social-list__knowledge .social-list--list-item:focus {
    color: #003f97; } }

@media screen {
  .social-list.social-list__knowledge .social-list--list-item:active {
    color: #003f97; } }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .social-list.social-list__education .social-list--list-item:hover, .social-list.social-list__education .social-list--list-item:focus {
    color: #36003a; } }

@media screen {
  .social-list.social-list__education .social-list--list-item:active {
    color: #36003a; } }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .social-list.social-list__sustainability .social-list--list-item:hover, .social-list.social-list__sustainability .social-list--list-item:focus {
    color: #116214; } }

@media screen {
  .social-list.social-list__sustainability .social-list--list-item:active {
    color: #116214; } }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .social-list.social-list__default .social-list--list-item:hover, .social-list.social-list__default .social-list--list-item:focus {
    color: #a00017; } }

@media screen {
  .social-list.social-list__default .social-list--list-item:active {
    color: #a00017; } }

.tag-list .tag-list--container {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  margin: auto; }

.tag-list .tag-list--collection {
  display: flex;
  flex-flow: row wrap; }
  html.ie11 .tag-list .tag-list--collection {
    min-height: 1px; }

.tag-list .tag-list--item {
  position: relative;
  min-height: 1px;
  max-width: 100%; }

.tag-list .tag-list--container {
  padding-top: 16px;
  padding-left: 16px;
  padding-bottom: 16px;
  padding-right: 16px; }
  .tag-list .tag-list--container .tag-list--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important; }
    .tag-list .tag-list--container .tag-list--container:not(.u-iknowwhatimdoing)::before {
      visibility: visible;
      content: 'Nested `grid--container` detected!';
      font-size: 20px;
      line-height: 1.2;
      color: red; }

.tag-list .tag-list--collection {
  margin-top: -8px;
  margin-left: -8px;
  margin-bottom: -8px;
  margin-right: -8px; }

.tag-list .tag-list--item {
  padding-top: 8px;
  padding-left: 8px;
  padding-bottom: 8px;
  padding-right: 8px; }

.tag-list .tag-list--nav {
  display: flex;
  flex-wrap: wrap; }
  @media (max-width: 991px) {
    .tag-list .tag-list--nav {
      display: none; } }

.tag-list .tag-list--collection {
  flex: 1 1 100%; }

.tag-list .tag-list--dropdown {
  position: relative;
  display: none; }
  @media (max-width: 991px) {
    .tag-list .tag-list--dropdown {
      display: flex; } }
  @media (max-width: 543px) {
    .tag-list .tag-list--dropdown {
      justify-content: center; } }

.tag-list .tag-list--link {
  color: #555555;
  padding: 8px 16px;
  border-radius: 16px;
  font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
  font-weight: 500;
  font-style: normal; }
  @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .tag-list .tag-list--link:hover, .tag-list .tag-list--link:focus {
      color: #111111; } }
  @media screen {
    .tag-list .tag-list--link:active {
      color: #111111; } }

.tag-list .tag-list--link.tag-list--link__active {
  background: #f1f1f2; }

.team-card {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  padding: 32px 16px;
  transition: box-shadow 200ms ease;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09), 0 1px 2px rgba(0, 0, 0, 0.2);
  text-align: center;
  border-radius: 4px;
  overflow: hidden; }
  .team-card:focus, .team-card:hover {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 3px 6px 0 rgba(0, 0, 0, 0.12); }
    .team-card:focus .team-card--figure-image-overlay, .team-card:hover .team-card--figure-image-overlay {
      visibility: hidden;
      opacity: 0;
      transition-duration: 200ms; }
    .team-card:focus .team-card--figure, .team-card:hover .team-card--figure {
      transform: scale(1); }
    .team-card:focus .team-card--phone,
    .team-card:focus .team-card--link,
    .team-card:focus .team-card--small-text,
    .team-card:focus .team-card--tail,
    .team-card:focus .team-card--name, .team-card:hover .team-card--phone,
    .team-card:hover .team-card--link,
    .team-card:hover .team-card--small-text,
    .team-card:hover .team-card--tail,
    .team-card:hover .team-card--name {
      opacity: 1;
      transform: translate(0, 0); }
  .team-card:focus-within {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 3px 6px 0 rgba(0, 0, 0, 0.12); }
    .team-card:focus-within .team-card--figure-image-overlay {
      visibility: hidden;
      opacity: 0;
      transition-duration: 200ms; }
    .team-card:focus-within .team-card--figure {
      transform: scale(1); }
    .team-card:focus-within .team-card--phone,
    .team-card:focus-within .team-card--link,
    .team-card:focus-within .team-card--small-text,
    .team-card:focus-within .team-card--tail,
    .team-card:focus-within .team-card--name {
      opacity: 1;
      transform: translate(0, 0); }
  @media (min-width: 992px) and (max-width: 1279px) {
    .team-card {
      min-height: 391px; } }
  @media (max-width: 543px) {
    .team-card {
      min-height: 100vw; } }

.team-card .team-card--figure-image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background: #ffffff;
  transition: visibility ease 0, opacity ease 0; }

.team-card .team-card--image-overlay {
  pointer-events: none;
  border-radius: 4px;
  position: absolute;
  left: 50%;
  top: 50%;
  height: 100%;
  width: auto;
  transform: translate(-50%, -50%);
  object-position: 50% 0; }
  @supports (object-fit: cover) {
    .team-card .team-card--image-overlay {
      width: 101%;
      object-fit: cover; } }

.team-card .team-card--image {
  display: inline-block;
  width: 100%; }

.team-card .team-card--info {
  display: flex;
  flex-direction: column; }

.team-card .team-card--figure {
  border-radius: 100%;
  overflow: hidden;
  margin: 0 auto;
  margin-bottom: 16px;
  transform: scale(0.9);
  transition: transform 200ms ease; }
  @media (min-width: 544px) {
    .team-card .team-card--figure {
      flex: 0 0 112px;
      width: 112px;
      height: 112px; } }
  @media (max-width: 543px) {
    .team-card .team-card--figure {
      flex: 0 0 144px;
      width: 144px;
      height: 144px; } }

.team-card .team-card--name {
  transition: opacity 200ms ease, transform 200ms ease;
  opacity: 0;
  transform: translate(0, 16px); }

.team-card .team-card--description {
  font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal; }
  @media (min-width: 1920px) {
    .team-card .team-card--description {
      font-size: 19px;
      line-height: 1.4; } }
  @media (min-width: 1280px) and (max-width: 1919px) {
    .team-card .team-card--description {
      font-size: 17px;
      line-height: 1.4; } }
  @media (min-width: 992px) and (max-width: 1279px) {
    .team-card .team-card--description {
      font-size: 15px;
      line-height: 1.4; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .team-card .team-card--description {
      font-size: 15px;
      line-height: 1.4; } }
  @media (min-width: 544px) and (max-width: 767px) {
    .team-card .team-card--description {
      font-size: 15px;
      line-height: 1.4; } }
  @media (min-width: 375px) and (max-width: 543px) {
    .team-card .team-card--description {
      font-size: 15px;
      line-height: 1.4; } }
  @media (max-width: 374px) {
    .team-card .team-card--description {
      font-size: 15px;
      line-height: 1.4; } }

.team-card .team-card--small-text {
  margin-bottom: 8px;
  display: inline-block;
  transition: opacity ease 250ms 50ms, transform ease 250ms 50ms;
  opacity: 0;
  transform: translate(0, 16px);
  font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal; }
  @media (min-width: 1920px) {
    .team-card .team-card--small-text {
      font-size: 17px;
      line-height: 1.4; } }
  @media (min-width: 1280px) and (max-width: 1919px) {
    .team-card .team-card--small-text {
      font-size: 15px;
      line-height: 1.4; } }
  @media (min-width: 992px) and (max-width: 1279px) {
    .team-card .team-card--small-text {
      font-size: 13px;
      line-height: 1.4; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .team-card .team-card--small-text {
      font-size: 13px;
      line-height: 1.4; } }
  @media (min-width: 544px) and (max-width: 767px) {
    .team-card .team-card--small-text {
      font-size: 13px;
      line-height: 1.4; } }
  @media (min-width: 375px) and (max-width: 543px) {
    .team-card .team-card--small-text {
      font-size: 13px;
      line-height: 1.4; } }
  @media (max-width: 374px) {
    .team-card .team-card--small-text {
      font-size: 13px;
      line-height: 1.4; } }

.team-card .team-card--phone {
  display: inline-block;
  transition: opacity ease 250ms 100ms, transform ease 250ms 100ms;
  opacity: 0;
  transform: translate(0, 16px); }

.team-card .team-card--link {
  display: inline-block;
  transition: opacity ease 250ms 125ms, transform ease 250ms 125ms;
  font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
  font-weight: 500;
  font-style: normal;
  opacity: 0;
  transform: translate(0, 16px); }

.team-card .team-card--tail {
  margin-top: auto;
  color: #111111;
  transition: opacity ease 250ms 50ms, transform ease 250ms 50ms;
  opacity: 0;
  transform: translate(0, 16px);
  font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
  font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
  font-weight: 500;
  font-style: normal; }
  @media (min-width: 1920px) {
    .team-card .team-card--tail {
      font-size: 19px;
      line-height: 1.4; } }
  @media (min-width: 1280px) and (max-width: 1919px) {
    .team-card .team-card--tail {
      font-size: 17px;
      line-height: 1.4; } }
  @media (min-width: 992px) and (max-width: 1279px) {
    .team-card .team-card--tail {
      font-size: 15px;
      line-height: 1.4; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .team-card .team-card--tail {
      font-size: 15px;
      line-height: 1.4; } }
  @media (min-width: 544px) and (max-width: 767px) {
    .team-card .team-card--tail {
      font-size: 15px;
      line-height: 1.4; } }
  @media (min-width: 375px) and (max-width: 543px) {
    .team-card .team-card--tail {
      font-size: 15px;
      line-height: 1.4; } }
  @media (max-width: 374px) {
    .team-card .team-card--tail {
      font-size: 15px;
      line-height: 1.4; } }

.team-card.team-card__focused {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 3px 6px 0 rgba(0, 0, 0, 0.12); }
  .team-card.team-card__focused .team-card--figure-image-overlay {
    visibility: hidden;
    opacity: 0;
    transition-duration: 200ms; }
  .team-card.team-card__focused .team-card--figure {
    transform: scale(1); }
  .team-card.team-card__focused .team-card--phone,
  .team-card.team-card__focused .team-card--link,
  .team-card.team-card__focused .team-card--small-text,
  .team-card.team-card__focused .team-card--tail,
  .team-card.team-card__focused .team-card--name {
    opacity: 1;
    transform: translate(0, 0); }

.team-list .team-list--container {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  margin: auto; }

.team-list .team-list--collection {
  display: flex;
  flex-flow: row wrap; }
  html.ie11 .team-list .team-list--collection {
    min-height: 1px; }

.team-list .team-list--item {
  position: relative;
  min-height: 1px;
  max-width: 100%; }

@media (min-width: 992px) {
  .team-list .team-list--container {
    padding-top: 32px;
    padding-left: 0;
    padding-bottom: 32px;
    padding-right: 0; }
    .team-list .team-list--container .team-list--container:not(.u-iknowwhatimdoing) {
      visibility: hidden !important; }
      .team-list .team-list--container .team-list--container:not(.u-iknowwhatimdoing)::before {
        visibility: visible;
        content: 'Nested `grid--container` detected!';
        font-size: 20px;
        line-height: 1.2;
        color: red; }
  .team-list .team-list--collection {
    margin-top: -16px;
    margin-left: 0;
    margin-bottom: -16px;
    margin-right: 0; }
  .team-list .team-list--item {
    padding-top: 16px;
    padding-left: 0;
    padding-bottom: 16px;
    padding-right: 0; } }

@media (max-width: 991px) {
  .team-list .team-list--container {
    padding-top: 16px;
    padding-left: 0;
    padding-bottom: 16px;
    padding-right: 0; }
    .team-list .team-list--container .team-list--container:not(.u-iknowwhatimdoing) {
      visibility: hidden !important; }
      .team-list .team-list--container .team-list--container:not(.u-iknowwhatimdoing)::before {
        visibility: visible;
        content: 'Nested `grid--container` detected!';
        font-size: 20px;
        line-height: 1.2;
        color: red; }
  .team-list .team-list--collection {
    margin-top: -8px;
    margin-left: 0;
    margin-bottom: -8px;
    margin-right: 0; }
  .team-list .team-list--item {
    padding-top: 8px;
    padding-left: 0;
    padding-bottom: 8px;
    padding-right: 0; } }

.team-list .team-list--title {
  padding-top: 64px; }
  @media (max-width: 991px) {
    .team-list .team-list--title {
      padding-top: 48px; } }

.team-list .team-list--title,
.team-list .team-list--description {
  margin-bottom: 24px; }

.team-list .team-list--item {
  display: flex;
  flex-direction: column;
  overflow: hidden; }
  @media (min-width: 1280px) {
    .team-list .team-list--item {
      flex: 0 0 33.33333%; }
      html.ie11 .team-list .team-list--item {
        flex-basis: auto;
        width: 33.33333%; }
      html.ie10 .team-list .team-list--item {
        flex-basis: auto;
        width: 33.33333%; } }
  @media (min-width: 768px) and (max-width: 991px), (min-width: 544px) and (max-width: 767px), (min-width: 992px) and (max-width: 1279px) {
    .team-list .team-list--item {
      flex: 0 0 50%; }
      html.ie11 .team-list .team-list--item {
        flex-basis: auto;
        width: 50%; }
      html.ie10 .team-list .team-list--item {
        flex-basis: auto;
        width: 50%; } }
  @media (max-width: 543px) {
    .team-list .team-list--item {
      flex: 0 0 100%; }
      html.ie11 .team-list .team-list--item {
        flex-basis: auto;
        width: 100%; }
      html.ie10 .team-list .team-list--item {
        flex-basis: auto;
        width: 100%; } }

.teaser-area .teaser-area--container {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  margin: auto; }

.teaser-area .teaser-area--collection {
  display: flex;
  flex-flow: row wrap; }
  html.ie11 .teaser-area .teaser-area--collection {
    min-height: 1px; }

.teaser-area .teaser-area--item {
  position: relative;
  min-height: 1px;
  max-width: 100%; }

.teaser-area .teaser-area--container {
  padding-left: 0;
  padding-right: 0; }
  .teaser-area .teaser-area--container .teaser-area--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important; }
    .teaser-area .teaser-area--container .teaser-area--container:not(.u-iknowwhatimdoing)::before {
      visibility: visible;
      content: 'Nested `grid--container` detected!';
      font-size: 20px;
      line-height: 1.2;
      color: red; }

.teaser-area .teaser-area--collection {
  margin-left: 0;
  margin-right: 0; }

.teaser-area .teaser-area--item {
  padding-left: 0;
  padding-right: 0; }

.teaser-area .teaser-area--main-title {
  color: #111111;
  font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
  font-weight: 500;
  font-style: normal;
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 0 auto 24px;
  color: #111111;
  font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
  font-weight: 500;
  font-style: normal; }
  @media (min-width: 1920px) {
    .teaser-area .teaser-area--main-title {
      font-size: 36px;
      line-height: 1.25; } }
  @media (min-width: 1280px) and (max-width: 1919px) {
    .teaser-area .teaser-area--main-title {
      font-size: 35px;
      line-height: 1.25; } }
  @media (min-width: 992px) and (max-width: 1279px) {
    .teaser-area .teaser-area--main-title {
      font-size: 30px;
      line-height: 1.25; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .teaser-area .teaser-area--main-title {
      font-size: 28px;
      line-height: 1.25; } }
  @media (min-width: 544px) and (max-width: 767px) {
    .teaser-area .teaser-area--main-title {
      font-size: 27px;
      line-height: 1.25; } }
  @media (min-width: 375px) and (max-width: 543px) {
    .teaser-area .teaser-area--main-title {
      font-size: 26px;
      line-height: 1.25; } }
  @media (max-width: 374px) {
    .teaser-area .teaser-area--main-title {
      font-size: 25px;
      line-height: 1.25; } }
  @media (min-width: 1920px) {
    .teaser-area .teaser-area--main-title {
      font-size: 36px;
      line-height: 1.25; } }
  @media (min-width: 1280px) and (max-width: 1919px) {
    .teaser-area .teaser-area--main-title {
      font-size: 35px;
      line-height: 1.25; } }
  @media (min-width: 992px) and (max-width: 1279px) {
    .teaser-area .teaser-area--main-title {
      font-size: 30px;
      line-height: 1.25; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .teaser-area .teaser-area--main-title {
      font-size: 28px;
      line-height: 1.25; } }
  @media (min-width: 544px) and (max-width: 767px) {
    .teaser-area .teaser-area--main-title {
      font-size: 27px;
      line-height: 1.25; } }
  @media (min-width: 375px) and (max-width: 543px) {
    .teaser-area .teaser-area--main-title {
      font-size: 26px;
      line-height: 1.25; } }
  @media (max-width: 374px) {
    .teaser-area .teaser-area--main-title {
      font-size: 25px;
      line-height: 1.25; } }
  @media (min-width: 768px) {
    .teaser-area .teaser-area--main-title {
      flex: 0 0 66.66667%; }
      html.ie11 .teaser-area .teaser-area--main-title {
        flex-basis: auto;
        width: 66.66667%; }
      html.ie10 .teaser-area .teaser-area--main-title {
        flex-basis: auto;
        width: 66.66667%; } }
  @media (max-width: 767px) {
    .teaser-area .teaser-area--main-title {
      flex: 0 0 100%; }
      html.ie11 .teaser-area .teaser-area--main-title {
        flex-basis: auto;
        width: 100%; }
      html.ie10 .teaser-area .teaser-area--main-title {
        flex-basis: auto;
        width: 100%; } }

.teaser-area .teaser-area--collection {
  overflow: hidden;
  border-radius: 4px;
  box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.08), 0 8px 16px 0 rgba(0, 0, 0, 0.12);
  transition: box-shadow 200ms ease; }
  @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .teaser-area .teaser-area--collection:hover, .teaser-area .teaser-area--collection:focus {
      box-shadow: 0 20px 32px 0 rgba(0, 0, 0, 0.2), 0 12px 16px 0 rgba(0, 0, 0, 0.12); } }
  @media screen {
    .teaser-area .teaser-area--collection:active {
      box-shadow: 0 20px 32px 0 rgba(0, 0, 0, 0.2), 0 12px 16px 0 rgba(0, 0, 0, 0.12); } }

.teaser-area .teaser-area--item {
  position: relative;
  display: flex;
  background: #ffffff; }
  @media (min-width: 992px) {
    .teaser-area .teaser-area--item {
      flex: 0 0 25%; }
      html.ie11 .teaser-area .teaser-area--item {
        flex-basis: auto;
        width: 25%; }
      html.ie10 .teaser-area .teaser-area--item {
        flex-basis: auto;
        width: 25%; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .teaser-area .teaser-area--item {
      flex: 0 0 50%; }
      html.ie11 .teaser-area .teaser-area--item {
        flex-basis: auto;
        width: 50%; }
      html.ie10 .teaser-area .teaser-area--item {
        flex-basis: auto;
        width: 50%; } }
  @media (max-width: 767px) {
    .teaser-area .teaser-area--item {
      flex: 0 0 100%; }
      html.ie11 .teaser-area .teaser-area--item {
        flex-basis: auto;
        width: 100%; }
      html.ie10 .teaser-area .teaser-area--item {
        flex-basis: auto;
        width: 100%; } }
  @media (min-width: 992px) {
    .teaser-area .teaser-area--item {
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      border-right: 1px solid rgba(0, 0, 0, 0.05); }
      .teaser-area .teaser-area--item:nth-child(4n + 1):nth-last-child(-n + 4),
      .teaser-area .teaser-area--item:nth-child(4n + 1):nth-last-child(-n + 4) ~ .teaser-area--item {
        border-bottom: none; }
      .teaser-area .teaser-area--item:nth-child(4n+0) {
        border-right: none; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .teaser-area .teaser-area--item {
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      border-right: 1px solid rgba(0, 0, 0, 0.05); }
      .teaser-area .teaser-area--item:nth-child(2n + 1):nth-last-child(-n + 2),
      .teaser-area .teaser-area--item:nth-child(2n + 1):nth-last-child(-n + 2) ~ .teaser-area--item {
        border-bottom: none; }
      .teaser-area .teaser-area--item:nth-child(2n+0) {
        border-right: none; } }
  @media (max-width: 767px) {
    .teaser-area .teaser-area--item {
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      border-right: none; }
      .teaser-area .teaser-area--item:last-child {
        border-bottom: none; } }
  .teaser-area .teaser-area--item::before {
    content: '';
    z-index: 1;
    position: absolute;
    display: block; }
  @media (min-width: 768px) {
    .teaser-area .teaser-area--item::before {
      top: 0;
      left: 0;
      height: 4px;
      width: calc(100% + 1px); } }
  @media (min-width: 768px) and (max-width: 991px) {
    .teaser-area .teaser-area--item:nth-last-child(-n+2)::before {
      top: inherit;
      bottom: 0;
      left: 0;
      width: calc(100% + 1px); } }
  @media (max-width: 767px) {
    .teaser-area .teaser-area--item::before {
      top: 0;
      left: 0;
      width: 4px;
      height: calc(100% + 1px); } }

.teaser-area .teaser-area--description {
  color: #555555;
  margin-bottom: 48px;
  font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal; }
  @media (min-width: 1920px) {
    .teaser-area .teaser-area--description {
      font-size: 19px;
      line-height: 1.4; } }
  @media (min-width: 1280px) and (max-width: 1919px) {
    .teaser-area .teaser-area--description {
      font-size: 17px;
      line-height: 1.4; } }
  @media (min-width: 992px) and (max-width: 1279px) {
    .teaser-area .teaser-area--description {
      font-size: 15px;
      line-height: 1.4; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .teaser-area .teaser-area--description {
      font-size: 15px;
      line-height: 1.4; } }
  @media (min-width: 544px) and (max-width: 767px) {
    .teaser-area .teaser-area--description {
      font-size: 15px;
      line-height: 1.4; } }
  @media (min-width: 375px) and (max-width: 543px) {
    .teaser-area .teaser-area--description {
      font-size: 15px;
      line-height: 1.4; } }
  @media (max-width: 374px) {
    .teaser-area .teaser-area--description {
      font-size: 15px;
      line-height: 1.4; } }
  @media (max-width: 767px) {
    .teaser-area .teaser-area--description {
      display: none; } }
  @media print {
    .teaser-area .teaser-area--description {
      display: block;
      margin-bottom: 0; } }

@media (min-width: 768px) {
  .teaser-area .teaser-area--title {
    margin-bottom: 24px; } }

@media (min-width: 768px) {
  .teaser-area .teaser-area--icon {
    margin-top: auto; } }

@media (max-width: 767px) {
  .teaser-area .teaser-area--icon {
    padding-left: 8px;
    margin-left: auto;
    margin-right: 0; } }

@media print {
  .teaser-area .teaser-area--icon {
    display: none; } }

.teaser-area .teaser-area--link {
  display: flex;
  flex: 1 1 100%;
  min-width: 100%; }
  @media print {
    .teaser-area .teaser-area--link {
      display: block; } }
  @media (min-width: 768px) {
    .teaser-area .teaser-area--link {
      padding: 40px 24px 24px;
      flex-direction: column; } }
  @media (max-width: 767px) {
    .teaser-area .teaser-area--link {
      align-items: center;
      justify-content: space-between;
      padding: 16px 24px; } }
  @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .teaser-area .teaser-area--link:hover, .teaser-area .teaser-area--link:focus {
      color: #ffffff; } }
  @media screen {
    .teaser-area .teaser-area--link:active {
      color: #ffffff; } }

.teaser-area {
  /* stylelint-disable max-nesting-depth */
  /* stylelint-enable max-nesting-depth */ }
  .teaser-area .teaser-area--item.teaser-area--item__association::before {
    background: #d9213d; }
  .teaser-area .teaser-area--item.teaser-area--item__association .teaser-area--icon {
    color: #d9213d; }
  @media (max-width: 767px) {
    .teaser-area .teaser-area--item.teaser-area--item__association .teaser-area--link {
      color: #d9213d; } }
  @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .teaser-area .teaser-area--item.teaser-area--item__association .teaser-area--link:hover, .teaser-area .teaser-area--item.teaser-area--item__association .teaser-area--link:focus {
      background: #d9213d; }
      .teaser-area .teaser-area--item.teaser-area--item__association .teaser-area--link:hover .teaser-area--description,
      .teaser-area .teaser-area--item.teaser-area--item__association .teaser-area--link:hover .teaser-area--title,
      .teaser-area .teaser-area--item.teaser-area--item__association .teaser-area--link:hover .teaser-area--icon, .teaser-area .teaser-area--item.teaser-area--item__association .teaser-area--link:focus .teaser-area--description,
      .teaser-area .teaser-area--item.teaser-area--item__association .teaser-area--link:focus .teaser-area--title,
      .teaser-area .teaser-area--item.teaser-area--item__association .teaser-area--link:focus .teaser-area--icon {
        color: #ffffff; } }
  @media screen {
    .teaser-area .teaser-area--item.teaser-area--item__association .teaser-area--link:active {
      background: #d9213d; }
      .teaser-area .teaser-area--item.teaser-area--item__association .teaser-area--link:active .teaser-area--description,
      .teaser-area .teaser-area--item.teaser-area--item__association .teaser-area--link:active .teaser-area--title,
      .teaser-area .teaser-area--item.teaser-area--item__association .teaser-area--link:active .teaser-area--icon {
        color: #ffffff; } }
  .teaser-area .teaser-area--item.teaser-area--item__safety::before {
    background: #e66e00; }
  .teaser-area .teaser-area--item.teaser-area--item__safety .teaser-area--icon {
    color: #e66e00; }
  @media (max-width: 767px) {
    .teaser-area .teaser-area--item.teaser-area--item__safety .teaser-area--link {
      color: #e66e00; } }
  @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .teaser-area .teaser-area--item.teaser-area--item__safety .teaser-area--link:hover, .teaser-area .teaser-area--item.teaser-area--item__safety .teaser-area--link:focus {
      background: #e66e00; }
      .teaser-area .teaser-area--item.teaser-area--item__safety .teaser-area--link:hover .teaser-area--description,
      .teaser-area .teaser-area--item.teaser-area--item__safety .teaser-area--link:hover .teaser-area--title,
      .teaser-area .teaser-area--item.teaser-area--item__safety .teaser-area--link:hover .teaser-area--icon, .teaser-area .teaser-area--item.teaser-area--item__safety .teaser-area--link:focus .teaser-area--description,
      .teaser-area .teaser-area--item.teaser-area--item__safety .teaser-area--link:focus .teaser-area--title,
      .teaser-area .teaser-area--item.teaser-area--item__safety .teaser-area--link:focus .teaser-area--icon {
        color: #ffffff; } }
  @media screen {
    .teaser-area .teaser-area--item.teaser-area--item__safety .teaser-area--link:active {
      background: #e66e00; }
      .teaser-area .teaser-area--item.teaser-area--item__safety .teaser-area--link:active .teaser-area--description,
      .teaser-area .teaser-area--item.teaser-area--item__safety .teaser-area--link:active .teaser-area--title,
      .teaser-area .teaser-area--item.teaser-area--item__safety .teaser-area--link:active .teaser-area--icon {
        color: #ffffff; } }
  .teaser-area .teaser-area--item.teaser-area--item__recycling::before {
    background: #2c834c; }
  .teaser-area .teaser-area--item.teaser-area--item__recycling .teaser-area--icon {
    color: #2c834c; }
  @media (max-width: 767px) {
    .teaser-area .teaser-area--item.teaser-area--item__recycling .teaser-area--link {
      color: #2c834c; } }
  @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .teaser-area .teaser-area--item.teaser-area--item__recycling .teaser-area--link:hover, .teaser-area .teaser-area--item.teaser-area--item__recycling .teaser-area--link:focus {
      background: #2c834c; }
      .teaser-area .teaser-area--item.teaser-area--item__recycling .teaser-area--link:hover .teaser-area--description,
      .teaser-area .teaser-area--item.teaser-area--item__recycling .teaser-area--link:hover .teaser-area--title,
      .teaser-area .teaser-area--item.teaser-area--item__recycling .teaser-area--link:hover .teaser-area--icon, .teaser-area .teaser-area--item.teaser-area--item__recycling .teaser-area--link:focus .teaser-area--description,
      .teaser-area .teaser-area--item.teaser-area--item__recycling .teaser-area--link:focus .teaser-area--title,
      .teaser-area .teaser-area--item.teaser-area--item__recycling .teaser-area--link:focus .teaser-area--icon {
        color: #ffffff; } }
  @media screen {
    .teaser-area .teaser-area--item.teaser-area--item__recycling .teaser-area--link:active {
      background: #2c834c; }
      .teaser-area .teaser-area--item.teaser-area--item__recycling .teaser-area--link:active .teaser-area--description,
      .teaser-area .teaser-area--item.teaser-area--item__recycling .teaser-area--link:active .teaser-area--title,
      .teaser-area .teaser-area--item.teaser-area--item__recycling .teaser-area--link:active .teaser-area--icon {
        color: #ffffff; } }
  .teaser-area .teaser-area--item.teaser-area--item__knowledge::before {
    background: #1469c9; }
  .teaser-area .teaser-area--item.teaser-area--item__knowledge .teaser-area--icon {
    color: #1469c9; }
  @media (max-width: 767px) {
    .teaser-area .teaser-area--item.teaser-area--item__knowledge .teaser-area--link {
      color: #1469c9; } }
  @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .teaser-area .teaser-area--item.teaser-area--item__knowledge .teaser-area--link:hover, .teaser-area .teaser-area--item.teaser-area--item__knowledge .teaser-area--link:focus {
      background: #1469c9; }
      .teaser-area .teaser-area--item.teaser-area--item__knowledge .teaser-area--link:hover .teaser-area--description,
      .teaser-area .teaser-area--item.teaser-area--item__knowledge .teaser-area--link:hover .teaser-area--title,
      .teaser-area .teaser-area--item.teaser-area--item__knowledge .teaser-area--link:hover .teaser-area--icon, .teaser-area .teaser-area--item.teaser-area--item__knowledge .teaser-area--link:focus .teaser-area--description,
      .teaser-area .teaser-area--item.teaser-area--item__knowledge .teaser-area--link:focus .teaser-area--title,
      .teaser-area .teaser-area--item.teaser-area--item__knowledge .teaser-area--link:focus .teaser-area--icon {
        color: #ffffff; } }
  @media screen {
    .teaser-area .teaser-area--item.teaser-area--item__knowledge .teaser-area--link:active {
      background: #1469c9; }
      .teaser-area .teaser-area--item.teaser-area--item__knowledge .teaser-area--link:active .teaser-area--description,
      .teaser-area .teaser-area--item.teaser-area--item__knowledge .teaser-area--link:active .teaser-area--title,
      .teaser-area .teaser-area--item.teaser-area--item__knowledge .teaser-area--link:active .teaser-area--icon {
        color: #ffffff; } }
  .teaser-area .teaser-area--item.teaser-area--item__education::before {
    background: #631e65; }
  .teaser-area .teaser-area--item.teaser-area--item__education .teaser-area--icon {
    color: #631e65; }
  @media (max-width: 767px) {
    .teaser-area .teaser-area--item.teaser-area--item__education .teaser-area--link {
      color: #631e65; } }
  @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .teaser-area .teaser-area--item.teaser-area--item__education .teaser-area--link:hover, .teaser-area .teaser-area--item.teaser-area--item__education .teaser-area--link:focus {
      background: #631e65; }
      .teaser-area .teaser-area--item.teaser-area--item__education .teaser-area--link:hover .teaser-area--description,
      .teaser-area .teaser-area--item.teaser-area--item__education .teaser-area--link:hover .teaser-area--title,
      .teaser-area .teaser-area--item.teaser-area--item__education .teaser-area--link:hover .teaser-area--icon, .teaser-area .teaser-area--item.teaser-area--item__education .teaser-area--link:focus .teaser-area--description,
      .teaser-area .teaser-area--item.teaser-area--item__education .teaser-area--link:focus .teaser-area--title,
      .teaser-area .teaser-area--item.teaser-area--item__education .teaser-area--link:focus .teaser-area--icon {
        color: #ffffff; } }
  @media screen {
    .teaser-area .teaser-area--item.teaser-area--item__education .teaser-area--link:active {
      background: #631e65; }
      .teaser-area .teaser-area--item.teaser-area--item__education .teaser-area--link:active .teaser-area--description,
      .teaser-area .teaser-area--item.teaser-area--item__education .teaser-area--link:active .teaser-area--title,
      .teaser-area .teaser-area--item.teaser-area--item__education .teaser-area--link:active .teaser-area--icon {
        color: #ffffff; } }
  .teaser-area .teaser-area--item.teaser-area--item__sustainability::before {
    background: #1da321; }
  .teaser-area .teaser-area--item.teaser-area--item__sustainability .teaser-area--icon {
    color: #1da321; }
  @media (max-width: 767px) {
    .teaser-area .teaser-area--item.teaser-area--item__sustainability .teaser-area--link {
      color: #1da321; } }
  @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .teaser-area .teaser-area--item.teaser-area--item__sustainability .teaser-area--link:hover, .teaser-area .teaser-area--item.teaser-area--item__sustainability .teaser-area--link:focus {
      background: #1da321; }
      .teaser-area .teaser-area--item.teaser-area--item__sustainability .teaser-area--link:hover .teaser-area--description,
      .teaser-area .teaser-area--item.teaser-area--item__sustainability .teaser-area--link:hover .teaser-area--title,
      .teaser-area .teaser-area--item.teaser-area--item__sustainability .teaser-area--link:hover .teaser-area--icon, .teaser-area .teaser-area--item.teaser-area--item__sustainability .teaser-area--link:focus .teaser-area--description,
      .teaser-area .teaser-area--item.teaser-area--item__sustainability .teaser-area--link:focus .teaser-area--title,
      .teaser-area .teaser-area--item.teaser-area--item__sustainability .teaser-area--link:focus .teaser-area--icon {
        color: #ffffff; } }
  @media screen {
    .teaser-area .teaser-area--item.teaser-area--item__sustainability .teaser-area--link:active {
      background: #1da321; }
      .teaser-area .teaser-area--item.teaser-area--item__sustainability .teaser-area--link:active .teaser-area--description,
      .teaser-area .teaser-area--item.teaser-area--item__sustainability .teaser-area--link:active .teaser-area--title,
      .teaser-area .teaser-area--item.teaser-area--item__sustainability .teaser-area--link:active .teaser-area--icon {
        color: #ffffff; } }
  .teaser-area .teaser-area--item.teaser-area--item__default::before {
    background: #d9213d; }
  .teaser-area .teaser-area--item.teaser-area--item__default .teaser-area--icon {
    color: #d9213d; }
  @media (max-width: 767px) {
    .teaser-area .teaser-area--item.teaser-area--item__default .teaser-area--link {
      color: #d9213d; } }
  @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .teaser-area .teaser-area--item.teaser-area--item__default .teaser-area--link:hover, .teaser-area .teaser-area--item.teaser-area--item__default .teaser-area--link:focus {
      background: #d9213d; }
      .teaser-area .teaser-area--item.teaser-area--item__default .teaser-area--link:hover .teaser-area--description,
      .teaser-area .teaser-area--item.teaser-area--item__default .teaser-area--link:hover .teaser-area--title,
      .teaser-area .teaser-area--item.teaser-area--item__default .teaser-area--link:hover .teaser-area--icon, .teaser-area .teaser-area--item.teaser-area--item__default .teaser-area--link:focus .teaser-area--description,
      .teaser-area .teaser-area--item.teaser-area--item__default .teaser-area--link:focus .teaser-area--title,
      .teaser-area .teaser-area--item.teaser-area--item__default .teaser-area--link:focus .teaser-area--icon {
        color: #ffffff; } }
  @media screen {
    .teaser-area .teaser-area--item.teaser-area--item__default .teaser-area--link:active {
      background: #d9213d; }
      .teaser-area .teaser-area--item.teaser-area--item__default .teaser-area--link:active .teaser-area--description,
      .teaser-area .teaser-area--item.teaser-area--item__default .teaser-area--link:active .teaser-area--title,
      .teaser-area .teaser-area--item.teaser-area--item__default .teaser-area--link:active .teaser-area--icon {
        color: #ffffff; } }

.teaser-events {
  position: relative; }
  .teaser-events .teaser-events--col {
    margin: auto;
    background: #ffffff; }
    @media (min-width: 1280px) {
      .teaser-events .teaser-events--col {
        flex: 0 0 66.66667%; }
        html.ie11 .teaser-events .teaser-events--col {
          flex-basis: auto;
          width: 66.66667%; }
        html.ie10 .teaser-events .teaser-events--col {
          flex-basis: auto;
          width: 66.66667%; } }
    @media (min-width: 992px) and (max-width: 1279px) {
      .teaser-events .teaser-events--col {
        flex: 0 0 83.33333%; }
        html.ie11 .teaser-events .teaser-events--col {
          flex-basis: auto;
          width: 83.33333%; }
        html.ie10 .teaser-events .teaser-events--col {
          flex-basis: auto;
          width: 83.33333%; } }
    @media (max-width: 991px) {
      .teaser-events .teaser-events--col {
        flex: 0 0 100%; }
        html.ie11 .teaser-events .teaser-events--col {
          flex-basis: auto;
          width: 100%; }
        html.ie10 .teaser-events .teaser-events--col {
          flex-basis: auto;
          width: 100%; } }
  .teaser-events .teaser-events--tags {
    margin: 0 auto 64px; }
    @media (max-width: 767px) {
      .teaser-events .teaser-events--tags {
        margin-bottom: 48px; } }
  .teaser-events .teaser-events--title {
    display: flex;
    justify-content: center;
    text-align: center;
    margin: 0 auto 24px;
    color: #111111;
    font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
    font-weight: 500;
    font-style: normal; }
    @media (min-width: 1920px) {
      .teaser-events .teaser-events--title {
        font-size: 36px;
        line-height: 1.25; } }
    @media (min-width: 1280px) and (max-width: 1919px) {
      .teaser-events .teaser-events--title {
        font-size: 35px;
        line-height: 1.25; } }
    @media (min-width: 992px) and (max-width: 1279px) {
      .teaser-events .teaser-events--title {
        font-size: 30px;
        line-height: 1.25; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .teaser-events .teaser-events--title {
        font-size: 28px;
        line-height: 1.25; } }
    @media (min-width: 544px) and (max-width: 767px) {
      .teaser-events .teaser-events--title {
        font-size: 27px;
        line-height: 1.25; } }
    @media (min-width: 375px) and (max-width: 543px) {
      .teaser-events .teaser-events--title {
        font-size: 26px;
        line-height: 1.25; } }
    @media (max-width: 374px) {
      .teaser-events .teaser-events--title {
        font-size: 25px;
        line-height: 1.25; } }
    @media (min-width: 768px) {
      .teaser-events .teaser-events--title {
        flex: 0 0 66.66667%; }
        html.ie11 .teaser-events .teaser-events--title {
          flex-basis: auto;
          width: 66.66667%; }
        html.ie10 .teaser-events .teaser-events--title {
          flex-basis: auto;
          width: 66.66667%; } }
    @media (max-width: 767px) {
      .teaser-events .teaser-events--title {
        flex: 0 0 100%; }
        html.ie11 .teaser-events .teaser-events--title {
          flex-basis: auto;
          width: 100%; }
        html.ie10 .teaser-events .teaser-events--title {
          flex-basis: auto;
          width: 100%; } }
  .teaser-events .teaser-events--collection {
    margin-bottom: 32px; }
  .teaser-events .teaser-events--item ~ .teaser-events--item {
    margin-top: 32px; }
  .teaser-events .teaser-events--link {
    display: flex;
    border-bottom: 1px solid #f1f1f2;
    transition: color 200ms ease, border-bottom-color 200ms ease;
    color: #111111; }
    @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .teaser-events .teaser-events--link:hover, .teaser-events .teaser-events--link:focus {
        border-bottom-color: currentColor; }
        .teaser-events .teaser-events--link:hover .teaser-events--event-title,
        .teaser-events .teaser-events--link:hover .teaser-events--event-description,
        .teaser-events .teaser-events--link:hover .teaser-events--event-icon, .teaser-events .teaser-events--link:focus .teaser-events--event-title,
        .teaser-events .teaser-events--link:focus .teaser-events--event-description,
        .teaser-events .teaser-events--link:focus .teaser-events--event-icon {
          color: currentColor; }
        html[data-theme="association"] .teaser-events .teaser-events--link:hover, html[data-theme="association"] .teaser-events .teaser-events--link:focus {
          color: #a00017; }
        html[data-theme="safety"] .teaser-events .teaser-events--link:hover, html[data-theme="safety"] .teaser-events .teaser-events--link:focus {
          color: #ad4000; }
        html[data-theme="recycling"] .teaser-events .teaser-events--link:hover, html[data-theme="recycling"] .teaser-events .teaser-events--link:focus {
          color: #005523; }
        html[data-theme="knowledge"] .teaser-events .teaser-events--link:hover, html[data-theme="knowledge"] .teaser-events .teaser-events--link:focus {
          color: #003f97; }
        html[data-theme="education"] .teaser-events .teaser-events--link:hover, html[data-theme="education"] .teaser-events .teaser-events--link:focus {
          color: #36003a; }
        html[data-theme="sustainability"] .teaser-events .teaser-events--link:hover, html[data-theme="sustainability"] .teaser-events .teaser-events--link:focus {
          color: #116214; }
        html[data-theme="default"] .teaser-events .teaser-events--link:hover, html[data-theme="default"] .teaser-events .teaser-events--link:focus {
          color: #a00017; } }
    @media screen {
      .teaser-events .teaser-events--link:active {
        border-bottom-color: currentColor; }
        .teaser-events .teaser-events--link:active .teaser-events--event-title,
        .teaser-events .teaser-events--link:active .teaser-events--event-description,
        .teaser-events .teaser-events--link:active .teaser-events--event-icon {
          color: currentColor; }
        html[data-theme="association"] .teaser-events .teaser-events--link:active {
          color: #a00017; }
        html[data-theme="safety"] .teaser-events .teaser-events--link:active {
          color: #ad4000; }
        html[data-theme="recycling"] .teaser-events .teaser-events--link:active {
          color: #005523; }
        html[data-theme="knowledge"] .teaser-events .teaser-events--link:active {
          color: #003f97; }
        html[data-theme="education"] .teaser-events .teaser-events--link:active {
          color: #36003a; }
        html[data-theme="sustainability"] .teaser-events .teaser-events--link:active {
          color: #116214; }
        html[data-theme="default"] .teaser-events .teaser-events--link:active {
          color: #a00017; } }
  .teaser-events .teaser-events--event-info {
    display: flex;
    flex: 1 1 100%;
    flex-direction: column;
    padding: 0 32px; }
    @media (min-width: 544px) {
      .teaser-events .teaser-events--event-info {
        justify-content: center; } }
    @media (max-width: 767px) {
      .teaser-events .teaser-events--event-info {
        padding: 0 24px; } }
    @media (max-width: 543px) {
      .teaser-events .teaser-events--event-info {
        padding: 8px 16px 0; } }
  .teaser-events .teaser-events--event-title {
    color: #111111;
    font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
    font-weight: 500;
    font-style: normal; }
    @media (min-width: 1920px) {
      .teaser-events .teaser-events--event-title {
        font-size: 21px;
        line-height: 1.25; } }
    @media (min-width: 1280px) and (max-width: 1919px) {
      .teaser-events .teaser-events--event-title {
        font-size: 19px;
        line-height: 1.3; } }
    @media (min-width: 992px) and (max-width: 1279px) {
      .teaser-events .teaser-events--event-title {
        font-size: 17px;
        line-height: 1.3; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .teaser-events .teaser-events--event-title {
        font-size: 17px;
        line-height: 1.3; } }
    @media (min-width: 544px) and (max-width: 767px) {
      .teaser-events .teaser-events--event-title {
        font-size: 17px;
        line-height: 1.3; } }
    @media (min-width: 375px) and (max-width: 543px) {
      .teaser-events .teaser-events--event-title {
        font-size: 17px;
        line-height: 1.3; } }
    @media (max-width: 374px) {
      .teaser-events .teaser-events--event-title {
        font-size: 17px;
        line-height: 1.4; } }
    @media (min-width: 768px) {
      .teaser-events .teaser-events--event-title {
        padding-bottom: 4px; } }
  .teaser-events .teaser-events--event-icon {
    display: flex;
    align-items: center; }
    html[data-theme="association"] .teaser-events .teaser-events--event-icon {
      color: #d9213d; }
    html[data-theme="safety"] .teaser-events .teaser-events--event-icon {
      color: #e66e00; }
    html[data-theme="recycling"] .teaser-events .teaser-events--event-icon {
      color: #2c834c; }
    html[data-theme="knowledge"] .teaser-events .teaser-events--event-icon {
      color: #1469c9; }
    html[data-theme="education"] .teaser-events .teaser-events--event-icon {
      color: #631e65; }
    html[data-theme="sustainability"] .teaser-events .teaser-events--event-icon {
      color: #1da321; }
    html[data-theme="default"] .teaser-events .teaser-events--event-icon {
      color: #d9213d; }
  .teaser-events .teaser-events--event-description {
    color: #555555;
    overflow: hidden;
    position: relative;
    line-height: 1.4em;
    max-height: 2.8em;
    /* stylelint-disable */
    /* stylelint-enable */
    font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
    font-weight: 400;
    font-style: normal; }
    @supports (-webkit-line-clamp: 2) {
      .teaser-events .teaser-events--event-description {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical; } }
    @supports not (-webkit-line-clamp: 2) {
      .teaser-events .teaser-events--event-description::after {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        display: block;
        background: linear-gradient(to right, rgba(255, 255, 255, 0), white 50%);
        width: 5.6em;
        height: 1.4em; } }
    @media (min-width: 1920px) {
      .teaser-events .teaser-events--event-description {
        font-size: 19px;
        line-height: 1.4; } }
    @media (min-width: 1280px) and (max-width: 1919px) {
      .teaser-events .teaser-events--event-description {
        font-size: 17px;
        line-height: 1.4; } }
    @media (min-width: 992px) and (max-width: 1279px) {
      .teaser-events .teaser-events--event-description {
        font-size: 15px;
        line-height: 1.4; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .teaser-events .teaser-events--event-description {
        font-size: 15px;
        line-height: 1.4; } }
    @media (min-width: 544px) and (max-width: 767px) {
      .teaser-events .teaser-events--event-description {
        font-size: 15px;
        line-height: 1.4; } }
    @media (min-width: 375px) and (max-width: 543px) {
      .teaser-events .teaser-events--event-description {
        font-size: 15px;
        line-height: 1.4; } }
    @media (max-width: 374px) {
      .teaser-events .teaser-events--event-description {
        font-size: 15px;
        line-height: 1.4; } }
    @media (max-width: 991px) {
      .teaser-events .teaser-events--event-description {
        padding-bottom: 8px; } }
    @media (max-width: 374px) {
      .teaser-events .teaser-events--event-description {
        display: none; } }
  .teaser-events .teaser-events--more-link {
    display: flex;
    justify-content: center;
    font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
    font-weight: 500;
    font-style: normal; }
  .teaser-events .teaser-events--no-events-col {
    display: flex;
    justify-content: center;
    text-align: center;
    margin: 0 auto 32px;
    padding-top: 40px;
    padding-bottom: 40px;
    border-top: 1px solid #f1f1f2;
    border-bottom: 1px solid #f1f1f2; }
    @media (min-width: 1280px) {
      .teaser-events .teaser-events--no-events-col {
        flex: 0 0 66.66667%; }
        html.ie11 .teaser-events .teaser-events--no-events-col {
          flex-basis: auto;
          width: 66.66667%; }
        html.ie10 .teaser-events .teaser-events--no-events-col {
          flex-basis: auto;
          width: 66.66667%; } }
    @media (min-width: 992px) and (max-width: 1279px) {
      .teaser-events .teaser-events--no-events-col {
        flex: 0 0 83.33333%; }
        html.ie11 .teaser-events .teaser-events--no-events-col {
          flex-basis: auto;
          width: 83.33333%; }
        html.ie10 .teaser-events .teaser-events--no-events-col {
          flex-basis: auto;
          width: 83.33333%; } }
    @media (max-width: 991px) {
      .teaser-events .teaser-events--no-events-col {
        flex: 0 0 100%; }
        html.ie11 .teaser-events .teaser-events--no-events-col {
          flex-basis: auto;
          width: 100%; }
        html.ie10 .teaser-events .teaser-events--no-events-col {
          flex-basis: auto;
          width: 100%; } }
  .teaser-events .teaser-events--hook {
    position: absolute;
    top: 50%; }

@media print {
  .teaser-person {
    page-break-before: always; } }

.teaser-person .teaser-person--col {
  margin: 0 auto; }
  @media (min-width: 992px) {
    .teaser-person .teaser-person--col {
      flex: 0 0 66.66667%; }
      html.ie11 .teaser-person .teaser-person--col {
        flex-basis: auto;
        width: 66.66667%; }
      html.ie10 .teaser-person .teaser-person--col {
        flex-basis: auto;
        width: 66.66667%; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .teaser-person .teaser-person--col {
      flex: 0 0 83.33333%; }
      html.ie11 .teaser-person .teaser-person--col {
        flex-basis: auto;
        width: 83.33333%; }
      html.ie10 .teaser-person .teaser-person--col {
        flex-basis: auto;
        width: 83.33333%; } }
  @media (max-width: 767px) {
    .teaser-person .teaser-person--col {
      flex: 0 0 100%; }
      html.ie11 .teaser-person .teaser-person--col {
        flex-basis: auto;
        width: 100%; }
      html.ie10 .teaser-person .teaser-person--col {
        flex-basis: auto;
        width: 100%; } }

.teaser-person .teaser-person--wrap {
  padding: 48px;
  box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.08), 0 8px 16px 0 rgba(0, 0, 0, 0.12);
  border-radius: 4px; }
  @media print {
    .teaser-person .teaser-person--wrap {
      padding: 24px; } }

.teaser-person .teaser-person--title {
  text-align: center;
  color: #111111;
  font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
  font-weight: 500;
  font-style: normal; }
  @media (min-width: 1920px) {
    .teaser-person .teaser-person--title {
      font-size: 29px;
      line-height: 1.25; } }
  @media (min-width: 1280px) and (max-width: 1919px) {
    .teaser-person .teaser-person--title {
      font-size: 25px;
      line-height: 1.25; } }
  @media (min-width: 992px) and (max-width: 1279px) {
    .teaser-person .teaser-person--title {
      font-size: 25px;
      line-height: 1.25; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .teaser-person .teaser-person--title {
      font-size: 24px;
      line-height: 1.25; } }
  @media (min-width: 544px) and (max-width: 767px) {
    .teaser-person .teaser-person--title {
      font-size: 24px;
      line-height: 1.25; } }
  @media (min-width: 375px) and (max-width: 543px) {
    .teaser-person .teaser-person--title {
      font-size: 22px;
      line-height: 1.3; } }
  @media (max-width: 374px) {
    .teaser-person .teaser-person--title {
      font-size: 21px;
      line-height: 1.4; } }
  .teaser-person .teaser-person--title::after {
    content: '';
    display: block;
    width: 32px;
    height: 4px;
    margin: 32px auto 0; }
    html[data-theme="association"] .teaser-person .teaser-person--title::after {
      background: #d9213d; }
    html[data-theme="safety"] .teaser-person .teaser-person--title::after {
      background: #e66e00; }
    html[data-theme="recycling"] .teaser-person .teaser-person--title::after {
      background: #2c834c; }
    html[data-theme="knowledge"] .teaser-person .teaser-person--title::after {
      background: #1469c9; }
    html[data-theme="education"] .teaser-person .teaser-person--title::after {
      background: #631e65; }
    html[data-theme="sustainability"] .teaser-person .teaser-person--title::after {
      background: #1da321; }
    html[data-theme="default"] .teaser-person .teaser-person--title::after {
      background: #d9213d; }

.teaser-person .teaser-person--card {
  display: flex;
  justify-content: center;
  margin: 32px auto 0; }
  @media (max-width: 767px) {
    .teaser-person .teaser-person--card {
      flex-direction: column;
      align-items: center;
      text-align: center; } }

.teaser-person .teaser-person--name {
  color: #111111;
  font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
  font-weight: 500;
  font-style: normal; }
  @media (min-width: 1920px) {
    .teaser-person .teaser-person--name {
      font-size: 19px;
      line-height: 1.4; } }
  @media (min-width: 1280px) and (max-width: 1919px) {
    .teaser-person .teaser-person--name {
      font-size: 17px;
      line-height: 1.4; } }
  @media (min-width: 992px) and (max-width: 1279px) {
    .teaser-person .teaser-person--name {
      font-size: 15px;
      line-height: 1.4; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .teaser-person .teaser-person--name {
      font-size: 15px;
      line-height: 1.4; } }
  @media (min-width: 544px) and (max-width: 767px) {
    .teaser-person .teaser-person--name {
      font-size: 15px;
      line-height: 1.4; } }
  @media (min-width: 375px) and (max-width: 543px) {
    .teaser-person .teaser-person--name {
      font-size: 15px;
      line-height: 1.4; } }
  @media (max-width: 374px) {
    .teaser-person .teaser-person--name {
      font-size: 15px;
      line-height: 1.4; } }

@media (min-width: 768px) {
  .teaser-person .teaser-person--meta {
    margin-left: 24px; } }

.teaser-person .teaser-person--description {
  color: #555555;
  font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal; }
  @media (min-width: 1920px) {
    .teaser-person .teaser-person--description {
      font-size: 19px;
      line-height: 1.4; } }
  @media (min-width: 1280px) and (max-width: 1919px) {
    .teaser-person .teaser-person--description {
      font-size: 17px;
      line-height: 1.4; } }
  @media (min-width: 992px) and (max-width: 1279px) {
    .teaser-person .teaser-person--description {
      font-size: 15px;
      line-height: 1.4; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .teaser-person .teaser-person--description {
      font-size: 15px;
      line-height: 1.4; } }
  @media (min-width: 544px) and (max-width: 767px) {
    .teaser-person .teaser-person--description {
      font-size: 15px;
      line-height: 1.4; } }
  @media (min-width: 375px) and (max-width: 543px) {
    .teaser-person .teaser-person--description {
      font-size: 15px;
      line-height: 1.4; } }
  @media (max-width: 374px) {
    .teaser-person .teaser-person--description {
      font-size: 15px;
      line-height: 1.4; } }

.teaser-person .teaser-person--figure {
  width: 115px;
  height: 115px;
  border-radius: 100%;
  overflow: hidden; }
  @media (max-width: 767px) {
    .teaser-person .teaser-person--figure {
      margin-bottom: 24px; } }

.teaser-person .teaser-person--image {
  display: inline-block;
  width: 100%; }
  @supports (object-fit: cover) {
    .teaser-person .teaser-person--image {
      height: 100%;
      object-fit: cover; } }

.teaser-person .teaser-person--role {
  display: inline-block;
  margin-bottom: 8px; }
  @media (max-width: 767px) {
    .teaser-person .teaser-person--role {
      margin-bottom: 16px; } }

.teaser-person .teaser-person--link {
  display: inline-block;
  margin-top: 8px; }
  @media (max-width: 767px) {
    .teaser-person .teaser-person--link {
      margin-top: 16px; } }

.teaser-person .teaser-person--link,
.teaser-person .teaser-person--tel {
  font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
  font-weight: 500;
  font-style: normal; }
  html[data-theme="association"] .teaser-person .teaser-person--link, html[data-theme="association"]
  .teaser-person .teaser-person--tel {
    color: #d9213d; }
  html[data-theme="safety"] .teaser-person .teaser-person--link, html[data-theme="safety"]
  .teaser-person .teaser-person--tel {
    color: #e66e00; }
  html[data-theme="recycling"] .teaser-person .teaser-person--link, html[data-theme="recycling"]
  .teaser-person .teaser-person--tel {
    color: #2c834c; }
  html[data-theme="knowledge"] .teaser-person .teaser-person--link, html[data-theme="knowledge"]
  .teaser-person .teaser-person--tel {
    color: #1469c9; }
  html[data-theme="education"] .teaser-person .teaser-person--link, html[data-theme="education"]
  .teaser-person .teaser-person--tel {
    color: #631e65; }
  html[data-theme="sustainability"] .teaser-person .teaser-person--link, html[data-theme="sustainability"]
  .teaser-person .teaser-person--tel {
    color: #1da321; }
  html[data-theme="default"] .teaser-person .teaser-person--link, html[data-theme="default"]
  .teaser-person .teaser-person--tel {
    color: #d9213d; }
  @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    html[data-theme="association"] .teaser-person .teaser-person--link:hover, html[data-theme="association"] .teaser-person .teaser-person--link:focus, html[data-theme="association"]
    .teaser-person .teaser-person--tel:hover, html[data-theme="association"]
    .teaser-person .teaser-person--tel:focus {
      color: #a00017; }
    html[data-theme="safety"] .teaser-person .teaser-person--link:hover, html[data-theme="safety"] .teaser-person .teaser-person--link:focus, html[data-theme="safety"]
    .teaser-person .teaser-person--tel:hover, html[data-theme="safety"]
    .teaser-person .teaser-person--tel:focus {
      color: #ad4000; }
    html[data-theme="recycling"] .teaser-person .teaser-person--link:hover, html[data-theme="recycling"] .teaser-person .teaser-person--link:focus, html[data-theme="recycling"]
    .teaser-person .teaser-person--tel:hover, html[data-theme="recycling"]
    .teaser-person .teaser-person--tel:focus {
      color: #005523; }
    html[data-theme="knowledge"] .teaser-person .teaser-person--link:hover, html[data-theme="knowledge"] .teaser-person .teaser-person--link:focus, html[data-theme="knowledge"]
    .teaser-person .teaser-person--tel:hover, html[data-theme="knowledge"]
    .teaser-person .teaser-person--tel:focus {
      color: #003f97; }
    html[data-theme="education"] .teaser-person .teaser-person--link:hover, html[data-theme="education"] .teaser-person .teaser-person--link:focus, html[data-theme="education"]
    .teaser-person .teaser-person--tel:hover, html[data-theme="education"]
    .teaser-person .teaser-person--tel:focus {
      color: #36003a; }
    html[data-theme="sustainability"] .teaser-person .teaser-person--link:hover, html[data-theme="sustainability"] .teaser-person .teaser-person--link:focus, html[data-theme="sustainability"]
    .teaser-person .teaser-person--tel:hover, html[data-theme="sustainability"]
    .teaser-person .teaser-person--tel:focus {
      color: #116214; }
    html[data-theme="default"] .teaser-person .teaser-person--link:hover, html[data-theme="default"] .teaser-person .teaser-person--link:focus, html[data-theme="default"]
    .teaser-person .teaser-person--tel:hover, html[data-theme="default"]
    .teaser-person .teaser-person--tel:focus {
      color: #a00017; } }
  @media screen {
    html[data-theme="association"] .teaser-person .teaser-person--link:active, html[data-theme="association"]
    .teaser-person .teaser-person--tel:active {
      color: #a00017; }
    html[data-theme="safety"] .teaser-person .teaser-person--link:active, html[data-theme="safety"]
    .teaser-person .teaser-person--tel:active {
      color: #ad4000; }
    html[data-theme="recycling"] .teaser-person .teaser-person--link:active, html[data-theme="recycling"]
    .teaser-person .teaser-person--tel:active {
      color: #005523; }
    html[data-theme="knowledge"] .teaser-person .teaser-person--link:active, html[data-theme="knowledge"]
    .teaser-person .teaser-person--tel:active {
      color: #003f97; }
    html[data-theme="education"] .teaser-person .teaser-person--link:active, html[data-theme="education"]
    .teaser-person .teaser-person--tel:active {
      color: #36003a; }
    html[data-theme="sustainability"] .teaser-person .teaser-person--link:active, html[data-theme="sustainability"]
    .teaser-person .teaser-person--tel:active {
      color: #116214; }
    html[data-theme="default"] .teaser-person .teaser-person--link:active, html[data-theme="default"]
    .teaser-person .teaser-person--tel:active {
      color: #a00017; } }

.teaser-text-images .teaser-text-images--text-col {
  margin: 0 auto; }
  @media (min-width: 992px) {
    .teaser-text-images .teaser-text-images--text-col {
      flex: 0 0 66.66667%; }
      html.ie11 .teaser-text-images .teaser-text-images--text-col {
        flex-basis: auto;
        width: 66.66667%; }
      html.ie10 .teaser-text-images .teaser-text-images--text-col {
        flex-basis: auto;
        width: 66.66667%; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .teaser-text-images .teaser-text-images--text-col {
      flex: 0 0 83.33333%; }
      html.ie11 .teaser-text-images .teaser-text-images--text-col {
        flex-basis: auto;
        width: 83.33333%; }
      html.ie10 .teaser-text-images .teaser-text-images--text-col {
        flex-basis: auto;
        width: 83.33333%; } }
  @media (max-width: 767px) {
    .teaser-text-images .teaser-text-images--text-col {
      flex: 0 0 100%; }
      html.ie11 .teaser-text-images .teaser-text-images--text-col {
        flex-basis: auto;
        width: 100%; }
      html.ie10 .teaser-text-images .teaser-text-images--text-col {
        flex-basis: auto;
        width: 100%; } }

.teaser-text-images .teaser-text-images--title {
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 0 auto 24px;
  color: #111111;
  font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
  font-weight: 500;
  font-style: normal; }
  @media (min-width: 1920px) {
    .teaser-text-images .teaser-text-images--title {
      font-size: 36px;
      line-height: 1.25; } }
  @media (min-width: 1280px) and (max-width: 1919px) {
    .teaser-text-images .teaser-text-images--title {
      font-size: 35px;
      line-height: 1.25; } }
  @media (min-width: 992px) and (max-width: 1279px) {
    .teaser-text-images .teaser-text-images--title {
      font-size: 30px;
      line-height: 1.25; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .teaser-text-images .teaser-text-images--title {
      font-size: 28px;
      line-height: 1.25; } }
  @media (min-width: 544px) and (max-width: 767px) {
    .teaser-text-images .teaser-text-images--title {
      font-size: 27px;
      line-height: 1.25; } }
  @media (min-width: 375px) and (max-width: 543px) {
    .teaser-text-images .teaser-text-images--title {
      font-size: 26px;
      line-height: 1.25; } }
  @media (max-width: 374px) {
    .teaser-text-images .teaser-text-images--title {
      font-size: 25px;
      line-height: 1.25; } }
  @media (min-width: 768px) {
    .teaser-text-images .teaser-text-images--title {
      flex: 0 0 66.66667%; }
      html.ie11 .teaser-text-images .teaser-text-images--title {
        flex-basis: auto;
        width: 66.66667%; }
      html.ie10 .teaser-text-images .teaser-text-images--title {
        flex-basis: auto;
        width: 66.66667%; } }
  @media (max-width: 767px) {
    .teaser-text-images .teaser-text-images--title {
      flex: 0 0 100%; }
      html.ie11 .teaser-text-images .teaser-text-images--title {
        flex-basis: auto;
        width: 100%; }
      html.ie10 .teaser-text-images .teaser-text-images--title {
        flex-basis: auto;
        width: 100%; } }

.teaser-text-images .teaser-text-images--text {
  text-align: center;
  margin-bottom: 32px; }

.teaser-text-images .teaser-text-images--cta {
  display: flex;
  justify-content: center;
  margin-bottom: 48px; }

.teaser-text-images .teaser-text-images--collection.teaser-text-images--collection {
  justify-content: center; }

.teaser-text-images .teaser-text-images--item {
  text-align: center; }
  @media (min-width: 992px) {
    .teaser-text-images .teaser-text-images--item {
      flex: 0 0 16.66667%; }
      html.ie11 .teaser-text-images .teaser-text-images--item {
        flex-basis: auto;
        width: 16.66667%; }
      html.ie10 .teaser-text-images .teaser-text-images--item {
        flex-basis: auto;
        width: 16.66667%; } }
  @media (max-width: 767px) {
    .teaser-text-images .teaser-text-images--item {
      flex: 0 0 100%; }
      html.ie11 .teaser-text-images .teaser-text-images--item {
        flex-basis: auto;
        width: 100%; }
      html.ie10 .teaser-text-images .teaser-text-images--item {
        flex-basis: auto;
        width: 100%; } }
  @media (max-width: 767px) {
    .teaser-text-images .teaser-text-images--item ~ .teaser-text-images--item {
      margin-top: 24px; } }

.testimonial .testimonial--container {
  position: relative; }

@media (min-width: 768px) {
  .testimonial .testimonial--image-col {
    flex: 0 0 50%; }
    html.ie11 .testimonial .testimonial--image-col {
      flex-basis: auto;
      width: 50%; }
    html.ie10 .testimonial .testimonial--image-col {
      flex-basis: auto;
      width: 50%; } }

@media (max-width: 767px) {
  .testimonial .testimonial--image-col {
    flex: 0 0 100%; }
    html.ie11 .testimonial .testimonial--image-col {
      flex-basis: auto;
      width: 100%; }
    html.ie10 .testimonial .testimonial--image-col {
      flex-basis: auto;
      width: 100%; } }

.testimonial .testimonial--quote-col {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: flex-end; }
  @media print {
    .testimonial .testimonial--quote-col {
      justify-content: unset; } }
  @media (min-width: 768px) {
    .testimonial .testimonial--quote-col {
      flex: 0 0 50%; }
      html.ie11 .testimonial .testimonial--quote-col {
        flex-basis: auto;
        width: 50%; }
      html.ie10 .testimonial .testimonial--quote-col {
        flex-basis: auto;
        width: 50%; } }
  @media (max-width: 767px) {
    .testimonial .testimonial--quote-col {
      flex: 0 0 100%; }
      html.ie11 .testimonial .testimonial--quote-col {
        flex-basis: auto;
        width: 100%; }
      html.ie10 .testimonial .testimonial--quote-col {
        flex-basis: auto;
        width: 100%; } }
  @media (min-width: 768px) {
    .testimonial .testimonial--quote-col {
      padding-top: 16%; } }

.testimonial .testimonial--quote-wrap {
  padding: 40px;
  background: #ffffff;
  border: 4px solid #f1f1f2; }
  @media (min-width: 768px) {
    .testimonial .testimonial--quote-wrap {
      flex: 0 0 116.66667%; }
      html.ie11 .testimonial .testimonial--quote-wrap {
        flex-basis: auto;
        width: 116.66667%; }
      html.ie10 .testimonial .testimonial--quote-wrap {
        flex-basis: auto;
        width: 116.66667%; } }
  @media (max-width: 767px) {
    .testimonial .testimonial--quote-wrap {
      padding: 16px;
      margin-top: -16px;
      width: calc(100% - 16px); } }
  @media print {
    .testimonial .testimonial--quote-wrap {
      width: 100%;
      margin-top: 0; } }

.testimonial .testimonial--quote-text {
  margin-bottom: 24px;
  font-family: "IBM Plex Serif", "Times New Roman", Times, serif;
  font-weight: 400;
  font-style: italic; }
  .testimonial .testimonial--quote-text::before {
    content: '\00ab'; }
  .testimonial .testimonial--quote-text::after {
    content: '\00bb'; }
  @media (min-width: 1920px) {
    .testimonial .testimonial--quote-text {
      font-size: 31px;
      line-height: 1.4; } }
  @media (min-width: 1280px) and (max-width: 1919px) {
    .testimonial .testimonial--quote-text {
      font-size: 29px;
      line-height: 1.4; } }
  @media (min-width: 992px) and (max-width: 1279px) {
    .testimonial .testimonial--quote-text {
      font-size: 25px;
      line-height: 1.4; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .testimonial .testimonial--quote-text {
      font-size: 24px;
      line-height: 1.4; } }
  @media (min-width: 544px) and (max-width: 767px) {
    .testimonial .testimonial--quote-text {
      font-size: 24px;
      line-height: 1.4; } }
  @media (min-width: 375px) and (max-width: 543px) {
    .testimonial .testimonial--quote-text {
      font-size: 22px;
      line-height: 1.4; } }
  @media (max-width: 374px) {
    .testimonial .testimonial--quote-text {
      font-size: 21px;
      line-height: 1.4; } }

.testimonial .testimonial--image {
  width: 100%; }
  @media (max-width: 767px) {
    .testimonial .testimonial--image {
      padding-right: 16px; } }

.testimonial .testimonial--author {
  display: inline-block;
  width: 100%;
  font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
  font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
  font-weight: 500;
  font-style: normal; }
  @media (min-width: 1920px) {
    .testimonial .testimonial--author {
      font-size: 21px;
      line-height: 1.44; } }
  @media (min-width: 1280px) and (max-width: 1919px) {
    .testimonial .testimonial--author {
      font-size: 19px;
      line-height: 1.57; } }
  @media (min-width: 992px) and (max-width: 1279px) {
    .testimonial .testimonial--author {
      font-size: 17px;
      line-height: 1.52; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .testimonial .testimonial--author {
      font-size: 17px;
      line-height: 1.52; } }
  @media (min-width: 544px) and (max-width: 767px) {
    .testimonial .testimonial--author {
      font-size: 17px;
      line-height: 1.52; } }
  @media (min-width: 375px) and (max-width: 543px) {
    .testimonial .testimonial--author {
      font-size: 17px;
      line-height: 1.52; } }
  @media (max-width: 374px) {
    .testimonial .testimonial--author {
      font-size: 17px;
      line-height: 1.52; } }

.testimonial .testimonial--role {
  color: #a5a3a3;
  font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal; }
  @media (min-width: 1920px) {
    .testimonial .testimonial--role {
      font-size: 19px;
      line-height: 1.4; } }
  @media (min-width: 1280px) and (max-width: 1919px) {
    .testimonial .testimonial--role {
      font-size: 17px;
      line-height: 1.4; } }
  @media (min-width: 992px) and (max-width: 1279px) {
    .testimonial .testimonial--role {
      font-size: 15px;
      line-height: 1.4; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .testimonial .testimonial--role {
      font-size: 15px;
      line-height: 1.4; } }
  @media (min-width: 544px) and (max-width: 767px) {
    .testimonial .testimonial--role {
      font-size: 15px;
      line-height: 1.4; } }
  @media (min-width: 375px) and (max-width: 543px) {
    .testimonial .testimonial--role {
      font-size: 15px;
      line-height: 1.4; } }
  @media (max-width: 374px) {
    .testimonial .testimonial--role {
      font-size: 15px;
      line-height: 1.4; } }

.testimonial.testimonial__without-image .testimonial--quote-col {
  display: block;
  padding-top: 0; }
  @media (min-width: 992px) {
    .testimonial.testimonial__without-image .testimonial--quote-col {
      margin: auto 40px; } }

.testimonial.testimonial__without-image .testimonial--quote-wrap {
  width: 100%; }

.ui-accordion .ui-accordion--head {
  display: flex;
  align-items: center;
  width: 100%;
  text-align: left;
  cursor: pointer;
  color: #555555;
  padding: 16px 0; }
  @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    html[data-theme="association"] .ui-accordion .ui-accordion--head:hover .ui-accordion--title, html[data-theme="association"]
    .ui-accordion .ui-accordion--head:hover .ui-accordion--icon, html[data-theme="association"] .ui-accordion .ui-accordion--head:focus .ui-accordion--title, html[data-theme="association"]
    .ui-accordion .ui-accordion--head:focus .ui-accordion--icon {
      color: #a00017; }
    html[data-theme="safety"] .ui-accordion .ui-accordion--head:hover .ui-accordion--title, html[data-theme="safety"]
    .ui-accordion .ui-accordion--head:hover .ui-accordion--icon, html[data-theme="safety"] .ui-accordion .ui-accordion--head:focus .ui-accordion--title, html[data-theme="safety"]
    .ui-accordion .ui-accordion--head:focus .ui-accordion--icon {
      color: #ad4000; }
    html[data-theme="recycling"] .ui-accordion .ui-accordion--head:hover .ui-accordion--title, html[data-theme="recycling"]
    .ui-accordion .ui-accordion--head:hover .ui-accordion--icon, html[data-theme="recycling"] .ui-accordion .ui-accordion--head:focus .ui-accordion--title, html[data-theme="recycling"]
    .ui-accordion .ui-accordion--head:focus .ui-accordion--icon {
      color: #005523; }
    html[data-theme="knowledge"] .ui-accordion .ui-accordion--head:hover .ui-accordion--title, html[data-theme="knowledge"]
    .ui-accordion .ui-accordion--head:hover .ui-accordion--icon, html[data-theme="knowledge"] .ui-accordion .ui-accordion--head:focus .ui-accordion--title, html[data-theme="knowledge"]
    .ui-accordion .ui-accordion--head:focus .ui-accordion--icon {
      color: #003f97; }
    html[data-theme="education"] .ui-accordion .ui-accordion--head:hover .ui-accordion--title, html[data-theme="education"]
    .ui-accordion .ui-accordion--head:hover .ui-accordion--icon, html[data-theme="education"] .ui-accordion .ui-accordion--head:focus .ui-accordion--title, html[data-theme="education"]
    .ui-accordion .ui-accordion--head:focus .ui-accordion--icon {
      color: #36003a; }
    html[data-theme="sustainability"] .ui-accordion .ui-accordion--head:hover .ui-accordion--title, html[data-theme="sustainability"]
    .ui-accordion .ui-accordion--head:hover .ui-accordion--icon, html[data-theme="sustainability"] .ui-accordion .ui-accordion--head:focus .ui-accordion--title, html[data-theme="sustainability"]
    .ui-accordion .ui-accordion--head:focus .ui-accordion--icon {
      color: #116214; }
    html[data-theme="default"] .ui-accordion .ui-accordion--head:hover .ui-accordion--title, html[data-theme="default"]
    .ui-accordion .ui-accordion--head:hover .ui-accordion--icon, html[data-theme="default"] .ui-accordion .ui-accordion--head:focus .ui-accordion--title, html[data-theme="default"]
    .ui-accordion .ui-accordion--head:focus .ui-accordion--icon {
      color: #a00017; } }
  @media screen {
    html[data-theme="association"] .ui-accordion .ui-accordion--head:active .ui-accordion--title, html[data-theme="association"]
    .ui-accordion .ui-accordion--head:active .ui-accordion--icon {
      color: #a00017; }
    html[data-theme="safety"] .ui-accordion .ui-accordion--head:active .ui-accordion--title, html[data-theme="safety"]
    .ui-accordion .ui-accordion--head:active .ui-accordion--icon {
      color: #ad4000; }
    html[data-theme="recycling"] .ui-accordion .ui-accordion--head:active .ui-accordion--title, html[data-theme="recycling"]
    .ui-accordion .ui-accordion--head:active .ui-accordion--icon {
      color: #005523; }
    html[data-theme="knowledge"] .ui-accordion .ui-accordion--head:active .ui-accordion--title, html[data-theme="knowledge"]
    .ui-accordion .ui-accordion--head:active .ui-accordion--icon {
      color: #003f97; }
    html[data-theme="education"] .ui-accordion .ui-accordion--head:active .ui-accordion--title, html[data-theme="education"]
    .ui-accordion .ui-accordion--head:active .ui-accordion--icon {
      color: #36003a; }
    html[data-theme="sustainability"] .ui-accordion .ui-accordion--head:active .ui-accordion--title, html[data-theme="sustainability"]
    .ui-accordion .ui-accordion--head:active .ui-accordion--icon {
      color: #116214; }
    html[data-theme="default"] .ui-accordion .ui-accordion--head:active .ui-accordion--title, html[data-theme="default"]
    .ui-accordion .ui-accordion--head:active .ui-accordion--icon {
      color: #a00017; } }

.ui-accordion .ui-accordion--title {
  padding-left: 8px;
  transform: translate(0, 1px);
  color: #111111;
  font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
  font-weight: 500;
  font-style: normal;
  color: #555555; }
  @media (min-width: 1920px) {
    .ui-accordion .ui-accordion--title {
      font-size: 21px;
      line-height: 1.25; } }
  @media (min-width: 1280px) and (max-width: 1919px) {
    .ui-accordion .ui-accordion--title {
      font-size: 19px;
      line-height: 1.3; } }
  @media (min-width: 992px) and (max-width: 1279px) {
    .ui-accordion .ui-accordion--title {
      font-size: 17px;
      line-height: 1.3; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .ui-accordion .ui-accordion--title {
      font-size: 17px;
      line-height: 1.3; } }
  @media (min-width: 544px) and (max-width: 767px) {
    .ui-accordion .ui-accordion--title {
      font-size: 17px;
      line-height: 1.3; } }
  @media (min-width: 375px) and (max-width: 543px) {
    .ui-accordion .ui-accordion--title {
      font-size: 17px;
      line-height: 1.3; } }
  @media (max-width: 374px) {
    .ui-accordion .ui-accordion--title {
      font-size: 17px;
      line-height: 1.4; } }

.ui-accordion .ui-accordion--body {
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  padding-left: 32px;
  transition: opacity 300ms ease; }
  @media print {
    .ui-accordion .ui-accordion--body {
      max-height: unset;
      opacity: 1; } }
  .ui-accordion .ui-accordion--body h3 {
    padding-top: 16px;
    padding-bottom: 16px; }

.ui-accordion .ui-accordion--icon {
  flex: 0 0 auto;
  align-self: baseline; }
  html[data-theme="association"] .ui-accordion .ui-accordion--icon {
    color: #d9213d; }
  html[data-theme="safety"] .ui-accordion .ui-accordion--icon {
    color: #e66e00; }
  html[data-theme="recycling"] .ui-accordion .ui-accordion--icon {
    color: #2c834c; }
  html[data-theme="knowledge"] .ui-accordion .ui-accordion--icon {
    color: #1469c9; }
  html[data-theme="education"] .ui-accordion .ui-accordion--icon {
    color: #631e65; }
  html[data-theme="sustainability"] .ui-accordion .ui-accordion--icon {
    color: #1da321; }
  html[data-theme="default"] .ui-accordion .ui-accordion--icon {
    color: #d9213d; }

.ui-accordion.ui-accordion__is-open > .ui-accordion--body {
  opacity: 1;
  margin-bottom: 24px; }

.ui-accordion.ui-accordion__is-animating .ui-accordion--body {
  transition: opacity 300ms ease, max-height 300ms ease; }

@media print {
  .video {
    display: none; } }

.video .video--title {
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 0 auto 24px;
  color: #111111;
  font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
  font-weight: 500;
  font-style: normal; }
  @media (min-width: 1920px) {
    .video .video--title {
      font-size: 36px;
      line-height: 1.25; } }
  @media (min-width: 1280px) and (max-width: 1919px) {
    .video .video--title {
      font-size: 35px;
      line-height: 1.25; } }
  @media (min-width: 992px) and (max-width: 1279px) {
    .video .video--title {
      font-size: 30px;
      line-height: 1.25; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .video .video--title {
      font-size: 28px;
      line-height: 1.25; } }
  @media (min-width: 544px) and (max-width: 767px) {
    .video .video--title {
      font-size: 27px;
      line-height: 1.25; } }
  @media (min-width: 375px) and (max-width: 543px) {
    .video .video--title {
      font-size: 26px;
      line-height: 1.25; } }
  @media (max-width: 374px) {
    .video .video--title {
      font-size: 25px;
      line-height: 1.25; } }
  @media (min-width: 768px) {
    .video .video--title {
      flex: 0 0 66.66667%; }
      html.ie11 .video .video--title {
        flex-basis: auto;
        width: 66.66667%; }
      html.ie10 .video .video--title {
        flex-basis: auto;
        width: 66.66667%; } }
  @media (max-width: 767px) {
    .video .video--title {
      flex: 0 0 100%; }
      html.ie11 .video .video--title {
        flex-basis: auto;
        width: 100%; }
      html.ie10 .video .video--title {
        flex-basis: auto;
        width: 100%; } }

.video .video--row {
  justify-content: center; }

@media (min-width: 768px) {
  .video .video--col {
    flex: 0 0 83.33333%; }
    html.ie11 .video .video--col {
      flex-basis: auto;
      width: 83.33333%; }
    html.ie10 .video .video--col {
      flex-basis: auto;
      width: 83.33333%; } }

@media (max-width: 767px) {
  .video .video--col {
    flex: 0 0 100%; }
    html.ie11 .video .video--col {
      flex-basis: auto;
      width: 100%; }
    html.ie10 .video .video--col {
      flex-basis: auto;
      width: 100%; } }

.video .video--wrapper {
  position: relative;
  margin: 0 auto;
  height: 0;
  padding-top: 56.25%; }

.video .video--frame {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09), 0 1px 2px rgba(0, 0, 0, 0.2); }

.xml-feed .xml-feed--container {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  margin: auto; }

.xml-feed .xml-feed--row {
  display: flex;
  flex-flow: row wrap; }
  html.ie11 .xml-feed .xml-feed--row {
    min-height: 1px; }

.xml-feed .xml-feed--col {
  position: relative;
  min-height: 1px;
  max-width: 100%; }

.xml-feed .xml-feed--container {
  padding-left: 32px;
  padding-right: 32px; }
  .xml-feed .xml-feed--container .xml-feed--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important; }
    .xml-feed .xml-feed--container .xml-feed--container:not(.u-iknowwhatimdoing)::before {
      visibility: visible;
      content: 'Nested `grid--container` detected!';
      font-size: 20px;
      line-height: 1.2;
      color: red; }

.xml-feed .xml-feed--row {
  margin-left: -16px;
  margin-right: -16px; }

.xml-feed .xml-feed--col {
  padding-left: 16px;
  padding-right: 16px; }

.xml-feed .xml-feed--title {
  margin-bottom: 16px;
  padding-top: 64px; }
  @media (max-width: 991px) {
    .xml-feed .xml-feed--title {
      padding-top: 48px; } }

.xml-feed .xml-feed--description {
  margin-bottom: 24px;
  color: #555555; }

.xml-feed .xml-feed--row {
  justify-content: space-between; }
  @media (min-width: 768px) {
    .xml-feed .xml-feed--row {
      align-items: center;
      flex-wrap: nowrap; } }

@media (max-width: 767px) {
  .xml-feed .xml-feed--col {
    flex: 0 0 100%; }
    html.ie11 .xml-feed .xml-feed--col {
      flex-basis: auto;
      width: 100%; }
    html.ie10 .xml-feed .xml-feed--col {
      flex-basis: auto;
      width: 100%; } }

.xml-feed .xml-feed--col:first-child {
  flex: 1 1 auto; }

@media (min-width: 1280px) {
  .xml-feed .xml-feed--col:not(:first-child) {
    flex: 0 0 25%; }
    html.ie11 .xml-feed .xml-feed--col:not(:first-child) {
      flex-basis: auto;
      width: 25%; }
    html.ie10 .xml-feed .xml-feed--col:not(:first-child) {
      flex-basis: auto;
      width: 25%; } }

@media (min-width: 768px) and (max-width: 991px), (min-width: 992px) and (max-width: 1279px) {
  .xml-feed .xml-feed--col:not(:first-child) {
    flex: 0 0 33.33333%; }
    html.ie11 .xml-feed .xml-feed--col:not(:first-child) {
      flex-basis: auto;
      width: 33.33333%; }
    html.ie10 .xml-feed .xml-feed--col:not(:first-child) {
      flex-basis: auto;
      width: 33.33333%; } }

.xml-feed .xml-feed--tbody {
  display: flex;
  flex-direction: column; }

.xml-feed .xml-feed--toggle {
  cursor: pointer;
  padding: 16px 0 32px; }
  html[data-theme="association"] .xml-feed .xml-feed--toggle {
    color: #d9213d; }
  html[data-theme="safety"] .xml-feed .xml-feed--toggle {
    color: #e66e00; }
  html[data-theme="recycling"] .xml-feed .xml-feed--toggle {
    color: #2c834c; }
  html[data-theme="knowledge"] .xml-feed .xml-feed--toggle {
    color: #1469c9; }
  html[data-theme="education"] .xml-feed .xml-feed--toggle {
    color: #631e65; }
  html[data-theme="sustainability"] .xml-feed .xml-feed--toggle {
    color: #1da321; }
  html[data-theme="default"] .xml-feed .xml-feed--toggle {
    color: #d9213d; }
  @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    html[data-theme="association"] .xml-feed .xml-feed--toggle:hover, html[data-theme="association"] .xml-feed .xml-feed--toggle:focus {
      color: #a00017; }
    html[data-theme="safety"] .xml-feed .xml-feed--toggle:hover, html[data-theme="safety"] .xml-feed .xml-feed--toggle:focus {
      color: #ad4000; }
    html[data-theme="recycling"] .xml-feed .xml-feed--toggle:hover, html[data-theme="recycling"] .xml-feed .xml-feed--toggle:focus {
      color: #005523; }
    html[data-theme="knowledge"] .xml-feed .xml-feed--toggle:hover, html[data-theme="knowledge"] .xml-feed .xml-feed--toggle:focus {
      color: #003f97; }
    html[data-theme="education"] .xml-feed .xml-feed--toggle:hover, html[data-theme="education"] .xml-feed .xml-feed--toggle:focus {
      color: #36003a; }
    html[data-theme="sustainability"] .xml-feed .xml-feed--toggle:hover, html[data-theme="sustainability"] .xml-feed .xml-feed--toggle:focus {
      color: #116214; }
    html[data-theme="default"] .xml-feed .xml-feed--toggle:hover, html[data-theme="default"] .xml-feed .xml-feed--toggle:focus {
      color: #a00017; } }
  @media screen {
    html[data-theme="association"] .xml-feed .xml-feed--toggle:active {
      color: #a00017; }
    html[data-theme="safety"] .xml-feed .xml-feed--toggle:active {
      color: #ad4000; }
    html[data-theme="recycling"] .xml-feed .xml-feed--toggle:active {
      color: #005523; }
    html[data-theme="knowledge"] .xml-feed .xml-feed--toggle:active {
      color: #003f97; }
    html[data-theme="education"] .xml-feed .xml-feed--toggle:active {
      color: #36003a; }
    html[data-theme="sustainability"] .xml-feed .xml-feed--toggle:active {
      color: #116214; }
    html[data-theme="default"] .xml-feed .xml-feed--toggle:active {
      color: #a00017; } }

.xml-feed .xml-feed--toggle-text {
  padding: 0 8px;
  transform: translate(0, 1px);
  font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
  font-weight: 500;
  font-style: normal; }

.xml-feed .xml-feed--more,
.xml-feed .xml-feed--less {
  display: flex;
  align-items: center; }
  @media print {
    .xml-feed .xml-feed--more,
    .xml-feed .xml-feed--less {
      display: none; } }

.xml-feed .xml-feed--less {
  display: none; }

.xml-feed .xml-feed--extra-items {
  order: 1;
  display: none; }
  @media print {
    .xml-feed .xml-feed--extra-items {
      display: block; } }

.xml-feed .xml-feed--meta {
  font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal; }
  @media (min-width: 1920px) {
    .xml-feed .xml-feed--meta {
      font-size: 19px;
      line-height: 1.4; } }
  @media (min-width: 1280px) and (max-width: 1919px) {
    .xml-feed .xml-feed--meta {
      font-size: 17px;
      line-height: 1.4; } }
  @media (min-width: 992px) and (max-width: 1279px) {
    .xml-feed .xml-feed--meta {
      font-size: 15px;
      line-height: 1.4; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .xml-feed .xml-feed--meta {
      font-size: 15px;
      line-height: 1.4; } }
  @media (min-width: 544px) and (max-width: 767px) {
    .xml-feed .xml-feed--meta {
      font-size: 15px;
      line-height: 1.4; } }
  @media (min-width: 375px) and (max-width: 543px) {
    .xml-feed .xml-feed--meta {
      font-size: 15px;
      line-height: 1.4; } }
  @media (max-width: 374px) {
    .xml-feed .xml-feed--meta {
      font-size: 15px;
      line-height: 1.4; } }

.xml-feed .xml-feed--link {
  font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
  font-weight: 500;
  font-style: normal; }
  @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .xml-feed .xml-feed--link:hover, .xml-feed .xml-feed--link:focus {
      color: #2c2c2c; } }
  @media screen {
    .xml-feed .xml-feed--link:active {
      color: #2c2c2c; } }

.xml-feed .xml-feed--row {
  position: relative; }
  .xml-feed .xml-feed--row::after {
    content: '';
    position: absolute;
    left: 16px;
    bottom: 0;
    display: block;
    width: calc(100% - 32px); }
  .xml-feed .xml-feed--row.xml-feed--row__head {
    padding-bottom: 6px; }
    .xml-feed .xml-feed--row.xml-feed--row__head::after {
      border-bottom: 2px solid #f1f1f2; }
  .xml-feed .xml-feed--row.xml-feed--row__item {
    padding-top: 16px;
    padding-bottom: 17px; }
    .xml-feed .xml-feed--row.xml-feed--row__item::after {
      border-bottom: 1px solid #f1f1f2; }

.xml-feed .xml-feed--col.xml-feed--col__head {
  font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
  font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
  font-weight: 500;
  font-style: normal; }
  @media (min-width: 1920px) {
    .xml-feed .xml-feed--col.xml-feed--col__head {
      font-size: 19px;
      line-height: 1.4; } }
  @media (min-width: 1280px) and (max-width: 1919px) {
    .xml-feed .xml-feed--col.xml-feed--col__head {
      font-size: 17px;
      line-height: 1.4; } }
  @media (min-width: 992px) and (max-width: 1279px) {
    .xml-feed .xml-feed--col.xml-feed--col__head {
      font-size: 15px;
      line-height: 1.4; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .xml-feed .xml-feed--col.xml-feed--col__head {
      font-size: 15px;
      line-height: 1.4; } }
  @media (min-width: 544px) and (max-width: 767px) {
    .xml-feed .xml-feed--col.xml-feed--col__head {
      font-size: 15px;
      line-height: 1.4; } }
  @media (min-width: 375px) and (max-width: 543px) {
    .xml-feed .xml-feed--col.xml-feed--col__head {
      font-size: 15px;
      line-height: 1.4; } }
  @media (max-width: 374px) {
    .xml-feed .xml-feed--col.xml-feed--col__head {
      font-size: 15px;
      line-height: 1.4; } }
  @media (max-width: 767px) {
    .xml-feed .xml-feed--col.xml-feed--col__head:not(:first-child) {
      display: none; } }

.xml-feed .xml-feed--col.xml-feed--col__item {
  color: #555555; }

.xml-feed .xml-feed--checkbox:checked ~ .xml-feed--extra-items {
  order: 0;
  display: block; }

.xml-feed .xml-feed--checkbox:checked ~ .xml-feed--toggle .xml-feed--less {
  display: flex; }

.xml-feed .xml-feed--checkbox:checked ~ .xml-feed--toggle .xml-feed--more {
  display: none; }

.loader-btn {
  position: relative; }
  .loader-btn .loader-btn--content {
    transition: opacity 200ms ease; }
  .loader-btn .loader-btn--loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1; }

.loader-btn.loader-btn__is-loading .loader-btn--content {
  opacity: 0; }

.mobile-nav-btn {
  cursor: pointer; }
  .mobile-nav-btn .mobile-nav-btn--root {
    position: relative;
    display: block;
    width: 18px;
    height: 12px; }
  .mobile-nav-btn .mobile-nav-btn--bar {
    position: absolute;
    left: 0;
    top: 50%;
    display: block;
    width: 100%;
    height: 2px;
    background-color: currentColor;
    opacity: 1;
    transition: transform 200ms ease-out, opacity 200ms ease-out, background-color 200ms ease-out; }
    .mobile-nav-btn .mobile-nav-btn--bar:nth-child(1) {
      transform: translateY(-6px); }
    .mobile-nav-btn .mobile-nav-btn--bar:nth-child(2) {
      transform: translateY(-1px); }
    .mobile-nav-btn .mobile-nav-btn--bar:nth-child(3) {
      transform: translateY(4px); }

.mobile-nav-btn.mobile-nav-btn__is-disabled {
  cursor: default; }
  .mobile-nav-btn.mobile-nav-btn__is-disabled .mobile-nav-btn--bar {
    background-color: #eeeeee; }

.mobile-nav-btn.mobile-nav-btn__is-active .mobile-nav-btn--bar:nth-child(1) {
  transform: rotate(45deg); }

.mobile-nav-btn.mobile-nav-btn__is-active .mobile-nav-btn--bar:nth-child(2) {
  opacity: 0; }

.mobile-nav-btn.mobile-nav-btn__is-active .mobile-nav-btn--bar:nth-child(3) {
  transform: rotate(-45deg); }

.collection-points-details .collection-points-details--container {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  margin: auto; }

.collection-points-details .collection-points-details--content-items {
  display: flex;
  flex-flow: row wrap; }
  html.ie11 .collection-points-details .collection-points-details--content-items {
    min-height: 1px; }

.collection-points-details .collection-points-details--content-item {
  position: relative;
  min-height: 1px;
  max-width: 100%; }

.collection-points-details .collection-points-details--container {
  padding-top: 24px;
  padding-left: 0;
  padding-bottom: 24px;
  padding-right: 0; }
  .collection-points-details .collection-points-details--container .collection-points-details--container:not(.u-iknowwhatimdoing) {
    visibility: hidden !important; }
    .collection-points-details .collection-points-details--container .collection-points-details--container:not(.u-iknowwhatimdoing)::before {
      visibility: visible;
      content: 'Nested `grid--container` detected!';
      font-size: 20px;
      line-height: 1.2;
      color: red; }

.collection-points-details .collection-points-details--content-items {
  margin-top: -12px;
  margin-left: 0;
  margin-bottom: -12px;
  margin-right: 0; }

.collection-points-details .collection-points-details--content-item {
  padding-top: 12px;
  padding-left: 0;
  padding-bottom: 12px;
  padding-right: 0; }

.collection-points-details .collection-points-details--content {
  width: 100%;
  padding: 24px 20px 32px 16px; }

.collection-points-details .collection-points-details--content-item {
  flex: 0 0 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between; }
  html.ie11 .collection-points-details .collection-points-details--content-item {
    flex-basis: auto;
    width: 100%; }
  html.ie10 .collection-points-details .collection-points-details--content-item {
    flex-basis: auto;
    width: 100%; }

.collection-points-details .collection-points-details--content-icon {
  flex: 0 0 24px; }

.collection-points-details .collection-points-details--content-icon-el {
  position: relative; }
  @media (max-width: 1279px) {
    .collection-points-details .collection-points-details--content-icon-el {
      top: -1px; } }

.collection-points-details .collection-points-details--content-text {
  flex: 0 1 100%;
  padding-left: 12px; }

.collection-points-details .collection-points-details--back-btn {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 100%;
  height: 100%;
  border: none;
  padding: 0 16px;
  text-align: left; }
  @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .collection-points-details .collection-points-details--back-btn:hover, .collection-points-details .collection-points-details--back-btn:focus {
      color: #2c834c; } }
  @media screen {
    .collection-points-details .collection-points-details--back-btn:active {
      color: #2c834c; } }
  .collection-points-details .collection-points-details--back-btn:active {
    color: #005523; }

.collection-points-details .collection-points-details--back-btn-icon {
  margin-right: 12px; }

.collection-points-details .collection-points-details--back-btn-text {
  position: relative;
  transform: translateY(2px); }

.collection-points-details .collection-points-details--map {
  position: relative; }

.collection-points-details .collection-points-details--map-link {
  display: block;
  width: 100%;
  padding-top: 56.25%; }

.collection-points-details .collection-points-details--map-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block; }

.collection-points-details .collection-points-details--map-marker {
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -100%); }

.collection-points-details .collection-points-details--title {
  color: #2c2c2c; }

.collection-points-details .collection-points-details--title-link {
  color: #2c834c; }
  @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .collection-points-details .collection-points-details--title-link:hover, .collection-points-details .collection-points-details--title-link:focus {
      color: #005523; } }
  @media screen {
    .collection-points-details .collection-points-details--title-link:active {
      color: #005523; } }

.collection-points-details .collection-points-details--phone {
  font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
  font-weight: 500;
  font-style: normal;
  color: #2c834c; }
  @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .collection-points-details .collection-points-details--phone:hover, .collection-points-details .collection-points-details--phone:focus {
      color: #005523; } }
  @media screen {
    .collection-points-details .collection-points-details--phone:active {
      color: #005523; } }

.collection-points-details .collection-points-details--opening-hours-table {
  width: 100%;
  color: #555555; }

.collection-points-details .collection-points-details--opening-hours-disclaimer {
  font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
  display: block;
  margin-top: 8px;
  color: #555555; }
  @media (min-width: 1920px) {
    .collection-points-details .collection-points-details--opening-hours-disclaimer {
      font-size: 17px;
      line-height: 1.4; } }
  @media (min-width: 1280px) and (max-width: 1919px) {
    .collection-points-details .collection-points-details--opening-hours-disclaimer {
      font-size: 15px;
      line-height: 1.4; } }
  @media (min-width: 992px) and (max-width: 1279px) {
    .collection-points-details .collection-points-details--opening-hours-disclaimer {
      font-size: 13px;
      line-height: 1.4; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .collection-points-details .collection-points-details--opening-hours-disclaimer {
      font-size: 13px;
      line-height: 1.4; } }
  @media (min-width: 544px) and (max-width: 767px) {
    .collection-points-details .collection-points-details--opening-hours-disclaimer {
      font-size: 13px;
      line-height: 1.4; } }
  @media (min-width: 375px) and (max-width: 543px) {
    .collection-points-details .collection-points-details--opening-hours-disclaimer {
      font-size: 13px;
      line-height: 1.4; } }
  @media (max-width: 374px) {
    .collection-points-details .collection-points-details--opening-hours-disclaimer {
      font-size: 13px;
      line-height: 1.4; } }

.collection-points-details .collection-points-details--opening-hours-notice {
  margin-bottom: 8px; }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .collection-points-details .collection-points-details--location:hover, .collection-points-details .collection-points-details--location:focus {
    color: #111111; } }

@media screen {
  .collection-points-details .collection-points-details--location:active {
    color: #111111; } }

.collection-points-details .collection-points-details--directions {
  font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
  font-weight: 500;
  font-style: normal; }

.collection-points-info-panel {
  position: relative;
  width: 100%;
  height: 100%; }
  @media (min-width: 768px) and (max-width: 991px) {
    .collection-points-info-panel {
      width: 304px; } }
  @media (min-width: 992px) {
    .collection-points-info-panel {
      width: 372px; } }
  .collection-points-info-panel .collection-points-info-panel--loader,
  .collection-points-info-panel .collection-points-info-panel--details,
  .collection-points-info-panel .collection-points-info-panel--search {
    width: 100%; }
  .collection-points-info-panel .collection-points-info-panel--loader {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    padding: 24px 12px; }

.collection-points-map .collection-points-map--outer-wrapper {
  position: relative;
  width: 100%;
  padding-top: 66.66667%; }
  @media (min-width: 768px) and (max-width: 991px) {
    .collection-points-map .collection-points-map--outer-wrapper {
      padding-top: 100%; } }

.collection-points-map .collection-points-map--inner-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.collection-points-map .collection-points-map--element {
  width: 100%;
  height: 100%; }

.collection-points-map .collection-points-map--info-panel {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  height: 100%;
  padding: 16px; }

.collection-points-map .collection-points-map--marker-cluster {
  /* stylelint-disable declaration-no-important */
  /* stylelint-enable declaration-no-important */ }
  .collection-points-map .collection-points-map--marker-cluster > img {
    pointer-events: none;
    width: 100%;
    height: 100%;
    opacity: 0; }
  .collection-points-map .collection-points-map--marker-cluster > div {
    color: #2c834c !important;
    font-size: 1.7rem !important;
    line-height: 1 !important;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    border-radius: 50%;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.09), 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 0 0 4px rgba(95, 179, 120, 0.3);
    border: solid 2px #2c834c;
    box-sizing: border-box; }

.collection-points-opening-hours-notice {
  font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
  display: block; }
  @media (min-width: 1920px) {
    .collection-points-opening-hours-notice {
      font-size: 21px;
      line-height: 1.44; } }
  @media (min-width: 1280px) and (max-width: 1919px) {
    .collection-points-opening-hours-notice {
      font-size: 19px;
      line-height: 1.57; } }
  @media (min-width: 992px) and (max-width: 1279px) {
    .collection-points-opening-hours-notice {
      font-size: 17px;
      line-height: 1.52; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .collection-points-opening-hours-notice {
      font-size: 17px;
      line-height: 1.52; } }
  @media (min-width: 544px) and (max-width: 767px) {
    .collection-points-opening-hours-notice {
      font-size: 17px;
      line-height: 1.52; } }
  @media (min-width: 375px) and (max-width: 543px) {
    .collection-points-opening-hours-notice {
      font-size: 17px;
      line-height: 1.52; } }
  @media (max-width: 374px) {
    .collection-points-opening-hours-notice {
      font-size: 17px;
      line-height: 1.52; } }

.collection-points-opening-hours-notice.collection-points-opening-hours-notice__base {
  font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
  font-weight: 500; }
  @media (min-width: 1920px) {
    .collection-points-opening-hours-notice.collection-points-opening-hours-notice__base {
      font-size: 17px;
      line-height: 1.4; } }
  @media (min-width: 1280px) and (max-width: 1919px) {
    .collection-points-opening-hours-notice.collection-points-opening-hours-notice__base {
      font-size: 15px;
      line-height: 1.4; } }
  @media (min-width: 992px) and (max-width: 1279px) {
    .collection-points-opening-hours-notice.collection-points-opening-hours-notice__base {
      font-size: 13px;
      line-height: 1.4; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .collection-points-opening-hours-notice.collection-points-opening-hours-notice__base {
      font-size: 13px;
      line-height: 1.4; } }
  @media (min-width: 544px) and (max-width: 767px) {
    .collection-points-opening-hours-notice.collection-points-opening-hours-notice__base {
      font-size: 13px;
      line-height: 1.4; } }
  @media (min-width: 375px) and (max-width: 543px) {
    .collection-points-opening-hours-notice.collection-points-opening-hours-notice__base {
      font-size: 13px;
      line-height: 1.4; } }
  @media (max-width: 374px) {
    .collection-points-opening-hours-notice.collection-points-opening-hours-notice__base {
      font-size: 13px;
      line-height: 1.4; } }
  .collection-points-opening-hours-notice.collection-points-opening-hours-notice__base.collection-points-opening-hours-notice__opening-state-open {
    color: #2c834c; }
  .collection-points-opening-hours-notice.collection-points-opening-hours-notice__base.collection-points-opening-hours-notice__opening-state-closing-soon {
    color: #e66e00; }
  .collection-points-opening-hours-notice.collection-points-opening-hours-notice__base.collection-points-opening-hours-notice__opening-state-closed {
    color: #555555; }
  .collection-points-opening-hours-notice.collection-points-opening-hours-notice__base.collection-points-opening-hours-notice__opening-state-no-info {
    color: #555555; }

.collection-points-opening-hours-notice.collection-points-opening-hours-notice__in-detail {
  color: #555555;
  font-weight: 500; }

.collection-points-search-result {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding: 8px 16px;
  transition: background-color 200ms ease; }
  @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .collection-points-search-result:hover, .collection-points-search-result:focus {
      background-color: #f1f1f2; } }
  @media screen {
    .collection-points-search-result:active {
      background-color: #f1f1f2; } }
  .collection-points-search-result .collection-points-search-result--icon {
    flex: 0 0 24px; }
  .collection-points-search-result .collection-points-search-result--text {
    position: relative;
    transform: translateY(3px);
    flex: 0 1 100%;
    padding-left: 12px; }
  .collection-points-search-result .collection-points-search-result--title {
    color: #555555; }
  .collection-points-search-result .collection-points-search-result--description {
    font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
    font-weight: 400;
    font-style: normal; }
    @media (min-width: 1920px) {
      .collection-points-search-result .collection-points-search-result--description {
        font-size: 17px;
        line-height: 1.4; } }
    @media (min-width: 1280px) and (max-width: 1919px) {
      .collection-points-search-result .collection-points-search-result--description {
        font-size: 15px;
        line-height: 1.4; } }
    @media (min-width: 992px) and (max-width: 1279px) {
      .collection-points-search-result .collection-points-search-result--description {
        font-size: 13px;
        line-height: 1.4; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .collection-points-search-result .collection-points-search-result--description {
        font-size: 13px;
        line-height: 1.4; } }
    @media (min-width: 544px) and (max-width: 767px) {
      .collection-points-search-result .collection-points-search-result--description {
        font-size: 13px;
        line-height: 1.4; } }
    @media (min-width: 375px) and (max-width: 543px) {
      .collection-points-search-result .collection-points-search-result--description {
        font-size: 13px;
        line-height: 1.4; } }
    @media (max-width: 374px) {
      .collection-points-search-result .collection-points-search-result--description {
        font-size: 13px;
        line-height: 1.4; } }

.collection-points-search-result .collection-points-search-result--icon.collection-points-search-result--icon__is-active {
  color: #2c834c; }

.collection-points-search-results .collection-points-search-results--item ~ .collection-points-search-results--item {
  border-top: 1px solid #f1f1f2; }

.collection-points-search .collection-points-search--input {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  height: 64px; }

.collection-points-search .collection-points-search--input-element {
  flex: 1 0 auto; }

.collection-points-search .collection-points-search--input-actions {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: stretch;
  flex: 1 0 auto; }

.collection-points-search .collection-points-search--input-search-btn,
.collection-points-search .collection-points-search--input-clear-btn {
  pointer-events: auto;
  height: 100%;
  border: none; }
  @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .collection-points-search .collection-points-search--input-search-btn:hover, .collection-points-search .collection-points-search--input-search-btn:focus,
    .collection-points-search .collection-points-search--input-clear-btn:hover,
    .collection-points-search .collection-points-search--input-clear-btn:focus {
      color: #2c834c; } }
  @media screen {
    .collection-points-search .collection-points-search--input-search-btn:active,
    .collection-points-search .collection-points-search--input-clear-btn:active {
      color: #2c834c; } }
  .collection-points-search .collection-points-search--input-search-btn:active,
  .collection-points-search .collection-points-search--input-clear-btn:active {
    color: #005523; }

.collection-points-search .collection-points-search--input-search-btn:disabled {
  color: #a5a3a3; }

.collection-points-search .collection-points-search--input-clear-btn:disabled {
  opacity: 0;
  color: #a5a3a3; }

.collection-points-search .collection-points-search--input-search-btn {
  padding: 0 12px 0 16px; }

.collection-points-search .collection-points-search--input-clear-btn {
  padding: 0 16px 0 12px; }

.collection-points-search .collection-points-search--locator-btn {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 100%;
  height: 56px;
  border: none;
  padding: 0 16px;
  text-align: left; }
  @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .collection-points-search .collection-points-search--locator-btn:hover, .collection-points-search .collection-points-search--locator-btn:focus {
      color: #2c834c; } }
  @media screen {
    .collection-points-search .collection-points-search--locator-btn:active {
      color: #2c834c; } }
  .collection-points-search .collection-points-search--locator-btn:active {
    color: #005523; }

.collection-points-search .collection-points-search--locator-btn-icon {
  margin-right: 12px; }

.collection-points-search .collection-points-search--locator-btn-text {
  font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
  font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
  font-weight: 500;
  font-style: normal; }
  @media (min-width: 1920px) {
    .collection-points-search .collection-points-search--locator-btn-text {
      font-size: 19px;
      line-height: 1.4; } }
  @media (min-width: 1280px) and (max-width: 1919px) {
    .collection-points-search .collection-points-search--locator-btn-text {
      font-size: 17px;
      line-height: 1.4; } }
  @media (min-width: 992px) and (max-width: 1279px) {
    .collection-points-search .collection-points-search--locator-btn-text {
      font-size: 15px;
      line-height: 1.4; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .collection-points-search .collection-points-search--locator-btn-text {
      font-size: 15px;
      line-height: 1.4; } }
  @media (min-width: 544px) and (max-width: 767px) {
    .collection-points-search .collection-points-search--locator-btn-text {
      font-size: 15px;
      line-height: 1.4; } }
  @media (min-width: 375px) and (max-width: 543px) {
    .collection-points-search .collection-points-search--locator-btn-text {
      font-size: 15px;
      line-height: 1.4; } }
  @media (max-width: 374px) {
    .collection-points-search .collection-points-search--locator-btn-text {
      font-size: 15px;
      line-height: 1.4; } }

.collection-points-search .collection-points-search--footer {
  padding: 10px 16px; }

.collection-points-search .collection-points-search--footer-text {
  font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
  font-weight: 500;
  font-style: normal;
  font-size: 1.3rem;
  line-height: 1.3rem;
  text-align: center; }
  .collection-points-search .collection-points-search--footer-text.collection-points-search--footer-text__base {
    color: #2c2c2c; }
  .collection-points-search .collection-points-search--footer-text.collection-points-search--footer-text__error {
    color: #ff0033; }

.collection-points-search .collection-points-search--loader {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  padding: 24px 12px; }

.collection-points-search .collection-points-search--footer {
  border-top: none; }
  .collection-points-search .collection-points-search--footer.collection-points-search--footer__has-results {
    border-top: 1px solid #f1f1f2; }

.form-field-messages {
  position: relative;
  margin-top: 8px;
  text-align: left; }
  .form-field-messages .form-field-messages--message {
    display: block;
    font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
    font-weight: 400;
    font-style: normal; }
    @media (min-width: 1920px) {
      .form-field-messages .form-field-messages--message {
        font-size: 17px;
        line-height: 1.4; } }
    @media (min-width: 1280px) and (max-width: 1919px) {
      .form-field-messages .form-field-messages--message {
        font-size: 15px;
        line-height: 1.4; } }
    @media (min-width: 992px) and (max-width: 1279px) {
      .form-field-messages .form-field-messages--message {
        font-size: 13px;
        line-height: 1.4; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .form-field-messages .form-field-messages--message {
        font-size: 13px;
        line-height: 1.4; } }
    @media (min-width: 544px) and (max-width: 767px) {
      .form-field-messages .form-field-messages--message {
        font-size: 13px;
        line-height: 1.4; } }
    @media (min-width: 375px) and (max-width: 543px) {
      .form-field-messages .form-field-messages--message {
        font-size: 13px;
        line-height: 1.4; } }
    @media (max-width: 374px) {
      .form-field-messages .form-field-messages--message {
        font-size: 13px;
        line-height: 1.4; } }
    .form-field-messages .form-field-messages--message ~ .form-field-messages--message {
      margin-top: 3px; }
    .form-field-messages .form-field-messages--message.form-field-messages--message__info {
      color: #0000ff; }
    .form-field-messages .form-field-messages--message.form-field-messages--message__error {
      color: #ff0033; }

.form-fieldset .form-fieldset--label {
  display: block;
  margin-bottom: 16px; }

.form-general-messages .form-general-messages--item {
  display: flex;
  width: 100%;
  line-height: initial; }
  .form-general-messages .form-general-messages--item ~ .form-general-messages--item {
    margin-top: 4px; }

.form-general-messages .form-general-messages--text {
  display: inline-block;
  font-size: 1.3rem;
  line-height: 1.3;
  color: #ff0033; }

.form-submit {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  width: 100%; }
  .form-submit .form-submit--wrap {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-end;
    width: 100%; }
  .form-submit .form-submit--general-errors {
    margin-top: 8px; }
  .form-submit .form-submit--recaptcha-notice {
    display: block;
    margin-top: 8px;
    font-size: 1.3rem;
    line-height: 1.3; }

@keyframes ani-dot-loader {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.dot-loader {
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  .dot-loader .dot-loader--loader {
    display: flex;
    justify-content: space-between;
    width: 36px;
    height: 8px; }
  .dot-loader .dot-loader--dot {
    opacity: 0;
    border-radius: 50%;
    display: block;
    width: 8px;
    height: 8px;
    animation: ani-dot-loader 1200ms linear infinite; }
    .dot-loader .dot-loader--dot ~ .dot-loader--dot {
      margin-left: 8px; }
    .dot-loader .dot-loader--dot.dot-loader--dot:nth-child(1) {
      animation-delay: 0ms; }
    .dot-loader .dot-loader--dot.dot-loader--dot:nth-child(2) {
      animation-delay: 200ms; }
    .dot-loader .dot-loader--dot.dot-loader--dot:nth-child(3) {
      animation-delay: 400ms; }

.dot-loader.dot-loader__base {
  background-color: rgba(255, 255, 255, 0.125); }
  .dot-loader.dot-loader__base .dot-loader--dot {
    background-color: rgba(255, 255, 255, 0.5); }

.dot-loader.dot-loader__main {
  background-color: rgba(255, 255, 255, 0.125); }
  .dot-loader.dot-loader__main .dot-loader--dot {
    background-color: rgba(0, 0, 0, 0.5); }

.dot-loader {
  transition: visibility 0ms linear 200ms, opacity 200ms ease 0ms;
  visibility: hidden;
  opacity: 0; }

.dot-loader.dot-loader__is-active {
  transition: visibility 0ms linear 0ms, opacity 200ms ease 0ms;
  visibility: inherit;
  opacity: 1; }

.spinning-loader {
  display: inline-block;
  margin: auto; }
  .spinning-loader .spinning-loader--svg {
    width: 100%;
    height: 100%; }
    .spinning-loader .spinning-loader--svg .spinning-loader--svg-path {
      fill: none;
      stroke: currentColor;
      stroke-width: 2px; }

.opening-hours-table {
  font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal; }
  @media (min-width: 1920px) {
    .opening-hours-table {
      font-size: 19px;
      line-height: 1.4; } }
  @media (min-width: 1280px) and (max-width: 1919px) {
    .opening-hours-table {
      font-size: 17px;
      line-height: 1.4; } }
  @media (min-width: 992px) and (max-width: 1279px) {
    .opening-hours-table {
      font-size: 15px;
      line-height: 1.4; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .opening-hours-table {
      font-size: 15px;
      line-height: 1.4; } }
  @media (min-width: 544px) and (max-width: 767px) {
    .opening-hours-table {
      font-size: 15px;
      line-height: 1.4; } }
  @media (min-width: 375px) and (max-width: 543px) {
    .opening-hours-table {
      font-size: 15px;
      line-height: 1.4; } }
  @media (max-width: 374px) {
    .opening-hours-table {
      font-size: 15px;
      line-height: 1.4; } }
  .opening-hours-table thead {
    position: absolute;
    overflow: hidden;
    max-width: 1px;
    max-height: 1px;
    padding: 0;
    border: 0;
    margin: -1px;
    clip: rect(0 0 0 0); }
  .opening-hours-table td {
    padding-top: 3px;
    padding-bottom: 1px; }
    .opening-hours-table td > span {
      display: block; }
    .opening-hours-table td:first-child {
      padding-right: 16px; }
  .opening-hours-table tr:first-child td {
    padding-top: 0; }
  .opening-hours-table tr:last-child td {
    padding-bottom: 0; }

.opening-hours-table .opening-hours-table--tr.opening-hours-table--tr__is-current-day {
  font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
  font-weight: 500;
  font-style: normal;
  color: #2c2c2c; }

.accordion-wrapper .accordion-wrapper--head {
  display: flex;
  flex-wrap: nowrap;
  width: 100%; }

@keyframes flash {
  0%, 100% {
    background-color: initial; }
  50% {
    background-color: rgba(217, 33, 61, 0.2); } }

.accordion-wrapper--highlight {
  animation: flash 2500ms ease-in-out; }

.animation-hook {
  position: absolute;
  width: 4px;
  height: 4px; }

.canvas-animation {
  z-index: -1;
  position: absolute;
  left: 0;
  top: 0; }
  @media (min-width: 992px) {
    .canvas-animation {
      position: fixed; } }
  @media print {
    .canvas-animation {
      display: none; } }
  html[data-theme="association"] .canvas-animation {
    color: #d9213d; }
  html[data-theme="safety"] .canvas-animation {
    color: #e66e00; }
  html[data-theme="recycling"] .canvas-animation {
    color: #2c834c; }
  html[data-theme="knowledge"] .canvas-animation {
    color: #1469c9; }
  html[data-theme="education"] .canvas-animation {
    color: #631e65; }
  html[data-theme="sustainability"] .canvas-animation {
    color: #1da321; }
  html[data-theme="default"] .canvas-animation {
    color: #d9213d; }
  html[data-theme="default"] .canvas-animation {
    color: #d9213d; }

.collection-points-app {
  margin-top: 24px; }

.color-filter {
  pointer-events: none;
  z-index: 10000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

@keyframes animate-arcobaleno {
  0% {
    background-position: 0% 99%; }
  50% {
    background-position: 100% 2%; }
  100% {
    background-position: 0% 99%; } }

.color-filter.color-filter__arcobaleno {
  background-image: linear-gradient(45deg, orange, yellow, green, cyan, blue, violet);
  background-size: 100% 1000%;
  mix-blend-mode: overlay;
  animation: animate-arcobaleno 10000ms ease infinite; }

.color-filter.color-filter__what-is-love {
  background-image: linear-gradient(45deg, #eb80ff, #ff0065);
  background-size: 100% 1000%;
  mix-blend-mode: overlay; }

.cookie-banner {
  z-index: 99;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 16px 0;
  background-color: #111111;
  color: #ffffff; }
  @media (max-width: 991px) {
    .cookie-banner {
      padding: 8px 0; } }
  @media print {
    .cookie-banner {
      display: none; } }

.cookie-banner .cookie-banner--message-col {
  flex: 0 0 75%; }
  html.ie11 .cookie-banner .cookie-banner--message-col {
    flex-basis: auto;
    width: 75%; }
  html.ie10 .cookie-banner .cookie-banner--message-col {
    flex-basis: auto;
    width: 75%; }
  @media (max-width: 991px) {
    .cookie-banner .cookie-banner--message-col {
      flex: 0 0 100%; }
      html.ie11 .cookie-banner .cookie-banner--message-col {
        flex-basis: auto;
        width: 100%; }
      html.ie10 .cookie-banner .cookie-banner--message-col {
        flex-basis: auto;
        width: 100%; } }
  .cookie-banner .cookie-banner--message-col p {
    font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
    font-weight: 400;
    font-style: normal; }
    @media (min-width: 1920px) {
      .cookie-banner .cookie-banner--message-col p {
        font-size: 19px;
        line-height: 1.4; } }
    @media (min-width: 1280px) and (max-width: 1919px) {
      .cookie-banner .cookie-banner--message-col p {
        font-size: 17px;
        line-height: 1.4; } }
    @media (min-width: 992px) and (max-width: 1279px) {
      .cookie-banner .cookie-banner--message-col p {
        font-size: 15px;
        line-height: 1.4; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .cookie-banner .cookie-banner--message-col p {
        font-size: 15px;
        line-height: 1.4; } }
    @media (min-width: 544px) and (max-width: 767px) {
      .cookie-banner .cookie-banner--message-col p {
        font-size: 15px;
        line-height: 1.4; } }
    @media (min-width: 375px) and (max-width: 543px) {
      .cookie-banner .cookie-banner--message-col p {
        font-size: 15px;
        line-height: 1.4; } }
    @media (max-width: 374px) {
      .cookie-banner .cookie-banner--message-col p {
        font-size: 15px;
        line-height: 1.4; } }
  .cookie-banner .cookie-banner--message-col a {
    color: #ffffff !important;
    text-decoration: underline !important;
    font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
    font-weight: 400;
    font-style: normal; }
    @media (min-width: 1920px) {
      .cookie-banner .cookie-banner--message-col a {
        font-size: 19px;
        line-height: 1.4; } }
    @media (min-width: 1280px) and (max-width: 1919px) {
      .cookie-banner .cookie-banner--message-col a {
        font-size: 17px;
        line-height: 1.4; } }
    @media (min-width: 992px) and (max-width: 1279px) {
      .cookie-banner .cookie-banner--message-col a {
        font-size: 15px;
        line-height: 1.4; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .cookie-banner .cookie-banner--message-col a {
        font-size: 15px;
        line-height: 1.4; } }
    @media (min-width: 544px) and (max-width: 767px) {
      .cookie-banner .cookie-banner--message-col a {
        font-size: 15px;
        line-height: 1.4; } }
    @media (min-width: 375px) and (max-width: 543px) {
      .cookie-banner .cookie-banner--message-col a {
        font-size: 15px;
        line-height: 1.4; } }
    @media (max-width: 374px) {
      .cookie-banner .cookie-banner--message-col a {
        font-size: 15px;
        line-height: 1.4; } }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .cookie-banner .cookie-banner--button:hover, .cookie-banner .cookie-banner--button:focus {
    color: #f1f1f2; } }

@media screen {
  .cookie-banner .cookie-banner--button:active {
    color: #f1f1f2; } }

.cookie-banner .cookie-banner--actions-col {
  flex: 0 0 25%;
  display: flex;
  justify-content: flex-end; }
  html.ie11 .cookie-banner .cookie-banner--actions-col {
    flex-basis: auto;
    width: 25%; }
  html.ie10 .cookie-banner .cookie-banner--actions-col {
    flex-basis: auto;
    width: 25%; }
  @media (max-width: 991px) {
    .cookie-banner .cookie-banner--actions-col {
      flex: 0 0 100%; }
      html.ie11 .cookie-banner .cookie-banner--actions-col {
        flex-basis: auto;
        width: 100%; }
      html.ie10 .cookie-banner .cookie-banner--actions-col {
        flex-basis: auto;
        width: 100%; } }

.cookie-banner .cookie-banner--row {
  justify-content: space-between;
  align-items: center; }

/* stylelint-disable max-nesting-depth */
.desktop-menu {
  position: relative; }
  .desktop-menu .desktop-menu--collection {
    display: flex;
    align-items: center;
    border-radius: 4px; }
  .desktop-menu .desktop-menu--shadow {
    position: relative;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09), 0 1px 2px rgba(0, 0, 0, 0.2);
    transition: box-shadow 200ms ease;
    border-radius: 4px;
    background-color: #ffffff; }
  .desktop-menu .desktop-menu--wrap {
    position: relative;
    min-height: 1px;
    max-width: 100%;
    flex: 0 0 100%; }
    html.ie11 .desktop-menu .desktop-menu--wrap {
      flex-basis: auto;
      width: 100%; }
    html.ie10 .desktop-menu .desktop-menu--wrap {
      flex-basis: auto;
      width: 100%; }
  .desktop-menu .desktop-menu--link {
    display: flex;
    padding-left: 16px;
    padding-right: 16px;
    color: #555555;
    transform: translate(0, 1px);
    cursor: pointer;
    font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
    font-weight: 500;
    font-style: normal; }
    @media (min-width: 1920px) {
      .desktop-menu .desktop-menu--link {
        padding-left: 24px;
        padding-right: 24px; } }
    @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .desktop-menu .desktop-menu--link:hover, .desktop-menu .desktop-menu--link:focus {
        color: #111111; } }
    @media screen {
      .desktop-menu .desktop-menu--link:active {
        color: #111111; } }
  .desktop-menu .desktop-menu--dropdown {
    z-index: 11;
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%; }
    .desktop-menu .desktop-menu--dropdown::before {
      content: '';
      position: absolute;
      top: -4px;
      left: 0;
      height: 4px;
      width: 100%;
      background: #ffffff; }

.desktop-menu.desktop-menu__big-shadow .desktop-menu--shadow {
  box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.08), 0 8px 16px 0 rgba(0, 0, 0, 0.12); }

.desktop-menu.desktop-menu__no-shadow .desktop-menu--shadow {
  box-shadow: none; }

.desktop-menu .desktop-menu--collection {
  /**
            * START first level navigation styling
            */
  /**
            * END first level navigation styling
            */
  /**
            * START second level navigation styling
            */
  /**
            * END second level navigation styling
            */ }
  .desktop-menu .desktop-menu--collection.desktop-menu--collection__first > .desktop-menu--item > .desktop-menu--link {
    padding-top: 20px;
    padding-bottom: 18px;
    font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
    font-weight: 400;
    font-style: normal;
    font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
    font-weight: 500;
    font-style: normal; }
    @media (min-width: 1920px) {
      .desktop-menu .desktop-menu--collection.desktop-menu--collection__first > .desktop-menu--item > .desktop-menu--link {
        font-size: 19px;
        line-height: 1.4; } }
    @media (min-width: 1280px) and (max-width: 1919px) {
      .desktop-menu .desktop-menu--collection.desktop-menu--collection__first > .desktop-menu--item > .desktop-menu--link {
        font-size: 17px;
        line-height: 1.4; } }
    @media (min-width: 992px) and (max-width: 1279px) {
      .desktop-menu .desktop-menu--collection.desktop-menu--collection__first > .desktop-menu--item > .desktop-menu--link {
        font-size: 15px;
        line-height: 1.4; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .desktop-menu .desktop-menu--collection.desktop-menu--collection__first > .desktop-menu--item > .desktop-menu--link {
        font-size: 15px;
        line-height: 1.4; } }
    @media (min-width: 544px) and (max-width: 767px) {
      .desktop-menu .desktop-menu--collection.desktop-menu--collection__first > .desktop-menu--item > .desktop-menu--link {
        font-size: 15px;
        line-height: 1.4; } }
    @media (min-width: 375px) and (max-width: 543px) {
      .desktop-menu .desktop-menu--collection.desktop-menu--collection__first > .desktop-menu--item > .desktop-menu--link {
        font-size: 15px;
        line-height: 1.4; } }
    @media (max-width: 374px) {
      .desktop-menu .desktop-menu--collection.desktop-menu--collection__first > .desktop-menu--item > .desktop-menu--link {
        font-size: 15px;
        line-height: 1.4; } }
    @media (max-width: 1919px) {
      .desktop-menu .desktop-menu--collection.desktop-menu--collection__first > .desktop-menu--item > .desktop-menu--link {
        padding-top: 17px;
        padding-bottom: 16px; } }
  .desktop-menu .desktop-menu--collection.desktop-menu--collection__first > .desktop-menu--item.desktop-menu--item__search {
    margin-left: auto; }
    .desktop-menu .desktop-menu--collection.desktop-menu--collection__first > .desktop-menu--item.desktop-menu--item__search .desktop-menu--link {
      padding-top: 16px;
      padding-bottom: 16px; }
  .desktop-menu .desktop-menu--collection.desktop-menu--collection__first > .desktop-menu--item.desktop-menu--item__association > .desktop-menu--link.desktop-menu--link__active {
    color: #a00017; }
  @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .desktop-menu .desktop-menu--collection.desktop-menu--collection__first > .desktop-menu--item.desktop-menu--item__association > .desktop-menu--link:hover, .desktop-menu .desktop-menu--collection.desktop-menu--collection__first > .desktop-menu--item.desktop-menu--item__association > .desktop-menu--link:focus {
      color: #a00017; } }
  @media screen {
    .desktop-menu .desktop-menu--collection.desktop-menu--collection__first > .desktop-menu--item.desktop-menu--item__association > .desktop-menu--link:active {
      color: #a00017; } }
  .desktop-menu .desktop-menu--collection.desktop-menu--collection__first > .desktop-menu--item.desktop-menu--item__safety > .desktop-menu--link.desktop-menu--link__active {
    color: #ad4000; }
  @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .desktop-menu .desktop-menu--collection.desktop-menu--collection__first > .desktop-menu--item.desktop-menu--item__safety > .desktop-menu--link:hover, .desktop-menu .desktop-menu--collection.desktop-menu--collection__first > .desktop-menu--item.desktop-menu--item__safety > .desktop-menu--link:focus {
      color: #ad4000; } }
  @media screen {
    .desktop-menu .desktop-menu--collection.desktop-menu--collection__first > .desktop-menu--item.desktop-menu--item__safety > .desktop-menu--link:active {
      color: #ad4000; } }
  .desktop-menu .desktop-menu--collection.desktop-menu--collection__first > .desktop-menu--item.desktop-menu--item__recycling > .desktop-menu--link.desktop-menu--link__active {
    color: #005523; }
  @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .desktop-menu .desktop-menu--collection.desktop-menu--collection__first > .desktop-menu--item.desktop-menu--item__recycling > .desktop-menu--link:hover, .desktop-menu .desktop-menu--collection.desktop-menu--collection__first > .desktop-menu--item.desktop-menu--item__recycling > .desktop-menu--link:focus {
      color: #005523; } }
  @media screen {
    .desktop-menu .desktop-menu--collection.desktop-menu--collection__first > .desktop-menu--item.desktop-menu--item__recycling > .desktop-menu--link:active {
      color: #005523; } }
  .desktop-menu .desktop-menu--collection.desktop-menu--collection__first > .desktop-menu--item.desktop-menu--item__knowledge > .desktop-menu--link.desktop-menu--link__active {
    color: #003f97; }
  @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .desktop-menu .desktop-menu--collection.desktop-menu--collection__first > .desktop-menu--item.desktop-menu--item__knowledge > .desktop-menu--link:hover, .desktop-menu .desktop-menu--collection.desktop-menu--collection__first > .desktop-menu--item.desktop-menu--item__knowledge > .desktop-menu--link:focus {
      color: #003f97; } }
  @media screen {
    .desktop-menu .desktop-menu--collection.desktop-menu--collection__first > .desktop-menu--item.desktop-menu--item__knowledge > .desktop-menu--link:active {
      color: #003f97; } }
  .desktop-menu .desktop-menu--collection.desktop-menu--collection__first > .desktop-menu--item.desktop-menu--item__education > .desktop-menu--link.desktop-menu--link__active {
    color: #36003a; }
  @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .desktop-menu .desktop-menu--collection.desktop-menu--collection__first > .desktop-menu--item.desktop-menu--item__education > .desktop-menu--link:hover, .desktop-menu .desktop-menu--collection.desktop-menu--collection__first > .desktop-menu--item.desktop-menu--item__education > .desktop-menu--link:focus {
      color: #36003a; } }
  @media screen {
    .desktop-menu .desktop-menu--collection.desktop-menu--collection__first > .desktop-menu--item.desktop-menu--item__education > .desktop-menu--link:active {
      color: #36003a; } }
  .desktop-menu .desktop-menu--collection.desktop-menu--collection__first > .desktop-menu--item.desktop-menu--item__sustainability > .desktop-menu--link.desktop-menu--link__active {
    color: #116214; }
  @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .desktop-menu .desktop-menu--collection.desktop-menu--collection__first > .desktop-menu--item.desktop-menu--item__sustainability > .desktop-menu--link:hover, .desktop-menu .desktop-menu--collection.desktop-menu--collection__first > .desktop-menu--item.desktop-menu--item__sustainability > .desktop-menu--link:focus {
      color: #116214; } }
  @media screen {
    .desktop-menu .desktop-menu--collection.desktop-menu--collection__first > .desktop-menu--item.desktop-menu--item__sustainability > .desktop-menu--link:active {
      color: #116214; } }
  .desktop-menu .desktop-menu--collection.desktop-menu--collection__first > .desktop-menu--item.desktop-menu--item__default > .desktop-menu--link.desktop-menu--link__active {
    color: #a00017; }
  @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .desktop-menu .desktop-menu--collection.desktop-menu--collection__first > .desktop-menu--item.desktop-menu--item__default > .desktop-menu--link:hover, .desktop-menu .desktop-menu--collection.desktop-menu--collection__first > .desktop-menu--item.desktop-menu--item__default > .desktop-menu--link:focus {
      color: #a00017; } }
  @media screen {
    .desktop-menu .desktop-menu--collection.desktop-menu--collection__first > .desktop-menu--item.desktop-menu--item__default > .desktop-menu--link:active {
      color: #a00017; } }
  .desktop-menu .desktop-menu--collection.desktop-menu--collection__first > .desktop-menu--item.desktop-menu--item__active {
    border-radius: 4px 4px 0 0;
    background: #ffffff; }
  .desktop-menu .desktop-menu--collection.desktop-menu--collection__second {
    position: absolute;
    left: 0;
    width: 100%; }
    .desktop-menu .desktop-menu--collection.desktop-menu--collection__second::before {
      content: '';
      z-index: 12;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background: #ffffff;
      border-radius: 0 0 4px 4px; }
    .desktop-menu .desktop-menu--collection.desktop-menu--collection__second > .desktop-menu--item > .desktop-menu--details > .desktop-menu--link,
    .desktop-menu .desktop-menu--collection.desktop-menu--collection__second > .desktop-menu--item > .desktop-menu--link {
      z-index: 13;
      position: relative;
      font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
      font-weight: 400;
      font-style: normal;
      font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
      font-weight: 500;
      font-style: normal; }
      @media (min-width: 1920px) {
        .desktop-menu .desktop-menu--collection.desktop-menu--collection__second > .desktop-menu--item > .desktop-menu--details > .desktop-menu--link,
        .desktop-menu .desktop-menu--collection.desktop-menu--collection__second > .desktop-menu--item > .desktop-menu--link {
          font-size: 19px;
          line-height: 1.4; } }
      @media (min-width: 1280px) and (max-width: 1919px) {
        .desktop-menu .desktop-menu--collection.desktop-menu--collection__second > .desktop-menu--item > .desktop-menu--details > .desktop-menu--link,
        .desktop-menu .desktop-menu--collection.desktop-menu--collection__second > .desktop-menu--item > .desktop-menu--link {
          font-size: 17px;
          line-height: 1.4; } }
      @media (min-width: 992px) and (max-width: 1279px) {
        .desktop-menu .desktop-menu--collection.desktop-menu--collection__second > .desktop-menu--item > .desktop-menu--details > .desktop-menu--link,
        .desktop-menu .desktop-menu--collection.desktop-menu--collection__second > .desktop-menu--item > .desktop-menu--link {
          font-size: 15px;
          line-height: 1.4; } }
      @media (min-width: 768px) and (max-width: 991px) {
        .desktop-menu .desktop-menu--collection.desktop-menu--collection__second > .desktop-menu--item > .desktop-menu--details > .desktop-menu--link,
        .desktop-menu .desktop-menu--collection.desktop-menu--collection__second > .desktop-menu--item > .desktop-menu--link {
          font-size: 15px;
          line-height: 1.4; } }
      @media (min-width: 544px) and (max-width: 767px) {
        .desktop-menu .desktop-menu--collection.desktop-menu--collection__second > .desktop-menu--item > .desktop-menu--details > .desktop-menu--link,
        .desktop-menu .desktop-menu--collection.desktop-menu--collection__second > .desktop-menu--item > .desktop-menu--link {
          font-size: 15px;
          line-height: 1.4; } }
      @media (min-width: 375px) and (max-width: 543px) {
        .desktop-menu .desktop-menu--collection.desktop-menu--collection__second > .desktop-menu--item > .desktop-menu--details > .desktop-menu--link,
        .desktop-menu .desktop-menu--collection.desktop-menu--collection__second > .desktop-menu--item > .desktop-menu--link {
          font-size: 15px;
          line-height: 1.4; } }
      @media (max-width: 374px) {
        .desktop-menu .desktop-menu--collection.desktop-menu--collection__second > .desktop-menu--item > .desktop-menu--details > .desktop-menu--link,
        .desktop-menu .desktop-menu--collection.desktop-menu--collection__second > .desktop-menu--item > .desktop-menu--link {
          font-size: 15px;
          line-height: 1.4; } }
    .desktop-menu .desktop-menu--collection.desktop-menu--collection__second > .desktop-menu--item .desktop-menu--link-label {
      display: inline-block;
      padding-top: 19px;
      padding-bottom: 18px;
      border-bottom: 1px solid transparent;
      font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
      font-weight: 400;
      font-style: normal;
      font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
      font-weight: 500;
      font-style: normal; }
      @media (min-width: 1920px) {
        .desktop-menu .desktop-menu--collection.desktop-menu--collection__second > .desktop-menu--item .desktop-menu--link-label {
          font-size: 19px;
          line-height: 1.4; } }
      @media (min-width: 1280px) and (max-width: 1919px) {
        .desktop-menu .desktop-menu--collection.desktop-menu--collection__second > .desktop-menu--item .desktop-menu--link-label {
          font-size: 17px;
          line-height: 1.4; } }
      @media (min-width: 992px) and (max-width: 1279px) {
        .desktop-menu .desktop-menu--collection.desktop-menu--collection__second > .desktop-menu--item .desktop-menu--link-label {
          font-size: 15px;
          line-height: 1.4; } }
      @media (min-width: 768px) and (max-width: 991px) {
        .desktop-menu .desktop-menu--collection.desktop-menu--collection__second > .desktop-menu--item .desktop-menu--link-label {
          font-size: 15px;
          line-height: 1.4; } }
      @media (min-width: 544px) and (max-width: 767px) {
        .desktop-menu .desktop-menu--collection.desktop-menu--collection__second > .desktop-menu--item .desktop-menu--link-label {
          font-size: 15px;
          line-height: 1.4; } }
      @media (min-width: 375px) and (max-width: 543px) {
        .desktop-menu .desktop-menu--collection.desktop-menu--collection__second > .desktop-menu--item .desktop-menu--link-label {
          font-size: 15px;
          line-height: 1.4; } }
      @media (max-width: 374px) {
        .desktop-menu .desktop-menu--collection.desktop-menu--collection__second > .desktop-menu--item .desktop-menu--link-label {
          font-size: 15px;
          line-height: 1.4; } }
      @media (min-width: 1280px) and (max-width: 1919px) {
        .desktop-menu .desktop-menu--collection.desktop-menu--collection__second > .desktop-menu--item .desktop-menu--link-label {
          padding-top: 17px;
          padding-bottom: 15px; } }
      @media (max-width: 1279px) {
        .desktop-menu .desktop-menu--collection.desktop-menu--collection__second > .desktop-menu--item .desktop-menu--link-label {
          padding-top: 18px;
          padding-bottom: 16px; } }
  .desktop-menu .desktop-menu--collection .desktop-menu--item.desktop-menu--item__association .desktop-menu--collection__second .desktop-menu--item .desktop-menu--details[open] .desktop-menu--link-label {
    color: #a00017;
    border-bottom: 1px solid #a00017; }
  .desktop-menu .desktop-menu--collection .desktop-menu--item.desktop-menu--item__association .desktop-menu--collection__second .desktop-menu--item .desktop-menu--details > .desktop-menu--link.desktop-menu--link__active,
  .desktop-menu .desktop-menu--collection .desktop-menu--item.desktop-menu--item__association .desktop-menu--collection__second .desktop-menu--item > .desktop-menu--link.desktop-menu--link__active {
    color: #a00017; }
  @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .desktop-menu .desktop-menu--collection .desktop-menu--item.desktop-menu--item__association .desktop-menu--collection__second .desktop-menu--item .desktop-menu--details > .desktop-menu--link:hover, .desktop-menu .desktop-menu--collection .desktop-menu--item.desktop-menu--item__association .desktop-menu--collection__second .desktop-menu--item .desktop-menu--details > .desktop-menu--link:focus,
    .desktop-menu .desktop-menu--collection .desktop-menu--item.desktop-menu--item__association .desktop-menu--collection__second .desktop-menu--item > .desktop-menu--link:hover,
    .desktop-menu .desktop-menu--collection .desktop-menu--item.desktop-menu--item__association .desktop-menu--collection__second .desktop-menu--item > .desktop-menu--link:focus {
      color: #a00017; } }
  @media screen {
    .desktop-menu .desktop-menu--collection .desktop-menu--item.desktop-menu--item__association .desktop-menu--collection__second .desktop-menu--item .desktop-menu--details > .desktop-menu--link:active,
    .desktop-menu .desktop-menu--collection .desktop-menu--item.desktop-menu--item__association .desktop-menu--collection__second .desktop-menu--item > .desktop-menu--link:active {
      color: #a00017; } }
  .desktop-menu .desktop-menu--collection .desktop-menu--item.desktop-menu--item__safety .desktop-menu--collection__second .desktop-menu--item .desktop-menu--details[open] .desktop-menu--link-label {
    color: #ad4000;
    border-bottom: 1px solid #ad4000; }
  .desktop-menu .desktop-menu--collection .desktop-menu--item.desktop-menu--item__safety .desktop-menu--collection__second .desktop-menu--item .desktop-menu--details > .desktop-menu--link.desktop-menu--link__active,
  .desktop-menu .desktop-menu--collection .desktop-menu--item.desktop-menu--item__safety .desktop-menu--collection__second .desktop-menu--item > .desktop-menu--link.desktop-menu--link__active {
    color: #ad4000; }
  @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .desktop-menu .desktop-menu--collection .desktop-menu--item.desktop-menu--item__safety .desktop-menu--collection__second .desktop-menu--item .desktop-menu--details > .desktop-menu--link:hover, .desktop-menu .desktop-menu--collection .desktop-menu--item.desktop-menu--item__safety .desktop-menu--collection__second .desktop-menu--item .desktop-menu--details > .desktop-menu--link:focus,
    .desktop-menu .desktop-menu--collection .desktop-menu--item.desktop-menu--item__safety .desktop-menu--collection__second .desktop-menu--item > .desktop-menu--link:hover,
    .desktop-menu .desktop-menu--collection .desktop-menu--item.desktop-menu--item__safety .desktop-menu--collection__second .desktop-menu--item > .desktop-menu--link:focus {
      color: #ad4000; } }
  @media screen {
    .desktop-menu .desktop-menu--collection .desktop-menu--item.desktop-menu--item__safety .desktop-menu--collection__second .desktop-menu--item .desktop-menu--details > .desktop-menu--link:active,
    .desktop-menu .desktop-menu--collection .desktop-menu--item.desktop-menu--item__safety .desktop-menu--collection__second .desktop-menu--item > .desktop-menu--link:active {
      color: #ad4000; } }
  .desktop-menu .desktop-menu--collection .desktop-menu--item.desktop-menu--item__recycling .desktop-menu--collection__second .desktop-menu--item .desktop-menu--details[open] .desktop-menu--link-label {
    color: #005523;
    border-bottom: 1px solid #005523; }
  .desktop-menu .desktop-menu--collection .desktop-menu--item.desktop-menu--item__recycling .desktop-menu--collection__second .desktop-menu--item .desktop-menu--details > .desktop-menu--link.desktop-menu--link__active,
  .desktop-menu .desktop-menu--collection .desktop-menu--item.desktop-menu--item__recycling .desktop-menu--collection__second .desktop-menu--item > .desktop-menu--link.desktop-menu--link__active {
    color: #005523; }
  @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .desktop-menu .desktop-menu--collection .desktop-menu--item.desktop-menu--item__recycling .desktop-menu--collection__second .desktop-menu--item .desktop-menu--details > .desktop-menu--link:hover, .desktop-menu .desktop-menu--collection .desktop-menu--item.desktop-menu--item__recycling .desktop-menu--collection__second .desktop-menu--item .desktop-menu--details > .desktop-menu--link:focus,
    .desktop-menu .desktop-menu--collection .desktop-menu--item.desktop-menu--item__recycling .desktop-menu--collection__second .desktop-menu--item > .desktop-menu--link:hover,
    .desktop-menu .desktop-menu--collection .desktop-menu--item.desktop-menu--item__recycling .desktop-menu--collection__second .desktop-menu--item > .desktop-menu--link:focus {
      color: #005523; } }
  @media screen {
    .desktop-menu .desktop-menu--collection .desktop-menu--item.desktop-menu--item__recycling .desktop-menu--collection__second .desktop-menu--item .desktop-menu--details > .desktop-menu--link:active,
    .desktop-menu .desktop-menu--collection .desktop-menu--item.desktop-menu--item__recycling .desktop-menu--collection__second .desktop-menu--item > .desktop-menu--link:active {
      color: #005523; } }
  .desktop-menu .desktop-menu--collection .desktop-menu--item.desktop-menu--item__knowledge .desktop-menu--collection__second .desktop-menu--item .desktop-menu--details[open] .desktop-menu--link-label {
    color: #003f97;
    border-bottom: 1px solid #003f97; }
  .desktop-menu .desktop-menu--collection .desktop-menu--item.desktop-menu--item__knowledge .desktop-menu--collection__second .desktop-menu--item .desktop-menu--details > .desktop-menu--link.desktop-menu--link__active,
  .desktop-menu .desktop-menu--collection .desktop-menu--item.desktop-menu--item__knowledge .desktop-menu--collection__second .desktop-menu--item > .desktop-menu--link.desktop-menu--link__active {
    color: #003f97; }
  @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .desktop-menu .desktop-menu--collection .desktop-menu--item.desktop-menu--item__knowledge .desktop-menu--collection__second .desktop-menu--item .desktop-menu--details > .desktop-menu--link:hover, .desktop-menu .desktop-menu--collection .desktop-menu--item.desktop-menu--item__knowledge .desktop-menu--collection__second .desktop-menu--item .desktop-menu--details > .desktop-menu--link:focus,
    .desktop-menu .desktop-menu--collection .desktop-menu--item.desktop-menu--item__knowledge .desktop-menu--collection__second .desktop-menu--item > .desktop-menu--link:hover,
    .desktop-menu .desktop-menu--collection .desktop-menu--item.desktop-menu--item__knowledge .desktop-menu--collection__second .desktop-menu--item > .desktop-menu--link:focus {
      color: #003f97; } }
  @media screen {
    .desktop-menu .desktop-menu--collection .desktop-menu--item.desktop-menu--item__knowledge .desktop-menu--collection__second .desktop-menu--item .desktop-menu--details > .desktop-menu--link:active,
    .desktop-menu .desktop-menu--collection .desktop-menu--item.desktop-menu--item__knowledge .desktop-menu--collection__second .desktop-menu--item > .desktop-menu--link:active {
      color: #003f97; } }
  .desktop-menu .desktop-menu--collection .desktop-menu--item.desktop-menu--item__education .desktop-menu--collection__second .desktop-menu--item .desktop-menu--details[open] .desktop-menu--link-label {
    color: #36003a;
    border-bottom: 1px solid #36003a; }
  .desktop-menu .desktop-menu--collection .desktop-menu--item.desktop-menu--item__education .desktop-menu--collection__second .desktop-menu--item .desktop-menu--details > .desktop-menu--link.desktop-menu--link__active,
  .desktop-menu .desktop-menu--collection .desktop-menu--item.desktop-menu--item__education .desktop-menu--collection__second .desktop-menu--item > .desktop-menu--link.desktop-menu--link__active {
    color: #36003a; }
  @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .desktop-menu .desktop-menu--collection .desktop-menu--item.desktop-menu--item__education .desktop-menu--collection__second .desktop-menu--item .desktop-menu--details > .desktop-menu--link:hover, .desktop-menu .desktop-menu--collection .desktop-menu--item.desktop-menu--item__education .desktop-menu--collection__second .desktop-menu--item .desktop-menu--details > .desktop-menu--link:focus,
    .desktop-menu .desktop-menu--collection .desktop-menu--item.desktop-menu--item__education .desktop-menu--collection__second .desktop-menu--item > .desktop-menu--link:hover,
    .desktop-menu .desktop-menu--collection .desktop-menu--item.desktop-menu--item__education .desktop-menu--collection__second .desktop-menu--item > .desktop-menu--link:focus {
      color: #36003a; } }
  @media screen {
    .desktop-menu .desktop-menu--collection .desktop-menu--item.desktop-menu--item__education .desktop-menu--collection__second .desktop-menu--item .desktop-menu--details > .desktop-menu--link:active,
    .desktop-menu .desktop-menu--collection .desktop-menu--item.desktop-menu--item__education .desktop-menu--collection__second .desktop-menu--item > .desktop-menu--link:active {
      color: #36003a; } }
  .desktop-menu .desktop-menu--collection .desktop-menu--item.desktop-menu--item__sustainability .desktop-menu--collection__second .desktop-menu--item .desktop-menu--details[open] .desktop-menu--link-label {
    color: #116214;
    border-bottom: 1px solid #116214; }
  .desktop-menu .desktop-menu--collection .desktop-menu--item.desktop-menu--item__sustainability .desktop-menu--collection__second .desktop-menu--item .desktop-menu--details > .desktop-menu--link.desktop-menu--link__active,
  .desktop-menu .desktop-menu--collection .desktop-menu--item.desktop-menu--item__sustainability .desktop-menu--collection__second .desktop-menu--item > .desktop-menu--link.desktop-menu--link__active {
    color: #116214; }
  @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .desktop-menu .desktop-menu--collection .desktop-menu--item.desktop-menu--item__sustainability .desktop-menu--collection__second .desktop-menu--item .desktop-menu--details > .desktop-menu--link:hover, .desktop-menu .desktop-menu--collection .desktop-menu--item.desktop-menu--item__sustainability .desktop-menu--collection__second .desktop-menu--item .desktop-menu--details > .desktop-menu--link:focus,
    .desktop-menu .desktop-menu--collection .desktop-menu--item.desktop-menu--item__sustainability .desktop-menu--collection__second .desktop-menu--item > .desktop-menu--link:hover,
    .desktop-menu .desktop-menu--collection .desktop-menu--item.desktop-menu--item__sustainability .desktop-menu--collection__second .desktop-menu--item > .desktop-menu--link:focus {
      color: #116214; } }
  @media screen {
    .desktop-menu .desktop-menu--collection .desktop-menu--item.desktop-menu--item__sustainability .desktop-menu--collection__second .desktop-menu--item .desktop-menu--details > .desktop-menu--link:active,
    .desktop-menu .desktop-menu--collection .desktop-menu--item.desktop-menu--item__sustainability .desktop-menu--collection__second .desktop-menu--item > .desktop-menu--link:active {
      color: #116214; } }
  .desktop-menu .desktop-menu--collection .desktop-menu--item.desktop-menu--item__default .desktop-menu--collection__second .desktop-menu--item .desktop-menu--details[open] .desktop-menu--link-label {
    color: #a00017;
    border-bottom: 1px solid #a00017; }
  .desktop-menu .desktop-menu--collection .desktop-menu--item.desktop-menu--item__default .desktop-menu--collection__second .desktop-menu--item .desktop-menu--details > .desktop-menu--link.desktop-menu--link__active,
  .desktop-menu .desktop-menu--collection .desktop-menu--item.desktop-menu--item__default .desktop-menu--collection__second .desktop-menu--item > .desktop-menu--link.desktop-menu--link__active {
    color: #a00017; }
  @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .desktop-menu .desktop-menu--collection .desktop-menu--item.desktop-menu--item__default .desktop-menu--collection__second .desktop-menu--item .desktop-menu--details > .desktop-menu--link:hover, .desktop-menu .desktop-menu--collection .desktop-menu--item.desktop-menu--item__default .desktop-menu--collection__second .desktop-menu--item .desktop-menu--details > .desktop-menu--link:focus,
    .desktop-menu .desktop-menu--collection .desktop-menu--item.desktop-menu--item__default .desktop-menu--collection__second .desktop-menu--item > .desktop-menu--link:hover,
    .desktop-menu .desktop-menu--collection .desktop-menu--item.desktop-menu--item__default .desktop-menu--collection__second .desktop-menu--item > .desktop-menu--link:focus {
      color: #a00017; } }
  @media screen {
    .desktop-menu .desktop-menu--collection .desktop-menu--item.desktop-menu--item__default .desktop-menu--collection__second .desktop-menu--item .desktop-menu--details > .desktop-menu--link:active,
    .desktop-menu .desktop-menu--collection .desktop-menu--item.desktop-menu--item__default .desktop-menu--collection__second .desktop-menu--item > .desktop-menu--link:active {
      color: #a00017; } }

.desktop-menu.desktop-menu__open .desktop-menu--collection__first {
  background: #eeeeee;
  padding-bottom: 64px; }
  @media (max-width: 1919px) {
    .desktop-menu.desktop-menu__open .desktop-menu--collection__first {
      padding-bottom: 56px; } }
  .desktop-menu.desktop-menu__open .desktop-menu--collection__first > .desktop-menu--item > .desktop-menu--link {
    padding-top: 12px;
    padding-bottom: 11px;
    font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
    font-weight: 500;
    font-style: normal; }
    @media (max-width: 1279px) {
      .desktop-menu.desktop-menu__open .desktop-menu--collection__first > .desktop-menu--item > .desktop-menu--link {
        padding-top: 13px;
        padding-bottom: 12px; } }
    @media (min-width: 1920px) {
      .desktop-menu.desktop-menu__open .desktop-menu--collection__first > .desktop-menu--item > .desktop-menu--link {
        font-size: 15px;
        line-height: 1.4; } }
    @media (min-width: 1280px) and (max-width: 1919px) {
      .desktop-menu.desktop-menu__open .desktop-menu--collection__first > .desktop-menu--item > .desktop-menu--link {
        font-size: 15px;
        line-height: 1.4; } }
    @media (min-width: 992px) and (max-width: 1279px) {
      .desktop-menu.desktop-menu__open .desktop-menu--collection__first > .desktop-menu--item > .desktop-menu--link {
        font-size: 14px;
        line-height: 1.4; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .desktop-menu.desktop-menu__open .desktop-menu--collection__first > .desktop-menu--item > .desktop-menu--link {
        font-size: 14px;
        line-height: 1.4; } }
    @media (min-width: 544px) and (max-width: 767px) {
      .desktop-menu.desktop-menu__open .desktop-menu--collection__first > .desktop-menu--item > .desktop-menu--link {
        font-size: 14px;
        line-height: 1.4; } }
    @media (min-width: 375px) and (max-width: 543px) {
      .desktop-menu.desktop-menu__open .desktop-menu--collection__first > .desktop-menu--item > .desktop-menu--link {
        font-size: 14px;
        line-height: 1.4; } }
    @media (max-width: 374px) {
      .desktop-menu.desktop-menu__open .desktop-menu--collection__first > .desktop-menu--item > .desktop-menu--link {
        font-size: 14px;
        line-height: 1.4; } }
  .desktop-menu.desktop-menu__open .desktop-menu--collection__first > .desktop-menu--item.desktop-menu--item__search .desktop-menu--link {
    padding-top: 10px;
    padding-bottom: 10px; }

@keyframes dropdown-animation {
  0% {
    transform: translate(0, 10px);
    opacity: 0; }
  100% {
    transform: translate(0, 0);
    opacity: 1; } }

.dropdown {
  font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
  font-weight: 500;
  font-style: normal; }
  .dropdown .dropdown--label {
    position: relative;
    display: flex;
    align-items: center;
    flex: 0 0 100%;
    min-width: 100%; }
  .dropdown .dropdown--summary {
    position: relative;
    display: flex;
    align-items: center; }
    @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .dropdown .dropdown--summary:hover .dropdown--label, .dropdown .dropdown--summary:focus .dropdown--label {
        color: #111111; } }
    @media screen {
      .dropdown .dropdown--summary:active .dropdown--label {
        color: #111111; } }
  .dropdown .dropdown--collection-wrapper {
    z-index: 17;
    position: absolute;
    top: calc(100% + 16px);
    left: 50%;
    right: auto;
    transform: translate(-50%, 0); }
  .dropdown .dropdown--collection {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    border-top: 2px solid #2c2c2c;
    border-radius: 4px;
    padding: 0;
    box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.08), 0 8px 16px 0 rgba(0, 0, 0, 0.12); }
  .dropdown .dropdown--item {
    display: block; }
    .dropdown .dropdown--item:last-of-type .dropdown--link {
      border-bottom: none; }
  .dropdown .dropdown--icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px; }
  .dropdown .dropdown--link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.3rem;
    padding: 16px 16px 14px;
    border-bottom: 1px solid #f1f1f2;
    color: #555555; }
    @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .dropdown .dropdown--link:hover, .dropdown .dropdown--link:focus {
        color: #111111; } }
    @media screen {
      .dropdown .dropdown--link:active {
        color: #111111; } }
  .dropdown .dropdown--link-icon {
    padding-left: 8px; }

.dropdown.dropdown__meta .dropdown--label {
  color: #555555;
  font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
  font-weight: 500;
  font-style: normal; }
  @media (min-width: 1920px) {
    .dropdown.dropdown__meta .dropdown--label {
      font-size: 15px;
      line-height: 1.4; } }
  @media (min-width: 1280px) and (max-width: 1919px) {
    .dropdown.dropdown__meta .dropdown--label {
      font-size: 15px;
      line-height: 1.4; } }
  @media (min-width: 992px) and (max-width: 1279px) {
    .dropdown.dropdown__meta .dropdown--label {
      font-size: 14px;
      line-height: 1.4; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .dropdown.dropdown__meta .dropdown--label {
      font-size: 14px;
      line-height: 1.4; } }
  @media (min-width: 544px) and (max-width: 767px) {
    .dropdown.dropdown__meta .dropdown--label {
      font-size: 14px;
      line-height: 1.4; } }
  @media (min-width: 375px) and (max-width: 543px) {
    .dropdown.dropdown__meta .dropdown--label {
      font-size: 14px;
      line-height: 1.4; } }
  @media (max-width: 374px) {
    .dropdown.dropdown__meta .dropdown--label {
      font-size: 14px;
      line-height: 1.4; } }

.dropdown.dropdown__meta .dropdown--link {
  font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
  font-weight: 500;
  font-style: normal; }
  @media (min-width: 1920px) {
    .dropdown.dropdown__meta .dropdown--link {
      font-size: 15px;
      line-height: 1.4; } }
  @media (min-width: 1280px) and (max-width: 1919px) {
    .dropdown.dropdown__meta .dropdown--link {
      font-size: 15px;
      line-height: 1.4; } }
  @media (min-width: 992px) and (max-width: 1279px) {
    .dropdown.dropdown__meta .dropdown--link {
      font-size: 14px;
      line-height: 1.4; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .dropdown.dropdown__meta .dropdown--link {
      font-size: 14px;
      line-height: 1.4; } }
  @media (min-width: 544px) and (max-width: 767px) {
    .dropdown.dropdown__meta .dropdown--link {
      font-size: 14px;
      line-height: 1.4; } }
  @media (min-width: 375px) and (max-width: 543px) {
    .dropdown.dropdown__meta .dropdown--link {
      font-size: 14px;
      line-height: 1.4; } }
  @media (max-width: 374px) {
    .dropdown.dropdown__meta .dropdown--link {
      font-size: 14px;
      line-height: 1.4; } }

.dropdown.dropdown__side-panel .dropdown--label {
  font-size: 1.5rem; }

.dropdown.dropdown__tags-list .dropdown--label {
  padding: 8px 20px;
  color: #555555;
  width: 100%;
  background: #ffffff; }

.dropdown.dropdown__tags-list .dropdown--collection-wrapper {
  left: 0;
  transform: translate(0, 0); }

.dropdown.dropdown__tags-list .dropdown--icon {
  order: -1;
  margin-right: 4px; }

.dropdown.dropdown__inline-links-menu {
  font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
  font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
  font-weight: 500;
  font-style: normal; }
  @media (min-width: 1920px) {
    .dropdown.dropdown__inline-links-menu {
      font-size: 19px;
      line-height: 1.4; } }
  @media (min-width: 1280px) and (max-width: 1919px) {
    .dropdown.dropdown__inline-links-menu {
      font-size: 17px;
      line-height: 1.4; } }
  @media (min-width: 992px) and (max-width: 1279px) {
    .dropdown.dropdown__inline-links-menu {
      font-size: 15px;
      line-height: 1.4; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .dropdown.dropdown__inline-links-menu {
      font-size: 15px;
      line-height: 1.4; } }
  @media (min-width: 544px) and (max-width: 767px) {
    .dropdown.dropdown__inline-links-menu {
      font-size: 15px;
      line-height: 1.4; } }
  @media (min-width: 375px) and (max-width: 543px) {
    .dropdown.dropdown__inline-links-menu {
      font-size: 15px;
      line-height: 1.4; } }
  @media (max-width: 374px) {
    .dropdown.dropdown__inline-links-menu {
      font-size: 15px;
      line-height: 1.4; } }
  .dropdown.dropdown__inline-links-menu .dropdown--label {
    display: flex;
    justify-content: center;
    color: #555555;
    width: 100%;
    background: #ffffff; }
  .dropdown.dropdown__inline-links-menu .dropdown--icon {
    order: -1;
    margin-right: 4px; }
  .dropdown.dropdown__inline-links-menu .dropdown--link {
    font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
    font-weight: 400;
    font-style: normal;
    font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
    font-weight: 500;
    font-style: normal; }
    @media (min-width: 1920px) {
      .dropdown.dropdown__inline-links-menu .dropdown--link {
        font-size: 19px;
        line-height: 1.4; } }
    @media (min-width: 1280px) and (max-width: 1919px) {
      .dropdown.dropdown__inline-links-menu .dropdown--link {
        font-size: 17px;
        line-height: 1.4; } }
    @media (min-width: 992px) and (max-width: 1279px) {
      .dropdown.dropdown__inline-links-menu .dropdown--link {
        font-size: 15px;
        line-height: 1.4; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .dropdown.dropdown__inline-links-menu .dropdown--link {
        font-size: 15px;
        line-height: 1.4; } }
    @media (min-width: 544px) and (max-width: 767px) {
      .dropdown.dropdown__inline-links-menu .dropdown--link {
        font-size: 15px;
        line-height: 1.4; } }
    @media (min-width: 375px) and (max-width: 543px) {
      .dropdown.dropdown__inline-links-menu .dropdown--link {
        font-size: 15px;
        line-height: 1.4; } }
    @media (max-width: 374px) {
      .dropdown.dropdown__inline-links-menu .dropdown--link {
        font-size: 15px;
        line-height: 1.4; } }

.dropdown .dropdown--link.dropdown--link__highlight {
  color: #d9213d; }

.dropdown .dropdown--link.dropdown--link__active {
  color: #d9213d; }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .dropdown .dropdown--link.dropdown--link__primary:hover .dropdown--link-icon, .dropdown .dropdown--link.dropdown--link__primary:focus .dropdown--link-icon {
    color: #d9213d; } }

@media screen {
  .dropdown .dropdown--link.dropdown--link__primary:active .dropdown--link-icon {
    color: #d9213d; } }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .dropdown .dropdown--link.dropdown--link__facebook:hover .dropdown--link-icon, .dropdown .dropdown--link.dropdown--link__facebook:focus .dropdown--link-icon {
    color: #3c5a99; } }

@media screen {
  .dropdown .dropdown--link.dropdown--link__facebook:active .dropdown--link-icon {
    color: #3c5a99; } }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .dropdown .dropdown--link.dropdown--link__whatstapp:hover .dropdown--link-icon, .dropdown .dropdown--link.dropdown--link__whatstapp:focus .dropdown--link-icon {
    color: #25d366; } }

@media screen {
  .dropdown .dropdown--link.dropdown--link__whatstapp:active .dropdown--link-icon {
    color: #25d366; } }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .dropdown .dropdown--link.dropdown--link__twitter:hover .dropdown--link-icon, .dropdown .dropdown--link.dropdown--link__twitter:focus .dropdown--link-icon {
    color: #1da1f2; } }

@media screen {
  .dropdown .dropdown--link.dropdown--link__twitter:active .dropdown--link-icon {
    color: #1da1f2; } }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .dropdown .dropdown--link.dropdown--link__linkedin:hover .dropdown--link-icon, .dropdown .dropdown--link.dropdown--link__linkedin:focus .dropdown--link-icon {
    color: #0077b5; } }

@media screen {
  .dropdown .dropdown--link.dropdown--link__linkedin:active .dropdown--link-icon {
    color: #0077b5; } }

.dropdown.dropdown__sharer {
  font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal;
  font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
  font-weight: 500;
  font-style: normal; }
  @media (min-width: 1920px) {
    .dropdown.dropdown__sharer {
      font-size: 19px;
      line-height: 1.4; } }
  @media (min-width: 1280px) and (max-width: 1919px) {
    .dropdown.dropdown__sharer {
      font-size: 17px;
      line-height: 1.4; } }
  @media (min-width: 992px) and (max-width: 1279px) {
    .dropdown.dropdown__sharer {
      font-size: 15px;
      line-height: 1.4; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .dropdown.dropdown__sharer {
      font-size: 15px;
      line-height: 1.4; } }
  @media (min-width: 544px) and (max-width: 767px) {
    .dropdown.dropdown__sharer {
      font-size: 15px;
      line-height: 1.4; } }
  @media (min-width: 375px) and (max-width: 543px) {
    .dropdown.dropdown__sharer {
      font-size: 15px;
      line-height: 1.4; } }
  @media (max-width: 374px) {
    .dropdown.dropdown__sharer {
      font-size: 15px;
      line-height: 1.4; } }
  .dropdown.dropdown__sharer .dropdown--label {
    display: flex;
    justify-content: flex-start;
    color: #555555;
    background: #ffffff;
    padding-left: 8px;
    padding-right: 8px; }
  .dropdown.dropdown__sharer .dropdown--icon {
    order: -1;
    margin-right: 8px; }
  .dropdown.dropdown__sharer .dropdown--collection {
    padding-bottom: 0; }
  .dropdown.dropdown__sharer .dropdown--link {
    align-items: center;
    justify-content: flex-start;
    font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
    font-weight: 400;
    font-style: normal;
    font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
    font-weight: 500;
    font-style: normal; }
    @media (min-width: 1920px) {
      .dropdown.dropdown__sharer .dropdown--link {
        font-size: 19px;
        line-height: 1.4; } }
    @media (min-width: 1280px) and (max-width: 1919px) {
      .dropdown.dropdown__sharer .dropdown--link {
        font-size: 17px;
        line-height: 1.4; } }
    @media (min-width: 992px) and (max-width: 1279px) {
      .dropdown.dropdown__sharer .dropdown--link {
        font-size: 15px;
        line-height: 1.4; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .dropdown.dropdown__sharer .dropdown--link {
        font-size: 15px;
        line-height: 1.4; } }
    @media (min-width: 544px) and (max-width: 767px) {
      .dropdown.dropdown__sharer .dropdown--link {
        font-size: 15px;
        line-height: 1.4; } }
    @media (min-width: 375px) and (max-width: 543px) {
      .dropdown.dropdown__sharer .dropdown--link {
        font-size: 15px;
        line-height: 1.4; } }
    @media (max-width: 374px) {
      .dropdown.dropdown__sharer .dropdown--link {
        font-size: 15px;
        line-height: 1.4; } }
    .dropdown.dropdown__sharer .dropdown--link .dropdown--link-icon {
      order: -1;
      padding: 0 8px 0 0; }
    .dropdown.dropdown__sharer .dropdown--link .dropdown--link-text {
      transform: translate(0, 1px); }

.dropdown.dropdown__min-width-meta .dropdown--collection {
  min-width: 280px; }

.dropdown[open] .dropdown--collection {
  animation: dropdown-animation 200ms ease;
  transform: translate(0, 0);
  opacity: 1; }

.dropdown[open].dropdown__meta .dropdown--label {
  color: #111111; }

.dropdown[open].dropdown__sharer .dropdown--label {
  color: #111111; }

/* stylelint-disable plugin/selector-bem-pattern */
/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible; }
  .hamburger:hover {
    opacity: 0.7; }
  .hamburger.is-active:hover {
    opacity: 0.7; }
  .hamburger.is-active .hamburger-inner,
  .hamburger.is-active .hamburger-inner::before,
  .hamburger.is-active .hamburger-inner::after {
    background-color: #555555; }

.hamburger-box {
  width: 18px;
  height: 8px;
  display: inline-block;
  position: relative; }

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -1px; }
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 18px;
    height: 2px;
    background-color: #555555;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease; }
  .hamburger-inner::before, .hamburger-inner::after {
    content: "";
    display: block; }
  .hamburger-inner::before {
    top: -3px; }
  .hamburger-inner::after {
    bottom: -3px; }

/*
   * Elastic
   */
.hamburger--elastic .hamburger-inner {
  top: 1px;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }
  .hamburger--elastic .hamburger-inner::before {
    top: 3px;
    transition: opacity 0.125s 0.275s ease; }
  .hamburger--elastic .hamburger-inner::after {
    top: 6px;
    transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55); }

.hamburger--elastic.is-active .hamburger-inner {
  transform: translate3d(0, 3px, 0) rotate(135deg);
  transition-delay: 0.075s; }
  .hamburger--elastic.is-active .hamburger-inner::before {
    transition-delay: 0s;
    opacity: 0; }
  .hamburger--elastic.is-active .hamburger-inner::after {
    transform: translate3d(0, -6px, 0) rotate(-270deg);
    transition-delay: 0.075s; }

.hamburger {
  z-index: 15;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  padding: 16px; }
  .hamburger .hamburger-inner::before {
    display: none; }

@keyframes input-slide-from-right {
  from {
    opacity: 0;
    transform: translate(16px, 0); }
  to {
    opacity: 1;
    transform: translate(0, 0); } }

@keyframes items-slide-from-right {
  from {
    opacity: 0;
    transform: translate(64px, 0); }
  to {
    opacity: 1;
    transform: translate(0, 0); } }

@keyframes submit-button-slide-from-left {
  from {
    opacity: 0;
    transform: translate(-25%, 0); }
  to {
    opacity: 1;
    transform: translate(0, 0); } }

@keyframes close-button-scale {
  from {
    opacity: 0;
    transform: scale(0.5); }
  to {
    opacity: 1;
    transform: scale(1); } }

.header-search {
  z-index: 16;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  background: #ffffff;
  box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.08), 0 8px 16px 0 rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  overflow: hidden; }
  .header-search .header-search--container {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    margin: auto; }
  .header-search .header-search--row {
    display: flex;
    flex-flow: row wrap; }
    html.ie11 .header-search .header-search--row {
      min-height: 1px; }
  .header-search .header-search--col {
    position: relative;
    min-height: 1px;
    max-width: 100%; }
  .header-search .header-search--container {
    padding-left: 0;
    padding-right: 0; }
    .header-search .header-search--container .header-search--container:not(.u-iknowwhatimdoing) {
      visibility: hidden !important; }
      .header-search .header-search--container .header-search--container:not(.u-iknowwhatimdoing)::before {
        visibility: visible;
        content: 'Nested `grid--container` detected!';
        font-size: 20px;
        line-height: 1.2;
        color: red; }
  .header-search .header-search--row {
    margin-left: 0;
    margin-right: 0; }
  .header-search .header-search--col {
    padding-left: 0;
    padding-right: 0; }
  .header-search .header-search--col {
    display: flex;
    flex-wrap: wrap;
    align-items: center; }
  .header-search .header-search--top-wrap {
    display: flex;
    flex: 1 1 auto;
    box-shadow: inset 0 -1px 0 0 #f1f1f2; }
  .header-search .header-search--submit {
    margin-left: auto;
    cursor: pointer;
    opacity: 0;
    animation: submit-button-slide-from-left 200ms 480ms cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
    padding: 0 8px; }
    @media (max-width: 767px) {
      .header-search .header-search--submit {
        padding: 0 16px; } }
    @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .header-search .header-search--submit:hover, .header-search .header-search--submit:focus {
        color: #111111; } }
    @media screen {
      .header-search .header-search--submit:active {
        color: #111111; } }
  .header-search .header-search--input {
    flex: 1 1 100%;
    padding: 18px 0 16px;
    border-bottom: 1px solid #111111;
    box-shadow: none;
    animation: input-slide-from-right 200ms 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
    transition: border 200ms ease;
    opacity: 0; }
    .header-search .header-search--input:invalid {
      border-bottom-color: transparent; }
    @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .header-search .header-search--input:hover, .header-search .header-search--input:focus {
        border-bottom-color: #111111; } }
    @media screen {
      .header-search .header-search--input:active {
        border-bottom-color: #111111; } }
  .header-search .header-search--close {
    display: flex;
    justify-content: flex-end;
    padding: 0 16px;
    cursor: pointer;
    opacity: 0;
    animation: close-button-scale 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards; }
    @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .header-search .header-search--close:hover, .header-search .header-search--close:focus {
        color: #111111; } }
    @media screen {
      .header-search .header-search--close:active {
        color: #111111; } }
  .header-search .header-search--quick-links {
    flex: 1 1 100%;
    align-items: flex-start;
    margin: 16px 0 0;
    flex-direction: column;
    padding: 0 8px 24px; }
    @media (min-width: 768px) {
      .header-search .header-search--quick-links {
        box-shadow: inset 1px 0 0 0 #f1f1f2; } }
  .header-search .header-search--title {
    margin-bottom: 8px;
    padding: 8px 0;
    font-size: 1.2rem;
    color: #2c2c2c;
    opacity: 0;
    animation: items-slide-from-right 300ms 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
    font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
    font-weight: 700;
    font-style: italic; }
    @media (max-width: 767px) {
      .header-search .header-search--title {
        padding: 8px; } }
  .header-search .header-search--link {
    color: #555555;
    display: inline-block;
    font-size: 1.4rem;
    padding: 8px 0;
    opacity: 0;
    width: 100%;
    animation: items-slide-from-right 300ms 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
    font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
    font-weight: 500;
    font-style: normal; }
    @media (min-width: 1920px) {
      .header-search .header-search--link {
        font-size: 1.5rem; } }
    @media (max-width: 767px) {
      .header-search .header-search--link {
        padding: 8px; } }
    @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .header-search .header-search--link:hover, .header-search .header-search--link:focus {
        color: #d9213d; }
        html[data-theme="association"] .header-search .header-search--link:hover, html[data-theme="association"] .header-search .header-search--link:focus {
          color: #d9213d; }
        html[data-theme="safety"] .header-search .header-search--link:hover, html[data-theme="safety"] .header-search .header-search--link:focus {
          color: #e66e00; }
        html[data-theme="recycling"] .header-search .header-search--link:hover, html[data-theme="recycling"] .header-search .header-search--link:focus {
          color: #2c834c; }
        html[data-theme="knowledge"] .header-search .header-search--link:hover, html[data-theme="knowledge"] .header-search .header-search--link:focus {
          color: #1469c9; }
        html[data-theme="education"] .header-search .header-search--link:hover, html[data-theme="education"] .header-search .header-search--link:focus {
          color: #631e65; }
        html[data-theme="sustainability"] .header-search .header-search--link:hover, html[data-theme="sustainability"] .header-search .header-search--link:focus {
          color: #1da321; }
        html[data-theme="default"] .header-search .header-search--link:hover, html[data-theme="default"] .header-search .header-search--link:focus {
          color: #d9213d; } }
    @media screen {
      .header-search .header-search--link:active {
        color: #d9213d; }
        html[data-theme="association"] .header-search .header-search--link:active {
          color: #d9213d; }
        html[data-theme="safety"] .header-search .header-search--link:active {
          color: #e66e00; }
        html[data-theme="recycling"] .header-search .header-search--link:active {
          color: #2c834c; }
        html[data-theme="knowledge"] .header-search .header-search--link:active {
          color: #1469c9; }
        html[data-theme="education"] .header-search .header-search--link:active {
          color: #631e65; }
        html[data-theme="sustainability"] .header-search .header-search--link:active {
          color: #1da321; }
        html[data-theme="default"] .header-search .header-search--link:active {
          color: #d9213d; } }
  @media (max-width: 767px) {
    .header-search .header-search--collection {
      display: flex;
      flex-wrap: wrap; } }
  @media (max-width: 767px) {
    .header-search .header-search--item {
      flex: 0 0 50%; } }

.header-search .header-search--col.header-search--col__side {
  position: relative; }
  @media (min-width: 768px) {
    .header-search .header-search--col.header-search--col__side {
      flex: 0 0 25%; }
      html.ie11 .header-search .header-search--col.header-search--col__side {
        flex-basis: auto;
        width: 25%; }
      html.ie10 .header-search .header-search--col.header-search--col__side {
        flex-basis: auto;
        width: 25%; } }
  @media (max-width: 767px) {
    .header-search .header-search--col.header-search--col__side {
      flex: 1 1 auto; } }

@media (min-width: 768px) {
  .header-search .header-search--col.header-search--col__main {
    flex: 0 0 50%; }
    html.ie11 .header-search .header-search--col.header-search--col__main {
      flex-basis: auto;
      width: 50%; }
    html.ie10 .header-search .header-search--col.header-search--col__main {
      flex-basis: auto;
      width: 50%; } }

@media (max-width: 767px) {
  .header-search .header-search--col.header-search--col__main {
    flex: 1 1 100%; } }

.header-search .header-search--col.header-search--col__tail {
  justify-content: flex-end; }
  @media (min-width: 768px) {
    .header-search .header-search--col.header-search--col__tail {
      flex: 0 0 25%; }
      html.ie11 .header-search .header-search--col.header-search--col__tail {
        flex-basis: auto;
        width: 25%; }
      html.ie10 .header-search .header-search--col.header-search--col__tail {
        flex-basis: auto;
        width: 25%; } }
  @media (max-width: 767px) {
    .header-search .header-search--col.header-search--col__tail {
      flex: 1 1 auto; } }

.inline-links-menu .inline-links-menu--content {
  padding-left: 8px;
  border-left: 2px solid #f1f1f2;
  font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal; }
  @media (min-width: 1920px) {
    .inline-links-menu .inline-links-menu--content {
      font-size: 19px;
      line-height: 1.4; } }
  @media (min-width: 1280px) and (max-width: 1919px) {
    .inline-links-menu .inline-links-menu--content {
      font-size: 17px;
      line-height: 1.4; } }
  @media (min-width: 992px) and (max-width: 1279px) {
    .inline-links-menu .inline-links-menu--content {
      font-size: 15px;
      line-height: 1.4; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .inline-links-menu .inline-links-menu--content {
      font-size: 15px;
      line-height: 1.4; } }
  @media (min-width: 544px) and (max-width: 767px) {
    .inline-links-menu .inline-links-menu--content {
      font-size: 15px;
      line-height: 1.4; } }
  @media (min-width: 375px) and (max-width: 543px) {
    .inline-links-menu .inline-links-menu--content {
      font-size: 15px;
      line-height: 1.4; } }
  @media (max-width: 374px) {
    .inline-links-menu .inline-links-menu--content {
      font-size: 15px;
      line-height: 1.4; } }

.inline-links-menu .inline-links-menu--link {
  display: inline-flex;
  align-items: center;
  padding: 8px 0;
  font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
  font-weight: 500;
  font-style: normal; }
  html[data-theme="association"] .inline-links-menu .inline-links-menu--link {
    color: #d9213d; }
  html[data-theme="safety"] .inline-links-menu .inline-links-menu--link {
    color: #e66e00; }
  html[data-theme="recycling"] .inline-links-menu .inline-links-menu--link {
    color: #2c834c; }
  html[data-theme="knowledge"] .inline-links-menu .inline-links-menu--link {
    color: #1469c9; }
  html[data-theme="education"] .inline-links-menu .inline-links-menu--link {
    color: #631e65; }
  html[data-theme="sustainability"] .inline-links-menu .inline-links-menu--link {
    color: #1da321; }
  html[data-theme="default"] .inline-links-menu .inline-links-menu--link {
    color: #d9213d; }
  @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
    html[data-theme="association"] .inline-links-menu .inline-links-menu--link:hover, html[data-theme="association"] .inline-links-menu .inline-links-menu--link:focus {
      color: #a00017; }
    html[data-theme="safety"] .inline-links-menu .inline-links-menu--link:hover, html[data-theme="safety"] .inline-links-menu .inline-links-menu--link:focus {
      color: #ad4000; }
    html[data-theme="recycling"] .inline-links-menu .inline-links-menu--link:hover, html[data-theme="recycling"] .inline-links-menu .inline-links-menu--link:focus {
      color: #005523; }
    html[data-theme="knowledge"] .inline-links-menu .inline-links-menu--link:hover, html[data-theme="knowledge"] .inline-links-menu .inline-links-menu--link:focus {
      color: #003f97; }
    html[data-theme="education"] .inline-links-menu .inline-links-menu--link:hover, html[data-theme="education"] .inline-links-menu .inline-links-menu--link:focus {
      color: #36003a; }
    html[data-theme="sustainability"] .inline-links-menu .inline-links-menu--link:hover, html[data-theme="sustainability"] .inline-links-menu .inline-links-menu--link:focus {
      color: #116214; }
    html[data-theme="default"] .inline-links-menu .inline-links-menu--link:hover, html[data-theme="default"] .inline-links-menu .inline-links-menu--link:focus {
      color: #a00017; } }
  @media screen {
    html[data-theme="association"] .inline-links-menu .inline-links-menu--link:active {
      color: #a00017; }
    html[data-theme="safety"] .inline-links-menu .inline-links-menu--link:active {
      color: #ad4000; }
    html[data-theme="recycling"] .inline-links-menu .inline-links-menu--link:active {
      color: #005523; }
    html[data-theme="knowledge"] .inline-links-menu .inline-links-menu--link:active {
      color: #003f97; }
    html[data-theme="education"] .inline-links-menu .inline-links-menu--link:active {
      color: #36003a; }
    html[data-theme="sustainability"] .inline-links-menu .inline-links-menu--link:active {
      color: #116214; }
    html[data-theme="default"] .inline-links-menu .inline-links-menu--link:active {
      color: #a00017; } }

.inline-links-menu .inline-links-menu--text {
  margin-right: 8px;
  hyphens: auto; }

.inline-links-menu .inline-links-menu--dropdown {
  position: relative; }

.inline-links-menu .inline-links-menu--title {
  font-size: 1.2rem;
  color: #2c2c2c;
  margin-bottom: 8px;
  font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
  font-weight: 700;
  font-style: italic; }

html[data-theme="association"] .inline-links-menu .inline-links-menu--link.inline-links-menu--link__is-active {
  color: #a00017; }

html[data-theme="safety"] .inline-links-menu .inline-links-menu--link.inline-links-menu--link__is-active {
  color: #ad4000; }

html[data-theme="recycling"] .inline-links-menu .inline-links-menu--link.inline-links-menu--link__is-active {
  color: #005523; }

html[data-theme="knowledge"] .inline-links-menu .inline-links-menu--link.inline-links-menu--link__is-active {
  color: #003f97; }

html[data-theme="education"] .inline-links-menu .inline-links-menu--link.inline-links-menu--link__is-active {
  color: #36003a; }

html[data-theme="sustainability"] .inline-links-menu .inline-links-menu--link.inline-links-menu--link__is-active {
  color: #116214; }

html[data-theme="default"] .inline-links-menu .inline-links-menu--link.inline-links-menu--link__is-active {
  color: #a00017; }

.mobile-menu {
  margin-top: 8px; }
  .mobile-menu .mobile-menu--main-col {
    flex: 0 0 100%; }
    html.ie11 .mobile-menu .mobile-menu--main-col {
      flex-basis: auto;
      width: 100%; }
    html.ie10 .mobile-menu .mobile-menu--main-col {
      flex-basis: auto;
      width: 100%; }
  .mobile-menu .mobile-menu--bar {
    position: relative;
    display: flex;
    box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.08), 0 8px 16px 0 rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    justify-content: space-between;
    align-content: center;
    padding-top: 16px;
    padding-bottom: 16px;
    transition: box-shadow 200ms ease; }
  .mobile-menu .mobile-menu--logo {
    display: flex;
    align-items: center;
    margin: auto; }
  .mobile-menu .mobile-menu--search {
    padding-right: 16px; }
  .mobile-menu .mobile-menu--img {
    height: 19px;
    margin-left: 50px; }

.mobile-menu.mobile-menu__no-shadow .mobile-menu--bar {
  box-shadow: none; }

.old-browser {
  background: #eeeeee; }
  .old-browser .old-browser--container {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    margin: auto; }
  .old-browser .old-browser--btn-group {
    display: flex;
    flex-flow: row wrap; }
    html.ie11 .old-browser .old-browser--btn-group {
      min-height: 1px; }
  .old-browser .old-browser--btn-col {
    position: relative;
    min-height: 1px;
    max-width: 100%; }
  .old-browser .old-browser--container {
    padding-top: 24px;
    padding-left: 24px;
    padding-bottom: 24px;
    padding-right: 24px; }
    .old-browser .old-browser--container .old-browser--container:not(.u-iknowwhatimdoing) {
      visibility: hidden !important; }
      .old-browser .old-browser--container .old-browser--container:not(.u-iknowwhatimdoing)::before {
        visibility: visible;
        content: 'Nested `grid--container` detected!';
        font-size: 20px;
        line-height: 1.2;
        color: red; }
  .old-browser .old-browser--btn-group {
    margin-top: -12px;
    margin-left: -12px;
    margin-bottom: -12px;
    margin-right: -12px; }
  .old-browser .old-browser--btn-col {
    padding-top: 12px;
    padding-left: 12px;
    padding-bottom: 12px;
    padding-right: 12px; }
  @media print {
    .old-browser {
      display: none; } }
  .old-browser .old-browser--wrapper {
    width: 100%;
    text-align: center; }
  .old-browser .old-browser--container {
    padding: 48px 0; }
  .old-browser .old-browser--paragraph {
    margin-bottom: 32px; }
  .old-browser .old-browser--title {
    margin-bottom: 16px; }
  .old-browser .old-browser--btn {
    box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.08), 0 8px 16px 0 rgba(0, 0, 0, 0.12);
    color: #ffffff;
    background: #2c834c; }
    @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .old-browser .old-browser--btn:hover, .old-browser .old-browser--btn:focus {
        box-shadow: 0 20px 32px 0 rgba(0, 0, 0, 0.2), 0 12px 16px 0 rgba(0, 0, 0, 0.12); } }
    @media screen {
      .old-browser .old-browser--btn:active {
        box-shadow: 0 20px 32px 0 rgba(0, 0, 0, 0.2), 0 12px 16px 0 rgba(0, 0, 0, 0.12); } }
  .old-browser .old-browser--btn-group {
    justify-content: center; }

.page-sharer {
  position: relative; }

.picture-element {
  display: block;
  width: 100%;
  height: auto;
  transition: filter 200ms ease; }

.picture-element.picture-element__is-blurry {
  filter: blur(12px); }

/* stylelint-disable max-nesting-depth,  plugin/selector-bem-pattern, selector-no-qualifying-type */
.rate-table {
  width: 100%; }
  .rate-table .rate-table--container {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    margin: auto; }
  .rate-table .rate-table--filters {
    display: flex;
    flex-flow: row wrap; }
    html.ie11 .rate-table .rate-table--filters {
      min-height: 1px; }
  .rate-table .rate-table--filter {
    position: relative;
    min-height: 1px;
    max-width: 100%; }
  .rate-table .rate-table--container {
    padding-top: 16px;
    padding-left: 16px;
    padding-bottom: 16px;
    padding-right: 16px; }
    .rate-table .rate-table--container .rate-table--container:not(.u-iknowwhatimdoing) {
      visibility: hidden !important; }
      .rate-table .rate-table--container .rate-table--container:not(.u-iknowwhatimdoing)::before {
        visibility: visible;
        content: 'Nested `grid--container` detected!';
        font-size: 20px;
        line-height: 1.2;
        color: red; }
  .rate-table .rate-table--filters {
    margin-top: -8px;
    margin-left: -8px;
    margin-bottom: -8px;
    margin-right: -8px; }
  .rate-table .rate-table--filter {
    padding-top: 8px;
    padding-left: 8px;
    padding-bottom: 8px;
    padding-right: 8px; }
  .rate-table .rate-table--loader {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 1 auto;
    min-height: 200px; }
  .rate-table .rate-table--content {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column; }
  .rate-table .rate-table--wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap; }
  .rate-table .rate-table--link {
    display: flex;
    align-items: center; }
  .rate-table .rate-table--link-label {
    margin-left: 8px;
    transform: translate(0, 1px);
    font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
    font-weight: 400;
    font-style: normal;
    font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
    font-weight: 500;
    font-style: normal; }
    @media (min-width: 1920px) {
      .rate-table .rate-table--link-label {
        font-size: 19px;
        line-height: 1.4; } }
    @media (min-width: 1280px) and (max-width: 1919px) {
      .rate-table .rate-table--link-label {
        font-size: 17px;
        line-height: 1.4; } }
    @media (min-width: 992px) and (max-width: 1279px) {
      .rate-table .rate-table--link-label {
        font-size: 15px;
        line-height: 1.4; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .rate-table .rate-table--link-label {
        font-size: 15px;
        line-height: 1.4; } }
    @media (min-width: 544px) and (max-width: 767px) {
      .rate-table .rate-table--link-label {
        font-size: 15px;
        line-height: 1.4; } }
    @media (min-width: 375px) and (max-width: 543px) {
      .rate-table .rate-table--link-label {
        font-size: 15px;
        line-height: 1.4; } }
    @media (max-width: 374px) {
      .rate-table .rate-table--link-label {
        font-size: 15px;
        line-height: 1.4; } }
  .rate-table .rate-table--filters {
    margin-bottom: 16px; }
    @media (min-width: 1280px) {
      .rate-table .rate-table--filters {
        justify-content: space-between; } }
  .rate-table .rate-table--filter {
    align-items: center;
    position: relative;
    min-height: 1px;
    max-width: 100%;
    position: relative;
    min-height: 1px;
    max-width: 100%; }
    @media (min-width: 1280px) {
      .rate-table .rate-table--filter {
        flex: 0 0 50%; }
        html.ie11 .rate-table .rate-table--filter {
          flex-basis: auto;
          width: 50%; }
        html.ie10 .rate-table .rate-table--filter {
          flex-basis: auto;
          width: 50%; } }
    @media (max-width: 1279px) {
      .rate-table .rate-table--filter {
        flex: 0 0 100%; }
        html.ie11 .rate-table .rate-table--filter {
          flex-basis: auto;
          width: 100%; }
        html.ie10 .rate-table .rate-table--filter {
          flex-basis: auto;
          width: 100%; } }
  .rate-table table tbody tr.VueTables__no-results,
  .rate-table table thead tr.VueTables__no-results {
    display: block;
    width: 100%;
    padding-top: 16px; }
  .rate-table table tr th:nth-child(1) {
    white-space: nowrap;
    width: 22%; }
  .rate-table table tr th:nth-last-child(5) {
    white-space: nowrap;
    width: 22%; }
  .rate-table table tr th:nth-last-child(5),
  .rate-table table tr th:nth-last-child(4),
  .rate-table table tr th:nth-last-child(3),
  .rate-table table tr th:nth-last-child(2),
  .rate-table table tr th:nth-last-child(1) {
    width: 14%; }
  .rate-table table tbody tr td:nth-last-child(2),
  .rate-table table tbody tr td:last-of-type {
    font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
    font-weight: 500;
    font-style: normal; }

.rate-table .rate-table--filter.rate-table--filter__checkbox {
  display: flex;
  padding-top: 16px;
  padding-bottom: 16px; }

.rate-table .rate-table--filter.rate-table--filter__export {
  position: relative;
  min-height: 1px;
  max-width: 100%; }
  @media (min-width: 1280px) {
    .rate-table .rate-table--filter.rate-table--filter__export {
      flex: 0 0 100%; }
      html.ie11 .rate-table .rate-table--filter.rate-table--filter__export {
        flex-basis: auto;
        width: 100%; }
      html.ie10 .rate-table .rate-table--filter.rate-table--filter__export {
        flex-basis: auto;
        width: 100%; } }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .search-input-field .search-input-field--button:hover, .search-input-field .search-input-field--button:focus {
    color: #111111; } }

@media screen {
  .search-input-field .search-input-field--button:active {
    color: #111111; } }

.side-panel {
  z-index: 9;
  position: fixed;
  top: 0;
  left: 0;
  flex-direction: column;
  display: none;
  width: 100%;
  height: 100%;
  background: #ffffff; }
  @media (max-width: 991px) {
    .side-panel {
      display: flex; } }
  .side-panel .side-panel--menu-wrap {
    overflow: auto;
    flex: 1 1 auto;
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;
    overflow-y: scroll; }
  .side-panel .side-panel--header {
    margin-top: 8px;
    padding-top: 20px;
    padding-bottom: 20px; }
  .side-panel .side-panel--header-row {
    justify-content: flex-end; }
  .side-panel .side-panel--breadcrumbs {
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    padding: 12px 0;
    border-top: 1px solid #f1f1f2;
    border-bottom: 1px solid #f1f1f2; }
  .side-panel .side-panel--back {
    display: flex;
    align-items: center;
    color: #555555; }
  .side-panel .side-panel--link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 16px 0;
    color: #555555;
    font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
    font-weight: 500;
    font-style: normal; }
    @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .side-panel .side-panel--link:hover, .side-panel .side-panel--link:focus {
        color: #d9213d; } }
    @media screen {
      .side-panel .side-panel--link:active {
        color: #d9213d; } }
    @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .side-panel .side-panel--link.side-panel--link__association:hover, .side-panel .side-panel--link.side-panel--link__association:focus {
        color: #a00017; } }
    @media screen {
      .side-panel .side-panel--link.side-panel--link__association:active {
        color: #a00017; } }
    @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .side-panel .side-panel--link.side-panel--link__safety:hover, .side-panel .side-panel--link.side-panel--link__safety:focus {
        color: #ad4000; } }
    @media screen {
      .side-panel .side-panel--link.side-panel--link__safety:active {
        color: #ad4000; } }
    @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .side-panel .side-panel--link.side-panel--link__recycling:hover, .side-panel .side-panel--link.side-panel--link__recycling:focus {
        color: #005523; } }
    @media screen {
      .side-panel .side-panel--link.side-panel--link__recycling:active {
        color: #005523; } }
    @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .side-panel .side-panel--link.side-panel--link__knowledge:hover, .side-panel .side-panel--link.side-panel--link__knowledge:focus {
        color: #003f97; } }
    @media screen {
      .side-panel .side-panel--link.side-panel--link__knowledge:active {
        color: #003f97; } }
    @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .side-panel .side-panel--link.side-panel--link__education:hover, .side-panel .side-panel--link.side-panel--link__education:focus {
        color: #36003a; } }
    @media screen {
      .side-panel .side-panel--link.side-panel--link__education:active {
        color: #36003a; } }
    @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .side-panel .side-panel--link.side-panel--link__sustainability:hover, .side-panel .side-panel--link.side-panel--link__sustainability:focus {
        color: #116214; } }
    @media screen {
      .side-panel .side-panel--link.side-panel--link__sustainability:active {
        color: #116214; } }
    @media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .side-panel .side-panel--link.side-panel--link__default:hover, .side-panel .side-panel--link.side-panel--link__default:focus {
        color: #a00017; } }
    @media screen {
      .side-panel .side-panel--link.side-panel--link__default:active {
        color: #a00017; } }
  .side-panel .side-panel--category-title {
    margin: 32px 0 0;
    color: #111111;
    font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
    font-weight: 500;
    font-style: normal; }
    @media (min-width: 1920px) {
      .side-panel .side-panel--category-title {
        font-size: 29px;
        line-height: 1.25; } }
    @media (min-width: 1280px) and (max-width: 1919px) {
      .side-panel .side-panel--category-title {
        font-size: 25px;
        line-height: 1.25; } }
    @media (min-width: 992px) and (max-width: 1279px) {
      .side-panel .side-panel--category-title {
        font-size: 25px;
        line-height: 1.25; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .side-panel .side-panel--category-title {
        font-size: 24px;
        line-height: 1.25; } }
    @media (min-width: 544px) and (max-width: 767px) {
      .side-panel .side-panel--category-title {
        font-size: 24px;
        line-height: 1.25; } }
    @media (min-width: 375px) and (max-width: 543px) {
      .side-panel .side-panel--category-title {
        font-size: 22px;
        line-height: 1.3; } }
    @media (max-width: 374px) {
      .side-panel .side-panel--category-title {
        font-size: 21px;
        line-height: 1.4; } }
  .side-panel .side-panel--group-title {
    font-size: 1.2rem;
    padding: 0 0 8px;
    margin-top: -16px;
    font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
    font-weight: 700;
    font-style: italic; }
  .side-panel .side-panel--col {
    display: flex;
    flex-direction: column;
    margin: auto;
    position: relative;
    min-height: 1px;
    max-width: 100%;
    position: relative;
    min-height: 1px;
    max-width: 100%; }
    @media (min-width: 768px) and (max-width: 991px) {
      .side-panel .side-panel--col {
        flex: 0 0 66.66667%; }
        html.ie11 .side-panel .side-panel--col {
          flex-basis: auto;
          width: 66.66667%; }
        html.ie10 .side-panel .side-panel--col {
          flex-basis: auto;
          width: 66.66667%; } }
    @media (max-width: 767px) {
      .side-panel .side-panel--col {
        flex: 0 0 100%; }
        html.ie11 .side-panel .side-panel--col {
          flex-basis: auto;
          width: 100%; }
        html.ie10 .side-panel .side-panel--col {
          flex-basis: auto;
          width: 100%; } }
  .side-panel .side-panel--group {
    display: flex;
    flex: 1 1 100%;
    min-width: 100%;
    font-size: 1.7rem;
    padding: 16px 0; }
    .side-panel .side-panel--group:first-of-type .side-panel--group-title {
      margin-top: 0;
      padding-top: 16px; }
  .side-panel .side-panel--collection {
    width: 100%; }

.side-panel {
  border-top: 4px solid transparent; }
  .side-panel.side-panel__association {
    border-top: 4px solid #d9213d; }
  .side-panel.side-panel__safety {
    border-top: 4px solid #e66e00; }
  .side-panel.side-panel__recycling {
    border-top: 4px solid #2c834c; }
  .side-panel.side-panel__knowledge {
    border-top: 4px solid #1469c9; }
  .side-panel.side-panel__education {
    border-top: 4px solid #631e65; }
  .side-panel.side-panel__sustainability {
    border-top: 4px solid #1da321; }
  .side-panel.side-panel__default {
    border-top: 4px solid #d9213d; }
  .side-panel.side-panel__root .side-panel--group:last-of-type {
    padding-top: 20px;
    font-size: 1.5rem;
    border-top: 1px solid #f1f1f2; }
    .side-panel.side-panel__root .side-panel--group:last-of-type .side-panel--link {
      padding: 12px 0; }
  .side-panel .side-panel--link.side-panel--link__active {
    color: #d9213d; }
  .side-panel .side-panel--link.side-panel--link__registration {
    color: #d9213d;
    font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
    font-weight: 500;
    font-style: normal; }
  .side-panel .side-panel--link.side-panel--link__association.side-panel--link__active {
    color: #a00017; }
  .side-panel .side-panel--link.side-panel--link__safety.side-panel--link__active {
    color: #ad4000; }
  .side-panel .side-panel--link.side-panel--link__recycling.side-panel--link__active {
    color: #005523; }
  .side-panel .side-panel--link.side-panel--link__knowledge.side-panel--link__active {
    color: #003f97; }
  .side-panel .side-panel--link.side-panel--link__education.side-panel--link__active {
    color: #36003a; }
  .side-panel .side-panel--link.side-panel--link__sustainability.side-panel--link__active {
    color: #116214; }
  .side-panel .side-panel--link.side-panel--link__default.side-panel--link__active {
    color: #a00017; }

.sticky-panel {
  z-index: 8;
  position: relative; }
  @media print {
    .sticky-panel {
      display: none; } }
  .sticky-panel .sticky-panel--wrap {
    transform: translate3d(0, 0, 0);
    pointer-events: none; }
    @media (min-width: 768px) {
      .sticky-panel .sticky-panel--wrap {
        position: absolute;
        top: 0;
        width: 100%; } }
  .sticky-panel .sticky-panel--col {
    pointer-events: initial;
    display: flex;
    flex-wrap: wrap; }
    @media (min-width: 768px) {
      .sticky-panel .sticky-panel--col {
        flex: 0 0 25%; }
        html.ie11 .sticky-panel .sticky-panel--col {
          flex-basis: auto;
          width: 25%; }
        html.ie10 .sticky-panel .sticky-panel--col {
          flex-basis: auto;
          width: 25%; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .sticky-panel .sticky-panel--col {
        flex: 0 0 33.33333%; }
        html.ie11 .sticky-panel .sticky-panel--col {
          flex-basis: auto;
          width: 33.33333%; }
        html.ie10 .sticky-panel .sticky-panel--col {
          flex-basis: auto;
          width: 33.33333%; } }
    @media (max-width: 767px) {
      .sticky-panel .sticky-panel--col {
        flex: 0 0 100%; }
        html.ie11 .sticky-panel .sticky-panel--col {
          flex-basis: auto;
          width: 100%; }
        html.ie10 .sticky-panel .sticky-panel--col {
          flex-basis: auto;
          width: 100%; } }
    @media (max-width: 767px) {
      .sticky-panel .sticky-panel--col {
        align-items: center;
        justify-content: space-between; } }
  @media (min-width: 768px) {
    .sticky-panel .sticky-panel--main {
      margin-top: 0.5em; } }
  @media (min-width: 992px) {
    .sticky-panel .sticky-panel--main {
      padding-top: 64px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .sticky-panel .sticky-panel--main {
      padding-top: 48px; } }
  .sticky-panel .sticky-panel--main {
    flex: 1 1 100%; }
    .sticky-panel .sticky-panel--main:empty {
      display: none; }
  .sticky-panel .sticky-panel--footer {
    flex: 0 1 auto; }
    @media (max-width: 767px) {
      .sticky-panel .sticky-panel--footer {
        flex: 1 1 100%; } }
  @media (min-width: 768px) {
    .sticky-panel .sticky-panel--footer {
      margin-top: 64px;
      margin-bottom: 64px; } }
  @media (max-width: 767px) {
    .sticky-panel .sticky-panel--footer {
      display: flex;
      justify-content: center;
      margin-bottom: 24px;
      margin-top: 24px;
      padding: 16px;
      box-shadow: 0 -1px 0 0 #eeeeee; } }

.sticky-panel.sticky-panel__is-sticky .sticky-panel--wrap {
  position: fixed; }

.sticky-panel.sticky-panel__is-hidden .sticky-panel--wrap {
  visibility: hidden; }

@media (max-width: 767px) {
  .sticky-panel.sticky-panel__detail-page .sticky-panel--footer {
    box-shadow: -1px 0 0 0 #eeeeee;
    margin: 0;
    padding: 0; } }

@media (max-width: 767px) {
  .sticky-panel.sticky-panel__detail-page .sticky-panel--main,
  .sticky-panel.sticky-panel__detail-page .sticky-panel--footer {
    display: flex;
    justify-content: center;
    flex: 1 1 50%; } }

.teaser-news {
  position: relative; }
  .teaser-news .teaser-news--hook {
    position: absolute;
    top: 50%; }
  .teaser-news .teaser-news--title {
    display: flex;
    justify-content: center;
    text-align: center;
    margin: 0 auto 24px;
    color: #111111;
    font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
    font-weight: 500;
    font-style: normal; }
    @media (min-width: 1920px) {
      .teaser-news .teaser-news--title {
        font-size: 36px;
        line-height: 1.25; } }
    @media (min-width: 1280px) and (max-width: 1919px) {
      .teaser-news .teaser-news--title {
        font-size: 35px;
        line-height: 1.25; } }
    @media (min-width: 992px) and (max-width: 1279px) {
      .teaser-news .teaser-news--title {
        font-size: 30px;
        line-height: 1.25; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .teaser-news .teaser-news--title {
        font-size: 28px;
        line-height: 1.25; } }
    @media (min-width: 544px) and (max-width: 767px) {
      .teaser-news .teaser-news--title {
        font-size: 27px;
        line-height: 1.25; } }
    @media (min-width: 375px) and (max-width: 543px) {
      .teaser-news .teaser-news--title {
        font-size: 26px;
        line-height: 1.25; } }
    @media (max-width: 374px) {
      .teaser-news .teaser-news--title {
        font-size: 25px;
        line-height: 1.25; } }
    @media (min-width: 768px) {
      .teaser-news .teaser-news--title {
        flex: 0 0 66.66667%; }
        html.ie11 .teaser-news .teaser-news--title {
          flex-basis: auto;
          width: 66.66667%; }
        html.ie10 .teaser-news .teaser-news--title {
          flex-basis: auto;
          width: 66.66667%; } }
    @media (max-width: 767px) {
      .teaser-news .teaser-news--title {
        flex: 0 0 100%; }
        html.ie11 .teaser-news .teaser-news--title {
          flex-basis: auto;
          width: 100%; }
        html.ie10 .teaser-news .teaser-news--title {
          flex-basis: auto;
          width: 100%; } }
  @media (max-width: 991px) {
    .teaser-news .teaser-news--cards-container {
      -webkit-overflow-scrolling: touch;
      overflow-x: hidden;
      overflow-y: scroll;
      margin: 0;
      padding: 0;
      max-width: 100%;
      overflow: auto; } }
  .teaser-news .teaser-news--list.teaser-news--list {
    display: flex;
    flex-wrap: nowrap; }
    @media print {
      .teaser-news .teaser-news--list.teaser-news--list {
        display: block; } }
  .teaser-news .teaser-news--item {
    flex: 0 0 25%; }
    html.ie11 .teaser-news .teaser-news--item {
      flex-basis: auto;
      width: 25%; }
    html.ie10 .teaser-news .teaser-news--item {
      flex-basis: auto;
      width: 25%; }
    @media print {
      .teaser-news .teaser-news--item {
        flex: 0 0 100%;
        margin-bottom: 48px; }
        html.ie11 .teaser-news .teaser-news--item {
          flex-basis: auto;
          width: 100%; }
        html.ie10 .teaser-news .teaser-news--item {
          flex-basis: auto;
          width: 100%; } }
    @media (max-width: 991px) {
      .teaser-news .teaser-news--item:first-of-type {
        padding-left: 32px; } }
  @media print and (max-width: 991px) {
    .teaser-news .teaser-news--item:first-of-type {
      padding-left: 12px; } }
    @media (max-width: 991px) {
      .teaser-news .teaser-news--item:last-of-type {
        padding-right: 32px; } }
  @media print and (max-width: 991px) {
    .teaser-news .teaser-news--item:last-of-type {
      padding-right: 12px; } }
  .teaser-news .teaser-news--link {
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 100%;
    height: 100%;
    user-select: none; }
  .teaser-news .teaser-news--controls {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto; }
  @media (max-width: 991px) {
    .teaser-news .teaser-news--buttons-container.teaser-news--buttons-container {
      display: none; } }
  @media print {
    .teaser-news .teaser-news--buttons-container.teaser-news--buttons-container {
      display: none; } }
  @media (hover: none) {
    .teaser-news .teaser-news--buttons-container.teaser-news--buttons-container {
      display: none; } }

.teaser-news .teaser-news--list.teaser-news--list__is-animated {
  transition: transform 600ms cubic-bezier(0.19, 1, 0.22, 1); }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .teaser-news .teaser-news--link.teaser-news--link__association:hover, .teaser-news .teaser-news--link.teaser-news--link__association:focus {
    color: #a00017; }
    .teaser-news .teaser-news--link.teaser-news--link__association:hover *, .teaser-news .teaser-news--link.teaser-news--link__association:focus * {
      color: #a00017; }
    .teaser-news .teaser-news--link.teaser-news--link__association:hover figure, .teaser-news .teaser-news--link.teaser-news--link__association:focus figure {
      filter: saturate(80%); } }

@media screen {
  .teaser-news .teaser-news--link.teaser-news--link__association:active {
    color: #a00017; }
    .teaser-news .teaser-news--link.teaser-news--link__association:active * {
      color: #a00017; }
    .teaser-news .teaser-news--link.teaser-news--link__association:active figure {
      filter: saturate(80%); } }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .teaser-news .teaser-news--link.teaser-news--link__safety:hover, .teaser-news .teaser-news--link.teaser-news--link__safety:focus {
    color: #ad4000; }
    .teaser-news .teaser-news--link.teaser-news--link__safety:hover *, .teaser-news .teaser-news--link.teaser-news--link__safety:focus * {
      color: #ad4000; }
    .teaser-news .teaser-news--link.teaser-news--link__safety:hover figure, .teaser-news .teaser-news--link.teaser-news--link__safety:focus figure {
      filter: saturate(80%); } }

@media screen {
  .teaser-news .teaser-news--link.teaser-news--link__safety:active {
    color: #ad4000; }
    .teaser-news .teaser-news--link.teaser-news--link__safety:active * {
      color: #ad4000; }
    .teaser-news .teaser-news--link.teaser-news--link__safety:active figure {
      filter: saturate(80%); } }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .teaser-news .teaser-news--link.teaser-news--link__recycling:hover, .teaser-news .teaser-news--link.teaser-news--link__recycling:focus {
    color: #005523; }
    .teaser-news .teaser-news--link.teaser-news--link__recycling:hover *, .teaser-news .teaser-news--link.teaser-news--link__recycling:focus * {
      color: #005523; }
    .teaser-news .teaser-news--link.teaser-news--link__recycling:hover figure, .teaser-news .teaser-news--link.teaser-news--link__recycling:focus figure {
      filter: saturate(80%); } }

@media screen {
  .teaser-news .teaser-news--link.teaser-news--link__recycling:active {
    color: #005523; }
    .teaser-news .teaser-news--link.teaser-news--link__recycling:active * {
      color: #005523; }
    .teaser-news .teaser-news--link.teaser-news--link__recycling:active figure {
      filter: saturate(80%); } }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .teaser-news .teaser-news--link.teaser-news--link__knowledge:hover, .teaser-news .teaser-news--link.teaser-news--link__knowledge:focus {
    color: #003f97; }
    .teaser-news .teaser-news--link.teaser-news--link__knowledge:hover *, .teaser-news .teaser-news--link.teaser-news--link__knowledge:focus * {
      color: #003f97; }
    .teaser-news .teaser-news--link.teaser-news--link__knowledge:hover figure, .teaser-news .teaser-news--link.teaser-news--link__knowledge:focus figure {
      filter: saturate(80%); } }

@media screen {
  .teaser-news .teaser-news--link.teaser-news--link__knowledge:active {
    color: #003f97; }
    .teaser-news .teaser-news--link.teaser-news--link__knowledge:active * {
      color: #003f97; }
    .teaser-news .teaser-news--link.teaser-news--link__knowledge:active figure {
      filter: saturate(80%); } }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .teaser-news .teaser-news--link.teaser-news--link__education:hover, .teaser-news .teaser-news--link.teaser-news--link__education:focus {
    color: #36003a; }
    .teaser-news .teaser-news--link.teaser-news--link__education:hover *, .teaser-news .teaser-news--link.teaser-news--link__education:focus * {
      color: #36003a; }
    .teaser-news .teaser-news--link.teaser-news--link__education:hover figure, .teaser-news .teaser-news--link.teaser-news--link__education:focus figure {
      filter: saturate(80%); } }

@media screen {
  .teaser-news .teaser-news--link.teaser-news--link__education:active {
    color: #36003a; }
    .teaser-news .teaser-news--link.teaser-news--link__education:active * {
      color: #36003a; }
    .teaser-news .teaser-news--link.teaser-news--link__education:active figure {
      filter: saturate(80%); } }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .teaser-news .teaser-news--link.teaser-news--link__sustainability:hover, .teaser-news .teaser-news--link.teaser-news--link__sustainability:focus {
    color: #116214; }
    .teaser-news .teaser-news--link.teaser-news--link__sustainability:hover *, .teaser-news .teaser-news--link.teaser-news--link__sustainability:focus * {
      color: #116214; }
    .teaser-news .teaser-news--link.teaser-news--link__sustainability:hover figure, .teaser-news .teaser-news--link.teaser-news--link__sustainability:focus figure {
      filter: saturate(80%); } }

@media screen {
  .teaser-news .teaser-news--link.teaser-news--link__sustainability:active {
    color: #116214; }
    .teaser-news .teaser-news--link.teaser-news--link__sustainability:active * {
      color: #116214; }
    .teaser-news .teaser-news--link.teaser-news--link__sustainability:active figure {
      filter: saturate(80%); } }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .teaser-news .teaser-news--link.teaser-news--link__default:hover, .teaser-news .teaser-news--link.teaser-news--link__default:focus {
    color: #a00017; }
    .teaser-news .teaser-news--link.teaser-news--link__default:hover *, .teaser-news .teaser-news--link.teaser-news--link__default:focus * {
      color: #a00017; }
    .teaser-news .teaser-news--link.teaser-news--link__default:hover figure, .teaser-news .teaser-news--link.teaser-news--link__default:focus figure {
      filter: saturate(80%); } }

@media screen {
  .teaser-news .teaser-news--link.teaser-news--link__default:active {
    color: #a00017; }
    .teaser-news .teaser-news--link.teaser-news--link__default:active * {
      color: #a00017; }
    .teaser-news .teaser-news--link.teaser-news--link__default:active figure {
      filter: saturate(80%); } }

._form-content {
  margin-bottom: 24px; }
  ._form-content ._form-title {
    font-weight: bold; }
  ._form-content ._form-label {
    font-size: 1.5rem;
    margin-bottom: 4px; }
  ._form-content ._error-inner {
    margin-top: 8px;
    color: #ff0033;
    font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
    font-weight: 400;
    font-style: normal; }
    @media (min-width: 1920px) {
      ._form-content ._error-inner {
        font-size: 17px;
        line-height: 1.4; } }
    @media (min-width: 1280px) and (max-width: 1919px) {
      ._form-content ._error-inner {
        font-size: 15px;
        line-height: 1.4; } }
    @media (min-width: 992px) and (max-width: 1279px) {
      ._form-content ._error-inner {
        font-size: 13px;
        line-height: 1.4; } }
    @media (min-width: 768px) and (max-width: 991px) {
      ._form-content ._error-inner {
        font-size: 13px;
        line-height: 1.4; } }
    @media (min-width: 544px) and (max-width: 767px) {
      ._form-content ._error-inner {
        font-size: 13px;
        line-height: 1.4; } }
    @media (min-width: 375px) and (max-width: 543px) {
      ._form-content ._error-inner {
        font-size: 13px;
        line-height: 1.4; } }
    @media (max-width: 374px) {
      ._form-content ._error-inner {
        font-size: 13px;
        line-height: 1.4; } }
  ._form-content ._field-wrapper {
    margin-bottom: 16px; }
  ._form-content input:not([type="checkbox"]):not([type="radio"]), ._form-content textarea {
    color: #555555;
    background-color: #ffffff;
    border-color: #555555;
    border-style: solid;
    border-radius: 4px;
    border-width: 1px;
    line-height: 1;
    transition: color 200ms ease, border-color 200ms ease, background-color 200ms ease, opacity 200ms ease, transform 200ms ease, box-shadow 200ms ease;
    font-size: 2.1rem;
    padding: 24px 24px 23px; }
    @media (min-width: 1280px) and (max-width: 1919px) {
      ._form-content input:not([type="checkbox"]):not([type="radio"]), ._form-content textarea {
        padding: 22px 12px 19px;
        font-size: 1.9rem; } }
    @media (max-width: 1279px) {
      ._form-content input:not([type="checkbox"]):not([type="radio"]), ._form-content textarea {
        padding: 20px 12px 17px;
        font-size: 1.7rem; } }
    @media (min-width: 768px) {
      ._form-content input:not([type="checkbox"]):not([type="radio"]), ._form-content textarea {
        min-width: 480px; } }
    @media (max-width: 767px) {
      ._form-content input:not([type="checkbox"]):not([type="radio"]), ._form-content textarea {
        min-width: 100%; } }
    ._form-content input:not([type="checkbox"]):not([type="radio"]):focus, ._form-content textarea:focus {
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 3px 6px 0 rgba(0, 0, 0, 0.12);
      border-color: transparent; }

.gm-style-pbc {
  pointer-events: none !important;
  z-index: 100002 !important; }

.grecaptcha-badge {
  display: none; }

/* Friendly reminder to myself: remember this file anytime you think you will be faster using 3rd party scripts */
.VueTables {
  font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
  font-weight: 400;
  font-style: normal; }
  @media (min-width: 1920px) {
    .VueTables {
      font-size: 17px;
      line-height: 1.4; } }
  @media (min-width: 1280px) and (max-width: 1919px) {
    .VueTables {
      font-size: 15px;
      line-height: 1.4; } }
  @media (min-width: 992px) and (max-width: 1279px) {
    .VueTables {
      font-size: 13px;
      line-height: 1.4; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .VueTables {
      font-size: 13px;
      line-height: 1.4; } }
  @media (min-width: 544px) and (max-width: 767px) {
    .VueTables {
      font-size: 13px;
      line-height: 1.4; } }
  @media (min-width: 375px) and (max-width: 543px) {
    .VueTables {
      font-size: 13px;
      line-height: 1.4; } }
  @media (max-width: 374px) {
    .VueTables {
      font-size: 13px;
      line-height: 1.4; } }
  .VueTables .VueTables__table {
    width: 100%;
    table-layout: fixed;
    margin-bottom: 16px;
    overflow: hidden;
    border-radius: 4px; }
    @media (max-width: 543px) {
      .VueTables .VueTables__table {
        display: block;
        overflow: auto;
        max-width: calc(100vw - 48px); } }
    @media (max-width: 374px) {
      .VueTables .VueTables__table {
        max-width: calc(100vw - 32px); } }
  .VueTables select.form-control {
    appearance: menulist;
    cursor: pointer;
    background: #eeeeee;
    margin-left: 8px; }
  .VueTables .table-responsive {
    border-bottom: 1px solid #eeeeee; }
  .VueTables .VueTables__sortable {
    cursor: pointer;
    text-align: left; }
  .VueTables .VueTables__limit,
  .VueTables .VueTables__filters-row {
    display: none; }
  .VueTables .VuePagination__count.VuePagination__count {
    text-align: center;
    font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
    font-weight: 400;
    font-style: normal; }
    @media (min-width: 1920px) {
      .VueTables .VuePagination__count.VuePagination__count {
        font-size: 17px;
        line-height: 1.4; } }
    @media (min-width: 1280px) and (max-width: 1919px) {
      .VueTables .VuePagination__count.VuePagination__count {
        font-size: 15px;
        line-height: 1.4; } }
    @media (min-width: 992px) and (max-width: 1279px) {
      .VueTables .VuePagination__count.VuePagination__count {
        font-size: 13px;
        line-height: 1.4; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .VueTables .VuePagination__count.VuePagination__count {
        font-size: 13px;
        line-height: 1.4; } }
    @media (min-width: 544px) and (max-width: 767px) {
      .VueTables .VuePagination__count.VuePagination__count {
        font-size: 13px;
        line-height: 1.4; } }
    @media (min-width: 375px) and (max-width: 543px) {
      .VueTables .VuePagination__count.VuePagination__count {
        font-size: 13px;
        line-height: 1.4; } }
    @media (max-width: 374px) {
      .VueTables .VuePagination__count.VuePagination__count {
        font-size: 13px;
        line-height: 1.4; } }
  .VueTables .VueTables__limit-field {
    padding: 8px; }
  .VueTables .VueTables__search-field {
    display: flex;
    align-items: center;
    padding: 8px 0; }
    .VueTables .VueTables__search-field > input {
      display: inline-block;
      padding: 7px 16px 6px;
      border-width: 1px;
      border-style: solid;
      border-radius: 4px;
      line-height: 1.3;
      cursor: text;
      transition: color 200ms ease, border-color 200ms ease, background-color 200ms ease, opacity 200ms ease, transform 200ms ease, box-shadow 200ms ease;
      margin-left: 16px;
      font-size: 1.6rem; }
      .VueTables .VueTables__search-field > input::-moz-placeholder {
        transition: color 200ms ease, border-color 200ms ease, background-color 200ms ease, opacity 200ms ease, transform 200ms ease, box-shadow 200ms ease; }
      .VueTables .VueTables__search-field > input:-ms-input-placeholder {
        transition: color 200ms ease, border-color 200ms ease, background-color 200ms ease, opacity 200ms ease, transform 200ms ease, box-shadow 200ms ease; }
      .VueTables .VueTables__search-field > input::-webkit-input-placeholder {
        transition: color 200ms ease, border-color 200ms ease, background-color 200ms ease, opacity 200ms ease, transform 200ms ease, box-shadow 200ms ease; }
      .VueTables .VueTables__search-field > input::placeholder {
        transition: color 200ms ease, border-color 200ms ease, background-color 200ms ease, opacity 200ms ease, transform 200ms ease, box-shadow 200ms ease; }
    .VueTables .VueTables__search-field > label {
      font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
      font-weight: 500;
      font-style: normal; }
  .VueTables .glyphicon-chevron-down {
    position: absolute; }
    .VueTables .glyphicon-chevron-down::before {
      content: '\2191'; }
  .VueTables .glyphicon-chevron-up {
    position: absolute; }
    .VueTables .glyphicon-chevron-up::before {
      content: '\2193'; }
  .VueTables thead tr:nth-child(1) th {
    padding-bottom: 0; }
  .VueTables thead tr:nth-child(2) {
    border-bottom: 1px solid #eeeeee; }
    .VueTables thead tr:nth-child(2) th {
      padding-top: 0; }
  .VueTables thead th {
    padding: 8px;
    text-align: left;
    color: #111111;
    font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
    font-weight: 500;
    font-style: normal; }
  .VueTables tbody tr:nth-child(even) {
    background: #f6f6f6; }
  .VueTables tbody tr:nth-child(odd) {
    background: #ffffff; }
  .VueTables tbody td {
    padding: 8px; }
  .VueTables .VuePagination__pagination.VuePagination__pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 32px auto 24px; }
    .VueTables .VuePagination__pagination.VuePagination__pagination .VuePagination__pagination-item {
      margin: 0;
      padding: 0 8px;
      width: auto; }
      .VueTables .VuePagination__pagination.VuePagination__pagination .VuePagination__pagination-item::before {
        display: none;
        padding: 0; }
      .VueTables .VuePagination__pagination.VuePagination__pagination .VuePagination__pagination-item.disabled a {
        color: #555555; }
      .VueTables .VuePagination__pagination.VuePagination__pagination .VuePagination__pagination-item.active a {
        font-family: "TTNorms", "Helvetica Neue", Helvetica, Arial;
        font-weight: 500;
        font-style: normal; }
        html[data-theme="association"] .VueTables .VuePagination__pagination.VuePagination__pagination .VuePagination__pagination-item.active a {
          color: #a00017; }
        html[data-theme="safety"] .VueTables .VuePagination__pagination.VuePagination__pagination .VuePagination__pagination-item.active a {
          color: #ad4000; }
        html[data-theme="recycling"] .VueTables .VuePagination__pagination.VuePagination__pagination .VuePagination__pagination-item.active a {
          color: #005523; }
        html[data-theme="knowledge"] .VueTables .VuePagination__pagination.VuePagination__pagination .VuePagination__pagination-item.active a {
          color: #003f97; }
        html[data-theme="education"] .VueTables .VuePagination__pagination.VuePagination__pagination .VuePagination__pagination-item.active a {
          color: #36003a; }
        html[data-theme="sustainability"] .VueTables .VuePagination__pagination.VuePagination__pagination .VuePagination__pagination-item.active a {
          color: #116214; }
        html[data-theme="default"] .VueTables .VuePagination__pagination.VuePagination__pagination .VuePagination__pagination-item.active a {
          color: #a00017; }
