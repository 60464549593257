.contact-person {
    // Vars

    // Support

    // Module
    & {
        display: flex;
        padding: 32px 0;

        .contact-person--image {
            overflow: hidden;
            width: 80px;
            height: 80px;
            border-radius: 100%;
            margin-right: 24px;

            > img {
                width: 100%;

                @supports (object-fit: cover) {
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

        .contact-person--name {
            @include type--h4;
        }

        .contact-person--description {
            @include type--copy-small;
        }

        .contact-person--link {
            @include type--copy-small;
        }
    }

    // Facets

    & {
        @each $name, $theme in $themes {
            &.contact-person__#{$name} {
                .contact-person--link {
                    color: get-theme-color($name, 'dark');

                    @include has-focus(true) {
                        color: get-theme-color($name);
                    }
                }
            }
        }
    }

    // States
}
