.dropdown {
    // Vars
    $bottom-offset: 16px;

    // Support
    @keyframes dropdown-animation {
        0% {
            transform: translate(0, 10px);
            opacity: 0;
        }

        100% {
            transform: translate(0, 0);
            opacity: 1;
        }
    }

    // Module
    & {
        @include ff--base-medium;

        .dropdown--label {
            position: relative;
            display: flex;
            align-items: center;
            flex: 0 0 100%;
            min-width: 100%;
        }

        .dropdown--summary {
            position: relative;
            display: flex;
            align-items: center;

            @include has-focus(true) {
                .dropdown--label {
                    color: $c--monochrome-quasi-black;
                }
            }
        }

        .dropdown--collection-wrapper {
            z-index: z-index(dropdown);
            position: absolute;
            // We need to disable a stylelint rule as it misinterprets expressions in the calc function
            // @link https://github.com/kristerkari/stylelint-scss/issues/270
            // stylelint-disable-next-line function-calc-no-invalid
            top: calc(100% + #{$bottom-offset});
            left: 50%;
            right: auto;
            transform: translate(-50%, 0);
        }

        .dropdown--collection {
            display: flex;
            flex-direction: column;

            background: $c--monochrome-white;
            border-top: 2px solid $c--monochrome-black-gray;
            border-radius: $sz--border-radius-base;
            padding: 0;
            box-shadow: $shadow-level-3;
        }

        .dropdown--item {
            display: block;

            &:last-of-type {
                .dropdown--link {
                    border-bottom: none;
                }
            }
        }

        .dropdown--icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;
        }

        .dropdown--link {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 1.3rem;
            padding: 16px 16px 14px;
            border-bottom: 1px solid $c--monochrome-paper-white;
            color: $c--monochrome-dark-gray;

            @include has-focus(true) {
                color: $c--monochrome-quasi-black;
            }
        }

        .dropdown--link-icon {
            padding-left: 8px;
        }
    }

    // Facets
    & {
        &.dropdown__meta {
            .dropdown--label {
                color: $c--monochrome-dark-gray;

                @include type--menu-meta;
            }

            .dropdown--link {
                @include type--menu-meta;
            }
        }

        &.dropdown__side-panel {
            .dropdown--label {
                font-size: 1.5rem;
            }
        }

        &.dropdown__tags-list {
            .dropdown--label {
                padding: 8px 20px;
                color: $c--monochrome-dark-gray;
                width: 100%;
                background: $c--monochrome-white;
            }

            .dropdown--collection-wrapper {
                left: 0;
                transform: translate(0, 0);
            }

            .dropdown--icon {
                order: -1;
                margin-right: 4px;
            }
        }

        &.dropdown__inline-links-menu {
            @include type--copy-small;
            @include ff--base-medium;

            .dropdown--label {
                display: flex;
                justify-content: center;
                color: $c--monochrome-dark-gray;
                width: 100%;
                background: $c--monochrome-white;
            }

            .dropdown--icon {
                order: -1;
                margin-right: 4px;
            }

            .dropdown--link {
                @include type--copy-small;
                @include ff--base-medium;
            }
        }

        .dropdown--link {
            &.dropdown--link__highlight {
                color: get-theme-color();
            }

            &.dropdown--link__active {
                color: get-theme-color();
            }

            @each $name, $color in $brands {
                &.dropdown--link__#{$name} {
                    @include has-focus(true) {
                        .dropdown--link-icon {
                            color: $color;
                        }
                    }
                }
            }
        }
    }

    &.dropdown__sharer {
        @include type--copy-small;
        @include ff--base-medium;

        .dropdown--label {
            display: flex;
            justify-content: flex-start;
            color: $c--monochrome-dark-gray;
            background: $c--monochrome-white;
            padding-left: 8px;
            padding-right: 8px;
        }

        .dropdown--icon {
            order: -1;
            margin-right: 8px;
        }

        .dropdown--collection {
            padding-bottom: 0;
        }

        .dropdown--link {
            align-items: center;
            justify-content: flex-start;

            @include type--copy-small;
            @include ff--base-medium;

            .dropdown--link-icon {
                order: -1;
                padding: 0 8px 0 0;
            }

            .dropdown--link-text {
                transform: translate(0, 1px);
            }
        }
    }

    &.dropdown__min-width-meta {
        .dropdown--collection {
            min-width: 280px;
        }
    }

    // States
    & {
        &[open] {
            .dropdown--collection {
                animation: dropdown-animation $trs--base;
                transform: translate(0, 0);
                opacity: 1;
            }

            &.dropdown__meta {
                .dropdown--label {
                    color: $c--monochrome-quasi-black;
                }
            }

            &.dropdown__sharer {
                .dropdown--label {
                    color: $c--monochrome-quasi-black;
                }
            }
        }
    }
}
