/// Field styles
///
@mixin ui-form-field--primary-all {
    // Vars

    // Support

    // Module
    & {
        display: flex;
        flex-flow: column nowrap;

        .ui-form-field--input-container {
            position: relative;
            display: flex;
        }

        .ui-form-field--title-label {
            @include ff--base-regular;
            display: flex;
            margin-bottom: 4px;

            @include type--copy-tiny;
        }
    }

    // States
    & {
        // Base
        @include ui-form-field--apply-states(()) {
            @include ui-form-field--primary-all-base;
        }

        // Required
        @include ui-form-field--apply-states(('required': true)) {
            @include ui-form-field--primary-all-required;
        }
    }
}

/// Base
///
@mixin ui-form-field--primary-all-base {
    .ui-form-field--title-label {
        color: $c--label-text;
    }
}

/// Required
///
@mixin ui-form-field--primary-all-required {
    .ui-form-field--title-label {
        @include ui-form-field--is-required;
    }
}
