.lead {
    // Vars

    // Support

    // Module
    & {
        margin-bottom: 64px;

        @include type--lead;

        @include mq('<=sm') {
            margin-bottom: 48px;
        }
    }

    // Facets

    // States
}
