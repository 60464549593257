.program-list {
    // Vars

    // Support

    // Module
    & {
        margin: 0 0 24px;

        .program-list--title {
            margin-bottom: 24px;

            @include unwrapped-title;
        }

        .program-list--item {
            display: flex;

            ~ .program-list--item {
                margin-top: 24px;
            }
        }

        .program-list--date {
            flex: 0 0 auto;
            padding-right: 16px;

            @include type--h3;
            color: $c--monochrome-dark-gray;
        }

        .program-list--info {
            display: flex;
            flex: 1 1 auto;
            flex-direction: column;
        }

        .program-list--item-title {
            @include type--h3;
            color: $c--monochrome-dark-gray;
        }
    }

    // Facets

    // States
}
