.main-navigation {
    // Vars

    // Support

    // Module
    & {
        @media print {
            display: none;
        }

        .main-navigation--desktop {
            display: none;

            @include mq('>md') {
                display: block;
            }
        }

        .main-navigation--mobile {
            display: none;

            @include mq('<=md') {
                display: block;
            }
        }
    }

    // Facets

    // States
}
