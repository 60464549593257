.search-input-field {
    // Vars

    // Support

    // Module
    & {
        .search-input-field--button {
            @include has-focus(true) {
                color: $c--monochrome-quasi-black;
            }
        }
    }

    // Facets

    // States
}
