// stylelint-disable plugin/selector-bem-pattern

////
/// Override styles for the google maps application in the recycling points search
/// See `collection-points-app`
////

.gm-style-pbc {
    pointer-events: none !important; // stylelint-disable-line declaration-no-important
    z-index: 100002 !important; // stylelint-disable-line declaration-no-important
}
