@mixin ui-form-field--primary-collection-points-search() {
    // Vars

    // Module
    & {
        .ui-form-field--input {
            $padding: 16px + 24px + 12px;

            @include type--copy-small;
            @include ff--base-medium;
            color: get-theme-color('recycling');
            border: none;
            padding-right: $padding !important; // stylelint-disable-line declaration-no-important
            padding-left: $padding !important; // stylelint-disable-line declaration-no-important
        }

        .ui-form-field--actions {
            width: 100%;
            padding: 0;
        }

        .ui-form-field--action {
            pointer-events: none;
            align-items: stretch;
            flex: 1 0 auto;
            padding: 0;
            color: get-theme-color('recycling');
        }
    }

    // States
    & {
        // Base
        @include ui-form-field--apply-states(()) {
            @include ui-form-field--primary-collection-points-search-input-base;
        }

        // filled
        @include ui-form-field--apply-states(('filled': true, 'focus': false)) {
            @include ui-form-field--primary-collection-points-search-input-filled;
        }

        // Focus
        @include ui-form-field--apply-states(('disabled': false, 'focus': true)) {
            @include ui-form-field--primary-collection-points-search-input-focus;
        }
    }
}

/// Base
///
@mixin ui-form-field--primary-collection-points-search-input-base {
    .ui-form-field--input {
        //color: $c--label-text;
        //background-color: $c--monochrome-white;
        border-color: transparent;

        .ui-form-field--action {
            color: get-theme-color('recycling');
        }

        @include placeholder {
            opacity: 1;
            color: $c--monochrome-silver;
        }
    }
}

/// Filled
///
@mixin ui-form-field--primary-collection-points-search-input-filled {
    .ui-form-field--input {
        box-shadow: none;
    }
}

/// Focus
///
@mixin ui-form-field--primary-collection-points-search-input-focus {
    .ui-form-field--input {
        box-shadow: none;
    }
}
