/// Font weights used throughout the project
///
$fw--normal: 400;
$fw--medium: 500;
$fw--bold: 700;

/// The project's font families
$ff--base: 'TTNorms';
$ff--quote: 'IBM Plex Serif';

// stylelint-disable value-keyword-case
$ff--base-stack: $ff--base, 'Helvetica Neue', Helvetica, Arial;
$ff--quote-stack: $ff--quote, 'Times New Roman', Times, serif;
