.skel {
    // vars
    //

    // base
    & {
        display: flex;
        flex-flow: column nowrap;
        width: 100%;
        min-height: 100%;
        overflow: hidden;

        @media print {
            display: block;
        }

        .skel--header {
            @each $name, $theme in $themes {
                @include at-root($themes-root-el, theme-name-to-css-selector($name)) {
                    border-top: 4px solid get-theme-color($name);
                }
            }

            @include at-root($themes-root-el, theme-name-to-css-selector('default')) {
                border-top-color: transparent;
            }
        }

        .skel--main {
            display: flex;
            flex-flow: column nowrap;
            flex: 1 0 auto;

            @media print {
                display: block;
            }
        }

        .skel--footer,
        .skel--main {
            > * { // stylelint-disable-line
                transition: opacity $trs--base;

                @media print {
                    transition: none;
                }
            }
        }

        //.skel--footer {}
        //.skel--root {}
    }

    // facets

    & {
        &.skel__is-menu-open {
            @include mq('>md') {
                .skel--footer,
                .skel--main {
                    > * { // stylelint-disable-line
                        opacity: 0.3;
                    }
                }
            }
        }
    }
}
