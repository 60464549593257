.link-list {
    // Vars

    // Support

    // Module
    & {
        padding: 16px 0 24px;

        .link-list--title {
            margin-bottom: 24px;

            @include unwrapped-title;
        }

        .link-list--collection {
            box-shadow: $shadow-level-1;
            transition: box-shadow $trs--base;
            border-radius: $sz--border-radius-base;
            overflow: hidden;

            @include has-focus(true) {
                box-shadow: $shadow-level-2;
            }
        }

        .link-list--description {
            color: $c--monochrome-dark-gray;
            margin-bottom: 24px;
        }
    }

    // Facets

    // States
}
