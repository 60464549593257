/* stylelint-disable max-nesting-depth */
.next-best-action {
    // Vars

    // Support

    // Module
    & {
        @media print {
            display: none;
        }

        .next-best-action--title {
            @include centered-section-title;
        }

        .next-best-action--title,
        .next-best-action--block-title {
            color: $c--monochrome-black-gray;
        }

        .next-best-action--block-title {
            margin-bottom: 24px;
        }

        .next-best-action--articles {
            display: flex;
            flex-wrap: wrap;
            box-shadow: $shadow-level-3;
            border-radius: $sz--border-radius-base;
            overflow: hidden;
            transition: box-shadow $trs--base;
            background: $c--monochrome-white;

            @include has-focus(true) {
                box-shadow: $shadow-level-4;
            }
        }

        .next-best-action--block {
            display: flex;
            flex: 0 0 100%;


            @include mq('>sm') {
                flex: 0 0 50%;
            }

            ~ .next-best-action--block {
                .next-best-action--link {
                    @include mq('<=sm') {
                        border-top: 1px solid $c--monochrome-paper-white;
                    }

                    @include mq('>sm') {
                        border-left: 1px solid $c--monochrome-paper-white;
                    }
                }
            }
        }

        .next-best-action--link {
            display: flex;
            flex: 1 1 100%;
            flex-direction: column;
            flex-wrap: wrap;
            padding: 40px;

            @include mq('md') {
                padding: 32px;
            }

            @include mq('<=sm') {
                padding: 32px 20px;
            }
        }

        .next-best-action--icon {
            margin-top: auto;
        }

        .next-best-action--description {
            color: $c--monochrome-dark-gray;
            margin-bottom: 32px;
        }
    }

    // Facets
    & {
        .next-best-action--block {
            @each $name, $theme in $themes {
                &.next-best-action--block__#{$name} {
                    @include mq('<=sm') {
                        border-left: 4px solid get-theme-color($name);
                    }

                    @include mq('>sm') {
                        border-top: 4px solid get-theme-color($name);
                    }

                    .next-best-action--icon {
                        color: get-theme-color($name);
                    }

                    .next-best-action--link {
                        @include has-focus(true) {
                            background: get-theme-color($name);

                            .next-best-action--description,
                            .next-best-action--block-title,
                            .next-best-action--icon {
                                color: $c--monochrome-white;
                            }
                        }
                    }
                }
            }
        }
    }

    // States
}
