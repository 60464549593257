.columns {
    // Vars

    // Support

    // Module
    & {
        display: flex;
        flex-wrap: wrap;
        padding: 64px 0;

        @include mq('>lg') {
            flex-wrap: nowrap;
            column-gap: 16px;
            justify-content: space-between;
        }

        p:last-child {
            margin-bottom: 0;
        }
    }

    // Facets

    // States
}
