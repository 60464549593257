.teaser-news {
    // Vars

    // Support

    // Module
    & {
        position: relative;

        .teaser-news--hook {
            position: absolute;
            top: 50%;
        }

        .teaser-news--title {
            @include centered-section-title;
        }

        .teaser-news--cards-container {
            @include mq('<=md') {
                @include ios-native-scrolling;
                margin: 0;
                padding: 0;
                max-width: 100%;
                overflow: auto;
            }
        }

        .teaser-news--list.teaser-news--list {
            display: flex;
            flex-wrap: nowrap;

            @media print {
                display: block;
            }
        }

        .teaser-news--item {
            @include grid--span((ratio: 3 / 12));

            @media print {
                @include grid--span((ratio: 12 / 12));
                margin-bottom: 48px;
            }

            @include mq('<=md') {
                &:first-of-type {
                    padding-left: 32px;

                    @media print {
                        padding-left: 12px;
                    }
                }

                &:last-of-type {
                    padding-right: 32px;

                    @media print {
                        padding-right: 12px;
                    }
                }
            }
        }

        .teaser-news--link {
            display: flex;
            flex-wrap: wrap;
            flex: 1 1 100%;
            height: 100%;
            user-select: none;
        }

        .teaser-news--controls {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto;
        }

        .teaser-news--buttons-container.teaser-news--buttons-container {
            @include mq('<=md') {
                display: none;
            }

            @media print {
                display: none;
            }

            @include on-touch-device {
                display: none;
            }
        }
    }

    // Facets

    .teaser-news--list {
        &.teaser-news--list__is-animated {
            transition: transform $trs--news-slider;
        }
    }

    .teaser-news--link {
        @each $name, $theme in $themes {
            &.teaser-news--link__#{$name} {
                @include has-focus(true) {
                    $theme-color: get-theme-color($name, 'dark');
                    color: $theme-color;

                    * { // stylelint-disable-line
                        color: $theme-color;
                    }

                    figure { // stylelint-disable-line
                        filter: saturate(80%);
                    }
                }
            }
        }
    }

    // States
}
