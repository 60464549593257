$themes-root-el: 'html';

@function theme-name-to-css-selector($theme) {
    @return '[data-theme="#{$theme}"]';
}

@function get-theme-color($theme-name: 'association', $facet: 'default') {
    @return map-deep-get($themes, $theme-name, $facet);
}

/**
 * Render the background primary color based on the theme received.
 */
@mixin background-color-by-theme-name($theme, $facet: 'default') {
    background: get-theme-color($theme, $facet);
}

/**
 * Use the theme primary color to style the color of a specific DOM element
 */
@mixin color-by-theme-name($theme, $facet: 'default') {
    color: get-theme-color($theme, $facet);
}

/**
 * Loop all the themes using the $themes variable,
 * if the body has that class to set the component will inherit the color property from the selected theme
 */
@mixin all-themes-color($facet: 'default', $hover-facet: false) {
    @each $name, $theme in $themes {
        @include at-root($themes-root-el, theme-name-to-css-selector($name)) {
            @include color-by-theme-name($name, $facet);

            @if $hover-facet {
                @include has-focus(true) {
                    @include color-by-theme-name($name, $hover-facet);
                }
            }
        }
    }
}

/**
 * Loop all the themes using the $themes variable
 * if the body has that class to set the component inherit the background primary color from the selected theme
 */
@mixin all-themes-background-color($facet: 'default') {
    @each $name, $theme in $themes {
        @include at-root($themes-root-el, theme-name-to-css-selector($name)) {
            @include background-color-by-theme-name($name, $facet);
        }
    }
}

/**
 * Helper function if we want to override the default themes in specific components
 */
@mixin themes-default-styles() {
    @include at-root($themes-root-el, theme-name-to-css-selector('association')) {
        @content;
    }
}

