/* stylelint-disable max-nesting-depth */
.facts-figures {
    // Vars

    // Support

    // Module
    & {
        @include grid--root((row: 'collection', cols: 'item'));
        @include grid--space((row: 'collection', cols: 'item', gutter: (48px, 32px)));

        .facts-figures--item {
            display: flex;
            align-items: stretch;
            justify-content: center;

            @include grid--col((ratio: 3 / 12, mq: '>md'));
            @include grid--col((ratio: 6 / 12, mq: '<=md'));
        }

        .facts-figures--link {
            display: flex;
            align-items: center;
            text-align: center;
            flex-direction: column;
            justify-content: stretch;
        }

        .facts-figures--number {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100%;
            box-shadow: $shadow-level-3;
            margin-bottom: 24px;
            transition: box-shadow $trs--base;
            background: $c--monochrome-white;

            @include square(112px);

            &::before {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                display: block;
                border-radius: 100%;
                border-width: 4px;
                border-style: solid;
                transform: translate(-50%, -50%) scale(0.8);
                opacity: 0;

                transition: opacity $trs--base, transform $trs--base;

                @include square(136px);
            }

            @include type--h2;
        }

        .facts-figures--paragraph {
            margin-bottom: 12px;
        }

        .facts-figures--description {
            display: flex;
            flex: 1 1 auto;
            margin: 0;
            flex-direction: column;
            color: $c--monochrome-dark-gray;

            @include type--copy;
        }

        .facts-figures--icon {
            display: flex;
            justify-content: center;
            margin-top: auto;
        }
    }

    // Facets

    & {
        @each $name, $theme in $themes {
            $current-color-name: get-theme-color($name);
            $current-color-name-dark: get-theme-color($name, 'dark');

            .facts-figures--item {
                &.facts-figures--item__#{$name} {
                    .facts-figures--icon {
                        color: $current-color-name;
                    }

                    .facts-figures--number {
                        &::before {
                            border-color: $current-color-name;
                        }
                    }

                    .facts-figures--link {
                        @include has-focus(true) {
                            .facts-figures--number {
                                &::before {
                                    transform: translate(-50%, -50%) scale(1);
                                    opacity: 0.25;
                                }
                            }

                            .facts-figures--number {
                                box-shadow: $shadow-level-3, 0 0 0 4px lighten($current-color-name, 20%);
                            }

                            .facts-figures--description,
                            .facts-figures--icon {
                                color: $current-color-name-dark;
                            }
                        }
                    }
                }
            }
        }
    }

    // States
}
