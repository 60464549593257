.page-not-found {
    // Vars

    // Support

    // Module
    & {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 1 1 100%;
        padding-top: 60px;
        padding-bottom: 20px;

        .page-not-found--title {
            margin-bottom: 12px;

            @include type--h2;
        }

        .page-not-found--text {
            text-align: center;
            margin: 0 auto;

            @include grid--span((ratio: 8 / 12, mq:'>sm'));
            @include grid--span((ratio: 12 / 12, mq:'<=sm'));
        }
    }

    // Facets

    // States
}
