/// Secondary
///
@mixin btn--secondary {
    // Vars

    // Support

    // Module

    // Facets

    // States
    & {
        // Base
        @include btn--apply-states(('disabled': false)) {
            @include btn--secondary-base;
        }

        // Focus
        @include btn--apply-states(('disabled': false, 'focus': true, 'active': false)) {
            @include btn--secondary-focus;
        }

        // Active
        @include btn--apply-states(('disabled': false, 'focus': default, 'active': true)) {
            @include btn--secondary-active;
        }

        // Disabled
        @include btn--apply-states(('disabled': true, 'focus': default)) {
            @include btn--secondary-disabled;
        }
    }
}

/// Base
///
@mixin btn--secondary-base {
    background: $c--monochrome-white;

    @each $name, $theme in $themes {
        @include at-root($themes-root-el, theme-name-to-css-selector($name)) {
            color: get-theme-color($name);
            border-color: get-theme-color($name);
        }
    }
}

/// Focus
///
@mixin btn--secondary-focus {
    box-shadow: $shadow-level-4;
    color: $c--monochrome-white;

    @each $name, $theme in $themes {
        @include at-root($themes-root-el, theme-name-to-css-selector($name)) {
            background: get-theme-color($name);
        }
    }
}

/// Active
///
@mixin btn--secondary-active {
    box-shadow: none;

    @each $name, $theme in $themes {
        @include at-root($themes-root-el, theme-name-to-css-selector($name)) {
            background: get-theme-color($name, 'dark');
            border-color: get-theme-color($name, 'dark');
        }
    }
}

/// Disabled
///
@mixin btn--secondary-disabled {
    color: $c--monochrome-light-gray;
}
