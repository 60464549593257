.cms-entity {
    // Vars

    // Support

    // Module
    & {
        width: 100%;
        padding: 64px 0;

        @include mq('<=md') {
            padding: 48px 0;
        }

        @media print {
            padding: 24px 0;
        }
    }

    // Facets
    & {
        .cms-entity--col {
            &.cms-entity--col__full-width {
                @include grid--span((ratio: 1 / 1));
            }

            &.cms-entity--col__right-aligned {
                @media screen {
                    margin-left: auto;

                    @include grid--span((ratio: 8 / 12, mq: '>sm'));
                    @include grid--span((ratio: 12 / 12, mq: '<=sm'));
                }

                @media print {
                    @include grid--span((ratio: 12 / 12));
                }
            }

            &.cms-entity--col__pull {
                @media screen {
                    @include grid--span((ratio: 1 / 12, mq: '>md'));
                }
            }
        }
    }

    // States
}
