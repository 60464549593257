.ui-accordion {
    // Vars

    // Support

    // Module
    & {
        .ui-accordion--head {
            display: flex;
            align-items: center;
            width: 100%;
            text-align: left;
            cursor: pointer;
            color: $c--monochrome-dark-gray;
            padding: 16px 0;

            @include has-focus(true) {
                .ui-accordion--title,
                .ui-accordion--icon {
                    @include all-themes-color('dark');
                }
            }
        }

        .ui-accordion--title {
            padding-left: 8px;
            transform: translate(0, 1px);

            @include type--h3;

            color: $c--monochrome-dark-gray;
        }

        .ui-accordion--body {
            overflow: hidden;
            max-height: 0;
            opacity: 0;
            padding-left: 32px;

            transition: opacity $trs--accordion;

            @media print {
                max-height: unset;
                opacity: 1;
            }

            h3 {
                padding-top: 16px;
                padding-bottom: 16px;
            }
        }

        .ui-accordion--icon {
            flex: 0 0 auto;
            align-self: baseline;

            @include all-themes-color;
        }
    }

    // Facets
    & {
        &.ui-accordion__is-open {
            > .ui-accordion--body {
                opacity: 1;
                margin-bottom: 24px;
            }
        }

        &.ui-accordion__is-animating {
            .ui-accordion--body {
                transition: opacity $trs--accordion, max-height $trs--accordion;
            }
        }
    }

    // States
}
